
const Styles = theme => ({
    root: {
        width: '56px', 
        height: '32px',
    },
    background:{
        flex: 1, 
        display: 'flex',
        alignItems: 'center', 
        padding: '4px', 
        borderRadius: '16px',
        justifyContent: 'initial',
        transition: 'all 0.3s linear'
    },
    indicator: {
        width: '24px', 
        height: '24px', 
        borderRadius: '24px',
        backgroundColor: '#ffffff'
    }
})

export default Styles;