import React, { createContext, useState, useContext, useEffect } from 'react';

export const AppContext = createContext();



const AppContextProvider = ({children}) => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [carResults, setCarResults] = useState([]);
    const [carSelected, setCarSelected] = useState([]);
    const [carArchiveSelected, setCarArchiveSelected] = useState([]);

    const [tradeCar, setTradeCar] = useState(null);

    const [searchFilters, setSearchFilters] = useState(null);
    const [popupAltert, setPopupAlert] = useState(null);
    const [financeInfo, setFinanceInfo] = useState({ isSim: false, saved:false, data: {} });
    const [dataPIInsert, setDataPIInsert] = useState(null);
    const [searchPIResult, setSearchPIResult] = useState([]);

    const [popupTerms, setPopupTerms] = useState(false);

     const [refresh, setRefresh] = useState(false);



    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <AppContext.Provider value={{
            carResults, setCarResults,
            carSelected, setCarSelected,
            carArchiveSelected, setCarArchiveSelected,
            tradeCar, setTradeCar,
            searchFilters, setSearchFilters,
            popupAltert, setPopupAlert,
            financeInfo, setFinanceInfo,
            searchPIResult, setSearchPIResult,
            popupTerms, setPopupTerms,
            dataPIInsert, setDataPIInsert,
            windowDimensions,
            refresh, setRefresh
        }}>
            {children}
        </AppContext.Provider>
    )
}

function useAppContext() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useApp must be used within an AppProvider.');
    }
    return context;
}

export { AppContextProvider, useAppContext };

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}