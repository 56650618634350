import React from 'react';
import PrivateRoute from './privateRoute';
import { Switch, Redirect, Route } from 'react-router-dom';

/**
 * PRIVATE ROUTE VIEWS
 */
import Search from '../screens/SearchView';
import SearchResults from '../screens/SearchResultView';
import SearchDetailView from '../screens/SearchDetailView';
import SearchConsultaView from '../screens/SearchConsultaView';
import ConsultaDetailView from '../screens/ConsultaDetailView';
import UserView from '../screens/UserView';
import Login from '../screens/LoginView';

export const PublicRoutes = () => {
    return(
        <Switch>
            <Route path="/login" key="login" component={Login} exact />
            <Redirect from="/" to="/login" />
        </Switch>
    )
}

export const PrivateRoutes = () => {
    return(
        <Switch>
            <PrivateRoute path="/search" key="search"  component={Search} exact />
            <PrivateRoute path="/search/results" key="search-results" component={SearchResults} exact />
            <PrivateRoute path="/search/results/detail" key="search-detail" component={SearchDetailView} exact />
            <PrivateRoute path="/archive" key="info-request"component={SearchConsultaView} exact />
            <PrivateRoute path="/archive/detail" key="info-request"component={ConsultaDetailView} exact />
            <PrivateRoute path="/user" key="user"  component={UserView} exact />
            <Redirect from="/" to="/search" />
        </Switch>
    )
}