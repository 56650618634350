import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './style';
import { makeStyles, Modal, Typography } from '@material-ui/core';
import { AppContext } from '../../context/app/app-context';


const useStyles = makeStyles(Styles);

const PopupTerms = ({
    open
}) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const { setPopupTerms } = useContext(AppContext);


    return (
        <Modal
            open={open}
            onClose={() => console.log('close')}
            aria-labelledby="dropdown-modal-title"
            aria-describedby="dropdown-modal-description"
        >
            <div className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.boxHeader}>
                        <Typography variant='h2' style={{ textTransform: 'uppercase' }}>
                            {t('terms od use')}
                        </Typography>
                        <span onClick={() => setPopupTerms(false)}>F</span>
                    </div>
                    <div className={classes.boxBody}>
                        <div className={classes.limit_area}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis posuere. Suspendisse ac mi sed nisl varius tempor ut a mauris. Vivamus quis elit ut diam sollicitudin mollis. Maecenas at ullamcorper elit. Nam condimentum auctor quam, nec gravida libero dapibus nec. Etiam iaculis lacinia libero sit amet ultrices. Aliquam pellentesque, ligula a porttitor tincidunt, nisi libero vehicula dui, ac viverra lacus sapien tincidunt lectus. Sed lacinia justo et sapien sagittis vehicula. Maecenas arcu elit, varius quis bibendum non, euismod sed ipsum. Aliquam sollicitudin, nisi vitae volutpat tincidunt, augue sem varius nisi, ut tristique diam urna sit amet leo. Donec in arcu tellus.
                            Ut eleifend, enim quis viverra ornare, massa ante consectetur libero, eu vulputate nunc magna nec dolor. Cras gravida purus sit amet diam pulvinar in convallis ipsum molestie. Fusce rhoncus tempus sapien a vestibulum. Praesent gravida fermentum nisl sit amet fermentum. Sed luctus magna a dolor ullamcorper elementum. Phasellus varius fermentum gravida. Fusce sed elit sapien, et semper dui.
                            Nulla lacinia ante nec massa suscipit in elementum leo posuere. In hac habitasse platea dictumst. Sed cursus sem eu magna mollis malesuada. Duis consectetur facilisis sem ac placerat. Nullam pellentesque ultrices tristique. Curabitur mattis varius nisl eu semper. Phasellus egestas, orci sit amet tincidunt commodo, dui felis adipiscing justo, in feugiat quam leo ut purus. Praesent et nunc libero, quis hendrerit leo. Nullam rhoncus vestibulum tellus, vitae venenatis felis lacinia non. Quisque at dui diam, sed congue erat.
                            Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras luctus, leo sagittis eleifend laoreet, lorem nibh egestas urna, at tincidunt nulla tellus non magna. Sed vestibulum neque elementum urna adipiscing eget pulvinar dolor viverra. Nulla sollicitudin, tellus a convallis iaculis, nisi turpis ullamcorper tortor, quis vestibulum mauris mauris eget odio. Morbi leo elit, tincidunt sodales tempor vel, mattis suscipit magna. Etiam bibendum tortor sed dolor dictum bibendum. Suspendisse convallis iaculis pretium. Suspendisse sit amet feugiat nulla.
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default PopupTerms;