import React from 'react';
import { makeStyles } from '@material-ui/core/';
import Styles from './style';

const useStyles = makeStyles(Styles);

const CardKPI = ({
    title, count
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <span className={classes.title}>{title ? title : '-'}</span>
            <span className={classes.count}>{count ? count : '-'}</span>
        </div>
    );
}

export default CardKPI