import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import 'moment/locale/pt';
import Styles from './style';
import { makeStyles, Typography } from '@material-ui/core';

import * as Utils from '../../../../../../service/utils';

const useStyles = makeStyles(Styles);

const ScheduleCard = ({
    data,
    onClickShowPopup,
    onClickEditSchedule
}) => {

    const refContact = useRef(null);
    const refAttendance = useRef(null);
    const classes = useStyles();
    moment.locale('pt');

    const [state, setState] = useState(null)

    useEffect(() => {
        const _date = `${data?.registryDtSchedule}T${data?.registryTimeSchedule}`
        const info = {
            name: `${data?.registryFirstName} ${data?.registryLastName}`,
            phoneStr: Utils.changePhoneStringFormat(data?.registryPhone),
            phoneNumber: Utils.changePhoneStringFormat(data?.registryPhone),
            dateStr: _date,
            date: new Date(_date),
            dateFormated: {
                day: moment(new Date(_date)).date(),
                dayWeek: DAYS[moment(new Date(_date)).weekday()],
                hour: moment(new Date(_date)).format('LT')
            }
        }
        setState(info)
    }, [data])

    if (!state) return null

    const onClickEditScheduleHandler = () => {
        onClickEditSchedule(data);
    }

    return (
        <div className={classes.root}>
            <div className={classes.row1}>
                <div style={{ flex: 1, display: 'flex', cursor: 'pointer' }} onClick={onClickEditScheduleHandler}>
                    <div className={classes.boxDay}>
                        <span>{state?.dateFormated?.day}</span>
                    </div>
                    <div className={classes.boxMonth}>
                        <span>{state?.dateFormated?.dayWeek}</span>
                    </div>
                    <div className={classes.boxHour}>
                        <span>{state?.dateFormated?.hour}</span>
                    </div>
                </div>
                <div
                    className={clsx({
                        [classes.boxCheck]: true,
                        [classes.boxCheck1]: data?.registryIdContactStatus === 1,
                        [classes.boxCheck2]: data?.registryIdContactStatus === 2,
                        [classes.boxCheck3]: data?.registryIdContactStatus === 3
                    })}
                    ref={refContact}
                    onClick={() => onClickShowPopup({ ref: refContact.current, type: 0, event: data })}>
                    <span>{ICON_STATUS[data?.registryIdContactStatus - 1]}</span>
                </div>
                <div
                    className={clsx({
                        [classes.boxFav]: true,
                        [classes.boxFavActive]: data?.registryDtCheckIn
                    })}
                    ref={refAttendance}
                    onClick={() => onClickShowPopup({ ref: refAttendance.current, type: 1, event: data })}>
                    <span></span>
                </div>
            </div>
            <div className={classes.row2}>
                <Typography variant="h2">{state?.name}</Typography>
            </div>
            <div className={classes.row3}>
                <Typography variant="h2">{state?.phoneStr}</Typography>
                <a className={classes.boxPhone} href={`tel:${state?.phoneStr}`} style={{ textDecoration: 'none' }}>
                    <span className={classes.icon_phone} ></span>
                </a>
            </div>
        </div>
    );
}

export default ScheduleCard;

const DAYS = ['SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB', 'DOM']

const ICON_STATUS = ['w', '0', 'F']

