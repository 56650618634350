const Styles = theme => ({
    root: {
        width: '100%',
        minHeight: 'calc(100vh - 128px)',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        backgroundColor: theme.palette.fafafa
    },
    header_box: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000',
    },
    header: {
        flex: 1,
        height: '79px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '6px 10px 6px 10px',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.16)',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '14px',
            color: '#ffffff',
        },
        '& :nth-child(2)': {
            '& :nth-child(1)': {
                ...theme.typography.title,
                fontSize: '24px',
                color: '#ffffff',
                textDecorationColor: '#999999'
            },
            '& :nth-child(2)': {
                ...theme.typography.body,
                fontSize: '13px',
                color: '#999999',
            },
        },
    },
    container: {
        // flex: 1,
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        padding: '15px 20px',
    },
    box_root: {
        flex: 1,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    box_container: {
        padding: '15px 0px',
        overflowY: 'auto',
    },
    box: {
        width: '100%',
        // display: "flex",
        position: 'relative',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('limit')]: {
            width: '600px',
            margin: '0px auto',
        },
    },
    row: {
        width: '100%',
        padding: '5px 0px',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        width: '100%',
        padding: '5px 0px',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    collapse: {
        width: '100%',
        overflow: 'hidden',
        '& #equipments-row': {
            width: '100%',
            ...theme.typography.body
        },
    },
    field: {
        width: '280px',
        height: '68px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        border: 'solid 1px #000000',
        borderRadius: '8px',
        backgroundColor: '#eeeeee',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '24px',
        }
    },
    cell_1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& :nth-child(1)': {
            ...theme.typography.label,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '24px',
            textTransform: 'uppercase',
        }
    },
    cell_2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        '& :nth-child(1)': {
            ...theme.typography.label,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '24px',
        }
    },
    title: {
        width: '100%',
        height: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 0px',
        '& span': {
            ...theme.typography.titleBold,
            fontSize: '13px',
            textAlign: 'center',
            margin: '0px 0px 10px 0px'
        },
    },
    labelInput: {
        width: '100%',
        height: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 0px',
        '& span': {
            ...theme.typography.label,
            fontSize: '11px',
            color: '#000000'
        },
    },
    labelText: {
        width: '100%',
        height: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 0px',
        '& span': {
            ...theme.typography.label,
            fontSize: '11px',
        },
    },
    anchor: {
        ...theme.typography.label,
        margin: '10px 0px',
        cursor: 'pointer'
    },
    inputfield: {
        width: '100%',
        margin: '10px 0px'
    },
    dropdown: {
        width: '100%',
    },
    button: {
        margin: '10px 5px'
    },
    columnLeft: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '10px',
            color: '#999999',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '14px',
        },
    },
    columnRight: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '10px',
            color: '#999999',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '14px',
        },
    },
    vertical: {
        width: '1px',
        height: '32px',
        margin: '0px 8px',
        backgroundColor: '#cecece'
    },
    circularProgress: {
        color: '#DCDCDC'
    },



    info: {
        width: '100%',
        padding: '10px 0px'
    },
    line: {
        width: '100%',
        margin: '5px 0px',
        height: '1px',
        backgroundColor: '#000000'
    },
    license: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 10px',
        '& :nth-child(1)': {
            margin: 0,
            padding: 0,
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            margin: 0,
            padding: 0,
            ...theme.typography.title,
        },
    },
    equipments: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 10px 22px 10px',
        '& #equipments-title': {
            ...theme.typography.title,
            fontSize: '14px',
            margin: '0px 0px 10px 0px'
        },
        '& #equipments-row': {
            width: '100%',
            ...theme.typography.body
        }
    },
    message: {
        ...theme.typography.body,
        color: '#999999'
    },
    body: {
        fontSize: '14px',
        ...theme.typography.body,
    },
    legal: {
        width: '14px', 
        height: '14px', 
        borderRadius: '24px', 
        backgroundColor: '#000000', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'absolute', 
        top: '26px', 
        right: '-24px',
        cursor: 'pointer',
        '& :nth-child(1)': {
            fontFamily: 'Times New Roman',
            fontSize: '11px',
            fontWeight: 'bold',
            color: '#ffffff'
        },
    }
})

export default Styles;