import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Slider from "react-slick";
import Styles from './style';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles, Typography, Divider, Grid, Hidden } from '@material-ui/core';
import DropdownText from '../../components/DropdownText';
import InputText from '../../components/InputText';
import Button from '../../components/Button';
import DropCheckModal from '../../components/DropCheckModal';
import DropRadioModal from '../../components/DropRadioModal';
import PopupAlert from '../../components/PopupAlert';
import FeaturedCarCard from '../../components/FeaturedCarCard';
import { AuthContext } from '../../context/auth/auth-context';
import { useAppContext } from '../../context/app/app-context';
import { ServiceContext } from '../../context/service/service-context';
import * as Utils from '../../service/utils';
import Loading from '../../components/Loading';
import Images from '../../assets/images'



const useStyles = makeStyles(Styles);





const SearchView = () => {

    const { t } = useTranslation();

    const { Usados } = useContext(ServiceContext);


    const [loading, setLoading] = useState(true)
    const [loadingDealer, setLoadingDealer] = useState(false)


    const { setCarResults, setCarSelected, setSearchFilters, setSearchPIResult } = useAppContext()

    const { cognitoUser, brandAccess } = useContext(AuthContext)

    const classes = useStyles();
    const history = useHistory();

    const [dealer, setDealer] = useState([])

    const [openDropCheckModal, setOpenDropCheckModal] = useState(false)
    const [dataDropCheckModal, setDataDropCheckModal] = useState(null)

    const [openDropRadioModal, setOpenDropRadioModal] = useState(false)
    const [dataDropRadioModal, setDataDropRadioModal] = useState(null)

    const [openInfoLegal, setOpenInfoLegal] = useState(false)

    const [initialDealers, setInitialDealers] = useState([])
    const [seletecdDealers, setSeletecdDealers] = useState([])
    const [seletecdDealersInfo, setSeletecdDealersInfo] = useState(null)

    const [selectedModel, setSelectedModel] = useState('none')
    const [selectedVersion, setSelectedVersion] = useState('none')
    const [selectedColor, setSelectedColor] = useState('none')
    const [selectedFuel, setSelectedFuel] = useState('none')

    const [countModels, setCountModels] = useState(null)

    const [allDealerCars, setAllDealerCars] = useState([])
    const [filteredCars, setFilteredCars] = useState([])
    const [featuredCars, setFeaturedCars] = useState([])
    const [featuredCarsIndex, setFeaturedCarsIndex] = useState(0)

    const [edgeValues, setEdgeValues] = useState({})

    const refSlick = useRef(null)



    const SLIDER_SETTINGS = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 5,
        centerMode: false,
        swipeToSlide: true,
        rows: 1
    };



    const onSeeCarsResultsHandle = () => {
        const _filtersData = {
            formikValues: formik.values,
            edgeValues: edgeValues,
            selectedModel: selectedModel,
            selectedVersion: selectedVersion,
            selectedColor: selectedColor,
            selectedFuel: selectedFuel,
            dealer: dealer,
            allDealerCars: allDealerCars,
            filteredCars: filteredCars,
            seletecdDealers: seletecdDealers,
            seletecdDealersInfo: seletecdDealersInfo
        }
        setSearchFilters(_filtersData)
        setCarResults(filteredCars)
        history.push('/search/results', filteredCars)
    }

    const onCleanCarsResultsHandle = () => {
        const _dealer = cognitoUser?.dealerStructure
        setFilteredCars(allDealerCars)
        setDefaultDealer(_dealer)
        setSelectedModel('none')
        setSelectedVersion('none')
        setSelectedColor('none')
        setSelectedFuel('none')
        setup(allDealerCars)
    }

    const onViewDetailsHandler = (data) => {
        let finance = financeInfo(data);
        setCarSelected({ ...data, financeInfoRecommended: finance });
        history.push(`/search/results/detail`, { ...data, financeInfoRecommended: finance })
    }

    const onChangeFeatureIndex = (index) => {
        if (index === -1) {
            let nextValue = featuredCarsIndex - 1
            if (nextValue < 0) {
                nextValue = featuredCars?.length - 1
            }
            setFeaturedCarsIndex(nextValue)
        } else {
            let nextValue = featuredCarsIndex + 1
            if (nextValue >= featuredCars?.length) {
                nextValue = 0
            }
            setFeaturedCarsIndex(nextValue)
        }
    }

    const onSubmitDealers = async (_data) => {
        try {
            setLoadingDealer(true)
            let allCars = []
            setSelectedDealersInfo(_data)
            setOpenDropCheckModal(false)
            setSeletecdDealers(_data)
            let ids = _data.map(it => it.id)
            if (ids.includes('-1')) {
                const params = { dealerGroup: cognitoUser.dealerGroup }
                const data = await Usados.GetVehicleList(params)
                allCars = data?.getCarsWithFilter.cars;
                setAllDealerCars(allCars)
            } else {
                const its = _data.filter(it => !it.parent)
                ids = its.map(it => it.id)
                const params = { dealers: ids }
                const data = await Usados.GetVehicleList(params)
                allCars = data?.getCarsWithFilter.cars;
                setAllDealerCars(allCars)
            }
            setFilteredCars(allCars)
            setSelectedModel('none')
            setSelectedVersion('none')
            setSelectedColor('none')
            setSelectedFuel('none')
            setup(allCars)
            setLoadingDealer(false)
        } catch (error) {
            setLoadingDealer(false)
        }
    }

    const setSelectedDealersInfo = (data) => {
        const ids = data.map(it => it.id)
        if (ids.includes('-1')) {
            setSeletecdDealersInfo({
                title: t('all'),
                count: null
            })
            return null;
        }
        const parent = data.filter(item => item[`parent`]);
        if (parent.length === 1) {
            setSeletecdDealersInfo({
                title: parent[0].name,
                count: data.length
            })
            return null;
        }
        if (data.length > 1) {
            setSeletecdDealersInfo({
                title: t('all selected'),
                count: data.length
            })
            return null;
        }
        if (data.length === 1) {
            setSeletecdDealersInfo({
                title: data[0].name,
                count: null
            })
        }
    }

    const financeInfo = (data) => {
        let financeInfoRecommended = null;
        if (data?.fee || data.fee <= 0) {
            financeInfoRecommended = {
                acceptanceRent: data?.acceptanceFee,
                capitalToFinance: data?.capitalToFinance,
                initialEntrance: data?.initialEntrance,
                monthlyRent: data?.monthlyFee,
                nrPayments: data?.nrPayments,
                legalInfo: data?.legalInfo,
                rent: data?.fee,
                taeg: data?.taeg,
                tan: data?.tan,
                mtic: data?.MTIC
            }
        }
        return financeInfoRecommended
    }


    const filterCars = (allCars) => {
        let cars = []
        if (selectedModel !== 'none') {
            cars = allCars.filter(it => it.model === selectedModel)
        } else {
            cars = allCars
        }
        let _filteredCars = cars.filter(it => {
            const conditions =
                (
                    (it.price >= formik.values.priceDownRange && it.price <= formik.values.priceUpRange) &&
                    (it.fee >= formik.values.rentDownRange && it.fee <= formik.values.rentUpRange) &&
                    (it.year >= formik.values.yearDownRange && it.year <= formik.values.yearUpRange) &&
                    (it.km >= formik.values.kmDownRange && it.km <= formik.values.kmUpRange)
                )
            return (
                conditions
            )
        });
        setFilteredCars(_filteredCars)
    }



    const setup = (data) => {
        if (data && data.length) {
            const priceMin = Utils.getMin(data, 'price')
            const priceMax = Utils.getMax(data, 'price')
            const feeMin = Utils.getMin(data, 'fee')
            const feeMax = Utils.getMax(data, 'fee')
            const yearMin = Utils.getMin(data, 'year')
            const yearMax = Utils.getMax(data, 'year')
            const kmMin = Utils.getMin(data, 'km')
            const kmMax = Utils.getMax(data, 'km')
            formik.setFieldValue('priceDownRange', priceMin)
            formik.setFieldValue('priceUpRange', priceMax)
            formik.setFieldValue('rentDownRange', feeMin)
            formik.setFieldValue('rentUpRange', feeMax)
            formik.setFieldValue('yearDownRange', yearMin)
            formik.setFieldValue('yearUpRange', yearMax)
            formik.setFieldValue('kmDownRange', kmMin)
            formik.setFieldValue('kmUpRange', kmMax)
            setEdgeValues({ priceMin, priceMax, feeMin, feeMax, yearMin, yearMax, kmMin, kmMax })
            setLoading(false)
        }
    }


    const onDropdownClick = (key) => {
        let _data = null;
        switch (key) {
            case 'dealer':
                _data = {
                    title: 'dealer',
                    options: dealer.map(it => {
                        const dt = {
                            id: it.id,
                            name: it.name,
                            children: it.dealers
                        }
                        return dt
                    }),
                    selected: seletecdDealers,
                    default: initialDealers
                }
                setDataDropCheckModal(_data)
                setOpenDropCheckModal(true)
                break;

            case 'model':
                let allModels = []
                if (selectedModel === 'none' && selectedFuel === 'none') {
                    allModels = Utils.getUniquevalues(allDealerCars, 'model')
                } else {
                    allModels = Utils.getUniquevalues(filteredCars, 'model')
                }

                _data = {
                    title: 'model',
                    options: allModels.map(it => {
                        const dt = {
                            id: it,
                            name: it
                        }
                        return dt
                    }),
                    selected: selectedModel,
                    countCars: filteredCars?.length
                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            case 'version':
                let allVersions = []
                if (selectedModel === 'none') {
                    allVersions = Utils.getUniquevalues(allDealerCars, 'version')
                } else {
                    allVersions = Utils.getUniquevalues(filteredCars, 'version')
                }
                _data = {
                    title: 'version',
                    options: allVersions.map(it => {
                        const dt = {
                            id: it,
                            name: it
                        }
                        return dt
                    }),
                    selected: selectedVersion,
                    countCars: filteredCars?.length

                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            case 'color':
                let allColors = []
                if (selectedModel === 'none') {
                    allColors = Utils.getUniquevalues(allDealerCars, 'color')
                } else {
                    allColors = Utils.getUniquevalues(filteredCars, 'color')
                }
                _data = {
                    title: 'color',
                    options: allColors.map(it => {
                        const dt = {
                            id: it,
                            name: it
                        }
                        return dt
                    }),
                    selected: selectedColor,
                    countCars: filteredCars?.length

                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            case 'fuel':
                let allFuel = []
                if (selectedModel === 'none') {
                    allFuel = Utils.getUniquevalues(allDealerCars, 'fuel')
                } else {
                    allFuel = Utils.getUniquevalues(filteredCars, 'fuel')
                }
                _data = {
                    title: 'fuel',
                    options: allFuel.map(it => {
                        const dt = {
                            id: it,
                            name: it
                        }
                        return dt
                    }),
                    selected: selectedFuel,
                    countCars: filteredCars?.length

                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            default:
                break;
        }
    }


    const onSelectRadio = (data) => {
        setTimeout(() => {
            let _filteredCars = []
            let _filteredModelCars = []
            switch (data.name) {
                case 'model':
                    setSelectedModel(data.value)
                    _filteredModelCars = allDealerCars.filter(it => data.value !== 'none' ? it.model === data.value : true)
                    _filteredCars = _filteredModelCars.filter(it => {
                        return (
                            (selectedFuel !== 'none') ? it.fuel === selectedFuel : true
                        )
                    })
                    setSelectedVersion('none')
                    setSelectedColor('none')
                    setFilteredCars(_filteredCars)
                    setup(_filteredCars)
                    break;

                case 'version':
                    setSelectedVersion(data.value)
                    _filteredModelCars = allDealerCars.filter(it => (selectedModel !== 'none') ? it.model === selectedModel : true)
                    _filteredCars = _filteredModelCars.filter(it => {
                        return (
                            (data.value !== 'none') ? it.version === data.value : true &&
                                (selectedColor !== 'none') ? it.color === selectedColor : true &&
                                    (selectedFuel !== 'none') ? it.fuel === selectedFuel : true
                        )
                    })
                    setFilteredCars(_filteredCars)
                    setup(_filteredCars)
                    break;

                case 'color':
                    setSelectedColor(data.value)
                    _filteredModelCars = allDealerCars.filter(it => (selectedModel !== 'none') ? it.model === selectedModel : true)
                    _filteredCars = _filteredModelCars.filter(it => {
                        return (
                            (selectedVersion !== 'none') ? it.version === selectedVersion : true &&
                                (data.value !== 'none') ? it.color === data.value : true &&
                                    (selectedFuel !== 'none') ? it.fuel === selectedFuel : true
                        )
                    })
                    setFilteredCars(_filteredCars)
                    setup(_filteredCars)
                    break;

                case 'fuel':
                    setSelectedFuel(data.value)
                    _filteredModelCars = allDealerCars.filter(it => (selectedModel !== 'none') ? it.model === selectedModel : true)
                    _filteredCars = _filteredModelCars.filter(it => {
                        return (
                            (data.value !== 'none') ? it.fuel === data.value : true &&
                                (selectedVersion !== 'none') ? it.version === selectedVersion : true &&
                                    (selectedColor !== 'none') ? it.color === selectedColor : true
                        )
                    })
                    setFilteredCars(_filteredCars)
                    setup(_filteredCars)
                    break;

                default:
                    break;
            }
        }, 200);

    }

    const onSlickGoToPrev = () => {
        refSlick.current.slickPrev()
    }

    const onSlickGoToNext = () => {
        refSlick.current.slickNext()
    }

    const formik = useFormik({
        initialValues: {
            plateNumber: '',
            priceDownRange: '',
            priceUpRange: '',
            rentDownRange: '',
            rentUpRange: '',
            yearDownRange: '',
            yearUpRange: '',
            kmDownRange: '',
            kmUpRange: '',
        },
        validationSchema: Yup.object().shape({
            plateNumber: Yup.string()
                .required(t('required field')),
        }),
        onSubmit: async values => {
            try {
                const _plateNumber = values.plateNumber.replaceAll(/\s/g, '')
                const params = { plateNumber: _plateNumber }
                const data = await Usados.GetVehicleList(params)
                setCarResults(data?.getCarsWithFilter.cars)
                const _filtersData = {
                    formikValues: formik.values,
                    edgeValues: edgeValues,
                    selectedModel: selectedModel,
                    selectedVersion: selectedVersion,
                    selectedColor: selectedColor,
                    selectedFuel: selectedFuel,
                    dealer: dealer,
                    allDealerCars: allDealerCars,
                    filteredCars: filteredCars,
                    seletecdDealers: seletecdDealers,
                    seletecdDealersInfo: seletecdDealersInfo
                }
                setSearchFilters(_filtersData)
                history.push('/search/results', data?.getCarsWithFilter.cars)
            } catch (error) { }
        },
    });

    const setFormikField = (field, value) => {
        formik.setFieldValue(field, value)
    }

    const setDefaultDealer = (_delaer) => {
        if (_delaer?.length) {
            const options = _delaer[0].dealers?.map(it => it)
            const parent = {
                id: _delaer[0].id,
                name: _delaer[0].name,
                children: _delaer[0].dealers
            }
            setSeletecdDealers([parent, ...options])
            setInitialDealers([parent, ...options])
            setSeletecdDealersInfo({
                title: _delaer[0].name,
                count: _delaer[0].dealers.length + 0
            })
        }
    }


    useEffect(() => {
        if (selectedModel !== 'none') {
            const count = Utils.countByProperty(allDealerCars, 'model', selectedModel)
            setCountModels(count)
        } else {
            setCountModels(null)
        }
    }, [allDealerCars, selectedModel])


    useEffect(() => {
        const _dealer = cognitoUser?.dealerStructure
        setDefaultDealer(_dealer)
        setDealer(_dealer)
        // Get all cars 
        const fecthAllDealerCars = async () => {
            try {
                setLoading(true)
                const params = { dealers: cognitoUser.dealerStructure[0].id }
                const data = await Usados.GetVehicleList(params)
                const allCars = data?.getCarsWithFilter.cars;
                setAllDealerCars(allCars)
                setFilteredCars(allCars)
                const _featuredCars = allCars?.length > 10 ? allCars.slice(0, 10) : allCars
                setFeaturedCars(_featuredCars)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fecthAllDealerCars()

    }, [cognitoUser])


    useEffect(() => {
        setup(allDealerCars);
    }, [allDealerCars])

    useEffect(() => {
        setSearchPIResult([]);
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (formik.touched.plateNumber) {
                formik.setTouched('plateNumber', false)
            }
        }, 2000);
        
    }, [formik.errors.plateNumber, formik.touched.plateNumber])

    if (loading) return <Loading />


    return (
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
            <div className={classes.root}>

                <div className={classes.header}>
                    <div className={classes.headerBox}
                        style={{ background: `url(${brandAccess === 1 ? Images.search_header_toyota : Images.search_header_lexus}) no-repeat center center` }}>
                        <div className={classes.headerBoxOverlay} />
                        <Typography variant="h1" style={{ zIndex: 10 }}>{t('search-header-title')}</Typography>
                    </div>
                </div>

                <div className={classes.box}>
                    <div className={classes.box_container}>
                        <div className={classes.container}>
                            <div className={classes.searchRegistrationBox}>
                                <Typography variant="subtitle1">{t('search-license-place')}</Typography>
                                <div className={classes.searchRegistrationBoxInner}>
                                    <InputText
                                        id="plateNumber"
                                        name="plateNumber"
                                        type='car'
                                        onChange={formik.handleChange}
                                        value={formik.values.plateNumber}
                                        styles={classes.inputTextRegCar}
                                        error={formik.errors.plateNumber && formik.touched.plateNumber}
                                        onBlur={() => console.log('Blur')}
                                    />
                                    <Button styles={classes.buttonSubmitSearch} type='submit'>{t('search-platenumber-button')}</Button>
                                </div>
                            </div>
                            <div className={classes.boxDivider}>
                                <Divider variant='middle' />
                                <span>{t('search-form-or')}</span>
                                <Divider variant='middle' />
                            </div>
                            <div className={classes.dropdownBox}>
                                <Grid container justify='center' alignItems='center'>
                                    <Grid item xs={12} >
                                        <DropdownText
                                            id="dealer"
                                            title={t('search-form-dealer')}
                                            data={seletecdDealersInfo?.title}
                                            count={seletecdDealersInfo?.count}
                                            onClick={onDropdownClick} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DropdownText
                                            id="model"
                                            title={t('search-form-model')}
                                            data={selectedModel}
                                            count={countModels}
                                            onClick={onDropdownClick} />
                                    </Grid>
                                    {
                                        selectedModel === 'none' ? null : (
                                            <React.Fragment>
                                                <Grid item xs={12}>
                                                    <DropdownText
                                                        id="version"
                                                        children={true}
                                                        title={t('search-form-version')}
                                                        data={selectedVersion}
                                                        onClick={onDropdownClick} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DropdownText
                                                        id="color"
                                                        children={true}
                                                        title={t('search-form-color')}
                                                        data={selectedColor}
                                                        onClick={onDropdownClick} />
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    }
                                    <Grid item xs={12}>
                                        <DropdownText
                                            id="fuel"
                                            title={t('search-form-fuel')}
                                            data={selectedFuel}
                                            onClick={onDropdownClick} />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={clsx([classes.priceBox, classes.boxItem])}>
                                <Typography variant="subtitle1">{t('search-form-price')}</Typography>
                                <div className={classes.boxItemInner}>
                                    <InputText
                                        id="priceDownRange"
                                        name="priceDownRange"
                                        type='eur'
                                        value={formik.values.priceDownRange}
                                        minValue={edgeValues.priceMin}
                                        maxValue={edgeValues.priceMax}
                                        onChange={value => { setFormikField('priceDownRange', value) }}
                                        onBlur={() => {
                                            if (formik.values.priceDownRange > formik.values.priceUpRange) {
                                                setFormikField('priceDownRange', formik.values.priceUpRange)
                                            }
                                            filterCars(allDealerCars)
                                        }}
                                        styles={classes.inputText}
                                    />
                                    <Typography variant="h6">{t('search-form-until')}</Typography>
                                    <InputText
                                        id="priceUpRange"
                                        name="priceUpRange"
                                        type='eur'
                                        value={formik.values.priceUpRange}
                                        minValue={edgeValues.priceMin}
                                        maxValue={edgeValues.priceMax}
                                        onChange={value => { setFormikField('priceUpRange', value) }}
                                        onBlur={() => {
                                            if (formik.values.priceUpRange < formik.values.priceDownRange) {
                                                setFormikField('priceUpRange', formik.values.priceDownRange)
                                            }
                                            filterCars(allDealerCars)
                                        }}
                                        styles={classes.inputText}
                                    />
                                </div>
                            </div>
                            <div className={clsx([classes.incomeBox, classes.boxItem])}>
                                <Typography variant="subtitle1">{t('search-form-rent')}</Typography>
                                <div className={classes.boxItemInner}>
                                    <InputText
                                        id="rentDownRange"
                                        name="rentDownRange"
                                        type='eur'
                                        value={formik.values.rentDownRange}
                                        minValue={edgeValues.feeMin}
                                        maxValue={edgeValues.feeMax}
                                        onChange={value => { setFormikField('rentDownRange', value) }}
                                        onBlur={() => {
                                            if (formik.values.rentDownRange > formik.values.rentUpRange) {
                                                setFormikField('rentDownRange', formik.values.rentUpRange)
                                            }
                                            filterCars(allDealerCars)
                                        }}
                                        styles={classes.inputText}
                                    />
                                    <Typography variant="h6">{t('search-form-until')}</Typography>
                                    <InputText
                                        id="rentUpRange"
                                        name="rentUpRange"
                                        type='eur'
                                        value={formik.values.rentUpRange}
                                        minValue={edgeValues.feeMin}
                                        maxValue={edgeValues.feeMax}
                                        onChange={value => { setFormikField('rentUpRange', value) }}
                                        onBlur={() => {
                                            if (formik.values.rentUpRange < formik.values.rentDownRange) {
                                                setFormikField('rentUpRange', formik.values.rentDownRange)
                                            }
                                            filterCars(allDealerCars)
                                        }}
                                        styles={classes.inputText}
                                    />
                                </div>
                            </div>
                            <div className={clsx([classes.yearBox, classes.boxItem])}>
                                <Typography variant="subtitle1">{t('search-form-year')}</Typography>
                                <div className={classes.boxItemInner}>
                                    <InputText
                                        id="yearDownRange"
                                        name="yearDownRange"
                                        type='year'
                                        value={formik.values.yearDownRange}
                                        minValue={edgeValues.yearMin}
                                        maxValue={edgeValues.yearMax}
                                        onChange={value => { setFormikField('yearDownRange', value) }}
                                        onBlur={() => {
                                            if (formik.values.yearDownRange > formik.values.yearUpRange) {
                                                setFormikField('yearDownRange', formik.values.yearUpRange)
                                            }
                                            filterCars(allDealerCars)
                                        }}
                                        styles={classes.inputText}
                                    />
                                    <Typography variant="h6">{t('search-form-to')}</Typography>
                                    <InputText
                                        id="yearUpRange"
                                        name="yearUpRange"
                                        type='year'
                                        value={formik.values.yearUpRange}
                                        minValue={edgeValues.yearMin}
                                        maxValue={edgeValues.yearMax}
                                        onChange={value => { setFormikField('yearUpRange', value) }}
                                        onBlur={() => {
                                            if (formik.values.yearUpRange < formik.values.yearDownRange) {
                                                setFormikField('yearUpRange', formik.values.yearDownRange)
                                            }
                                            filterCars(allDealerCars)
                                        }}
                                        styles={classes.inputText}
                                    />
                                </div>
                            </div>
                            <div className={clsx([classes.kmBox, classes.boxItem])}>
                                <Typography variant="subtitle1">{t('search-form-km')}</Typography>
                                <div className={classes.boxItemInner}>
                                    <InputText
                                        id="kmDownRange"
                                        name="kmDownRange"
                                        type='km'
                                        value={formik.values.kmDownRange}
                                        minValue={edgeValues.kmMin}
                                        maxValue={edgeValues.kmMax}
                                        onChange={value => { setFormikField('kmDownRange', value) }}
                                        onBlur={() => {
                                            if (formik.values.kmDownRange > formik.values.kmUpRange) {
                                                setFormikField('kmDownRange', formik.values.kmUpRange)
                                            }
                                            filterCars(allDealerCars)
                                        }}
                                        styles={classes.inputText}
                                    />
                                    <Typography variant="h6">{t('search-form-until')}</Typography>
                                    <InputText
                                        id="kmUpRange"
                                        name="kmUpRange"
                                        type='km'
                                        value={formik.values.kmUpRange}
                                        minValue={edgeValues.kmMin}
                                        maxValue={edgeValues.kmMax}
                                        onChange={value => { setFormikField('kmUpRange', value) }}
                                        onBlur={() => {
                                            if (formik.values.kmUpRange < formik.values.kmDownRange) {
                                                setFormikField('kmUpRange', formik.values.kmDownRange)
                                            }
                                            filterCars(allDealerCars)
                                        }}
                                        styles={classes.inputText}
                                    />
                                </div>
                            </div>

                            <div className={classes.searchActionsBox}>
                                <Button
                                    variant='outlined'
                                    styles={classes.buttonSearch}
                                    onClick={onCleanCarsResultsHandle}
                                >
                                    {t('search-form-clean')}
                                </Button>
                                <Button
                                    styles={classes.buttonSearch}
                                    onClick={onSeeCarsResultsHandle}
                                >
                                    {t('search-form-see')}&nbsp;({filteredCars?.length})
                                </Button>
                            </div>
                        </div>

                        <Hidden smUp>
                            <div className={classes.moreRecentBox}>
                                <Typography variant="h1">{t('search-more-recent')}</Typography>
                                <FeaturedCarCard
                                    data={featuredCars[featuredCarsIndex]}
                                    actions={{
                                        onView: onViewDetailsHandler
                                    }}
                                    onSeeLegalInfo={() => setOpenInfoLegal(true)}
                                />
                                <div className={classes.iconBox}>
                                    <span onClick={() => onChangeFeatureIndex(-1)}>n</span>
                                    <span onClick={() => onChangeFeatureIndex(1)}>{'{'}</span>
                                </div>
                            </div>
                        </Hidden>

                        <Hidden xsDown>
                            <div className={classes.moreRecentBox}>
                                <Typography variant="h1">{t('search-more-recent')}</Typography>
                                <div className={classes.moreRecentBoxSlickBox}>
                                    <Slider {...SLIDER_SETTINGS} className="more-recent-slider" ref={refSlick}>
                                        {featuredCars.map(it => (
                                            <FeaturedCarCard
                                                key={it?.plateNumber}
                                                data={it}
                                                actions={{
                                                    onView: onViewDetailsHandler
                                                }}
                                                onSeeLegalInfo={() => setOpenInfoLegal(true)}
                                            />
                                        ))}
                                    </Slider>

                                </div>
                                <div className={classes.iconBox}>
                                    <span onClick={() => onSlickGoToPrev()}>n</span>
                                    <span onClick={() => onSlickGoToNext()}>{'{'}</span>
                                </div>
                            </div>
                        </Hidden>


                    </div>
                </div>



            </div>
            {openDropCheckModal ? (
                <DropCheckModal
                    open={openDropCheckModal}
                    data={dataDropCheckModal}
                    onSubmit={data => {
                        onSubmitDealers(data)
                    }}
                    onClose={() => setOpenDropCheckModal(false)} />
            ) : null}
            {openDropRadioModal ? (
                <DropRadioModal
                    open={openDropRadioModal}
                    data={dataDropRadioModal}
                    hiddenButton={false}
                    onSelect={data => onSelectRadio(data)}
                    onClose={() => setOpenDropRadioModal(false)} />
            ) : null}
            {openInfoLegal ? (
                <PopupAlert
                    type='ALERT_TEXT'
                    open={openInfoLegal}
                    onClose={() => setOpenInfoLegal(false)}
                />
            ) : null}
            {loadingDealer && <Loading />}
        </form>
    );
}

export default SearchView;