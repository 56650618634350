
import React, { useContext, useEffect, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core/';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import CardKPI from '../../../../components/CardKPI';
import DropRadioModal from '../../../../components/DropRadioModal';
import InputText from '../../../../components/InputText';

import * as Utils from '../../../../service/utils';
import { ServiceContext } from '../../../../context/service/service-context';
import { useAppContext } from '../../../../context/app/app-context';
import { AuthContext } from '../../../../context/auth/auth-context';
import { useNavBar } from '../../';

import Styles from './style';

const useStyles = makeStyles(Styles);

const PerformanceSubview = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { Usados } = useContext(ServiceContext);
    const { setRequestStatus } = useNavBar();
    const { cognitoUser, brandAccess } = useContext(AuthContext);
    const [kpi, setKpi] = useState(null);

    const [sort, setSort] = useState(FILTER[0]);
    const [openDropRadioModal, setOpenDropRadioModal] = useState(false);
    const [dataDropRadioModal, setDataDropRadioModal] = useState(null);


    useEffect(() => {
        getKPIList(Utils.getFormattedDate(new Date()), Utils.getFormattedDate(new Date()))
    }, []);

    const getKPIList = async (dtStart, dtEnd) => {
        const params = {
            login: cognitoUser.login,
            idBrand: brandAccess,
            dtStart: dtStart,
            dtEnd: dtEnd
        }
        setRequestStatus(true);
        let result = await Usados.GetKPIList(params);
        setKpi(result);
        setRequestStatus(false);
    }

    const serarchKPIs = useFormik({
        initialValues: {
            dtStart: Utils.getFormattedDate(new Date()),
            dtEnd: Utils.getFormattedDate(new Date()),
        },
        onSubmit: async (values) => {
            try {
                const params = {
                    ...values,
                    login: cognitoUser.login,
                    idBrand: brandAccess,
                }
                setRequestStatus(true);
                let result = await Usados.GetKPIList(params);
                setKpi(result);
                setRequestStatus(false);
            } catch (error) {
                console.log(error);
                setRequestStatus(false);
                throw error
            }
        },
    });

    const onDropdownClick = () => {
        const _data = {
            title: 'Filtro',
            options: FILTER,
            selected: sort.id
        }
        setDataDropRadioModal(_data);
        setOpenDropRadioModal(true);
    }

    const buildGrid = (column, data) => {
        let array = [];
        let count = 0;
        let list = [{ type: 'btn', name: 'Aceder ao Power BI' }];

        for (var i = 0; i < data?.length; i++) {
            list = Utils.AppendArrayHandler(list, data[i]);
        }
        for (var i = 0; i < column; i++) {
            array.push([]);
        }
        for (var i = 0; i < list.length; i++) {
            if (count >= column)
                count = 0;
            array[count].push(list[i]);
            count += 1
        }
        return array.map((prop, index) => {
            return (
                <div key={index} className={classes.grid_column}>
                    {
                        prop.map((prop, index) => {
                            return (
                                prop?.type == "btn"
                                    ?
                                    <div key={index} className={classes.card_btn} onClick={() => window.open(kpi?.dashboard)}>
                                        <span>{prop.name ? prop.name : '-'}</span>
                                    </div>
                                    :
                                    <CardKPI key={index} title={prop.name} count={prop.value} />
                            )
                        })
                    }
                </div>
            )
        })
    }

    const onSelectRadio = (data) => {
        const _sort = FILTER.find(it => it.id === data.value)
        if (data.value == 0) {
            getKPIList(Utils.getFormattedDate(new Date()), Utils.getFormattedDate(new Date()));
        } else if (data.value == 1) {
            getKPIList(Utils.getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 7))), Utils.getFormattedDate(new Date()));
        }
        else if (data.value == 2) {
            getKPIList(Utils.getFormattedDate(new Date(new Date().setMonth(new Date().getMonth() - 1))), Utils.getFormattedDate(new Date()));
        }
        else if (data.value == 3) {
            getKPIList(Utils.getFormattedDate(new Date(new Date().setMonth(new Date().getMonth() - 12))), Utils.getFormattedDate(new Date()));
        }
        else {
            getKPIList(serarchKPIs.values.dtStart, serarchKPIs.values.dtEnd);
        }
        setSort(_sort);
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <span className={classes.header_title}>{cognitoUser?.displayName}</span>
                <span className={classes.header_subtitle}>{`${cognitoUser?.dealer.designation} - ${cognitoUser?.dealer.postalcodeExtension}`}</span>
            </div>

            {/* <div className={classes.nav}>
                <div className={classes.nav_child1}></div>
                <div className={classes.nav_child2}>
                    <div className={classes.actionsBoxRight} onClick={} >
                        <Typography variant="h6">{t(sort?.name)}</Typography>
                        <span className={classes.icon_nav} style={{ marginRight: '0px' }}>7</span>
                    </div>
                </div>
                <div className={classes.nav_child3}></div>
            </div> */}

            <div className={classes.nav}>
                <div className={classes.nav_child1}></div>
                <div className={classes.nav_actions}>
                    <div className={classes.nav_child2}>
                        <div className={classes.actionsBoxRight} onClick={onDropdownClick} >
                            <Typography variant="h6">{t(sort?.name)}</Typography>
                            <span className={classes.icon_nav} style={{ marginRight: '0px' }}>7</span>
                        </div>
                    </div>
                    <div className={classes.nav_child3}></div>
                </div>
                <div className={classes.nav_child3}></div>
            </div>



            <div className={classes.box}>
                <div className={classes.container}>
                    <div className={classes.area}>
                        <div className={classes.limit_area}>
                            <div className={classes.title}>{sort?.id == "4" ? "Indicadores de entre:" : "Indicadores de Hoje"}</div>

                            <div style={{ width: '100%' }} hidden={sort?.id == "4" ? false : true}>
                                <form onSubmit={serarchKPIs.handleSubmit} className={classes.field}>
                                    <div className={classes.time_row}>
                                        <div className={classes.date_cell}>
                                            <InputText
                                                id="dtStart"
                                                type="date"
                                                value={serarchKPIs.values.dtStart}
                                                onChange={serarchKPIs.handleChange}
                                                styles={classes.inputfield}
                                                required
                                            />
                                        </div>
                                        <span className={classes.text_cell}>e</span>
                                        <div className={classes.date_cell}>
                                            <InputText
                                                id="dtEnd"
                                                type="date"
                                                value={serarchKPIs.values.dtEnd}
                                                onChange={serarchKPIs.handleChange}
                                                styles={classes.inputfield}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.row}>
                                        <Button type="submit" styles={classes.button}>Submeter</Button>
                                    </div>
                                </form>
                                <div style={{ width: '100%', height: '1px', margin: '20px 0px', backgroundColor: '#cecece' }}></div>
                            </div>

                            <div className={classes.grid}>
                                {
                                    kpi?.kpiList ? buildGrid(isWidthUp('sm', props.width) ? 3 : 2, kpi?.kpiList) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openDropRadioModal ? (
                <DropRadioModal
                    open={openDropRadioModal}
                    data={dataDropRadioModal}
                    hiddenDefault={true}
                    hiddenButton={false}
                    onSelect={data => onSelectRadio(data)}
                    onClose={() => setOpenDropRadioModal(false)} />
            ) : null}
        </div>
    );
}

export default withWidth()(PerformanceSubview);


const KPI_LIST = [
    [{
        id: 1,
        type: 'card',
        name: 'Número de PIs  - Toyota Caetano Portugal, S.A.',
        value: '65'
    },
    {
        id: 2,
        type: 'card',
        name: 'Número de PIs  - Caetano - Auto (Porto) ou Exemplo de uma String Mesmo Muito Longa',
        value: '43'
    },
    {
        id: 3,
        type: 'card',
        name: 'Nº Total de PIs Vendedor',
        value: '12'
    }],
    [
        {
            id: 4,
            type: 'card',
            name: 'Média Diária PI Vendedor',
            value: '5'
        },
        {
            id: 5,
            type: 'card',
            name: 'Reservas efectuadas',
            value: '5'
        }
    ],
    [{
        id: 6,
        type: 'card',
        name: 'Retoma (Nº de Simulações submetidas)',
        value: '0'
    },
    {
        id: 7,
        type: 'card',
        name: 'Financiamento (Nº de Simulações submetidas)',
        value: '25'
    },]
];

const FILTER = [
    { id: "0", name: 'Hoje' },
    { id: "1", name: 'Última semana' },
    { id: "2", name: 'Último mês' },
    { id: "3", name: 'Último ano' },
    { id: "4", name: 'Definir' },
]