import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Toolbar } from '@material-ui/core';
import MenuItem from './components/menu-item';
import MenuUser from '../MenuUser'
import ToolbarMenuStructer from './components/config/toolbar-menu.config';
import { AuthContext } from './../../context/auth/auth-context';
import Styles from './style';
import Images from '../../assets/images'

const useStyles = makeStyles(Styles);

const ToolbarMenu = () => {

    
    const classes = useStyles();
    const history = useHistory();

    const [openUserMenu, setOpenUserMenu] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const { brandAccess } = useContext(AuthContext);




    const onClickMenuHandler = (data) => {
        history.push(data.menu.path)
    }

    return (
        <React.Fragment>
            <Toolbar className={classes.root}>
                <div className={classes.logoContainer} onClick={() => history.push(window.location.origin)}>
                    <div className={classes.logoBox}>
                        <img src={(brandAccess === 1) ? Images.logo_toyota : Images.logo_lexus} alt="" />
                    </div>
                    <div className={classes.textBox}>
                        <img src={(brandAccess === 1) ? Images.logo_text_toyota : Images.logo_text_lexus } alt="" />
                    </div>
                </div>
                {ToolbarMenuStructer.map(menu =>
                    <MenuItem
                        key={menu.id}
                        menu={menu}
                        onClick={(menu) => onClickMenuHandler(menu)}
                    />)}
            </Toolbar>
            <MenuUser anchorEl={anchorEl} onClose={() => setAnchorEl(null)}/>
        </React.Fragment>
       
    );
}

export default ToolbarMenu;