import React, { useState, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';

import PopupAlert from '../../../../components/PopupAlert';


import * as Utils from '../../../../service/utils';
import { ServiceContext } from '../../../../context/service/service-context';
import { useAppContext } from '../../../../context/app/app-context';
import { AuthContext } from '../../../../context/auth/auth-context';
import { useNavBar } from '../../';

import Styles from './style';

const useStyles = makeStyles(Styles);

const FinanceSubview = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { carArchiveSelected, setCarArchiveSelected, setPopupAlert, setRefresh} = useAppContext();
    const { Usados } = useContext(ServiceContext);
    const { setRequestStatus } = useNavBar();
    const { cognitoUser, brandAccess } = useContext(AuthContext);
    const [financia, setFinancia] = useState({ data: {}, isSim: false });
    const [openPopUpConfirm, setOpenPopUpConfirm] = useState(false);


    useEffect(() => {
        setFinancia({ data: carArchiveSelected.financeInfoSimulated ? carArchiveSelected.financeInfoSimulated : carArchiveSelected.financeInfoRecommended, isSim: carArchiveSelected.financeInfoSimulated ? true : false })
    }, []);

    const reserveVehicle = async () => {
        const params = {
            idClientOrigin: brandAccess,
            idPI: carArchiveSelected?.id,
            login: cognitoUser.login,
            plateNumber: carArchiveSelected?.plateNumber,
            vin: carArchiveSelected?.vin
        }
        try {
            setRequestStatus(true);
            await Usados.ReserveVehicle(params)
            let reservation = { ...carArchiveSelected?.car, carReservation: null }
            setCarArchiveSelected({ ...carArchiveSelected, car: reservation });
            history.push({ state: { ...carArchiveSelected, car: reservation } });
            setRequestStatus(false);
            setRefresh(true)
        } catch (error) {
            setRequestStatus(false);
            console.log(error);
        }
    }

    const printPI = async (idPI) => {
        const params = {
            login: cognitoUser.login,
            idPI: idPI,
        }
        try {
            setRequestStatus(true);
            await Usados.PrintProposal(params);
            setRequestStatus(false);
        } catch (error) {
            console.log(error);
            setRequestStatus(false);
        }
    }

    const sendPI = async (idPI) => {
        const params = {
            login: cognitoUser.login,
            idPI: idPI,
        }
        try {
            setRequestStatus(true);
            await Usados.SendProposal(params);
            setRequestStatus(false);
        } catch (error) {
            console.log(error);
            setRequestStatus(false);
        }
    }

    const handleRemoveReservation = () => {
        const popupAlerData = {
            description: t('cancel-reservation-confirm')
        }
        setPopupAlert(popupAlerData)
        setOpenPopUpConfirm(true)       
    }

    const onConfirmCancelReservation = async () => {
        setOpenPopUpConfirm(false)
        setRequestStatus(true);
        try {
            const params = {
                idPI: carArchiveSelected?.car?.carReservation?.extIdInOrigin,
                login: cognitoUser?.login,
                plateNumber: carArchiveSelected?.car?.plateNumber,
                vin: carArchiveSelected?.car?.vin
            }
            const result = await Usados.CancelReserveVehicle(params);
            let reservation = { ...carArchiveSelected?.car, carReservation: result?.cancelReserveVehicle?.vehicleReservation }
            setCarArchiveSelected({ ...carArchiveSelected, car: reservation });
            history.push({ state: { ...carArchiveSelected, car: reservation } });
            setRequestStatus(false);
            setRefresh(true)

        } catch (error) {
            setRequestStatus(false);

        }
    }


    const onFooterRender = () => {
        let status = carArchiveSelected?.car?.carStatus?.description;
        let reservation = carArchiveSelected?.car?.carReservation;

        if (status == "Ativo") {
            if (!reservation) {
                return (
                    <div className={classes.footer}>
                        <div className={classes.limit_area}>
                            <div className={classes.row} onClick={reserveVehicle}>
                                <Button styles={classes.button}>{t('footer-proposal-btn1-label')}</Button>
                            </div>
                            <div className={classes.row}>
                                <Button variant='outlined' styles={classes.button} onClick={() => sendPI(carArchiveSelected?.id)}>{t('footer-proposal-btn2-label')}</Button>
                                <Button variant='outlined' styles={classes.button} onClick={() => printPI(carArchiveSelected?.id)}>{t('footer-proposal-btn3-label')}</Button>
                            </div>
                        </div>
                    </div>
                );
            } else {
                if (reservation?.salesman?.idUser == cognitoUser.idUser) {
                    return (
                        <div className={classes.footer}>
                            <div className={classes.limit_area}>
                                <div className={classes.row}>
                                    <div className={classes.cell_footer}>
                                        <div className={classes.item1} style={{cursor: 'pointer'}} onClick={() => handleRemoveReservation()} ><div className={classes.icon}><span>h</span></div></div>
                                        <div className={classes.item2}><div className={classes.title_footer}>{t('footer-proposal-text-label1')}</div></div>
                                        <div className={classes.item1}>
                                            <div className={classes.middle_column}>
                                                <span className={classes.text_footer}>{`${t('footer-proposal-text-label2')} ${carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car?.carReservation?.reservationEndDate).time : '-'}`}</span>
                                                <span className={classes.label_footer}><strong>{carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car?.carReservation?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <Button variant='outlined' styles={classes.button} onClick={() => sendPI(carArchiveSelected?.id)}>{t('footer-proposal-btn2-label')}</Button>
                                    <Button variant='outlined' styles={classes.button} onClick={() => printPI(carArchiveSelected?.id)}>{t('footer-proposal-btn3-label')}</Button>
                                </div>
                            </div>
                        </div >
                    );
                } else {
                    return (
                        <div className={classes.footer}>
                            <div className={classes.limit_area}>
                                <div className={classes.row}>
                                    <div className={classes.cell_footer}>
                                        <div className={classes.item1} style={{cursor: 'pointer'}} onClick={() => handleRemoveReservation()}><div className={classes.icon_invert}><span>h</span></div></div>
                                        <div className={classes.item2}><div className={classes.title_footer}>{t('footer-proposal-text-label3')}</div></div>
                                        <div className={classes.item1}>
                                            <div className={classes.middle_column}>
                                                <span className={classes.text_footer}>{`${t('footer-proposal-text-label2')} ${carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car.carReservation?.reservationEndDate).time : '-'}`}</span>
                                                <span className={classes.text_footer}><strong>{carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car.carReservation?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.columnLeft_footer}>
                                        <a>{t('footer-proposal-text-label4')}</a>
                                        <a>{reservation?.salesman?.name ? reservation?.salesman?.name : '-'}</a>
                                    </div>
                                    <div className={classes.vertical_footer}></div>
                                    <div className={classes.columnRight_footer}>
                                        <a>{t('footer-proposal-text-label5')}</a>
                                        <a>{`${reservation?.salesman?.dealer?.comercialName ? reservation?.salesman?.dealer?.comercialName : reservation?.salesman?.dealer?.desig} - ${reservation?.salesman?.dealer?.cpExt ? reservation?.salesman?.dealer.cpExt : '-'}`}</a>
                                    </div>
                                </div>
                            </div>
                        </div >
                    );
                }
            }
        } else {
            return (
                <div className={classes.footer} style={{ backgroundColor: '#eeeeee' }}>
                    <div className={classes.limit_area}>
                        <div className={classes.row} style={{ justifyContent: 'center' }}>
                            <span className={classes.icon_span}>F</span>
                        </div>
                        <div className={classes.row} style={{ justifyContent: 'center' }}>
                            <span className={classes.label_footer_bold}>Veículo indisponível</span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div>{financia.isSim ? t('proposal-detail-subview-finance-header-title2') : t('proposal-detail-subview-finance-header-title1')}</div>
            </div>

            <div className={classes.box}>
                <div className={classes.container}>
                    <div className={classes.area}>
                        <div className={classes.limit_area}>

                            <div className={classes.title}>
                                {
                                    financia.isSim ? t('proposal-detail-subview-finance-text-subtitle2') : t('proposal-detail-subview-finance-text-subtitle1')
                                }
                            </div>

                            <div className={classes.extra_space}></div>

                            <div className={classes.row}>
                                <div className={classes.columnRight}>
                                    <span>{t('proposal-detail-subview-finance-text-label1')}</span>
                                    <span>{financia.data.initialEntrance ? Utils.formatPrice(financia.data.initialEntrance) : '-'}</span>
                                </div>
                                <div className={classes.vertical}></div>
                                <div className={classes.columnLeft}>
                                    <span>{t('proposal-detail-subview-finance-text-label2')}</span>
                                    <span>{financia.data.capitalToFinance ? Utils.formatPrice(financia.data.capitalToFinance) : '-'}</span>
                                </div>
                            </div>

                            <div className={classes.extra_space}></div>

                            <div className={classes.column}>
                                <div className={classes.field}>
                                    <span>{t('proposal-detail-subview-finance-text-label3')}</span>
                                    <span>{financia.data.nrPayments && financia.data.rent ? `${Utils.formatPrice(financia.data.rent)}/ mês x ${financia.data.nrPayments}` : '-'}</span>
                                </div>
                            </div>

                            <div className={classes.extra_space}></div>

                            <div className={classes.column}>
                                <div className={classes.cell_2}>
                                    <span>{t('proposal-detail-subview-finance-text-label4')}</span>
                                    <span>{`${financia?.data?.tan && financia.data.tan > 0 ? Utils.formatPercentage(financia.data.tan) : '-'}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                !history.location.state.isArchive ?
                    onFooterRender()
                    : null
            }
              {openPopUpConfirm && <PopupAlert
                open={openPopUpConfirm}
                type="ALERT_CONFIRM"
                onCancel={() => setOpenPopUpConfirm(false)}
                onClose={() => setOpenPopUpConfirm(false)}
                onConfirm={() => onConfirmCancelReservation()}
            />}
        </div>
    );
}

export default withRouter(FinanceSubview);