const Styles = theme => ({
    root: {
        cursor: 'pointer',
        backgroundColor: theme.palette.white,
        height: 48,
        width: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            fontSize: 32,
            lineHeight: 1.19,
            fontFamily: 'Cycloid-Font'
        },
        '&:hover': {
            backgroundColor: theme.palette.fafafa,
        }
    },
    active: {
        zIndex: 10,
        height: 58,
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.2)',
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.initGradient}, ${theme.palette.primary.main})`,
        '& span': {
            color: theme.palette.white
        },
        borderRadius: 3,
        '&:hover': {
            backgroundColor: theme.palette.fafafa,
            backgroundImage: `linear-gradient(to bottom, ${theme.palette.fafafa}, ${theme.palette.fafafa})`,
            '& span': {
                color: theme.palette.black
            },
        }
    }
})

export default Styles;