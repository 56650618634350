import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/';
import Styles from './style';

const useStyles = makeStyles(Styles);

const PedidoSubview = (props, ref) => {

    const classes = useStyles();
    const [isActive, setIsActive] = useState(false);

    const onChangeStatusHandler = () => {
        setIsActive(!isActive);
        props.onChange(!isActive);
    }

    useImperativeHandle(ref, () => ({
        setOn () {
            setIsActive(true);
        },
        setOff () {
            setIsActive(false);
        },
        value () {
            return isActive;
        }
    }));


    return (
        <div className={classes.root} onClick={onChangeStatusHandler} testeAction>
            <div className={classes.background} style={{ backgroundColor: isActive ? '#000000' : '#cecece', justifyContent: isActive ? 'flex-end' : 'flex-start' }}>
                <div className={classes.indicator}></div>
            </div>
        </div>
    );
}

export default forwardRef(PedidoSubview);