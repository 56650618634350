
const Styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: theme.breakpoints.values.sm,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    boxHeader: {
        height: 48,
        minHeight: 48,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: theme.palette.black,
        '& span': {
            cursor: 'pointer',
            position: 'absolute',
            left: 0,
            fontSize: 48,
            lineHeight: 1.21,
            fontFamily: 'Cycloid-Font',
            color: theme.palette.white,
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)'
            }
        },
        '& h2': {
            color: theme.palette.white,
            lineHeight: 1,
            fontWeight: 'normal'
        },
        [theme.breakpoints.up('sm')]: {
            '& span': {
                position: 'relative',
            }
        }
    },
    boxBody: {
        backgroundColor: theme.palette.black90,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        height: 'calc(100% - 48px)',
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center'
        }
    },
    searchActionsBox: {
        backgroundColor: theme.palette.black,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 8px',
        marginTop: 'auto',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',

        },
        '& .searchActionsBoxInner': {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '400px',
                width: '100%'
            }
        } 
    },
    buttonSearch: {
        width: '100%',
        margin: '0 12px'
    },
    buttonSearchBlack: {
        width: '100%',
        margin: '0 12px',
        '& button': {
            backgroundColor: theme.palette.black
        }
    },
    boxSeleted: {
        padding: '10px 20px 20px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '400px',
            width: '100%'
        }
    },
    boxSeletedTitle: {
        color: theme.palette.white,
        fontWeight: 'normal',
        marginBottom: 10,
        [theme.breakpoints.up('sm')]: {
            '&:nth-child(1)': {
               textAlign: 'center'
           }
        }
    },
    boxSeletedTitleValue: {
        color: theme.palette.darkgray,
        fontWeight: 'normal',
        marginBottom: 5
    },
    divider: {
        maxHeight: 1,
        margin: '0 20px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '400px',
            width: '100%'
        }
    },
    boxContent: {
        padding: '18px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '400px',
            width: '100%'
        }
    },
    ckeckBoxLabel: {
        color: theme.palette.white,
        lineHeight: 1.13,
        fontWeight: 400
    },
    marginLeft: {
        marginLeft: '12px'
    },
    formControlLabel: {
        marginBottom: '10px'
    },
    bold: {
        fontWeight: 700
    }
})

export default Styles;