import React, { useState, useEffect } from 'react';
import Styles from './style';
import { makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles(Styles);

const popOverlayStatus = {
    state: {
        isOpen: false,
    },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const usePopOverlay = () => {

    const [state, set] = useState(popOverlayStatus.state);
    if (!popOverlayStatus.setters.includes(set)) {
        popOverlayStatus.setters.push(set);
    }

    useEffect(() => () => {
        popOverlayStatus.setters = popOverlayStatus.setters.filter(setter => setter !== set)
    }, []);

    function open() {
        popOverlayStatus.setState({ ...state, isOpen: true });
    }

    function close() {
        popOverlayStatus.setState({ ...state, isOpen: false });
    }

    function init(config, open = false) {
        popOverlayStatus.setState({ ...config, isOpen: open });
    }

    function setFooter(footer) {
        popOverlayStatus.setState({ ...footer, isOpen: true});
    }

    return { state, init, open, close, setFooter };
}

popOverlayStatus.setState = popOverlayStatus.setState.bind(popOverlayStatus);

const PopOverlayView = (props) => {

    const classes = useStyles();
    const { state, close } = usePopOverlay();

    const header = Object.assign({}, defaultProps.header, props.header, state.header);
    const footer = Object.assign({}, defaultProps.footer, props.footer, state.footer);
    const render = [].concat(defaultProps.render, props.render, state.render);

    useEffect(() => {
        // console.log(state);
    }, [state])

    const onCloseHandler = () => {
        if (header.close.action) {
            header.close.action(state);
        } else {
            close();
        }
    }

    const onBackHandler = () => {
        header.back.action(state);
    }

    return (
        <Modal
            open={state.isOpen}
            aria-labelledby="dropdown-modal-title"
            aria-describedby="dropdown-modal-description">

            <div className={classes.root}>
                <div className={classes.header}>
                    <div>
                        <div onClick={onBackHandler} hidden={header.back.hide}>n</div>
                    </div>
                    <div>{header.title}</div>
                    <div>
                        <div onClick={onCloseHandler} hidden={header.close.hide}>F</div>
                    </div>
                </div>
                <div className={classes.body}>
                    <div className={classes.render}>
                        {/* <div className={classes.limit_area}> */}
                        {
                            props.children ? props.children : render
                        }
                        {/* </div> */}
                    </div>
                    <div className={classes.footer} style={{ position: footer.pin ? 'absolute' : 'relative' }} hidden={footer.hide}>
                        <div className={classes.limit_area}>
                            {
                                footer.hide ? null : footer?.render
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default PopOverlayView;

const defaultProps = {
    header: {
        back: {
            hide: true,
            action: null
        },
        close: {
            hide: false,
            action: null
        },
        title: ''
    },
    render: null,
    footer: {
        pin: false,
        hide: true,
        render: null
    }
}

PopOverlayView.defaultProps = defaultProps;