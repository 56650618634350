
const Styles = theme => ({
    root: {
        width: '100%',
        marginBottom: 10,
        borderRadius: 16,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.3)',
        backgroundColor: theme.palette.white,
        [theme.breakpoints.up('sm')]: {
            width: '360px',
            margin: '10px',
        },
    },
    box1: {
        display: 'flex',
        height: 90,
        borderRadius: 16
    },
    boxImage: {
        width: '120px',
        minWidth: '120px',
        height: '100%',
        objectFit: 'contain',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    boxInfo: {
        padding: '10px 10px 6px',
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderTopRightRadius: 16

    },
    carTitleBox: {
        display: 'flex'
    },
    carTitle: {
        textTransform: 'uppercase',
        lineHeight: 1.14,
        display: 'box',
        boxOrient: 'vertical',
        lineClamp: 2,
        overflow: 'hidden'
    },
    carModel: {
        color: theme.palette.darkgray,
        display: 'box',
        boxOrient: 'vertical',
        lineClamp: 2,
        overflow: 'hidden'
    },
    box2: {
        background: 'black',
        height: 34,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    oldPrice: {
        color: theme.palette.darkgray,
        margin: '0 8px'
    },
    price: {
        fontWeight: 700,
        margin: '0 8px'
    },
    box3: {
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.verylightgray}`,
        '& span': {
            fontSize: 32,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font'
        }
    },
    box4: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '4px 0 8px',
        borderRadius: 16
    },
    boxButton: {
        display: 'flex',
        alignitems: 'center'
    },
    feeAndTaeg: {
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            cursor: 'pointer',
            width: 14,
            height: 14,
            borderRadius: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.black,
            marginLeft: 5,
            '& span': {
                fontSize: 11,
                fontWeight: 'bold',
                color: theme.palette.white,
                fontFamily: 'roboto'
            }
        }
    },
    iconLock: {
        fontSize: 48,
        lineHeight: 1,
        fontFamily: 'Cycloid-Font'
    },
    boxlockedDate: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 10,
        '& span': {
            fontFamily: 'Open Sans',
            fontSize: '11px'
        },
        '& p': {
            fontFamily: 'Open Sans',
            fontSize: '11px',
            fontWeight: 700,
            textTransform: 'uppercase',
            margin: 0,
        }
    },
    heightAuto: {
        height: 'auto'
    },
    alignCenter: {
        textAlign: 'center'
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1px 0px'
    },
    column: {
        flex: '1',
        padding: '7px 10px',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center'
    },
    label: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
})

export default Styles;