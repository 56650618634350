
const ToolbarMenuStructer = [
    {
        id: "search",
        label: "",
        icon: '', //Font Cycloid
        path: "/search"
    },
    {
        id: "archive",
        label: "",
        icon: ';', //Font Cycloid
        path: "/archive"
    },
    {
        id: "user",
        label: "",
        icon: 'i', //Font Cycloid
        path: "/user"
    },
]

export default ToolbarMenuStructer;