import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Styles from './style';
import { makeStyles, Modal, Typography, Divider, Grid } from '@material-ui/core';
import DropdownText from '../DropdownText';
import InputText from '../InputText';
import Button from '../Button';
import DropCheckModal from '../DropCheckModal';
import DropRadioModal from '../DropRadioModal';
import { useAppContext } from '../../context/app/app-context';
import { ServiceContext } from '../../context/service/service-context';
import { AuthContext } from '../../context/auth/auth-context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as Utils from '../../service/utils';

const useStyles = makeStyles(Styles);

const SearchFilterModal = ({
    onClose
}) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const { searchFilters, setCarResults, setSearchFilters } = useAppContext()

    const [dealer, setDealer] = useState([])

    const [openDropCheckModal, setOpenDropCheckModal] = useState(false)
    const [dataDropCheckModal, setDataDropCheckModal] = useState(null)

    const [openDropRadioModal, setOpenDropRadioModal] = useState(false)
    const [dataDropRadioModal, setDataDropRadioModal] = useState(null)

    const [selectedModel, setSelectedModel] = useState('none')
    const [selectedVersion, setSelectedVersion] = useState('none')
    const [selectedColor, setSelectedColor] = useState('none')
    const [selectedFuel, setSelectedFuel] = useState('none')

    const [allDealerCars, setAllDealerCars] = useState([])
    const [filteredCars, setFilteredCars] = useState([])

    const [seletecdDealers, setSeletecdDealers] = useState([])
    const [seletecdDealersInfo, setSeletecdDealersInfo] = useState({})



    const [edgeValues, setEdgeValues] = useState({})

    const { Usados } = useContext(ServiceContext);


    const { cognitoUser } = useContext(AuthContext)



    const formik = useFormik({
        initialValues: {
            plateNumber: '',
            priceDownRange: '',
            priceUpRange: '',
            rentDownRange: '',
            rentUpRange: '',
            yearDownRange: '',
            yearUpRange: '',
            kmDownRange: '',
            kmUpRange: '',
        },
        validationSchema: Yup.object().shape({
            plateNumber: Yup.string()
                .required(t('required field')),
        }),
        onSubmit: async values => {
            try {
                const _plateNumber = values.plateNumber.replaceAll(/\s/g, '')
                const params = { plateNumber: _plateNumber }
                const data = await Usados.GetVehicleList(params)
                setCarResults(data?.getCarsWithFilter.cars)
                onClose()
            } catch (error) { }
        },
    });


    const setDefaultDealer = (_delaer) => {
        if (_delaer?.length) {
            const options = _delaer[0].dealers?.map(it => it)
            const parent = {
                id: _delaer[0].id,
                name: _delaer[0].name
            }
            setSeletecdDealers([parent, ...options])
            setSeletecdDealersInfo({
                title: _delaer[0].name,
                count: _delaer[0].dealers.length + 1
            })
        }
    }


    const onSubmitDealers = async (_data) => {
        try {
            setSelectedDealersInfo(_data)
            setOpenDropCheckModal(false)
            setSeletecdDealers(_data)
            let ids = _data.map(it => it.id)
            if (ids.includes('-1')) {
                const params = { dealerGroup: cognitoUser.dealerGroup }
                const data = await Usados.GetVehicleList(params)
                const allCars = data?.getCarsWithFilter.cars;
                setAllDealerCars(allCars)
            } else {
                const its = _data.filter(it => !it.parent)
                ids = its.map(it => it.id)
                const params = { dealers: ids }
                const data = await Usados.GetVehicleList(params)
                const allCars = data?.getCarsWithFilter.cars;
                setAllDealerCars(allCars)
            }
        } catch (error) {

        }
    }

    const onCleanCarsResultsHandle = () => {
        setFilteredCars(allDealerCars)
        setDefaultDealer(dealer)
        setSelectedModel('none')
        setSelectedVersion('none')
        setSelectedColor('none')
        setSelectedFuel('none')
        setup(allDealerCars)
    }

    const onSeeCarsResultsHandle = () => {
        const _filtersData = {
            formikValues: formik.values,
            edgeValues: edgeValues,
            selectedModel: selectedModel,
            selectedVersion: selectedVersion,
            selectedColor: selectedColor,
            selectedFuel: selectedFuel,
            dealer: dealer,
            allDealerCars: allDealerCars,
            filteredCars: filteredCars,
            seletecdDealers: seletecdDealers,
            seletecdDealersInfo: seletecdDealersInfo

        }
        setSearchFilters(_filtersData)
        setCarResults(filteredCars)
        onClose()
    }

    const setSelectedDealersInfo = (data) => {
        const ids = data.map(it => it.id)
        if (ids.includes('-1')) {
            setSeletecdDealersInfo({
                title: t('all'),
                count: null
            })
            return
        }
        const parent = data.filter(item => item[`parent`]);
        if (parent.length === 1) {
            setSeletecdDealersInfo({
                title: parent[0].name,
                count: data.length
            })
            return
        }
        if (data.length > 1) {
            setSeletecdDealersInfo({
                title: t('all selected'),
                count: data.length
            })
            return
        }
        if (data.length === 1) {
            setSeletecdDealersInfo({
                title: data.name,
                count: null
            })
        }
    }



    const filterCars = () => {
        let cars = []
        if (selectedModel !== 'none') {
            cars = allDealerCars.filter(it => it.model === selectedModel)
        } else {
            cars = allDealerCars
        }
        let _filteredCars = cars.filter(it => {
            const conditions =
                (
                    (it.price >= formik.values.priceDownRange && it.price <= formik.values.priceUpRange) &&
                    (it.fee >= formik.values.rentDownRange && it.fee <= formik.values.rentUpRange) &&
                    (it.year >= formik.values.yearDownRange && it.year <= formik.values.yearUpRange) &&
                    (it.km >= formik.values.kmDownRange && it.km <= formik.values.kmUpRange)
                )
            return (
                conditions
            )
        });
        setFilteredCars(_filteredCars)
    }



    const onDropdownClick = (key) => {
        let _data = null;
        switch (key) {
            case 'dealer':
                _data = {
                    title: 'dealer',
                    options: dealer.map(it => {
                        const dt = {
                            id: it.id,
                            name: it.name,
                            children: it.dealers
                        }
                        return dt
                    }),
                    selected: seletecdDealers
                }
                setDataDropCheckModal(_data)
                setOpenDropCheckModal(true)
                break;

            case 'model':
                let allModels = []
                if (selectedModel === 'none' && selectedFuel === 'none') {
                    allModels = Utils.getUniquevalues(allDealerCars, 'model')
                } else {
                    allModels = Utils.getUniquevalues(filteredCars, 'model')
                }
                
                _data = {
                    title: 'model',
                    options: allModels.map(it => {
                        const dt = {
                            id: it,
                            name: it
                        }
                        return dt
                    }),
                    selected: selectedModel,
                    countCars: filteredCars?.length
                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            case 'version':
                let allVersions = []
                if (selectedVersion === 'none') {
                    allVersions = Utils.getUniquevalues(allDealerCars, 'version')
                } else {
                    allVersions = Utils.getUniquevalues(filteredCars, 'version')
                }
                _data = {
                    title: 'version',
                    options: allVersions.map(it => {
                        const dt = {
                            id: it,
                            name: it
                        }
                        return dt
                    }),
                    selected: selectedVersion,
                    countCars: filteredCars?.length

                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            case 'color':
                let allColors = []
                if (selectedColor === 'none') {
                    allColors = Utils.getUniquevalues(allDealerCars, 'color')
                } else {
                    allColors = Utils.getUniquevalues(filteredCars, 'color')
                }
                _data = {
                    title: 'color',
                    options: allColors.map(it => {
                        const dt = {
                            id: it,
                            name: it
                        }
                        return dt
                    }),
                    selected: selectedColor,
                    countCars: filteredCars?.length

                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            case 'fuel':
                let allFuel = []
                if (selectedFuel === 'none') {
                    allFuel = Utils.getUniquevalues(allDealerCars, 'fuel')
                } else {
                    allFuel = Utils.getUniquevalues(filteredCars, 'fuel')
                }
                _data = {
                    title: 'fuel',
                    options: allFuel.map(it => {
                        const dt = {
                            id: it,
                            name: it
                        }
                        return dt
                    }),
                    selected: selectedFuel,
                    countCars: filteredCars?.length

                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            default:
                break;
        }
    }

    const onSelectRadio = (data) => {
        let _filteredCars = []
        let _filteredModelCars = []
        switch (data.name) {
            case 'model':
                setSelectedModel(data.value)
                _filteredModelCars = allDealerCars.filter(it => data.value !== 'none' ? it.model === data.value : true)
                _filteredCars = _filteredModelCars.filter(it => {
                    return (
                        (selectedFuel !== 'none') ? it.fuel === selectedFuel : true
                    )
                })
                setSelectedVersion('none')
                setSelectedColor('none')
                setFilteredCars(_filteredCars)
                setup(_filteredCars)
                break;

            case 'version':
                setSelectedVersion(data.value)
                _filteredModelCars = allDealerCars.filter(it => (selectedModel !== 'none') ? it.model === selectedModel : true)
                _filteredCars = _filteredModelCars.filter(it => {
                    return (
                        (data.value !== 'none') ? it.version === data.value : true &&
                            (selectedColor !== 'none') ? it.color === selectedColor : true &&
                                (selectedFuel !== 'none') ? it.fuel === selectedFuel : true
                    )
                })
                setFilteredCars(_filteredCars)
                setup(_filteredCars)
                break;

            case 'color':
                setSelectedColor(data.value)
                _filteredModelCars = allDealerCars.filter(it => (selectedModel !== 'none') ? it.model === selectedModel : true)
                _filteredCars = _filteredModelCars.filter(it => {
                    return (
                        (selectedVersion !== 'none') ? it.version === selectedVersion : true &&
                        (data.value !== 'none') ? it.color === data.value : true &&
                        (selectedFuel !== 'none') ? it.fuel === selectedFuel : true
                    )
                })
                setFilteredCars(_filteredCars)
                setup(_filteredCars)
                break;

            case 'fuel':
                setSelectedFuel(data.value)
                _filteredModelCars = allDealerCars.filter(it => (selectedModel !== 'none') ? it.model === selectedModel : true)
                _filteredCars = _filteredModelCars.filter(it => {
                    return (
                        (data.value !== 'none') ? it.fuel === data.value : true &&
                        (selectedVersion !== 'none') ? it.version === selectedVersion : true &&
                        (selectedColor !== 'none') ? it.color === selectedColor : true
                    )
                })
                setFilteredCars(_filteredCars)
                setup(_filteredCars)
                break;

            default:
                break;
        }
    }

    const setFormikField = (field, value) => {
        formik.setFieldValue(field, value)
    }

    const setup = (data) => {
        if (data && data.length) {
            const priceMin = Utils.getMin(data, 'price')
            const priceMax = Utils.getMax(data, 'price')
            const feeMin = Utils.getMin(data, 'fee')
            const feeMax = Utils.getMax(data, 'fee')
            const yearMin = Utils.getMin(data, 'year')
            const yearMax = Utils.getMax(data, 'year')
            const kmMin = Utils.getMin(data, 'km')
            const kmMax = Utils.getMax(data, 'km')
            formik.setFieldValue('priceDownRange', priceMin)
            formik.setFieldValue('priceUpRange', priceMax)
            formik.setFieldValue('rentDownRange', feeMin)
            formik.setFieldValue('rentUpRange', feeMax)
            formik.setFieldValue('yearDownRange', yearMin)
            formik.setFieldValue('yearUpRange', yearMax)
            formik.setFieldValue('kmDownRange', kmMin)
            formik.setFieldValue('kmUpRange', kmMax)
            setEdgeValues({ priceMin, priceMax, feeMin, feeMax, yearMin, yearMax, kmMin, kmMax })
        }
    }


    useEffect(() => {
        formik.setValues(searchFilters.formikValues)
        setSeletecdDealers(searchFilters.seletecdDealers)
        setEdgeValues(searchFilters.edgeValues)
        setSelectedModel(searchFilters.selectedModel)
        setSelectedVersion(searchFilters.selectedVersion)
        setSelectedColor(searchFilters.selectedColor)
        setSelectedFuel(searchFilters.selectedFuel)
        setAllDealerCars(searchFilters.allDealerCars)
        setDealer(searchFilters.dealer)
        setFilteredCars(searchFilters.filteredCars)
        setSeletecdDealersInfo(searchFilters.seletecdDealersInfo)
    }, [searchFilters])



    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="dropdown-modal-title"
            aria-describedby="dropdown-modal-description"
        >
            <form onSubmit={formik.handleSubmit} style={{ width: '100%', height: '100%' }}>
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.boxHeader}>
                            <span onClick={onClose}>n</span>
                            <Typography variant='h2' style={{ textTransform: 'uppercase' }}>
                                {t('search-header-title')}
                            </Typography>
                        </div>
                        <div className={classes.boxBody}>
                            <div className={classes.limit_area}>

                                <div className={classes.searchRegistrationBox}>
                                    <Typography variant="subtitle1">{t('search-license-place')}</Typography>
                                    <div className={classes.searchRegistrationBoxInner}>
                                        <InputText
                                            id="plateNumber"
                                            name="plateNumber"
                                            type='car'
                                            onChange={formik.handleChange}
                                            value={formik.values.plateNumber}
                                            styles={classes.inputTextRegCar}
                                            error={formik.errors.plateNumber && formik.touched.plateNumber}
                                        />
                                        <Button styles={classes.buttonSubmitSearch} type='submit'>{t('search-platenumber-button')}</Button>
                                    </div>
                                </div>
                                <div className={classes.boxDivider}>
                                    <Divider variant='middle' />
                                    <span>{t('search-form-or')}</span>
                                    <Divider variant='middle' />
                                </div>
                                <div className={classes.dropdownBox}>
                                    <Grid container justify='center' alignItems='center'>
                                        <Grid item xs={12} >
                                            <DropdownText
                                                id="dealer"
                                                title={t('search-form-dealer')}
                                                data={seletecdDealersInfo?.title}
                                                count={seletecdDealersInfo?.count}
                                                onClick={onDropdownClick} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DropdownText
                                                id="model"
                                                title={t('search-form-model')}
                                                data={selectedModel}
                                                count={2}
                                                onClick={onDropdownClick} />
                                        </Grid>
                                        {
                                            selectedModel === 'none' ? null : (
                                                <React.Fragment>
                                                    <Grid item xs={12}>
                                                        <DropdownText
                                                            id="version"
                                                            children={true}
                                                            title={t('search-form-version')}
                                                            data={selectedVersion}
                                                            onClick={onDropdownClick} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DropdownText
                                                            id="color"
                                                            children={true}
                                                            title={t('search-form-color')}
                                                            data={selectedColor}
                                                            onClick={onDropdownClick} />
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        }
                                        <Grid item xs={12}>
                                            <DropdownText
                                                id="fuel"
                                                title={t('search-form-fuel')}
                                                data={selectedFuel}
                                                onClick={onDropdownClick} />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={clsx([classes.priceBox, classes.boxItem])}>
                                    <Typography variant="subtitle1">{t('search-form-price')}</Typography>
                                    <div className={classes.boxItemInner}>
                                        <InputText
                                            id="priceDownRange"
                                            name="priceDownRange"
                                            type='eur'
                                            value={formik.values.priceDownRange}
                                            minValue={edgeValues.priceMin}
                                            maxValue={edgeValues.priceMax}
                                            onChange={value => { setFormikField('priceDownRange', value) }}
                                            onBlur={() => {
                                                if (formik.values.priceDownRange > formik.values.priceUpRange) {
                                                    setFormikField('priceDownRange', formik.values.priceUpRange)
                                                }
                                                filterCars()
                                            }}
                                            styles={classes.inputText}
                                        />
                                        <Typography variant="h6">{t('search-form-until')}</Typography>
                                        <InputText
                                            id="priceUpRange"
                                            name="priceUpRange"
                                            type='eur'
                                            value={formik.values.priceUpRange}
                                            minValue={edgeValues.priceMin}
                                            maxValue={edgeValues.priceMax}
                                            onChange={value => { setFormikField('priceUpRange', value) }}
                                            onBlur={() => {
                                                if (formik.values.priceUpRange < formik.values.priceDownRange) {
                                                    setFormikField('priceUpRange', formik.values.priceDownRange)
                                                }
                                                filterCars()
                                            }}
                                            styles={classes.inputText}
                                        />
                                    </div>
                                </div>
                                <div className={clsx([classes.incomeBox, classes.boxItem])}>
                                    <Typography variant="subtitle1">{t('search-form-rent')}</Typography>
                                    <div className={classes.boxItemInner}>
                                        <InputText
                                            id="rentDownRange"
                                            name="rentDownRange"
                                            type='eur'
                                            value={formik.values.rentDownRange}
                                            minValue={edgeValues.feeMin}
                                            maxValue={edgeValues.feeMax}
                                            onChange={value => { setFormikField('rentDownRange', value) }}
                                            onBlur={() => {
                                                if (formik.values.rentDownRange > formik.values.rentUpRange) {
                                                    setFormikField('rentDownRange', formik.values.rentUpRange)
                                                }
                                                filterCars()
                                            }}
                                            styles={classes.inputText}
                                        />
                                        <Typography variant="h6">{t('search-form-until')}</Typography>
                                        <InputText
                                            id="rentUpRange"
                                            name="rentUpRange"
                                            type='eur'
                                            value={formik.values.rentUpRange}
                                            minValue={edgeValues.feeMin}
                                            maxValue={edgeValues.feeMax}
                                            onChange={value => { setFormikField('rentUpRange', value) }}
                                            onBlur={() => {
                                                if (formik.values.rentUpRange < formik.values.rentDownRange) {
                                                    setFormikField('rentUpRange', formik.values.rentDownRange)
                                                }
                                                filterCars()
                                            }}
                                            styles={classes.inputText}
                                        />
                                    </div>
                                </div>
                                <div className={clsx([classes.yearBox, classes.boxItem])}>
                                    <Typography variant="subtitle1">{t('search-form-year')}</Typography>
                                    <div className={classes.boxItemInner}>
                                        <InputText
                                            id="yearDownRange"
                                            name="yearDownRange"
                                            type='year'
                                            value={formik.values.yearDownRange}
                                            minValue={edgeValues.yearMin}
                                            maxValue={edgeValues.yearMax}
                                            onChange={value => { setFormikField('yearDownRange', value) }}
                                            onBlur={() => {
                                                if (formik.values.yearDownRange > formik.values.yearUpRange) {
                                                    setFormikField('yearDownRange', formik.values.yearUpRange)
                                                }
                                                filterCars()
                                            }}
                                            styles={classes.inputText}
                                        />
                                        <Typography variant="h6">{t('search-form-to')}</Typography>
                                        <InputText
                                            id="yearUpRange"
                                            name="yearUpRange"
                                            type='year'
                                            value={formik.values.yearUpRange}
                                            minValue={edgeValues.yearMin}
                                            maxValue={edgeValues.yearMax}
                                            onChange={value => { setFormikField('yearUpRange', value) }}
                                            onBlur={() => {
                                                if (formik.values.yearUpRange < formik.values.yearDownRange) {
                                                    setFormikField('yearUpRange', formik.values.yearDownRange)
                                                }
                                                filterCars()
                                            }}
                                            styles={classes.inputText}
                                        />
                                    </div>
                                </div>
                                <div className={clsx([classes.kmBox, classes.boxItem])}>
                                    <Typography variant="subtitle1">{t('search-form-km')}</Typography>
                                    <div className={classes.boxItemInner}>
                                        <InputText
                                            id="kmDownRange"
                                            name="kmDownRange"
                                            type='km'
                                            value={formik.values.kmDownRange}
                                            minValue={edgeValues.kmMin}
                                            maxValue={edgeValues.kmMax}
                                            onChange={value => { setFormikField('kmDownRange', value) }}
                                            onBlur={() => {
                                                if (formik.values.kmDownRange > formik.values.kmUpRange) {
                                                    setFormikField('kmDownRange', formik.values.kmUpRange)
                                                }
                                                filterCars()
                                            }}
                                            styles={classes.inputText}
                                        />
                                        <Typography variant="h6">{t('search-form-until')}</Typography>
                                        <InputText
                                            id="kmUpRange"
                                            name="kmUpRange"
                                            type='km'
                                            value={formik.values.kmUpRange}
                                            minValue={edgeValues.kmMin}
                                            maxValue={edgeValues.kmMax}
                                            onChange={value => { setFormikField('kmUpRange', value) }}
                                            onBlur={() => {
                                                if (formik.values.kmUpRange < formik.values.kmDownRange) {
                                                    setFormikField('kmUpRange', formik.values.kmDownRange)
                                                }
                                                filterCars()
                                            }}
                                            styles={classes.inputText}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{backgroundColor: '#000000'}}>
                            <div className={classes.limit_area}>
                                <div className={classes.searchActionsBox}>
                                    <Button
                                        variant='outlined'
                                        styles={classes.buttonSearchBlack}
                                        onClick={onCleanCarsResultsHandle}
                                    >
                                        {t('search-form-clean')}
                                    </Button>
                                    <Button
                                        styles={classes.buttonSearch}
                                        onClick={onSeeCarsResultsHandle}
                                    >
                                        {t('search-form-see')}&nbsp;({filteredCars?.length})
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openDropCheckModal ? (
                    <DropCheckModal
                        open={openDropCheckModal}
                        data={dataDropCheckModal}
                        onSubmit={data => {
                            onSubmitDealers(data)
                        }}
                        onClose={() => setOpenDropCheckModal(false)} />
                ) : null}
                {openDropRadioModal ? (
                    <DropRadioModal
                        open={openDropRadioModal}
                        data={dataDropRadioModal}
                        hiddenButton={false}
                        onSelect={data => onSelectRadio(data)}
                        onClose={() => setOpenDropRadioModal(false)} />
                ) : null}
            </form>
        </Modal>
    );
}

export default SearchFilterModal;