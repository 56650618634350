import palette from './palette';


const typography =  {
    titleBold: {
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 800,
        lineHeight: 1,
        letterSpacing: 'normal',
        color: '#000000'
    },
    title: {
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000'
    },
    body: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000'
    },
    label: {
        fontFamily: 'Open Sans',
        fontSize: '11px',
        fontWeight: 700,
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#999999'
    },

    
    h1: {
        fontFamily: 'Open Sans',
        color: palette.white,
        fontWeight: 800,
        fontSize: '24px',
        lineHeight: 1.38,
        textTransform: 'uppercase',
        letterSpacing: 'normal'
    },
    h2: {
        fontFamily: 'Open Sans',
        color: palette.black,
        fontWeight: 800,
        fontSize: '18px',
        lineHeight: 1.38,
        letterSpacing: 'normal'
    },
    h3: {
        fontFamily: 'Open Sans',
        color: palette.black,
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: 1.13,
        letterSpacing: 'normal'
    },
    h4: {
        fontFamily: 'Open Sans',
        color: palette.black,
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: 1,
        letterSpacing: 'normal'
    },
    h5: {
        fontFamily: 'Open Sans',
        color: palette.black,
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: 1.38,
        letterSpacing: 'normal'
    },
    h6: {
        fontFamily: 'Open Sans',
        color: palette.black,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: 1.38,
        letterSpacing: 'normal'
    },
    subtitle1: {
        fontFamily: 'Open Sans',
        color: palette.text.primary,
        fontSize: '11px',
        lineHeight: 1.36,
        fontWeight: 700,
        letterSpacing: 'normal'
    },
    subtitle2: {
        fontFamily: 'Open Sans',
        color: palette.text.secondary,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: 'normal'
    },
    body1: {
        color: palette.black,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: 'normal'
    },
    body2: {
        color: palette.text.secondary,
        fontSize: '12px',
        letterSpacing: 'normal',
        lineHeight: '18px'
    },
    button: {
        color: palette.text.primary,
        fontSize: '14px'
    },
    caption: {
        fontFamily: 'Open Sans',
        color: palette.darkgray,
        fontSize: '11px',
        lineHeight: 1,
        letterSpacing: 'normal',
        fontWeight: 800
    },
    overline: {
        color: palette.text.secondary,
        fontSize: '11px',
        fontWeight: 500,
        letterSpacing: 'normal',
        lineHeight: '13px',
        textTransform: 'uppercase'
    }
}

export default typography;