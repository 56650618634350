
const Styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: theme.palette.black,
    },
    header: {
        flex: 1,
        maxHeight: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            width: '48px',
            height: '48px',
            fontSize: 48,
            fontFamily: 'Cycloid-Font',
            color: theme.palette.white
        },
        '& :nth-child(2)': {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...theme.typography.titleBold,
            fontSize: '18px',
            lineHeight: '1',
            textTransform: 'uppercase',
            color: theme.palette.white,
            [theme.breakpoints.up('limit')]: {
                flex: 0
            },
        },
        '& :nth-child(3)': {
            width: '48px',
            height: '48px',
            fontSize: 48,
            fontFamily: 'Cycloid-Font',
            color: theme.palette.white
        },
        backgroundColor: theme.palette.black,
    },
    body: {
        flex: 1,
        overflow: 'auto',
        backgroundColor: theme.palette.black
    },
    footer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px',
        backgroundColor: theme.palette.black,
        overflow: 'hidden'
    },
    render:{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('limit')]: {
            width: '600px',
            margin: 'auto',
        },
    },
})

export default Styles;