import React, { useContext } from 'react';
import PrivateLayout from './private-layout';
import PublicLayout from './public-layout';
import Notifications from '../components/notification-snackbar';
import ScrollReset from '../components/ScrollReset';
import { AuthContext } from '../context/auth/auth-context';


const Layout = () => {

    const { isAuthenticated, isLoading } = useContext(AuthContext);


    return (
        <>
            { !isLoading ? (isAuthenticated ? <PrivateLayout /> : (<PublicLayout />)) : <div>Loading . . .</div> }
            <Notifications />
            <ScrollReset />
        </>
    );
}

export default Layout;