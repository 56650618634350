
const Styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: theme.breakpoints.values.sm,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    boxHeader: {
        height: 48,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: theme.palette.black,
        '& span': {
            position: 'absolute',
            right: 0,
            fontSize: 48,
            lineHeight: 1.21,
            fontFamily: 'Cycloid-Font',
            color: theme.palette.white,
            cursor: 'pointer'
        },
        '& h2': {
            color: theme.palette.white,
            lineHeight: 1,
            fontWeight: 800
        }
    },
    boxBody: {
        backgroundColor: theme.palette.black90,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        height: 'calc(100% - 48px)',
        overflowY: 'auto',
        color: '#FFFFFF',
        padding: 20,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center'
        }
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '600px',
            margin: '0px auto',
        },
    },
})

export default Styles;