const Styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '5px',
        padding: '8px',
        borderRadius: '8px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)',
        backgroundColor: '#ffffff'
    },
    title: {
        ...theme.typography.title,
        textAlign: 'center',
        fontSize: '11px',
    },
    count: {
        ...theme.typography.title,
        textAlign: 'center',
        fontSize: '28px',
    },
})

export default Styles;