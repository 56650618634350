
const Styles = theme => ({
    root: {
        width: 134,
        height: 48,
        margin: '0 8px'
    },
    error: {
        borderRadius: 8,
        '& .MuiInput-root': {
            backgroundColor: theme.palette.primary.main
        }
    },
    car: {
        '& input': {
            '&::placeholder': {
                transition: 'opacity 0.2s linear',
                opacity: 0.6,
                fontSize: '22px'
            },
            '&:focus': {
                '&::placeholder': {
                    opacity: 0
                },
            }
        }
    }
})

export default Styles;