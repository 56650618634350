import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './style';
import { makeStyles, Modal, Typography, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import Button from '../Button';

const useStyles = makeStyles(Styles);

const DropdownModal = ({
    open,
    data,
    hiddenDefault,
    hiddenButton,
    onSelect,
    onClose
}) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const [state, setState] = useState(null)
    const [dataToSubmit, setDataToSubmit] = useState(null)

    const [options, setOptions] = useState([])


    const handleChange = (event) => {
        const _data = { name: data?.title, value: event.target.value, type: data?.type }
        setDataToSubmit(_data)
        setState(event.target.value);
        if (hiddenButton) {
            onSelect(_data)
        }
    };

    const submitHandler = () => {
        onSelect(dataToSubmit)
        onClose()
    }


    useEffect(() => {
        if (data) {
            if (!hiddenDefault) {
                const none = { id: 'none', name: 'Nenhum selecionado' }
                const _options = [none, ...data?.options]
                setOptions(_options)
            } else {
                setOptions(data?.options)
            }
            setState(data.selected)
        }
    }, [data])


    if(!data) return null
   

    return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="dropdown-modal-title"
                aria-describedby="dropdown-modal-description">
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.boxHeader}>
                        <span onClick={onClose}>n</span>
                            <Typography variant='h2' style={{textTransform: 'uppercase'}}>
                                {t(data?.title)}
                            </Typography>
                        </div>
                    <div className={classes.boxBody}>
                        <div className={classes.boxContent}>
                            <RadioGroup aria-label="gender" name="gender1" value={state} onChange={handleChange} >
                                {options?.map(opt => (
                                    <FormControlLabel key={opt.id} value={opt.id} control={<Radio color="default"/>} label={t(opt.name)}  />
                                ))}
                            </RadioGroup>
                        </div>
                    </div>
                    {!hiddenButton ? (
                        <div className={classes.searchActionsBox}>
                               <div className="searchActionsBoxInner">
                                <Button
                                    variant='outlined' styles={classes.buttonSearchBlack}
                                    onClick={onClose}
                                >Cancelar</Button>
                                <Button styles={classes.buttonSearch}
                                    onClick={submitHandler}
                                >Submeter</Button>
                               </div>
                        </div>
                    ) :  null}
                    </div>
                </div>
            </Modal>
    );
}

export default DropdownModal;