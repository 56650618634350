import React, { useState, useEffect } from 'react';
import Styles from './style';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import Images from '../../assets/images'
import DropRadioModal from '../../components/DropRadioModal';
import SearchFilterModal from '../../components/SearchFilterModal';
import CardResultCar from '../../components/CardResultCar';
import PopupAlert from '../../components/PopupAlert';
import { useAppContext } from '../../context/app/app-context';
import Loading from '../../components/Loading';
import Button from '../../components/Button';

const OFFSET = 48


const useStyles = makeStyles(Styles);


const FILTER = [
    { id: 'more recent', name: '(+) Recentes' },
    { id: 'year asc', name: '(+) Anos' },
    { id: 'year desc', name: '(-) Anos' },
    { id: 'price asc', name: '(+) Preço' },
    { id: 'price desc', name: '(-) Preço' },
    { id: 'km asc', name: '(+) Km' },
    { id: 'km desc', name: '(-) Km' }
]


const SearchResultView = () => {
    
    const { t } = useTranslation();

    const classes = useStyles();
    const history = useHistory();
    const { carResults, setCarSelected, setCarResults} = useAppContext()

    const [loading, setLoading] = useState(true)

    const [fullDetail, setFullDetail] = useState(true)

    const [openInfoLegal, setOpenInfoLegal] = useState(false)

    const [openDropRadioModal, setOpenDropRadioModal] = useState(false)
    const [dataDropRadioModal, setDataDropRadioModal] = useState(null)

    const [openSearchModal, setOpenSearchModal] = useState(false)

    const [cars, setCars] = useState([])

    const [index, setIndex] = useState(0)

    const [sort, setSort] = useState(FILTER[0])


    const onViewDetailsHandler = (data) => {
        let finance = financeInfo(data);
        setCarSelected({ ...data, financeInfoRecommended: finance });
        history.push(`/search/results/detail`, { ...data, financeInfoRecommended: finance })
    }

    const onSelectRadio = (data) => {
        const _sort = FILTER.find(it => it.id === data.value)
        setSort(_sort)
    }

    const onDropdownClick = () => {
        const _data = {
            title: t('search-result-sort'),
            options: FILTER,
            selected: sort.id
        }
        setDataDropRadioModal(_data)
        setOpenDropRadioModal(true)
    }

    const financeInfo = (data) => {
        let financeInfoRecommended = null;
        if (data?.fee || data.fee <= 0) {
            financeInfoRecommended = {
                acceptanceRent: data?.acceptanceFee,
                capitalToFinance: data?.capitalToFinance,
                initialEntrance: data?.initialEntrance,
                monthlyRent: data?.monthlyFee,
                nrPayments: data?.nrPayments,
                legalInfo: data?.legalInfo,
                rent: data?.fee,
                taeg: data?.taeg,
                tan: data?.tan,
                mtic: data?.MTIC
            }
        }
        return financeInfoRecommended
    }

    const seeMoreHandle = () => {
        const _index = index * OFFSET;
        let _cars = carResults?.slice(_index, ((index + 1) * OFFSET))
        setIndex(index + 1)
        setCars([...cars, ..._cars])
    }


    useEffect(() => {
        const _carResults = history.location.state
        setCarResults(_carResults)
    }, [history.location.state, setCarResults])



    useEffect(() => {
        let allCars = carResults?.length > 50 ? carResults?.slice(index, ((index + 1) * OFFSET)) : carResults // TO REMOVE
        let _cars = [];
        switch (sort.id) {
            case 'more recent':
                _cars = allCars?.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
                setCars(_cars)
                break;

            case 'year desc':
                _cars = allCars?.sort((a, b) => b.year - a.year)
                setCars(_cars)
                break;

            case 'year asc':
                _cars = allCars?.sort((a, b) => a.year - b.year)
                setCars(_cars)
                break;

            case 'price asc':
                _cars = allCars?.sort((a, b) => b.price - a.price)
                setCars(_cars)
                break;

            case 'price desc':
                _cars = allCars?.sort((a, b) => a.price - b.price)
                setCars(_cars)
                break;

            case 'km asc':
                _cars = allCars?.sort((a, b) => b.km - a.km)
                setCars(_cars)
                break;

            case 'km desc':
                _cars = allCars?.sort((a, b) => a.km - b.km)
                setCars(_cars)
                break;

            default:
                break;
        }
        setLoading(false)
    }, [carResults, sort])


    if (loading) return <Loading />

    return (
        <div className={classes.root}>
            <div className={classes.headerBox}>
                <Typography variant="h2">Pesquisa ({carResults?.length})</Typography>
                <div className={classes.iconBox} onClick={() => setOpenSearchModal(true)}>
                    <img src={Images.icon_config} alt="" />
                </div>
            </div>
            <div className={classes.actionsBox}>
                <div className={classes.actionsBoxLeft}>
                    <span
                        className={clsx({ [classes.red]: !fullDetail })}
                        onClick={() => setFullDetail(false)}
                    >u</span>
                    <span
                        className={clsx({ [classes.red]: fullDetail })}
                        onClick={() => setFullDetail(true)}
                    >a</span>
                </div>

                { /*
                    <div className={classes.actionsBoxCenter}>
                        <span className={clsx({ [classes.red]: true })}>j</span>
                        {
                            {fullDetail ? (
                            <span className={clsx({ [classes.red]: fullDetail })}>W</span>
                        ) : null}
                        }
                    </div>
                */}
                
                <div
                    className={classes.actionsBoxRight}
                    onClick={() => onDropdownClick()}
                >
                    <Typography variant="h6">{sort?.name}</Typography>
                    <span className={classes.icon}>7</span>
                </div>
            </div>
            <div className={classes.content}>
                <div className={classes.limit_area}>
                    {cars?.map(it => (
                        <CardResultCar
                            key={it.plateNumber}
                            fullDetail={fullDetail}
                            data={it}
                            actions={{
                                onView: onViewDetailsHandler
                            }}
                            onSeeLegalInfo={() => setOpenInfoLegal(true)}
                        />
                    ))}
                </div>
                { carResults?.length > 48 ? (
                    <Button
                        variant='outlined'
                        styles={classes.buttonSeeMore}
                        disabled={cars?.length >= carResults?.length}
                        onClick={() => seeMoreHandle()}
                    >
                        {t('search-result-see-more')}
                    </Button>
                ) : null }
                
                
            </div>
            {openDropRadioModal ? (
                <DropRadioModal
                    open={openDropRadioModal}
                    data={dataDropRadioModal}
                    hiddenDefault={true}
                    hiddenButton={false}
                    onSelect={data => onSelectRadio(data)}
                    onClose={() => setOpenDropRadioModal(false)} />
            ) : null}
            {openSearchModal ? (
                <SearchFilterModal
                    open={openSearchModal}
                    onClose={() => setOpenSearchModal(false)} />
            ) : null}
            {openInfoLegal ? (
                <PopupAlert
                    type='ALERT_TEXT'
                    open={openInfoLegal}
                    onClose={() => setOpenInfoLegal(false)}
                />
            ) : null}
        </div>
    );
}

export default SearchResultView;