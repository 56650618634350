import React, { useEffect, useState, createContext } from 'react';
import * as AuthService from '../../service/api/auth/';
import * as ApiService from '../../service/api';
import Cookies from 'js-cookie';
import { useNotifications } from '../../components/notification-snackbar';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {

    const { triggerNotification } = useNotifications()
    const [state, setState] = useState({
        isLoading: false,
        isAuthenticated: false,
        cognitoUser: null,
        brandAccess: null,
        attributes: null
    });

    useEffect(() => {
        const checkCookieSessionAndGetAuthenticationData = async () => {
            if (process.env.REACT_APP_ENV !== 'PRD') {
                handleAuthDev()
            } else { 
                handleAuthProd()
            }  
        }
         checkCookieSessionAndGetAuthenticationData()
    }, []);


    const handleAuthDev = async () => {
        let cookieToyota = null
        let cookieLexus = null
        let cookie = null
        cookieToyota = decodeURI(Cookies.get('extranettoyota'))
        cookieLexus = decodeURI(Cookies.get('extranetlexus'))
        if (cookieToyota !== 'undefined') {
            cookie = {
                name: 'extranettoyota',
                value: cookieToyota,
                brandAccess: 1
            }
        } else if (cookieLexus !== 'undefined') {
            cookie = {
                name: 'extranetlexus',
                value: cookieLexus,
                brandAccess: 2
            }
        } else {
            cookie = null
        }
        if (!cookie) {
            cookieToyota = decodeURI(Cookies.get('extranettoyota'))
            cookie = {
                name: 'extranettoyota',
                value: cookieToyota,
                brandAccess: 1
            }
        }
        const user = cookie.value.split('||')[0];
        const hash = cookie.value.split('||')[1];
        try {
            const params = { hash: hash, appId: '98', login: user }
            const resp = await ApiService.getAuthenticationExtra(params)
            const data = resp.data.authenticationExtraDirectLambda
            if (data.code === '0') {
                setState({ ...state, isLoading: false, isAuthenticated: true, cognitoUser: data.user, attributes: null, brandAccess: cookie?.brandAccess });
            }
        } catch (error) {
            console.log(error)
        }
    }


    const handleAuthProd = async () => {
        let cookieToyota = null
        let cookieLexus = null
        let cookie = null
        let urlRedirect = ''
        let appId = ''
        cookieToyota = decodeURI(Cookies.get('extranettoyota'))
        cookieLexus = decodeURI(Cookies.get('extranetlexus'))
        if (cookieToyota !== 'undefined') {
            cookie = {
                name: 'extranettoyota',
                value: cookieToyota,
                brandAccess: 1,
            }
            appId = 98;
        } else if (cookieLexus !== 'undefined') {
            cookie = {
                name: 'extranetlexus',
                value: cookieLexus,
                brandAccess: 2
            }
            appId = 10061;
        } else {
            cookie = null
        }
        if (!cookie) {
            const hostname = window.location.origin
            const isToyota = hostname.includes('toyota')
            if (isToyota) {
                appId = 98;
                urlRedirect = `https://portalsso.toyota.pt/PortalManagerExtranet/toyota?id=98&linkredir=${hostname}`
            } else {
                appId = 10061;
                urlRedirect = `https://portalsso.lexus.pt/PortalManagerExtranet/toyota?id=10061&linkredir=${hostname}`
            }
            window.open(urlRedirect, "_parent");
        }
        const user = cookie?.value.split('||')[0];
        const hash = cookie?.value.split('||')[1];
        try {
            const params = { hash: hash, appId: appId, login: user }
            const resp = await ApiService.getAuthenticationExtra(params)
            const data = resp.data.authenticationExtraDirectLambda
            if (data.code === '0') {
                setState({ ...state, isLoading: false, isAuthenticated: true, cognitoUser: data.user, attributes: null, brandAccess: cookie?.brandAccess });
            }
        } catch (error) {
            console.log(error)
        }
    }

    const login = () => {
        let appId = ''
        let urlRedirect = ''
        const hostname = window.location.origin
        const isToyota = hostname.includes('toyota')
        if (isToyota) {
            appId = 98;
            urlRedirect = `https://portalsso.toyota.pt/PortalManagerExtranet/toyota?id=98&linkredir=${hostname}`
        } else {
            appId = 1006;
            urlRedirect = `https://portalsso.lexus.pt/PortalManagerExtranet/toyota?id=10061&linkredir=${hostname}`
        }
        window.open(urlRedirect, "_parent");
    }


    /**
     * Sign to the user account
     * @param {*} username email used to register the account.
     * @param {*} password password to access de account.
     * @returns 
     */
    const signin = async (username, password) => {
        try {
            const result = await AuthService.signIn(username, password);
            const { challengeName } = result
            if (challengeName) {
                setState({ isLoading: false, isAuthenticated: false, cognitoUser: result, attributes: null });
            } else {
                setState({ isLoading: false, isAuthenticated: true, cognitoUser: result, attributes: result.attributes });
            }
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-signin-NotAuthorizedException-message", "notifications-signin-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    const signUp = async (data) => {
        try {
            const result = await AuthService.signUp(data);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    break;
                case 'NotAuthorizedException':
                    break;
                case 'UserNotFoundException':
                    break;
                default:
                    break;

            }
        }
    }

    const confirmSignUp = async (username, code) => {
        try {
            const result = await AuthService.confirmSignUp(username, code);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    break;
                case 'NotAuthorizedException':
                    break;
                case 'UserNotFoundException':
                    break;
                default:
                    break;
            }
        }
    }


    /**
     * Signout the session of the currect signin account.
     */
    const signout = async () => {
        setState({ ...state, isLoading: false, isAuthenticated: false, cognitoUser: null, attributes: null });

    };


    /**
     * Request password recover to access the account
     * @param {*} username email used to register the account.
     * @returns 
     */
    const forgotPassword = async (username) => {
        try {
            const result = await AuthService.forgotPassword(username);
            setState({ isLoading: false, isAuthenticated: false, cognitoUser: result, attributes: null });
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'InvalidParameterException':
                    triggerNotification("warning", "notifications-forgotpassword-InvalidParameterException-message", "notifications-forgotpassword-InvalidParameterException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    /**
     * Password recover confirmation
     * @param {*} username email used to register the account.
     * @param {*} code verify code received via email to confirm the recover.
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const forgotPasswordSubmit = async (username, code, newPassword) => {
        try {
            const result = await AuthService.forgotPasswordSubmit(username, code, newPassword);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'InvalidParameterException':
                    triggerNotification("warning", "notifications-forgotpassword-InvalidParameterException-message", "notifications-forgotpassword-InvalidParameterException-title");
                    break;
                case 'ExpiredCodeException':
                    triggerNotification("warning", "notifications-forgotpassword-ExpiredCodeException-message", "notifications-forgotpassword-ExpiredCodeException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    /**
     * Change currrent password to access the account
     * @param {*} oldPassword old password used to access the account.
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const changePassword = async (oldPassword, newPassword) => {
        try {
            const result = await AuthService.changePassword(state.cognitoUser, oldPassword, newPassword);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-changepassword-NotAuthorizedException-message", "notifications-changepassword-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'LimitExceededException':
                    triggerNotification("error", "notifications-default-LimitExceededException-message", "notifications-default-LimitExceededException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    /**
     * First login replace password
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const completeNewPassword = async (newPassword) => {
        try {
            const result = await AuthService.completeNewPassword(state.cognitoUser, newPassword);
            setState({ isLoading: false, isAuthenticated: true, cognitoUser: result, attributes: result.attributes });
            return result;

        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    /**
     * Get the current user pool user information, if the session is valid.
     */
    const currentUserPoolUser = async () => {
        try {
            let result = await AuthService.currentUserPoolUser();
            if (result) {
                setState({ isAuthenticated: true, isLoading: false, cognitoUser: result, attributes: result.attributes });
            }
        } catch (error) {
            switch (error) {
                case 'No current user':
                    setState({ isAuthenticated: false, isLoading: false, cognitoUser: null, attributes: null });
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    };

    /**
     * Update user account attributes
     * @param {object} attributes object with diferente user attributes.
     * @returns 
     */
    const updateUserAttributes = async (attributes) => {
        try {
            const updatedAttributes = updateToKeyCustomAttribute(attributes);
            const result = await AuthService.updateUserAttributes(state.cognitoUser, updatedAttributes);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    return (
        <AuthContext.Provider
            value={{ ...state, login, signin, signout, signUp, confirmSignUp, forgotPassword, forgotPasswordSubmit, changePassword, completeNewPassword, updateUserAttributes }}>
            { props.children}
        </AuthContext.Provider>
    )
}
export default AuthContextProvider;



//UTILS
function updateToKeyCustomAttribute(attributes) {
    let array = {}
    Object.entries(attributes).forEach(([key, value]) => {
        array = {
            ...array,
            [`custom:${key}`]: value.toString()
        }
    });
    return array;
}