import React, { useContext, useState } from 'react';

import { makeStyles } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment-precise-range-plugin';

import { withRouter, useHistory } from 'react-router-dom';

import Button from '../../../../components/Button';
import Carrousel from '../../../../components/Carrousel';
import PopupAlert from '../../../../components/PopupAlert';


import * as Utils from '../../../../service/utils';
import { ServiceContext } from '../../../../context/service/service-context';
import { useAppContext } from '../../../../context/app/app-context';
import { AuthContext } from '../../../../context/auth/auth-context';
import { useNavBar } from '../../';

import Styles from './style';



const useStyles = makeStyles(Styles);

const VehicleSubview = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { carSelected, carArchiveSelected, setCarArchiveSelected, setPopupAlert, setRefresh } = useAppContext();
    const { Usados } = useContext(ServiceContext);
    const { setRequestStatus } = useNavBar();
    const { cognitoUser, brandAccess } = useContext(AuthContext);
    const [openPopUpConfirm, setOpenPopUpConfirm] = useState(false);


    const reserveVehicle = async () => {
        const params = {
            idClientOrigin: brandAccess,
            idPI: carArchiveSelected?.id,
            login: cognitoUser.login,
            plateNumber: carArchiveSelected?.plateNumber,
            vin: carArchiveSelected?.vin
        }
        try {
            setRequestStatus(true);
            await Usados.ReserveVehicle(params)
            let reservation = { ...carArchiveSelected?.car, carReservation: null }
            setCarArchiveSelected({ ...carArchiveSelected, car: reservation });
            history.push({ state: { ...carArchiveSelected, car: reservation } });
            setRequestStatus(false);
            setRefresh(true)
        } catch (error) {
            setRequestStatus(false);
            console.log(error);
        }
    }

    const printPI = async (idPI) => {
        const params = {
            login: cognitoUser.login,
            idPI: idPI,
        }
        try {
            setRequestStatus(true);
            await Usados.PrintProposal(params);
            setRequestStatus(false);
        } catch (error) {
            console.log(error);
            setRequestStatus(false);
        }
    }

    const sendPI = async (idPI) => {
        const params = {
            login: cognitoUser.login,
            idPI: idPI,
        }
        try {
            setRequestStatus(true);
            await Usados.SendProposal(params);
            setRequestStatus(false);
        } catch (error) {
            console.log(error);
            setRequestStatus(false);
        }
    }

    const calculateAge = (date) => {
        var start = moment(date);
        var time = moment.preciseDiff(start, moment(), true);
        return `(${time.years} Ano(s) e ${time.months} Mes(es))`
    }

    const calculateKmPerYear = (km, date) => {
        var start = moment(date);
        var time = moment.preciseDiff(start, moment(), true);
        var average = time.years > 0 ? km / time.years : km;
        return `(${Utils.formatMetric(average)} Km / Ano)`
    }

     const handleRemoveReservation = () => {
        const popupAlerData = {
            description: t('cancel-reservation-confirm')
        }
        setPopupAlert(popupAlerData)
        setOpenPopUpConfirm(true)       
    }


    const onConfirmCancelReservation = async () => {
        setOpenPopUpConfirm(false)
        setRequestStatus(true);
        try {
            const params = {
                idPI: carArchiveSelected?.car?.carReservation?.extIdInOrigin,
                login: cognitoUser?.login,
                plateNumber: carArchiveSelected?.car?.plateNumber,
                vin: carArchiveSelected?.car?.vin
            }
            const result = await Usados.CancelReserveVehicle(params);
            let reservation = { ...carArchiveSelected?.car, carReservation: result?.cancelReserveVehicle?.vehicleReservation }
            setCarArchiveSelected({ ...carArchiveSelected, car: reservation });
            history.push({ state: { ...carArchiveSelected, car: reservation } });
            setRequestStatus(false);
            setRefresh(true)
        } catch (error) {
            setRequestStatus(false);

        }
    }

    const onFooterRender = () => {
        let status = carArchiveSelected?.car?.carStatus?.description;
        let reservation = carArchiveSelected?.car?.carReservation;

        if (status == "Ativo") {
            if (!reservation) {
                return (
                    <div className={classes.footer}>
                        <div className={classes.limit_area}>
                            <div className={classes.row} onClick={reserveVehicle}>
                                <Button styles={classes.button}>{t('footer-proposal-btn1-label')}</Button>
                            </div>
                            <div className={classes.row}>
                                <Button variant='outlined' styles={classes.button} onClick={() => sendPI(carArchiveSelected?.id)}>{t('footer-proposal-btn2-label')}</Button>
                                <Button variant='outlined' styles={classes.button} onClick={() => printPI(carArchiveSelected?.id)}>{t('footer-proposal-btn3-label')}</Button>
                            </div>
                        </div>
                    </div>
                );
            } else {
                if (reservation?.salesman?.idUser == cognitoUser.idUser) {
                    return (
                        <div className={classes.footer}>
                            <div className={classes.limit_area}>
                                <div className={classes.row}>
                                    <div className={classes.cell_footer}>
                                        <div className={classes.item1} style={{cursor: 'pointer'}} onClick={() => handleRemoveReservation()} ><div className={classes.icon}><span>h</span></div></div>
                                        <div className={classes.item2}><div className={classes.title_footer}>{t('footer-proposal-text-label1')}</div></div>
                                        <div className={classes.item1}>
                                            <div className={classes.middle_column}>
                                                <span className={classes.text_footer}>{`${t('footer-proposal-text-label2')} ${carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car?.carReservation?.reservationEndDate).time : '-'}`}</span>
                                                <span className={classes.label_footer}><strong>{carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car?.carReservation?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <Button variant='outlined' styles={classes.button} onClick={() => sendPI(carArchiveSelected?.id)}>{t('footer-proposal-btn2-label')}</Button>
                                    <Button variant='outlined' styles={classes.button} onClick={() => printPI(carArchiveSelected?.id)}>{t('footer-proposal-btn3-label')}</Button>
                                </div>
                            </div>
                        </div >
                    );
                } else {
                    return (
                        <div className={classes.footer}>
                            <div className={classes.limit_area}>
                                <div className={classes.row}>
                                    <div className={classes.cell_footer}>
                                        <div className={classes.item1} style={{cursor: 'pointer'}} onClick={() => handleRemoveReservation()} ><div className={classes.icon_invert}><span>h</span></div></div>
                                        <div className={classes.item2}><div className={classes.title_footer}>{t('footer-proposal-text-label3')}</div></div>
                                        <div className={classes.item1}>
                                            <div className={classes.middle_column}>
                                                <span className={classes.text_footer}>{`${t('footer-proposal-text-label2')} ${carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car.carReservation?.reservationEndDate).time : '-'}`}</span>
                                                <span className={classes.text_footer}><strong>{carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car.carReservation?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.columnLeft_footer}>
                                        <a>{t('footer-proposal-text-label4')}</a>
                                        <a>{reservation?.salesman?.name ? reservation?.salesman?.name : '-'}</a>
                                    </div>
                                    <div className={classes.vertical_footer}></div>
                                    <div className={classes.columnRight_footer}>
                                        <a>{t('footer-proposal-text-label5')}</a>
                                        <a>{`${reservation?.salesman?.dealer?.comercialName ? reservation?.salesman?.dealer?.comercialName : reservation?.salesman?.dealer?.desig} - ${reservation?.salesman?.dealer?.cpExt ? reservation?.salesman?.dealer.cpExt : '-'}`}</a>
                                    </div>
                                </div>
                            </div>
                        </div >
                    );
                }
            }
        } else {
            return (
                <div className={classes.footer} style={{ backgroundColor: '#eeeeee' }}>
                    <div className={classes.limit_area}>
                        <div className={classes.row} style={{ justifyContent: 'center' }}>
                            <span className={classes.icon_span}>F</span>
                        </div>
                        <div className={classes.row} style={{ justifyContent: 'center' }}>
                            <span className={classes.label_footer_bold}>Veículo indisponível</span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div>{t('proposal-detail-subview-vehicle-header-title1')}</div>
            </div>

            <div className={classes.box}>
                <div className={classes.container}>
                    <div className={classes.area}>
                        <div className={classes.limit_area}>

                            <div className={classes.model}>
                                <span>{t('proposal-detail-subview-vehicle-text-label1')}</span>
                                <span>{
                                    `${carArchiveSelected?.car?.carBrand?.description ? carArchiveSelected?.car?.carBrand?.description : ''} ${carArchiveSelected?.car?.carModel?.description ? carArchiveSelected?.car?.carModel?.description : ''}`
                                }</span>
                                <span>{carArchiveSelected?.car?.carVersion?.description ? carArchiveSelected?.car?.carVersion?.description : '-'}</span>
                            </div>

                            <div className={classes.line}></div>

                            <div className={classes.price}>
                                <span>{t('proposal-detail-subview-vehicle-text-label2')}</span>
                                {
                                    carArchiveSelected?.priceWithDiscount > 0 ?
                                        <>
                                            <span>{carArchiveSelected.priceWithDiscount ? Utils.formatPrice(carArchiveSelected.priceWithDiscount) : '- €'}</span>
                                            <span>{carArchiveSelected.price ? `antes ${Utils.formatPrice(carArchiveSelected.price)}` : `antes - €`}</span>
                                        </>
                                        :
                                        <>
                                            <span>{carArchiveSelected.price ? Utils.formatPrice(carArchiveSelected.price) : '- €'}</span>
                                        </>
                                }
                            </div>

                            <div className={classes.line}></div>

                            <div className={classes.license}>
                                <span>{t('proposal-detail-subview-vehicle-text-label3')}</span>
                                <span>{carArchiveSelected.plateNumber ? Utils.formartLicensePlate(carArchiveSelected.plateNumber) : '-- -- --'}</span>
                            </div>

                            <div className={classes.line}></div>

                            <div className={classes.row}>

                                <div className={classes.columnLeft}>
                                    <div className={classes.cell}>
                                        <p>{t('proposal-detail-subview-vehicle-text-label4')}</p>
                                        <p>{carArchiveSelected?.car?.dtRegistration ? Utils.changeDateStringFormat(carArchiveSelected?.car?.dtRegistration) : '-'}</p>
                                        <p>{carArchiveSelected?.car?.dtRegistration ? calculateAge(carArchiveSelected?.car?.dtRegistration) : '-'}</p>
                                    </div>
                                    <div className={classes.cell}>
                                        <p>{t('proposal-detail-subview-vehicle-text-label5')}</p>
                                        <p>{carArchiveSelected?.car?.carFuel?.description ? carArchiveSelected?.car?.carFuel?.description : '-'}</p>
                                        <p></p>
                                    </div>
                                    <div className={classes.cell}>
                                        <p>{t('proposal-detail-subview-vehicle-text-label6')}</p>
                                        <p>{carArchiveSelected?.car?.carExteriorColor?.description ? carArchiveSelected?.car?.carExteriorColor?.description : '-'}</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className={classes.columnRight}>
                                    <div className={classes.cell}>
                                        <p>{t('proposal-detail-subview-vehicle-text-label7')}</p>
                                        <p>{carArchiveSelected?.car?.kilometres ? `${Utils.formatMetric(carArchiveSelected?.car?.kilometres)} Km` : '-'}</p>
                                        <p>{carArchiveSelected?.car?.kilometres && carArchiveSelected?.car?.dtRegistration ? calculateKmPerYear(carArchiveSelected?.car?.kilometres, carArchiveSelected?.car?.dtRegistration) : '-'}</p>
                                    </div>
                                    <div className={classes.cell}>
                                        <p>{t('proposal-detail-subview-vehicle-text-label8')}</p>
                                        <p>{carArchiveSelected?.car?.doorCount ? carArchiveSelected?.car?.doorCount : '-'}</p>
                                        <p></p>
                                    </div>
                                    <div className={classes.cell}>
                                        <p>{t('proposal-detail-subview-vehicle-text-label9')}</p>
                                        <p>{carSelected?.dealerName ? carSelected?.dealerName : '-'}</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.line}></div>

                            <div className={classes.equipments}>
                                <div id="equipments-title">{t('proposal-detail-subview-vehicle-text-subtitle1')}</div>
                                {
                                    carArchiveSelected?.car?.highlightedEquipment ?
                                        carArchiveSelected?.car?.highlightedEquipment.length > 0 ?
                                            carArchiveSelected?.car?.highlightedEquipment.map((prop, index) => {
                                                return (
                                                    <div id="equipments-row" key={index}>{`• ${prop}`}</div>
                                                );
                                            }) :
                                            <p className={classes.message}>{t('proposal-detail-subview-vehicle-text-message')}</p>
                                        :
                                        <p className={classes.message}>{t('proposal-detail-subview-vehicle-text-message')}</p>
                                }
                            </div>
                        </div>

                    </div>
                    <div className={classes.limit_area}>
                        <Carrousel src={carArchiveSelected?.car?.carPhotos} />
                    </div>
                </div>
            </div>
            {
                !history.location.state.isArchive ?
                    onFooterRender()
                    : null
            }

             {openPopUpConfirm && <PopupAlert
                open={openPopUpConfirm}
                type="ALERT_CONFIRM"
                onCancel={() => setOpenPopUpConfirm(false)}
                onClose={() => setOpenPopUpConfirm(false)}
                onConfirm={() => onConfirmCancelReservation()}
            />}
        </div >
    );
}

export default withRouter(VehicleSubview);