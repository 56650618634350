import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/auth/auth-context';

const PrivateRoute = ({ redirect: redirectPath, component: Component, ...rest }) => {

    const { isAuthenticated, isLoading } = useContext(AuthContext)



    return (
        <Route {...rest} render={props => (
            (isAuthenticated ? (<Component {...props} />) : (<Redirect to={{ pathname: redirectPath }} />)) 
        )}
        />
    );
}

export default PrivateRoute;