
const Styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: theme.breakpoints.values.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        // backdropFilter: 'blur(30px)',
        backgroundColor: 'rgba(0,0,0,0.15)'
    },
    container: {
        position: 'relative',
        width: 260,
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '4px 4px 8px 8px',
        paddingTop: '20px',
        '& h6': {
            margin: '0 20px',
            fontSize: 13,
            lineHeight: 1.15,
            marginBottom: 20
        }
    },
    boxTitle: {
        marginBottom: 10,
        padding: '0 20px',
        '& h2': {
            lineHeight: 1
        },
        '& span': {
            fontFamily: 'Cycloid-Font',
            fontSize: 64
        }
    },
    actionBox: {
        height: 48,
        width: '100%',
        borderTop: `1px solid ${theme.palette.verylightgray}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    actionOk: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        cursor: 'pointer'
    },
    actionCancel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        borderLeft: `1px solid ${theme.palette.verylightgray}`,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        cursor: 'pointer'

    },
    boxClose: {
        position: 'absolute',
        top: 4,
        right: 4,
        '& span': {
            height: '100%',
            fontFamily: 'Cycloid-Font',
            fontSize: 32
        }
    }
})

export default Styles;