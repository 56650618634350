const Styles = theme => ({
    root: {
        width: '100vw',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // position: 'relative'
    },
    line: {
        width: '100%',
        height: '1px',
        margin: '10px 0',
        backgroundColor: '#444444'
    },
    line_header: {
        flex: 1,
        height: '1px',
        backgroundColor: '#ffffff'
    },
    label_header: {
        ...theme.typography.titleBold,
        fontSize: '13px',
        color: '#ffffff',
        textAlign: 'center',
        padding: '15px'
    },
    header_regular: {
        ...theme.typography.body,
        fontSize: '10px',
        color: '#ffffff',
        lineHeight: '0.9'
    },
    calendar_month_button: {
        width: '32px', 
        height: '32px', 
        margin: '0px 4px', 
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#ffffff',
        cursor: 'pointer'
    },
    header_bold: {
        ...theme.typography.titleBold,
        fontSize: '13px',
        color: '#ffffff',
        marginRight: '20px',
    },
    header_regular2: {
        ...theme.typography.body,
        fontSize: '16px',
        color: '#ffffff',
        margin: '0px 5px'
    },
    header_bold2: {
        ...theme.typography.titleBold,
        fontSize: '16px',
        color: '#ffffff',
        margin: '0px 5px'
    },






    circularProgress: {
        color: '#DCDCDC'
    },

    box: {
        flex: 1,
        maxWidth: '1146px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        padding: '11px'
    },
    header_container: {

    },
    row: {
        flex: 1,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
    },

    
    header_light: {
        ...theme.typography.body,
        fontSize: '20px',
        color: '#ffffff'
    },

    card: {
        minWidth: '270px',
        maxWidth: '330px',
        padding: '15px',
        borderRadius: '0px 0px 16px 16px',
        backgroundColor: 'rgba(255,255,255,0.2)',
    },
    card_title: {
        ...theme.typography.titleBold,
        fontSize: '18px',
        color: '#ffffff'
    },
    card_subtitle: {
        ...theme.typography.title,
        fontSize: '16px',
        color: '#ffffff'
    },
    card_body: {
        ...theme.typography.label,
        fontSize: '16px',
        color: '#cecece',
        fontWeight: 'normal'
    },
    card_body_small: {
        ...theme.typography.label,
        fontSize: '14px',
        color: '#cecece',
        fontWeight: 'normal'
    },
    button: {
        margin: '15px 0px 5px 0px',
        '& button': {
            backgroundColor: '#eeeeee',
            color: '#000000'
        }
    },
})

export default Styles;