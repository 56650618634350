import React, { useEffect, useState, useContext } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, CircularProgress } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { withRouter, useLocation } from 'react-router-dom';

import ActivePIView from './Views/Active';

import { AuthContext } from '../../context/auth/auth-context';
import { ServiceContext } from '../../context/service/service-context';
import { useAppContext } from '../../context/app/app-context';
import Styles from './style';

const useStyles = makeStyles(Styles);


const StyledTabs = withStyles({
    root: {
        minHeight: '32px',
    },
    indicator: {
        display: 'none',
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        flex: 1,
        minHeight: '32px',
        maxWidth: '100%',
        margin: '0px 2px 0px 0px',
        padding: '3px 3px 3px 3px',
        borderRadius: '6px 6px 0px 0px',
        backgroundColor: '#ffffff',
        color: '#000000',
        opacity: 1,

        ...theme.typography.body,
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'center',
    },
    selected: {
        backgroundColor: '#000000',
        color: '#ffffff',
    }
}))((props) => <Tab disableRipple {...props} />);

const navBarStatus = {
    state: { index: 0, isRequest: false },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const useNavBar = () => {

    const [state, set] = useState(navBarStatus.state);
    if (!navBarStatus.setters.includes(set)) {
        navBarStatus.setters.push(set);
    }

    useEffect(() => () => {
        navBarStatus.setters = navBarStatus.setters.filter(setter => setter !== set)
    }, []);

    function goToTab(index) {
        navBarStatus.setState({ ...state, index: index });
    }

    function setRequestStatus(value) {
        navBarStatus.setState({ ...state, isRequest: value });
    }

    return { state, goToTab, setRequestStatus };
}

navBarStatus.setState = navBarStatus.setState.bind(navBarStatus);

const SearchConsultaView = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { cognitoUser } = useContext(AuthContext);
    const { Usados } = useContext(ServiceContext);
    const { searchPIResult, setSearchPIResult, refresh, setRefresh } = useAppContext();
    const { state, setRequestStatus } = useNavBar();

    const [pi, setPi] = useState({ active: [], archive: [] });
    const [index, setIndex] = useState(0);
    const [isSearching, setIsSearching] = useState(false);

    const location = useLocation();



    useEffect(() => {
        handleChange(null, state.index);
    }, [state.index]);

    useEffect(() => {
        if (searchPIResult.length <= 0 || refresh) {
            getListPI();
            setRefresh(false)
        } else {
            setPi(searchPIResult);
        }
    }, [searchPIResult, refresh]);


    const getListPI = async () => {
        try {
            setRequestStatus(true);
            const data = await Usados.GetProposalList(cognitoUser.login);
            let pis = filterPI(data?.listPI?.pi);
            setPi(pis);
            setSearchPIResult(pis);
            setRequestStatus(false);
        } catch (error) {
            setRequestStatus(false);
            console.log(error);
        }
    }

    const filterPI = (data) => {
        let active = [];
        let archive = [];
        data.filter(item => {
            if (checkIfExprired(item.dtEnd)) {
                archive.push(item);
            } else {
                active.push(item);
            }
        })
        return { active: active, archive: archive }
    }

    const checkIfExprired = (time) => {
        let current = Date.now()
        return current > time;
    }

    const handleChange = (event, newValue) => {
        setIndex(newValue);
    };

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const exitFromSearch = () => {
        setIsSearching(false);
        setPi(searchPIResult);
    }

    const onSearchHandler = (index, value) => {
        setIsSearching(true);
        if (index == 0) {
            setPi({ ...pi, active: filterSearch(value, searchPIResult.active) });
        } else if (index == 1) {
            setPi({ ...pi, archive: filterSearch(value, searchPIResult.archive) });
        }
    }

    const filterSearch = (value, list) => {
        let result = [];
        if (!isNaN(value)) {
            result = list.filter(item => item.client.phone == value);
        } else {
            result = list.filter(item => item.client.email == value);
        }
        return result;
    }

    return (
        <div className={classes.root}>

            <div className={classes.loading} style={{ display: state.isRequest ? 'flex' : 'none' }}>
                <CircularProgress className={classes.circularProgress} />
            </div>

            <div className={classes.header_box}>
                <div className={classes.header}>
                    <span></span>
                    <div>{t('proposal-search-subview-header-title')}</div>
                    <span></span>
                </div>
            </div>

            <div className={classes.nav}>
                <div className={classes.nav_child1}></div>
                <div className={classes.nav_child2}>
                    {
                        isSearching ?
                            <div style={{ flex: 1, backgroundColor: '#ffffff', display: 'flex', marginRight: '2px' }}>
                                <div className={classes.icon} onClick={exitFromSearch}>n</div>
                                <div className={classes.row}><span>{t('Resultado da Pesquisa')} </span></div>
                                <div className={classes.icon}></div>
                            </div>
                            :
                            <StyledTabs value={index} onChange={handleChange}>
                                <StyledTab label={`${t('proposal-search-subview-tab1-label')} (${pi.active.length})`} {...a11yProps(0)} />
                                <StyledTab label={`${t('proposal-search-subview-tab2-label')} (${pi.archive.length})`} {...a11yProps(1)} />
                            </StyledTabs>
                    }
                </div>
                <div className={classes.nav_child3}></div>
            </div>


            <div className={classes.subview}>
                <div role="tabpanel" hidden={index !== 0} id={`scrollable-prevent-tabpanel-${0}`} aria-labelledby={`scrollable-prevent-tab-${0}`} style={{ flex: 1 }}>
                    <ActivePIView tab={index} src={pi.active} onSearch={onSearchHandler} isArchive={false}/>
                </div>
                <div role="tabpanel" hidden={index !== 1} id={`scrollable-prevent-tabpanel-${1}`} aria-labelledby={`scrollable-prevent-tab-${1}`} style={{ flex: 1 }}>
                    <ActivePIView tab={index} src={pi.archive} onSearch={onSearchHandler} isArchive={true}/>
                </div>
            </div>
        </div>
    );
}

export default withRouter(SearchConsultaView);