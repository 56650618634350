import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import InputText from '../../../../components/InputText';
import Button from '../../../../components/Button';
import DropdownText from '../../../../components/DropdownText';
import DropRadioModal from '../../../../components/DropRadioModal';
import PopupAlert from '../../../../components/PopupAlert';


import { ServiceContext } from '../../../../context/service/service-context';
import { useAppContext } from '../../../../context/app/app-context';

import { AuthContext } from '../../../../context/auth/auth-context';
import * as Utils from '../../../../service/utils';
import { useNavBar } from '../../';
import Styles from './style';

const useStyles = makeStyles(Styles);

const RetomaSubview = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { setPopupAlert, carSelected, tradeCar, setTradeCar, setCarSelected } = useAppContext();
    const { brandAccess } = useContext(AuthContext);
    const { Usados } = useContext(ServiceContext);

    const [openDropRadioModal, setOpenDropRadioModal] = useState(false);
    const [dataDropRadioModal, setDataDropRadioModal] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState('none');
    const [simulation, setSimulation] = useState(null);
    const { setRequestStatus } = useNavBar();
    const [openOverwrite, setOpenOverwrite] = useState(false);

    const getTradePrice = useFormik({
        initialValues: {
            licensePlate: "",
        },
        onSubmit: async (values) => {
            try {
                setSelectedVersion('none');
                setTradeCar(null);
                setCarSelected({ ...carSelected, carTrade: null, carReturn: null });
                history.replace({ state: { ...carSelected, carTrade: null, carReturn: null } })
                const params = {
                    licensePlate: values.licensePlate.replaceAll(/\s/g, ''),
                    oidnet: brandAccess == 1 ? "SC00010001" : "SC00010002",
                }
                setRequestStatus(true);
                let result = await Usados.GetTradePrice(params);
                result.getTradePrice.types = Utils.stringToJson(result?.getTradePrice?.types);
                setSimulation(null);
                setTradeCar(result?.getTradePrice);
                setCarSelected({ ...carSelected, carTrade: { ...result?.getTradePrice }, carReturn: null });
                history.replace({ state: { ...carSelected, carTrade: { ...result?.getTradePrice }, carReturn: null } })
                setRequestStatus(false);
                getTradePrice.setFieldValue('licensePlate', '');
            } catch (error) {
                console.log(error);
                setRequestStatus(false);
                throw error
            }
        },
    });

    const getSimulation = useFormik({
        initialValues: {
        },
        onSubmit: async (values) => {
            try {
                const params = {
                    version: selectedVersion,
                    oidnet: brandAccess == 1 ? "SC00010001" : "SC00010002",
                    licensePlate: carSelected?.carTrade.licensePlate,
                    year: carSelected?.carTrade.year
                }
                setRequestStatus(true);
                const result = await Usados.GetTradePrice(params);
                setTradeCar({ ...tradeCar, version: selectedVersion, tradePrice: result?.getTradePrice?.tradePrice });
                setSimulation({ ...result.getTradePrice, finalPrice: (carSelected.discountPrice > 0 ? carSelected.discountPrice : carSelected.price) - result?.getTradePrice?.tradePrice });
                setCarSelected({ ...carSelected, carTrade: { ...carSelected.carTrade, tradePrice: result?.getTradePrice?.tradePrice, version: selectedVersion, finalPrice: (carSelected.discountPrice > 0 ? carSelected.discountPrice : carSelected.price) - result?.getTradePrice?.tradePrice }, carReturn: null });
                history.replace({ state: { ...carSelected, carTrade: { ...result?.getTradePrice, tradePrice: result?.getTradePrice?.tradePrice, version: selectedVersion, finalPrice: (carSelected.discountPrice > 0 ? carSelected.discountPrice : carSelected.price) - result?.getTradePrice?.tradePrice }, carReturn: null } });
                setRequestStatus(false);
                getTradePrice.setFieldValue('licensePlate', '');
            } catch (error) {
                setRequestStatus(false);
                console.log(error);
                throw error;
            }
        },
    });

    const saveFinance = useFormik({
        initialValues: {
        },
        onSubmit: async (values) => {
            try {
                if (carSelected.carReturn) {
                    const popupAlerData = {
                        description: "Vai substituir os dados gravados anteriormente. Concorda?",
                        type: 'ALERT_CONFIRM',
                        actions: {
                            onConfirm: onOverwriteSimulation,
                            onCancel: () => setOpenOverwrite(false)
                        }
                    }
                    setPopupAlert(popupAlerData)
                    setOpenOverwrite(true);
                } else {
                    onOverwriteSimulation()
                }
            } catch (error) {
                throw error
            }
        },
    });

    const onClearCarReturn = () => {
        setCarSelected({ ...carSelected, carReturn: null });
        history.replace({ state: { ...carSelected, carReturn: null } });
    }

    const onOverwriteSimulation = () => {
        setCarSelected({ ...carSelected, carReturn: carSelected.carTrade });
        history.replace({ state: { ...carSelected, carReturn: carSelected.carTrade } });
        setOpenOverwrite(false);
    }

    const onRemoveResultHandler = () => {
        setTradeCar(null);
        let selectedCar = carSelected;
        delete selectedCar?.carTrade;
        setCarSelected({ ...selectedCar });
        history.replace({ state: selectedCar })
    }

    const onRemoveResultSimulationHandler = () => {
        setSimulation(null);
        let selectedCar = carSelected;
        delete selectedCar?.carTrade?.finalPrice;
        setCarSelected({ ...selectedCar });
        history.replace({ state: selectedCar })
    }

    const onSelectVersionHandler = (data) => {
        setSelectedVersion(data.value)
    }

    const onDropdownClick = (key) => {
        let _data = null
        _data = {
            title: 'version',
            options: carSelected?.carTrade.types.map(it => {
                const dt = {
                    id: it,
                    name: formatStringVersion(it)
                }
                return dt
            }),
            selected: selectedVersion
        }
        setDataDropRadioModal(_data)
        setOpenDropRadioModal(true)
    }

    const formatStringVersion = (value) => {
        if (value.length > 0) {
            let formatString = value.split('|');
            formatString = `${formatString[0]} ${formatString[1]}p ${formatString[2]}cv`;
            return formatString;
        }
        return value
    }

    const calculatePrice = () => {
        if (carSelected.discountPrice > 0) {
            return (
                <div style={{ marginRight: '15px' }}>
                    <div style={{ textDecoration: simulation ? 'line-through' : 'none' }} >{carSelected.discountPrice ? Utils.formatPrice(carSelected.discountPrice) : '- €'}</div>
                    <div>{carSelected.price ? `${t('search-detail-subview-header-label')} ${Utils.formatPrice(carSelected.price)}` : `${t('search-detail-subview-header-label')} - €`}</div>
                </div>
            );
        } else {
            return (
                <div style={{ marginRight: '15px' }}>
                    <div style={{ textDecoration: simulation ? 'line-through' : 'none' }}>{carSelected.price ? Utils.formatPrice(carSelected.price) : '- €'}</div>
                </div>
            );
        }
    }

    const calculateExchange = () => {
        if (carSelected?.carTrade?.finalPrice) {
            return (
                <div style={{ Left: '15px' }}>
                    <div>{carSelected?.carTrade?.finalPrice ? Utils.formatPrice(carSelected?.carTrade?.finalPrice) : '- €'}</div>
                    <div>{t('search-exchange-subview-header-text1-title')}</div>
                </div>
            );
        } else {
            return (null);
        }
    }

    const onLicenseNumberChange = (event) => {
        const license = Utils.inputFormatLicensePlate(event.target.value);
        getTradePrice.setFieldValue('licensePlate', license.toUpperCase());
    }

    const renderLicenseView = () => {
        return (
            <div className={classes.limit_area}>
                <div style={{ padding: '0px 20px' }}>
                    <div className={classes.title}>
                        <span>{t('search-exchange-subview-search-text-title')}</span>
                    </div>
                    <form onSubmit={getTradePrice.handleSubmit} className={classes.box}>
                        <div className={classes.labelInput}><span>{t('search-exchange-subview-search-input-label')}</span></div>
                        <InputText
                            id="licensePlate"
                            name="licensePlate"
                            type="car"
                            onChange={getTradePrice.handleChange}
                            value={getTradePrice.values.licensePlate}
                            styles={classes.inputfield}
                        />
                        <Button styles={classes.button} type='submit'>{t('search-exchange-subview-search-btn-label')}</Button>
                    </form>
                </div>
            </div>
        );
    };

    const renderResultView = () => {
        return (
            <div className={classes.limit_area}>
                <div style={{ padding: '0px 20px' }}>
                    <div className={classes.title}>
                        <span>{t('search-exchange-subview-search-text-title')}</span>
                    </div>
                    <form onSubmit={getSimulation.handleSubmit} className={classes.box}>
                        <div className={classes.column}>
                            <div className={classes.cell_1}>
                                <span>{t('search-exchange-subview-version-license-label')}</span>
                                <span>{carSelected?.carTrade.licensePlate ? carSelected?.carTrade.licensePlate.match(/.{1,2}/g).join(' ') : '-- -- --'}</span>
                            </div>
                        </div>
                        <div className={classes.column}>
                            <div className={classes.cell_3}>
                                <span>{t('search-exchange-subview-version-year-label')}</span>
                                <span>{carSelected?.carTrade.year && carSelected?.carTrade.year > 0 ? carSelected?.carTrade.year : '-'}</span>
                            </div>
                        </div>
                        <DropdownText
                            id="dealer"
                            title={t('search-exchange-subview-version-dropdown-label')}
                            data={selectedVersion == 'none' ? selectedVersion : formatStringVersion(selectedVersion)}
                            count={carSelected?.carTrade.types?.length}
                            onClick={onDropdownClick} />
                        <div className={classes.row} style={{ marginTop: '10px' }}>
                            <Button variant='outlined' styles={classes.button} onClick={onRemoveResultHandler}>{t('search-exchange-subview-version-btn1-label')}</Button>
                            <Button styles={classes.button} type='submit' disabled={selectedVersion == 'none' ? true : false}>{t('search-exchange-subview-version-btn2-label')}</Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    const renderSimulationView = () => {
        return (
            <>
                <div className={classes.limit_area}>
                    <div style={{ padding: '0px 20px 10px 20px' }}>
                        <div className={classes.title}>
                            <span>{t('search-exchange-subview-simulation-text-title')}</span>
                        </div>
                        <div className={classes.line}></div>
                        <div className={classes.row}>
                            <div className={classes.columnRight}>
                                <span>{t('search-exchange-subview-simulation-license-label')}</span>
                                <span>{carSelected?.carTrade.licensePlate ? carSelected?.carTrade.licensePlate.match(/.{1,2}/g).join(' ') : '-- -- --'}</span>
                            </div>
                            <div className={classes.vertical}></div>
                            <div className={classes.columnLeft}>
                                <span>{t('search-exchange-subview-simulation-year-label')}</span>
                                <span>{carSelected?.carTrade.year && carSelected?.carTrade.year > 0 ? carSelected?.carTrade.year : '-'}</span>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.cell}>
                                <span>{t('search-exchange-subview-simulation-version-label')}</span>
                                <span>{carSelected?.carTrade.version ? formatStringVersion(carSelected?.carTrade.version) : '-'}</span>
                            </div>
                        </div>
                        <div className={classes.column}>
                            <div className={classes.field}>
                                <span> {t('search-exchange-subview-simulation-value-label')}</span>
                                <span>{carSelected?.carTrade.tradePrice ? Utils.formatPrice(carSelected?.carTrade.tradePrice) : '- €'}</span>
                            </div>
                        </div>
                        {
                            carSelected?.carTrade?.finalPrice ?
                                <div className={classes.column} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Button variant='outlined' styles={classes.button} onClick={onClearCarReturn} disabled={!carSelected?.carReturn}>Limpar</Button>
                                    <Button variant={carSelected?.carReturn ? 'outlined' : 'contained'} styles={classes.button} onClick={saveFinance.handleSubmit} disabled={carSelected?.carReturn}>{carSelected?.carReturn ? 'Gravado' : 'Gravar'}</Button>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                <div className={classes.line}></div>
                <div className={classes.limit_area}>
                    <div style={{ padding: '0px 20px' }}>

                        <div className={classes.title}>
                            <span>{t('search-exchange-subview-search-text-title')}</span>
                        </div>
                        <form onSubmit={getTradePrice.handleSubmit} className={classes.box}>
                            <div className={classes.labelInput}><span>{t('search-exchange-subview-simulation-license-label')}</span></div>
                            <InputText
                                id="licensePlate"
                                name="licensePlate"
                                type='car'
                                onChange={getTradePrice.handleChange}
                                value={getTradePrice.values.licensePlate}
                                styles={classes.inputfield}
                            />
                            <div className={classes.row} style={{ marginTop: '10px' }}>
                                <Button variant='outlined' styles={classes.button} onClick={onRemoveResultSimulationHandler}>{t('search-exchange-subview-simulation-btn1-label')}</Button>
                                <Button styles={classes.button} type='submit'>{t('search-exchange-subview-simulation-btn2-label')}</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.header_box}>
                <div className={classes.limit_area}>
                    <div className={classes.header}>
                        <div>{carSelected.version ? carSelected.version : '-'}</div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {calculatePrice()}
                            {calculateExchange()}
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.box_root}>
                <div className={classes.box_container}>
                    <div className={classes.container}>
                        {
                            carSelected?.carTrade ? (carSelected?.carTrade?.finalPrice ? renderSimulationView() : renderResultView()) : renderLicenseView()
                        }
                    </div>
                </div>
            </div>

            {openDropRadioModal ? (
                <DropRadioModal
                    open={openDropRadioModal}
                    data={dataDropRadioModal}
                    hiddenButton={false}
                    onSelect={data => onSelectVersionHandler(data)}
                    onClose={() => setOpenDropRadioModal(false)} />
            ) : null}
            {openOverwrite ? (<PopupAlert open={openOverwrite} />) : null}
        </div>
    );
}

export default withRouter(RetomaSubview);