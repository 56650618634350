import React, { useEffect, useState } from 'react';

import TimeButton from './Components/TimeButton';

import './style.css';

const Timer = ({ config }) => {

    const [state, setState] = useState({ current: null, original: null });

    useEffect(() => { init(config?.sizeSlot, config?.pickedDay, config?.timeSlots) }, [])

    const init = (sizeSlot = null, pickedDay = null, timeSlots = null) => {
        if (pickedDay == null) {
            pickedDay = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        }
        if (sizeSlot == null) {
            sizeSlot = 30
        }
        if (config?.validateSlots) {
            let range = getTimeRange(sizeSlot, pickedDay);
            let formatData = formatOjectValidate(range, timeSlots);
            setState({ current: formatData, original: range });
        } else {
            let formatData = formatOject(timeSlots, pickedDay);
            setState({ current: formatData, original: null });
        }
    }

    const getTimeRange = (sizeSlot = null, pickedDay = null) => {
        let time = [];
        const day = pickedDay.getDate();
        while (pickedDay.getDate() === day) {
            time.push(new Date(pickedDay));
            pickedDay.setMinutes(pickedDay.getMinutes() + 30);
        }
        return time;
    }

    const formatOject = (timeSlots, pickedDay) => {
        let array = [];
        for (let prop in timeSlots) {
            let hour = timeSlots[prop].split(":")[0];
            let minute = timeSlots[prop].split(":")[1];
            pickedDay.setHours(hour);
            pickedDay.setMinutes(minute);

            let params = {
                date: new Date(pickedDay),
                hour: hour,
                minute: minute,
                format: `${hour}:${minute}`,
                formatMili:  timeSlots[prop],
                isAvailable: true,
                isPassed: false,
            }
            array.push(params);
        }
        return array;
    }

    const formatOjectValidate = (data, timeSlots = null) => {
        let array = [];
        for (let prop in data) {
            let params = {
                date: data[prop],
                hour: '',
                minute: '',
                format: '',
                formatMili: '',
                isAvailable: true,
                isPassed: false,
            }
            params.isPassed = validatePassedTime(data[prop]);
            const t = new Date(data[prop]);
            params.hour = `${("0" + (t.getHours())).slice(-2)}`
            params.minute = `${("0" + (t.getMinutes())).slice(-2)}`
            params.format = `${("0" + (t.getHours())).slice(-2)}:${("0" + (t.getMinutes())).slice(-2)}`
            params.formatMili = `${("0" + (t.getHours())).slice(-2)}:${("0" + (t.getMinutes())).slice(-2)}:00`

            if (timeSlots) {
                let time = new Date(data[prop]);
                params.isAvailable = timeSlots.includes(`${("0" + (time.getHours())).slice(-2)}:${("0" + (time.getMinutes())).slice(-2)}:00`);
            }

            array.push(params);
        }
        return array;
    }

    const validatePassedTime = (date) => {
        const today = new Date();
        if (date > today) {
            return false;
        } else {
            return true;
        }
    }

    const onTimePickerHandler = async (value) => {
        await config?.onTimePicker(value);
    }

    const onRenderSlots = (slots) => { }

    return (
        <div className="timer-root">
            <div className="timer-container">
                {
                    state.current ?
                        state.current.map((prop, index) => {
                            return (
                                <TimeButton key={`time-${index}`} value={prop.format} onClick={() => onTimePickerHandler(prop)} passed={prop.isPassed} today={prop.isToday} disable={!prop.isAvailable} />
                            );
                        }) : null
                }

            </div>
        </div>
    );
}

export default Timer;