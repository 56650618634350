
const Styles = theme => ({
    root: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.fafafa
    },
    header_box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: '#ffffff'
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        '& span': {
            width: '48px',
            height: '48px',
            fontSize: '48px',
            fontFamily: 'Cycloid-Font',
            cursor: 'pointer'
        },
        '& div': {
            ...theme.typography.titleBold,
            // flex: '1',
            maxWidth: '540px',
            height: '48px',
            marginLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textTransform: 'uppercase',
        },
        [theme.breakpoints.up('limit')]: {
            width: '600px !important',
        }
    },
    back_button: {
        [theme.breakpoints.up('limit')]: {
            marginLeft: '30px',
        },
    },
    nav: {
        width: '100%',
        height: '32px',
        display: 'flex',
        paddingTop: '2px',
        overflow: 'hidden',
        backgroundColor: '#eeeeee'
    },
    nav_child1: {
        height: '100%',
        borderRadius: '0px 6px 0px 0px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            flex:1,
            margin: '0px 2px 0px 0px'
        },
    },
    nav_child2: {
        flex:1,
        height: '100%',
        [theme.breakpoints.up('limit')]: {
            minWidth: '600px !important',
        },
    },
    nav_child3: {
        height: '100%',
        borderRadius: '6px 0px 0px 0px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            flex:1,
        },
    },
    subview: {
        flex: 1,
        width: '100%',
        display: 'flex',
        overflow: 'hidden'
    },

    title: {
        margin: 0,
        padding: 0,
        ...theme.typography.title
    },
    body: {
        fontSize: '14px',
        ...theme.typography.body,
    },
    label: {
        margin: 0,
        padding: 0,
        ...theme.typography.label,
    },



    headline: {
        flex: 1,
        height: '79px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '6px 10px 6px 10px',
        backgroundColor: '#000000',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '14px',
            color: '#ffffff',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '24px',
            color: '#ffffff',

        },
        '& :nth-child(3)': {
            ...theme.typography.body,
            fontSize: '13px',
            color: '#999999',
        },
    },
    e_header: {
        ...theme.typography.title,
        fontSize: '14px',
        margin: '0px 0px 10px 0px'
    },
    topic: {
        width: '100%',
        ...theme.typography.body
    },
    circularProgress: {
        color: '#DCDCDC'
    },
    loading: {
        width: '100%', 
        height: '100%', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'fixed', 
        zIndex: '5', 
        backgroundColor: 'rgba(250,250,250,0.9)'
    }
})

export default Styles;