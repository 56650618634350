
const Styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: theme.breakpoints.values.sm,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '600px',
            margin: '0px auto',
        },
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    boxHeader: {
        height: 48,
        minHeight: 48,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: theme.palette.black,
        '& span': {
            position: 'absolute',
            left: 0,
            fontSize: 48,
            lineHeight: 1.21,
            fontFamily: 'Cycloid-Font',
            color: theme.palette.white,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)'
            }
        },
        '& h2': {
            color: theme.palette.white,
            lineHeight: 1,
            fontWeight: 'normal'
        }
    },
    boxBody: {
        backgroundColor: theme.palette.black90,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        height: 'calc(100% - 48px)',
        overflowY: 'auto'
    },
    searchRegistrationBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '11px 20px 5px',
        '& h6': {
            marginBottom: 6,
            color: theme.palette.white
        }
    },
    searchRegistrationBoxInner: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    searchActionsBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 8px',
        backgroundColor: theme.palette.black,
    },
    buttonSearch: {
        width: '100%',
        margin: '0 12px'
    },
    boxDivider: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 0',
        '& span': {
            fontFamily: 'Open Sans',
            color: theme.palette.darkgray,
            fontWeight: 400,
            fontSize: '13px',
            lineHeight: 1.38
        }
    },
    dropdownBox: {
        margin: '0 20px'
    },
    dropdownBoxInner: {
        width: '100%'
    },
    boxItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '5px 12px',
        '& h6': {
            marginBottom: 6,
            color: theme.palette.white
        }
    },
    boxItemInner: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputText: {
        width: '100%'
    },
    inputTextRegCar: {
        width: '100%',
        margin: 0,
        '& input': {
            fontSize: 22
        }
    },
    buttonSubmitSearch: {
        width: '100%',
        marginLeft: 11
    },
    buttonSearchBlack: {
        width: '100%',
        margin: '0 12px',
        '& button': {
            backgroundColor: theme.palette.black
        }
    }
})

export default Styles;