import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppContext } from '../context/app/app-context';
import { PrivateRoutes } from '../routes';
import { ToolbarMenu } from '../components';
import PopupTerms from '../components/PopupTerms';
import Styles from './style';



const useStyles = makeStyles(Styles);

const PrivateLayout = () => {

    const classes = useStyles();

    const { popupTerms } = useContext(AppContext);


  
    return (
        <div className={classes.root}>
            <div className={classes.contentContainer}>
                 <div className={classes.toolbarContainer}>
                    <ToolbarMenu />
                </div>
                <div className={classes.viewContainer}>
                    <PrivateRoutes />
                </div>
            </div>
            {popupTerms ? <PopupTerms open={popupTerms} /> :  null}
        </div>
    );
}

export default PrivateLayout;