import React, { useEffect, useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, CircularProgress } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useHistory, useParams, withRouter } from 'react-router-dom';

import { useAppContext } from '../../context/app/app-context';
import { Structer } from './config';
import Styles from './style';

const useStyles = makeStyles(Styles);


const StyledTabs = withStyles({
    root: {
        minHeight: '32px',
    },
    indicator: {
        display: 'none',
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        flex: 1,
        minHeight: '32px',
        maxWidth: '100%',
        margin: '0px 2px 0px 0px',
        padding: '3px 3px 3px 3px',
        borderRadius: '6px 6px 0px 0px',
        backgroundColor: '#ffffff',
        color: '#000000',
        opacity: 1,

        ...theme.typography.body,
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'center',
    },
    selected: {
        backgroundColor: '#000000',
        color: '#ffffff',
    }
}))((props) => <Tab disableRipple {...props} />);


const navBarStatus = {
    state: { index: 0, isRequest: false },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const useNavBar = () => {

    const [state, set] = useState(navBarStatus.state);
    if (!navBarStatus.setters.includes(set)) {
        navBarStatus.setters.push(set);
    }

    useEffect(() => () => {
        navBarStatus.setters = navBarStatus.setters.filter(setter => setter !== set)
    }, []);

    function goToTab(index) {
        navBarStatus.setState({ ...state, index: index });
    }

    function setRequestStatus(value) {
        navBarStatus.setState({ ...state, isRequest: value });
    }

    return { state, goToTab, setRequestStatus };
}

navBarStatus.setState = navBarStatus.setState.bind(navBarStatus);



const ConsultaDetailView = () => {

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { carArchiveSelected, setCarArchiveSelected } = useAppContext()
    const { viewId } = useParams();
    const [structer, setStructer] = useState({ index: 0, component: null });
    const { state, goToTab } = useNavBar();

    useEffect(() => {
        const _carArchiveSelected = history.location.state
        setCarArchiveSelected(_carArchiveSelected)
        onLoadPage();
        return () => {
            goToTab(0);
        }
    }, []);

    useEffect(() => { 
        handleChange(null, state.index);
    }, [state.index]);

    const onLoadPage = () => {
        const viewIndex = Structer.sections.find(item => item.id == viewId);
        setStructer({ index: viewIndex ? viewIndex.index : 0, component: viewIndex ? viewIndex.component : Structer.sections[0].component });
    }

    const handleChange = (event, newValue) => {
        const viewIndex = Structer.sections.find(item => item.index == newValue);
        setStructer({ index: viewIndex.index, component: viewIndex.component });
        goToTab(viewIndex.index);
        // history.push(viewIndex.path);
    };

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const headerBackHandler = () => {
        history.goBack();
        goToTab(0);
    }

    return (
        <div className={classes.root}>

            <div className={classes.loading} style={{ display: state.isRequest ? 'flex' : 'none' }}>
                <CircularProgress className={classes.circularProgress} />
            </div>

            <div className={classes.header_box}>
                <div className={classes.header}>
                    <span onClick={headerBackHandler} className={classes.back_button}>n</span>
                    <div>{carArchiveSelected.client?.firstName || carArchiveSelected.client?.lastName ? `${carArchiveSelected.client?.firstName} ${carArchiveSelected.client?.lastName}` : '-'}</div>
                    <span></span>
                </div>
            </div>

            <div className={classes.nav}>
                <div className={classes.nav_child1}></div>
                <div className={classes.nav_child2}>
                {
                    Structer.sections.length > 0 ?
                        <StyledTabs
                            value={structer.index}
                            onChange={handleChange}>
                            {
                                Structer.sections.map(prop => {
                                    return (
                                        <StyledTab key={prop.index} label={prop.bold ? <strong>{t(prop.label)}</strong> : t(prop.label)} icon={prop.icon} {...a11yProps(prop.index)} />
                                    )
                                })
                            }
                        </StyledTabs>
                        :
                        null
                }
                </div>
                <div className={classes.nav_child3}></div>
            </div>
            
            <div className={classes.subview}>
                {
                    structer.component
                }
            </div>
        </div>
    );
}

export default withRouter(ConsultaDetailView);