
import React, { useContext, useState, useEffect} from 'react';

import { makeStyles, Typography, CircularProgress} from '@material-ui/core/';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import DropRadioModal from '../../../../components/DropRadioModal';
import PopOverlayView, { usePopOverlay } from '../../../../components/PopOverlayView';
import PopupEditScheduleSubview from './PopupEditScheduleSubview';

import * as Utils from '../../../../service/utils';
import { ServiceContext } from '../../../../context/service/service-context';
import { AuthContext } from '../../../../context/auth/auth-context';

import Styles from './style';
import ScheduleCard from './components/ScheduleCard';
import ContactPopup from './components/ContactPopup';
import AttendancePopup from './components/AttendancePopup';


const useStyles = makeStyles(Styles);

const ScheduleSubview = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { Usados } = useContext(ServiceContext);
    const { cognitoUser } = useContext(AuthContext);
    const { state, init, close, setFooter } = usePopOverlay();

    const [filterItem, setFilterItem] = useState('all')

    const [sort, setSort] = useState(FILTER[0]);
    const [openDropRadioModal, setOpenDropRadioModal] = useState(false);
    const [dataDropRadioModal, setDataDropRadioModal] = useState(null);

    const [allEvents, setAllEvents] = useState([])

    const [filteredEvents, setFilteredEvents] = useState([])

    const [anchorContactEl, setAnchorContactEl] = useState(null);
    const [anchorAttendanceEl, setAnchorAttendanceEl] = useState(null);


    const [seletedEvent, setSeletedEvent] = useState(null);

    const [loading, setLoading] = useState(true)




    const onDropdownClick = () => {
        const _data = {
            title: 'Agenda',
            options: FILTER,
            selected: sort.id
        }
        setDataDropRadioModal(_data);
        setOpenDropRadioModal(true);
    }


    const onSelectRadio = (data) => {
        const _sort = FILTER.find(it => it.id === data.value)
        setSort(_sort);
        if (data.value === '0') {
            const _events = allEvents?.filter(it => {
                const _date = `${it?.registryDtSchedule}T${it?.registryTimeSchedule}`
                var now = new Date().getTime();
                var startOfDay = moment(now).startOf("day").toDate();
                return (new Date(_date).getTime() >= startOfDay.getTime())
            })
            const filterData = Utils.orderDataByMonthYear(_events);
            setFilteredEvents(filterData)
        } else {
            const _events = allEvents?.filter(it => {
                const _date = `${it?.registryDtSchedule}T${it?.registryTimeSchedule}`
                var now = new Date().getTime();
                var startOfDay = moment(now).startOf("day").toDate();
                return (new Date(_date).getTime() < startOfDay.getTime())
            })
            const filterData = Utils.orderDataByMonthYear(_events);
            setFilteredEvents(filterData)
        }
    }


    const changeRefHandler = (data) => {
        setSeletedEvent(data.event)
        if (data.type === 0) {
            setAnchorContactEl(data.ref)
        } else {
            setAnchorAttendanceEl(data.ref)
        }
    }


    const setEventAttendanceStatusHandler = async it => {
        setAnchorAttendanceEl(null)
        let params = null
        if (it === 1) {
            params = { action: 'checkIn', login: cognitoUser.login, idEvent: seletedEvent?.registryID}
        } else {
            params = { action: 'cancelCheckIn', login: cognitoUser.login, idEvent: seletedEvent?.registryID }
        }
        try {
            await Usados.SetEvent(params);
            fetchSchedules()
        } catch (error) {

        }
    }

    
    const setEventContactStatusHandler = async it => {
        setAnchorContactEl(null)
        try {
            let params = {
                action: 'changeContactStatus',
                login: cognitoUser.login,
                idEvent: seletedEvent?.registryID,
                body: {
                    idContactStatus: Number(it)
                }
            }
            await Usados.SetEvent(params);
            fetchSchedules()
        } catch (error) {

        }
    }

    const fetchSchedules = async () => {
        try {
            setLoading(true)
            const params = {
                idSalesman: cognitoUser.idUser,
                login: cognitoUser.login
            }
            const result = await Usados.GetSalesManSchedule(params);
            setAllEvents(result)
            let filterData = []
            switch (filterItem) {
                case 'all':
                    filterData = filterByDate(result)
                    setFilteredEvents(filterData)
                    break;

                case 'contact':
                    filterData = filterByDate(result)
                    filterData = sortData(filterData, 'registryIdContactStatus')
                    setFilteredEvents(filterData)
                    break;

                case 'attendance':
                    filterData = filterByDate(result)
                    filterData = sortData(filterData, 'registryDtCheckIn')
                    setFilteredEvents(filterData)
                    break

                default:
                    break;
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)

        }
    }

    const onSetFilterHandler = (filter) => {
        setFilterItem(filter)
        let filterData = []
        switch (filter) {
            case 'all':
                filterData = filterByDate(allEvents)
                setFilteredEvents(filterData)
                break;
            
            case 'contact':
                filterData = filterByDate(allEvents)
                filterData = sortData(filterData, 'registryIdContactStatus')
                setFilteredEvents(filterData)
                break;
            
            case 'attendance':
                filterData = filterByDate(allEvents)
                filterData = sortData(filterData, 'registryDtCheckIn')
                setFilteredEvents(filterData)
                break
        
            default:
                break;
        }
    }


    const sortData = (List, property) => {
        return List.map(it => {
            const item = {
                ...it,
                data: Utils.sortByProperty(it.data, property)
            }
            return item
        })
    }
    

    const filterByDate = (_allEvents) => {
        if (sort?.id === '0') {
            const _events = _allEvents?.filter(it => {
                const _date = `${it?.registryDtSchedule}T${it?.registryTimeSchedule}`
                var now = new Date().getTime();
                var startOfDay = moment(now).startOf("day").toDate();
                return (new Date(_date).getTime() >= startOfDay.getTime())
            })
            const filterData = Utils.orderDataByMonthYear(_events);
            return filterData
        } else {
            const _events = _allEvents?.filter(it => {
                const _date = `${it?.registryDtSchedule}T${it?.registryTimeSchedule}`
                var now = new Date().getTime();
                var startOfDay = moment(now).startOf("day").toDate();
                return (new Date(_date).getTime() < startOfDay.getTime())
            })
            const filterData = Utils.orderDataByMonthYear(_events);
            return filterData
        }
    }


    useEffect(() => {
        fetchSchedules()
    }, [])


    useEffect(() => {
        let filterData = []
        switch (filterItem) {
            case 'all':
                filterData = filterByDate(allEvents)
                setFilteredEvents(filterData)
                break;

            case 'contact':
                filterData = filterByDate(allEvents)
                filterData = sortData(filterData, 'registryIdContactStatus')
                setFilteredEvents(filterData)
                break;

            case 'attendance':
                filterData = filterByDate(allEvents)
                filterData = sortData(filterData, 'registryDtCheckIn')
                setFilteredEvents(filterData)
                break

            default:
                break;
        }
    }, [allEvents])


    const onEditScheduleHandler = (data) => {
        setSeletedEvent(data)
        init({
            header: {
                title: 'Alteração',
                back: {
                    hide: true
                },
                close: {
                    action: close,
                    hide: false
                }
            },
            footer: {
                hide: true
            },
            render: <PopupEditScheduleSubview confirmation={onConfirmationScheduleHandler}/>
        }, true);
    }

    const onConfirmationScheduleHandler = (data) => {
        close();
        editEventDate(data)
    }

    const editEventDate = async (data) => {
        try {
            let params = {
                action: 'changeSchedule',
                login: cognitoUser.login,
                idEvent: seletedEvent?.registryID,
                body: {
                    dtSchedule: data?.format.date,
                    timeSchedule: data?.format.timeMili
                }
            }
            await Usados.SetEvent(params);
            fetchSchedules()
        } catch (error) {

        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <span className={classes.header_title}>{cognitoUser?.displayName}</span>
                <span className={classes.header_subtitle}>{`${cognitoUser?.dealer.designation} - ${cognitoUser?.dealer.postalcodeExtension}`}</span>
            </div>
            <div className={classes.nav}>
                <div className={classes.actionsBoxDropdownContainer}>
                    <div className={classes.actionsBoxDropdownContainerInner}>
                        <div className={classes.actionsBoxDropdown} onClick={() => onDropdownClick()} >
                            <Typography variant="h6">{t(sort?.name)}</Typography>
                            <span className={classes.icon_nav} style={{ marginRight: '0px' }}>7</span>
                        </div>
                    </div>
                </div>
                
                <div className={classes.boxScrolled}>
                    {sort?.id === '0' ? (
                        <div className={classes.filterBoxContainer}>
                            <div className={classes.filterBoxContainerInner}>
                                <div className={clsx({
                                    [classes.filterItem]: true,
                                    [classes.filterItemActive]: filterItem === 'all'
                                })} onClick={() => onSetFilterHandler('all')}>
                                    <span>Todas</span>
                                </div>
                                <div className={clsx({
                                    [classes.filterItem]: true,
                                    [classes.filterItemActive]: filterItem === 'contact'
                                })} onClick={() => onSetFilterHandler('contact')}>
                                    <span>Contacto</span>
                                </div>
                                <div className={clsx({
                                    [classes.filterItem]: true,
                                    [classes.filterItemActive]: filterItem === 'attendance'
                                })} onClick={() => onSetFilterHandler('attendance')}>
                                    <span>Comparência</span>
                                </div>
                            </div>
                            {filteredEvents?.length ? (<Typography variant="h6">{t('schedule-pull-down-to-update')}</Typography>) : null}
                        </div>
                    ) : null}
                    <div className={classes.scheduleCardsContainer}>
                        {loading ? (
                            <div className={classes.loading}>
                                <CircularProgress className={classes.circularProgress} />
                            </div>
                        ) : (
                                filteredEvents?.length ? (
                                    filteredEvents?.map(ev => (
                                        <div className={classes.scheduleSectionContainer} key={ev.label}>
                                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                <div className={classes.line_header}></div>
                                                <div className={classes.label_header}>{ev.label?.toUpperCase().replace("DE", "")}</div>
                                                <div className={classes.line_header}></div>
                                            </div>
                                            <div className={classes.scheduleSectionContainerCardsBox} >
                                                <div className={classes.scheduleSectionContainerCardsBoxInner} >
                                                    {ev.data.map((it, index) => <ScheduleCard key={`${it.eventID}-${index}`} data={it} onClickShowPopup={data => changeRefHandler(data)}  onClickEditSchedule={onEditScheduleHandler} />)}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className={classes.noResults}>Sem resultados</div>
                                )
                        )}
                    </div>
                </div>
            </div>
            {openDropRadioModal ? (
                <DropRadioModal
                    open={openDropRadioModal}
                    data={dataDropRadioModal}
                    hiddenDefault={true}
                    hiddenButton={false}
                    onSelect={data => onSelectRadio(data)}
                    onClose={() => setOpenDropRadioModal(false)} />
            ) : null}
            <ContactPopup
                event={seletedEvent}
                anchorEl={anchorContactEl}
                onClose={() => setAnchorContactEl(null)}
                onSetEventStatus={setEventContactStatusHandler}
            />
            <AttendancePopup
                event={seletedEvent}
                anchorEl={anchorAttendanceEl}
                onClose={() => setAnchorAttendanceEl(null)}
                onSetEventStatus={setEventAttendanceStatusHandler}
            />
            <PopOverlayView />
        </div>
    );
}
export default withRouter(ScheduleSubview);


const FILTER = [
    { id: "0", name: 'Marcações' },
    { id: "1", name: 'Arquivo' }
]



