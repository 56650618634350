import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Styles from './style';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(Styles);

const DropdownText = ({
    id,
    children,
    data,
    count,
    title,
    onClick
}) => {


    const classes = useStyles();

    const [text, setText] = useState('')




    useEffect(() => {
        if (data === 'none') {
            setText('-')
        } else {
            if (Array.isArray(data)) {
                const _names = data.map(it => it.name)
                const _text = _names?.length ? _names?.join(',') : '-'
                setText(_text)
            } else {
                setText(data)
            }
        }
    }, [data])


    if (!data) return null

    if (!children) {
        return (
            <div className={classes.root} onClick={() => onClick(id)}>
                <div className={classes.inner}>
                    <div className={classes.left}>
                        <Typography variant='subtitle1' className={classes.title}>{title}</Typography>
                        <div className={classes.valueBox}>
                            {(count && data !== 'none') ? (<Typography variant='h5' className={classes.total}>({count})</Typography>) : null}
                            <Typography variant='h6' className={classes.text}>{text}</Typography>
                        </div>
                    </div>
                    <div className={classes.right}>
                        <span className={classes.icon}>7</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root} onClick={() => onClick(id)}>
            <div className={classes.box}>
                <div className={clsx({
                    [classes.inner]: true,
                    [classes.removeBorder]: children
                })}>
                    <div className={classes.left}>
                        <Typography variant='subtitle1' className={clsx({
                            [classes.tiitle]: true,
                            [classes.darkgray]: children
                        })}
                        >{title}</Typography>
                        <div className={classes.valueBox}>
                            {(count && data !== 'none') ? (<Typography variant='h5' className={classes.total}>({count})</Typography>) : null}
                            <Typography variant='h6' className={classes.text}>{text}</Typography>
                        </div>
                    </div>
                    <div className={classes.right}>
                        <span className={classes.icon}>7</span>
                    </div>
                </div>
            </div>
        </div>
    );


}

export default DropdownText;