
const Styles = theme => ({
    root: {
        zIndex: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        position: 'fixed'
    },
    pooper: {
        maxWidth: 260,
        marginTop: '-118px',
        left: '30px !important',
        right: '30px !important',
        '& .MuiPaper-root': {
            borderRadius: 10,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: theme.palette.white,
            padding: 0
        }
    },
    container: {
        width: 260,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    boxHeader: {
        width: '100%',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.verylightgray}`,
        textAlign: 'center',
        '& h2': {
            lineHeight: 1,
            textTransform: 'uppercase'
        }
    },
    boxInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: 10,
        padding: '0 20px',
        '& span': {
            marginBottom: 2
        },
        '& h2': {
            fontWeight: 700
        }
    },
    boxItems: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    item: {
        height: 48,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '& p': {
            fontSize: 16,
            lineHeight: 1.13,
            fontFamily: 'Open Sans',
            marginLeft: '10px'
        },
        '& span': {
            fontSize: 48,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font',
            color: '#cecece',
            marginRight: '10px'
        }
    },
    arrowUp: {
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid white',
        position: 'absolute',
        right: '48px',
        marginTop: '-10px'
    },
    itemActiveContact: {
        backgroundColor: '#000',
        '& p': {
            color: '#FFF'
        },
        '& span': {
            color: '#FFF'
        }
    },
    itemActiveContactLast: {
        backgroundColor: '#000',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        '& p': {
            color: '#FFF'
        },
        '& span': {
            color: '#FFF'
        }
    },
})

export default Styles;