
const Styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.fafafa
    },
    headerBox: {
        height: 48,
        paddingRight: 20,
        paddingLeft: 10,
        backgroundColor: theme.palette.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start !important',
        borderBottom: `1px solid ${theme.palette.verylightgray}`,
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        }
    },
    actionsBox: {
        width: '100%',
        height: 32,
        backgroundColor: theme.palette.white,
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center'
        }
    },
    actionsBoxLeft: {
        width: '29%',
        height: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'center',
        borderRight: `1px solid ${theme.palette.verylightgray}`,
        '& span': {
            cursor: 'pointer',
            fontSize: 32,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px'
        },
        [theme.breakpoints.up('md')]: {
            borderLeft: `1px solid ${theme.palette.verylightgray}`,
        }
    },
    actionsBoxCenter: {
        width: '29%',
        height: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'center',
        borderRight: `1px solid ${theme.palette.verylightgray}`,
        '& span': {
            fontSize: 32,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px'
        }
    },
    actionsBoxRight: {
        width: '71%',
        height: '100%',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h6': {
            fontSize: 13
        },
        '& span': {
            fontSize: 24,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
            borderRight: `1px solid ${theme.palette.verylightgray}`
        }
    },
    iconBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '10px',
        width: 32,
        height: 32,
        cursor: 'pointer',
        '& img': {
            width: '16px',
            height: '16px',
            objectFit: 'contain'
        }
    },
    content: {
        margin: 20,
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100% - 130px)',
        backgroundColor: theme.palette.fafafa,
        [theme.breakpoints.up('sm')]: {
            margin: '20px 0'
        },
    },
    red: {
        color: theme.palette.primary.main
    },
    limit_area: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.between('sm', 'lg')]: {
            maxWidth: '760px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1150px',
        },
    },
    buttonSeeMore: {
        width: 'calc(100% - 20px)',
        margin: '20px 20px 10px',
        '& button': {
            backgroundColor: theme.palette.darkgray,
            color: theme.palette.white,
            border: 'none'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '380px',
        },
    }
})

export default Styles;