import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { PublicRoutes } from '../routes';

import Styles from './style';

const useStyles = makeStyles(Styles);

const PublicLayout = () => {


    const classes = useStyles();
    return (
        <div className={classes.root}>
            <PublicRoutes />
        </div>
    );
}

export default PublicLayout;