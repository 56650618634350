
const Styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.fafafa
    },
    header_box: {
        padding: '0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000',
    },
    header: {
        maxHeight: '79px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '6px 0px',
        backgroundColor: '#000000',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.16)',
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff',
    },
    headerInvert: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '6px 20px',
        backgroundColor: '#444444',
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff;',
    },
    box: {
        width: '100%',
        position: 'relative',
    },
    box_root: {
        flex: 1,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    box_container: {
        padding: '15px 0px',
        overflowY: 'auto',
    },
    container: {
        padding: '15px 20px',
        [theme.breakpoints.up('limit')]: {
            padding: '15px 0px',
        },
    },
    row: {
        flex: 1,
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    row_center: {
        justifyContent: 'center',
        '& :nth-child(2)': {
            marginLeft: '30px'
        }
    },
    cell: {
        flex: 1,
        padding: '5px 0px',
        '& :nth-child(1)': {
            margin: 0,
            padding: 0,
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            margin: 0,
            padding: 0,
            ...theme.typography.title,
            fontSize: '14px',
        },
        '& :nth-child(3)': {
            margin: 0,
            padding: 0,
            ...theme.typography.body
        },
    },
    cellInvert: {
        flex: 1,
        padding: '5px 0px',
        '& :nth-child(1)': {
            margin: 0,
            padding: 0,
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            margin: 0,
            padding: 0,
            ...theme.typography.title,
            fontSize: '14px',
            color: '#ffffff'
        },
        '& :nth-child(3)': {
            margin: 0,
            padding: 0,
            ...theme.typography.body,
            color: '#ffffff'
        },
    },
    cell_2: {
        // flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        '& :nth-child(1)': {
            ...theme.typography.label,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '24px',
        }
    },
    cell_2Invert: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& :nth-child(1)': {
            ...theme.typography.label,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '24px',
            color:'#ffffff'
        }
    },
    field: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '&:nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '24px',
        }
    },

    field2: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '&:nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '18px',
        }
    },
    
    fieldInvert: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color:'#ffffff'
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '24px',
            color:'#ffffff'
        }
    },
    fieldInvert2: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color:'#ffffff'
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '16px',
            color:'#ffffff',
            marginTop: '6px'
        }
    },
    columnLeft: {
        flex: 1,
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
    },
    columnRight: {
        flex: 1,
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
    },
    collapse: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        overflow: 'hidden',
        '& #equipments-row': {
            width: '100%',
            ...theme.typography.body
        },
    },

    collapseInvert: {
        width: '100%',
        overflow: 'hidden',
        '& #equipments-row': {
            width: '100%',
            ...theme.typography.body,
            color: '#ffffff'
        },
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('limit')]: {
            width: '600px',
            margin: 'auto',
        },
    },





    button: {
        width: '100%',
        margin: '10px 5px'
    },

    buttonSelect: {
        margin: '15px 0px 5px 0px',
        '& button': {
            backgroundColor: '#eeeeee',
            color: '#000000'
        }
    },

    inputfield_bold: {
        width: '100%',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'center',
            fontWeight: 800
        }
    },

    inputfield: {
        width: '100%',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'center',
        }
    },

    inputfield_left: {
        width: '100%',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'left',
        }
    },

    time_row: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '16px !important',
        }
    },
    time_cell: {
        width: '95px',
        padding: '0px 7px 0px 0px',
        // backgroundColor: 'gray',s
    },
    date_cell: {
        width: '150px',
        padding: '0px 0px 0px 7px',
        // backgroundColor: 'brown',
    },
    text_cell:{
        ...theme.typography.body,
        fontSize: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center'
    },

    inputfield_time: {
        width: '48px',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'left',
        }
    },

    inputfield_date: {
        width: '122px',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'left',
        }
    },

    inputlabel: {
        width: '100%',
        height: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 0px',
        '& span': {
            ...theme.typography.label,
            fontSize: '11px',
            color: '#000000'
        },
    },
    inputarea_cell: {
        '& textarea': {
            margin: '10px',
            textAlign: 'left',
        }
    },
    inputarea: {
        height: '100% !important',
        textAlign: 'left !important',
        // '& input': {
        //     textAlign: 'left !important',
        // },
        // '& MuiInputBase-root': {
        //     minHeight: '67px !important'
        // }
    },
    line: {
        // flex: 1,
        margin: '10px 0px',
        height: '1px',
        backgroundColor: '#000000'
    },
    lineInvert: {
        flex: 1,
        margin: '10px 0px',
        height: '1px',
        backgroundColor: '#444444'
    },
    vertical: {
        width: '1px',
        height: '32px',
        margin: '0px 8px',
        backgroundColor: '#cecece'
    },

    verticalInvert: {
        width: '1px',
        height: '32px',
        margin: '0px 8px',
        backgroundColor: '#444444'
    },

    title: {
        ...theme.typography.titleBold,
        fontSize: '13px',
    },
    subtitle: {
        ...theme.typography.title,
        fontSize: '14px',
    },
    subtitleInvert: {
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff'
    },
    label: {
        ...theme.typography.title,
        fontSize: '10px',
        color: '#999999',
    },
    body: {
        ...theme.typography.body,
        fontSize: '13px',
        lineHeight: '1.15'
    },
    bodyInvert: {
        ...theme.typography.body,
        fontSize: '13px',
        lineHeight: '1.15',
        color: '#ffffff'
    },
    regular: {
        ...theme.typography.body,
        fontSize: '16px',
        lineHeight: '1.15',
        color: '#ffffff'
    },
    anchor: {
        ...theme.typography.label,
        margin: '10px 0px',
        cursor: 'pointer'
    },
    anchorInvert: {
        ...theme.typography.label,
        margin: '10px 0px',
        cursor: 'pointer',
        color: '#ffffff'
    },
    message: {
        ...theme.typography.body,
        color: '#999999'
    },
    icon: {
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#000000',
        marginRight: '6px',
        cursor: 'pointer'
    },


    

    model: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.titleBold,
            fontSize: '18px',
            lineHeight: '1'
        },
        '& :nth-child(3)': {
            ...theme.typography.title,
            fontSize: '14px',
        },
    },
    modelInvert: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.titleBold,
            fontSize: '18px',
            lineHeight: '1',
            color: '#ffffff'
        },
        '& :nth-child(3)': {
            ...theme.typography.title,
            fontSize: '14px',
            color: '#ffffff'
        },
    },
    license: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
        },
    },
    licenseInvert: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            color:'#ffffff'
        },
    },
    cliente: {
        flex: 1,
        display: 'flex',
        margin: '5px 0px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clienteInvert: {
        flex: 1,
        display: 'flex',
        margin: '5px 0px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '16px',
            color: '#ffffff'
        },
    },
    price: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
        },
        '& :nth-child(3)': {
            ...theme.typography.body,
            fontSize: '13px',
            color: '#999999'
        },
    },
    priceInvert: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            color: '#ffffff'
        },
        '& :nth-child(3)': {
            ...theme.typography.body,
            fontSize: '13px',
            color: '#999999'
        },
    },
    equipments: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px'
    },
    circularProgress: {
        color: '#DCDCDC'
    },



    cardTitle: {
        ...theme.typography.titleBold,
        fontSize: '18px',
        color:'#ffffff'
    },
    cardSubtitle: {
        ...theme.typography.title,
        fontSize: '16px',
        color:'#ffffff'
    },
    cardBody: {
        ...theme.typography.label,
        fontSize: '16px',
        color:'#cecece',
        fontWeight: 'normal'
    },
    cardBodySmall: {
        ...theme.typography.label,
        fontSize: '14px',
        color:'#cecece',
        fontWeight: 'normal'
    },
    legal: {
        width: '14px', 
        height: '14px', 
        borderRadius: '24px', 
        backgroundColor: '#000000', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'absolute', 
        top: '26px', 
        right: '-24px',
        cursor: 'pointer',
        '& :nth-child(1)': {
            fontFamily: 'Times New Roman',
            fontSize: '11px',
            fontWeight: 'bold',
            color: '#ffffff'
        },
    },

    cell_invert_footer: {
        flex: '1',
        display: 'flex',
        margin: '5px',
        height: '48px',
        backgroundColor: "#ffffff"
    },
    cell_footer: {
        flex: '1',
        display: 'flex',
        margin: '5px',
        height: '48px',
        borderRadius: '48px',
        border: 'solid 1px #444444'
    },
    item1: {
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }, 
    item2: {
        flex: '1.3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon_footer: {
        width: '32px',
        height: '32px',
        borderRadius: '32px',
        backgroundColor: '#000000',
        '& span': {
            fontSize: '32px',
            fontFamily: 'Cycloid-Font',
            margin: '0 143px 0 0',
            color: '#ffffff'
        }
    },
    title_footer: {
        ...theme.typography.titleBold,
        fontSize: '16px',
        textAlign:  'center',
        color: '#ffffff'
    },
    title_invert_footer: {
        ...theme.typography.titleBold,
        fontSize: '16px',
        textAlign:  'center',
    },
    text_footer: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
        color: '#ffffff'
    },
    label_footer: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
        color: '#ffffff'
    },
    text_invert_footer: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    label_invert_footer: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    vertical_footer: {
        width: '1px', 
        height: '100%', 
        backgroundColor: '#444444'
    },
    columnLeft_footer:{
        flex: 1,
        padding: '0px 10px',
        minHeight: '48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#999999',
            textAlign: 'right'
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '14px',
            textAlign: 'right',
            color: '#ffffff',
        }
    },
    columnRight_footer:{
        flex: 1,
        padding: '0px 10px',
        minHeight: '48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#999999',
            textAlign: 'left'
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '14px',
            color: '#ffffff',
            textAlign: 'left'
        }
    },
    middle_column:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
})

export default Styles;