
const Styles = theme => ({
    root: {
        flex: 1,
        padding: '30px',
        backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.03) 38%, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.fafafa
    },
    card: {
        flex: 1,
        maxWidth: '360px',
        height: '324px',
        borderRadius: 10,
        backgroundColor: theme.palette.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    textBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '40px',
    },
    logoBox: {
        height: 80,
        width: 80,
        marginBottom: '24px',
        '& img': {
            height: '100%',
            width: '100%'
        }
    }
})

export default Styles;