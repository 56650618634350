import React, { useState, useEffect } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';

import { useAppContext } from '../../../../context/app/app-context';

import InputText from '../../../../components/InputText';
import CardResultPI from '../../../../components/CardResultPI';
import DropRadioModal from '../../../../components/DropRadioModal';


import Styles from './style';

const FILTER = [
    { id: 'more recent', name: 'proposal-search-subview-filter1-label' },
    { id: 'less recent', name: 'proposal-search-subview-filter2-label' },
    { id: 'model asc', name: 'proposal-search-subview-filter3-label' },
    { id: 'model desc', name: 'proposal-search-subview-filter4-label' },
    { id: 'apelido asc', name: 'proposal-search-subview-filter5-label' },
    { id: 'apelido desc', name: 'proposal-search-subview-filter6-label' },
]

const useStyles = makeStyles(Styles);

const ActivePIView = ({
    src,
    tab,
    onSearch,
    isArchive
}) => {

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { setCarArchiveSelected } = useAppContext();

    const [openDropRadioModal, setOpenDropRadioModal] = useState(false);
    const [dataDropRadioModal, setDataDropRadioModal] = useState(null);

    const [sort, setSort] = useState(FILTER[0]);
    const [result, setResult] = useState([]);

    useEffect(() => {
        reorderList(sort)
    }, [src]);

    const reorderList = (sort) => {
        let _cars = [];
        switch (sort.id) {
            case 'more recent':
                _cars = src.sort((a, b) => new Date(b.dtCreated) - new Date(a.dtCreated));
                setResult(_cars);
                break;

            case 'less recent':
                _cars = src.sort((a, b) => new Date(a.dtCreated) - new Date(b.dtCreated));
                setResult(_cars);
                break;

            case 'model asc':
                _cars = src.sort((a, b) => a.car?.carModel?.description.localeCompare(b.car?.carModel?.description));
                setResult(_cars);
                break;

            case 'model desc':
                _cars = src.sort((a, b) => b.car?.carModel?.description.localeCompare(a.car?.carModel?.description));
                setResult(_cars);
                break;

            case 'apelido asc':
                _cars = src.sort((a, b) => a.client?.lastName.localeCompare(b.client?.lastName));
                setResult(_cars);
                break;

            case 'apelido desc':
                _cars = src.sort((a, b) => b.client?.lastName.localeCompare(a.client?.lastName));
                setResult(_cars);
                break;

            default:
                break;
        }
    }

    const searchPIRequest = useFormik({
        initialValues: {
            search: ''
        },
        onSubmit: async (values) => {
            try {
                onSearch(tab, values.search);
            } catch (error) {
                throw error
            }
        },
    });

    const onPIDetailHandler = (data) => {
        setCarArchiveSelected(data);
        history.push('/archive/detail', { ...data, isArchive: isArchive });
    }

    const extractCardData = (data) => {
        const params = {
            client: `${data.client?.firstName ? data.client?.firstName : ''} ${data.client?.lastName ? data.client?.lastName : ''}`,
            active: data?.car?.carReservation?.reservationEndDate,
            datePiEnd: data?.dtEnd,
            thumbnail: data.car?.carPhotos?.length > 0 ? data.car?.carPhotos[0].fileUrl : null,
            car: !data.car?.carBrand?.description && !data.car?.carModel?.description ? null : `${data.car?.carBrand?.description ? data.car?.carBrand?.description : ''} ${data.car?.carModel?.description ? data.car?.carModel?.description : ''}`,
            version: data.car?.carVersion?.description ? data.car?.carVersion?.description : null,
            reservation: data.car?.carReservation,
            status: data.car?.carStatus?.description
        }
        return params;
    }

    const clearSearchHandler = () => {
        searchPIRequest.setFieldValue('search', '');
    }

    const onDropdownClick = () => {
        const _data = {
            title: 'ordenação',
            options: FILTER,
            selected: sort.id
        }
        setDataDropRadioModal(_data)
        setOpenDropRadioModal(true)
    }

    const onSelectRadio = (data) => {
        const _sort = FILTER.find(it => it.id === data.value)
        reorderList(_sort)
        setSort(_sort)
    }

    return (
        <div className={classes.root}>

            <div className={classes.header_box}>
                <div className={classes.limit_area}>
                    <div className={classes.header}>
                        <form onSubmit={searchPIRequest.handleSubmit} style={{ width: '100%' }}>
                            <InputText
                                id="search"
                                name="search"
                                placeholder={t('proposal-search-subview-search-placeholder')}
                                value={searchPIRequest.values.search}
                                onChange={searchPIRequest.handleChange}
                                styles={classes.inputfield}
                                endAdornment={
                                    searchPIRequest.values.search.length > 0 ?
                                        <span onClick={clearSearchHandler} className={classes.icon}>F</span>
                                        :
                                        <span className={classes.icon}></span>
                                }
                            />
                        </form>
                    </div>
                </div>
            </div>

            <div className={classes.nav}>
                <div className={classes.nav_child1}></div>
                <div className={classes.nav_actions}>
                    <div className={classes.nav_child2}>
                        <div className={classes.actionsBoxRight} onClick={() => onDropdownClick()} >
                            <Typography variant="h6">{t(sort?.name)}</Typography>
                            <span className={classes.icon} style={{ marginRight: '0px' }}>7</span>
                        </div>
                    </div>
                    <div className={classes.nav_child3}></div>
                </div>
                <div className={classes.nav_child3}></div>
            </div>

            <div className={classes.box}>
                <div className={classes.container}>
                    {
                        result.map((prop, index) => {
                            const data = extractCardData(prop);
                            return (<CardResultPI key={index} data={data} onClick={() => onPIDetailHandler(prop)} btnVariant={tab === 0 ? 'contained' : 'outlined'} />);
                        })
                    }
                </div>
            </div>
            {openDropRadioModal ? (
                <DropRadioModal
                    open={openDropRadioModal}
                    data={dataDropRadioModal}
                    hiddenDefault={true}
                    hiddenButton={false}
                    onSelect={data => onSelectRadio(data)}
                    onClose={() => setOpenDropRadioModal(false)} />
            ) : null}
        </div>
    );
}

export default withRouter(ActivePIView);