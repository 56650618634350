
const Styles = theme => ({
    root: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.fafafa
    },
    header_box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff'
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        '& span': {
            width: '48px',
            height: '48px',
            fontSize: '48px',
            fontFamily: 'Cycloid-Font',
            cursor: 'pointer'
        },
        '& div': {
            ...theme.typography.titleBold,
            // flex: '1',
            maxWidth: '540px',
            height: '48px',
            marginLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textTransform: 'uppercase',
        },
        [theme.breakpoints.up('limit')]: {
            width: '600px !important',
            justifyContent: 'center',
        }
    },
    back_button: {
        [theme.breakpoints.up('limit')]: {
            marginLeft: '30px',
        },
    },
    nav: {
        width: '100%',
        height: '32px',
        display: 'flex',
        paddingTop: '2px',
        backgroundColor: '#eeeeee'
    },
    nav_child1: {
        height: '100%',
        borderRadius: '0px 6px 0px 0px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            flex: 1,
            margin: '0px 2px 0px 0px'
        },
    },
    nav_child2: {
        flex: 1,
        height: '100%',
        [theme.breakpoints.up('limit')]: {
            minWidth: '600px !important',
        },
    },
    nav_child3: {
        height: '100%',
        borderRadius: '6px 0px 0px 0px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            flex: 1,
        },
    },
    subview: {
        flex: 1,
        width: '100%',
        display: 'flex',
        overflow: 'hidden'
    },

    title: {
        margin: 0,
        padding: 0,
        ...theme.typography.title
    },
    body: {
        fontSize: '14px',
        ...theme.typography.body,
    },
    circularProgress: {
        color: '#DCDCDC'
    },
    loading: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '1',
        backgroundColor: 'rgba(250,250,250,0.9)'
    },
    custom_tab: {
        flex: 1,
        maxWidth: '100%',
        margin: '0px 2px 0px 0px',
        padding: '3px 3px 3px 3px',
        borderRadius: '6px 6px 0px 0px',
        backgroundColor: '#ffffff',
        color: '#000000',
        opacity: 1,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    custom_tab_reserved: {
        cursor: 'pointer',
        flex: 1,
        maxWidth: '100%',
        margin: '0px 2px 0px 0px',
        padding: '3px 3px 3px 3px',
        borderRadius: '6px 6px 0px 0px',
        backgroundColor: '#eeeeee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 1
    },
    custom_tab_cell1: {
        width: '24px',
        height: '100%',
        fontSize: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        fontFamily: 'Cycloid-Font',
    },
    custom_tab_cell2: {
        width: '70px',
        height: '100%',
    },
    middle_column: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    custom_tab_title: {
        ...theme.typography.body,
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'center',
    },
    custom_tab_text: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 1,
    },
    custom_tab_label: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 1,
    },
})

export default Styles;