import MuiTextField from './MuiTextField';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiButton from './MuiButton';
import MuiPaper from './MuiPaper';
import MuiDrawer from './MuiDrawer';
import MuiToolbar from './MuiToolbar';
import MuiTab from './MuiTab';
import MuiInput from './MuiInput';
import MuiDivider from './MuiDivider';
import MuiCheckbox from './MuiCheckbox';
import MuiRadio from './MuiRadio';
import MuiFormControlLabel from './MuiFormControlLabel';




const overrides =  {
    MuiTextField,
    MuiOutlinedInput,
    MuiButton,
    MuiPaper,
    MuiDrawer,
    MuiToolbar,
    MuiTab,
    MuiInput,
    MuiDivider,
    MuiCheckbox,
    MuiRadio,
    MuiFormControlLabel
}

export default overrides;