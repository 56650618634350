import { Hidden } from "@material-ui/core";

const Styles = theme => ({
    root: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.fafafa,
        overflow: 'hidden'
    },
    header_box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: '#ffffff'
    },
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        '& span': {
            width: '48px',
            height: '48px',
            fontSize: '48px',
            fontFamily: 'Cycloid-Font',
        },
        '& div': {
            ...theme.typography.titleBold,
            flex: '1',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textTransform: 'uppercase',
        },
        [theme.breakpoints.up('limit')]: {
            width: '600px !important',
        }
    },
    nav: {
        width: '100%',
        height: '32px',
        display: 'flex',
        paddingTop: '2px',
        overflow: 'hidden'
    },
    nav_child1: {
        height: '100%',
        borderRadius: '0px 6px 0px 0px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            flex:1,
            margin: '0px 2px 0px 0px'
        },
    },
    nav_child2: {
        flex:1,
        height: '100%',
        [theme.breakpoints.up('limit')]: {
            minWidth: '600px !important',
        },
    },
    nav_child3: {
        height: '100%',
        borderRadius: '6px 0px 0px 0px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            flex:1,
        },
    },
    searchNav: {
        width: '100%', 
        height: '32px', 
        display: 'flex', 
        marginTop: '2px', 
        bordeRadius: '0px 6px 0px 0px', 
        backgroundColor: '#ffffff',
        overflow: 'hidden'
    },
    subview: {
        flex: 1,
        width: '100%',
        display: 'flex',
        overflow: 'hidden'
    },
    row: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            ...theme.typography.titleBold,
            fontSize: '13px',
            lineHeight: '1.15'
        }
    },
    title: {
        margin: 0,
        padding: 0,
        ...theme.typography.title
    },
    body: {
        fontSize: '14px',
        ...theme.typography.body,
    },
    label: {
        margin: 0,
        padding: 0,
        ...theme.typography.label,
    },
    circularProgress: {
        color: '#DCDCDC'
    },
    icon: {
        width: '32px',
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#000000',
    },
    loading: {
        width: '100%', 
        height: '100%', 
        justifyContent: 'center', 
        alignItems: 'center', 
        position: 'fixed', 
        zIndex: '5', 
        backgroundColor: 'rgba(250,250,250,0.9)'
    }
})

export default Styles;