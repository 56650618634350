import React, { useContext, useState } from 'react';

import { makeStyles } from '@material-ui/core/';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PopupAlert from '../../../../components/PopupAlert';

import Button from '../../../../components/Button';

import * as Utils from '../../../../service/utils';
import { ServiceContext } from '../../../../context/service/service-context';
import { useAppContext } from '../../../../context/app/app-context';
import { AuthContext } from '../../../../context/auth/auth-context';
import { useNavBar } from '../../';

import Styles from './style';

const useStyles = makeStyles(Styles);

const ClienteSubview = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { carArchiveSelected, setCarArchiveSelected, setPopupAlert, setRefresh } = useAppContext();
    const { Usados } = useContext(ServiceContext);
    const { setRequestStatus } = useNavBar();
    const { cognitoUser, brandAccess } = useContext(AuthContext);
    const [openPopUpConfirm, setOpenPopUpConfirm] = useState(false);


    const reserveVehicle = async () => {
        const params = {
            idClientOrigin: brandAccess,
            idPI: carArchiveSelected?.id,
            login: cognitoUser.login,
            plateNumber: carArchiveSelected?.plateNumber,
            vin: carArchiveSelected?.vin
        }
        try {
            setRequestStatus(true);
            const result = await Usados.ReserveVehicle(params)
            let reservation = { ...carArchiveSelected?.car, carReservation: result?.reserveVehicle?.vehicleReservation }
            setCarArchiveSelected({ ...carArchiveSelected, car: reservation });
            history.push({ state: { ...carArchiveSelected, car: reservation } });
            setRequestStatus(false);
            setRefresh(true)
        } catch (error) {
            setRequestStatus(false);
            console.log(error);
        }
    }

    const printPI = async (idPI) => {
        const params = {
            login: cognitoUser.login,
            idPI: idPI,
        }
        try {
            setRequestStatus(true);
            await Usados.PrintProposal(params);
            setRequestStatus(false);
        } catch (error) {
            setRequestStatus(false);
        }
    }

    const sendPI = async (idPI) => {
        const params = {
            login: cognitoUser.login,
            idPI: idPI,
        }
        try {
            setRequestStatus(true);
            await Usados.SendProposal(params);
            setRequestStatus(false);
        } catch (error) {
            setRequestStatus(false);
        }
    }

    const handleRemoveReservation = () => {
        const popupAlerData = {
            description: t('cancel-reservation-confirm')
        }
        setPopupAlert(popupAlerData)
        setOpenPopUpConfirm(true)       
    }


    const onConfirmCancelReservation = async () => {
        setOpenPopUpConfirm(false)
        setRequestStatus(true);
        try {
            const params = {
                idPI: carArchiveSelected?.car?.carReservation?.extIdInOrigin,
                login: cognitoUser?.login,
                plateNumber: carArchiveSelected?.car?.plateNumber,
                vin: carArchiveSelected?.car?.vin
            }
            await Usados.CancelReserveVehicle(params);
            let reservation = { ...carArchiveSelected?.car, carReservation: null }
            setCarArchiveSelected({ ...carArchiveSelected, car: reservation });
            history.push({ state: { ...carArchiveSelected, car: reservation , refresh: true} });
            setRequestStatus(false);
            setRefresh(true)
        } catch (error) {
            setRequestStatus(false);

        }
    }



    const onFooterRender = () => {
        let status = carArchiveSelected?.car?.carStatus?.description;
        let reservation = carArchiveSelected?.car?.carReservation;

        if (status == "Ativo") {
            if (!reservation) {
                return (
                    <div className={classes.footer}>
                        <div className={classes.limit_area}>
                            <div className={classes.row} onClick={reserveVehicle}>
                                <Button styles={classes.button}>{t('footer-proposal-btn1-label')}</Button>
                            </div>
                            <div className={classes.row}>
                                <Button variant='outlined' styles={classes.button} onClick={() => sendPI(carArchiveSelected?.id)}>{t('footer-proposal-btn2-label')}</Button>
                                <Button variant='outlined' styles={classes.button} onClick={() => printPI(carArchiveSelected?.id)}>{t('footer-proposal-btn3-label')}</Button>
                            </div>
                        </div>
                    </div>
                );
            } else {
                if (reservation?.salesman?.idUser == cognitoUser.idUser) {
                    return (
                        <div className={classes.footer}>
                            <div className={classes.limit_area}>
                                <div className={classes.row}>
                                    <div className={classes.cell}>
                                        <div className={classes.item1} style={{cursor: 'pointer'}} onClick={() => handleRemoveReservation()} ><div className={classes.icon}><span>h</span></div></div>
                                        <div className={classes.item2}><div className={classes.title_footer}>{t('footer-proposal-text-label1')}</div></div>
                                        <div className={classes.item1}>
                                            <div className={classes.middle_column}>
                                                <span className={classes.text_footer}>{`${t('footer-proposal-text-label2')} ${carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car?.carReservation?.reservationEndDate).time : '-'}`}</span>
                                                <span className={classes.label_footer}><strong>{carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car?.carReservation?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <Button variant='outlined' styles={classes.button} onClick={() => sendPI(carArchiveSelected?.id)}>{t('footer-proposal-btn2-label')}</Button>
                                    <Button variant='outlined' styles={classes.button} onClick={() => printPI(carArchiveSelected?.id)}>{t('footer-proposal-btn3-label')}</Button>
                                </div>
                            </div>
                        </div >
                    );
                } else {
                    return (
                        <div className={classes.footer}>
                            <div className={classes.limit_area}>
                                <div className={classes.row}>
                                    <div className={classes.cell}>
                                        <div className={classes.item1} style={{cursor: 'pointer'}} onClick={() => handleRemoveReservation()} ><div className={classes.icon_invert}><span>h</span></div></div>
                                        <div className={classes.item2}><div className={classes.title_footer}>{t('footer-proposal-text-label3')}</div></div>
                                        <div className={classes.item1}>
                                            <div className={classes.middle_column}>
                                                <span className={classes.text_footer}>{`${t('footer-proposal-text-label2')} ${carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car.carReservation?.reservationEndDate).time : '-'}`}</span>
                                                <span className={classes.text_footer}><strong>{carArchiveSelected.car?.carReservation?.reservationEndDate ? Utils.formatStringDateTime(carArchiveSelected.car.carReservation?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.row}>
                                    <div className={classes.columnLeft}>
                                        <a>{t('footer-proposal-text-label4')}</a>
                                        <a>{reservation?.salesman?.name ? reservation?.salesman?.name : '-'}</a>
                                    </div>
                                    <div className={classes.vertical}></div>
                                    <div className={classes.columnRight}>
                                        <a>{t('footer-proposal-text-label5')}</a>
                                        <a>{`${reservation?.salesman?.dealer?.comercialName ? reservation?.salesman?.dealer?.comercialName : reservation?.salesman?.dealer?.desig} - ${reservation?.salesman?.dealer?.cpExt ? reservation?.salesman?.dealer.cpExt : '-'}`}</a>
                                    </div>
                                </div>
                            </div>
                        </div >
                    );
                }
            }
        } else {
            return (
                <div className={classes.footer} style={{ backgroundColor: '#eeeeee' }}>
                    <div className={classes.limit_area}>
                        <div className={classes.row} style={{ justifyContent: 'center' }}>
                            <span className={classes.icon_span}>F</span>
                        </div>
                        <div className={classes.row} style={{ justifyContent: 'center' }}>
                            <span className={classes.label_footer_bold}>Veículo indisponível</span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div>{t('proposal-detail-subview-cliente-header-title')}</div>
            </div>

            <div className={classes.box}>
                <div className={classes.container}>
                    <div className={classes.limit_area}>

                        <div className={classes.area}>
                            <div className={classes.column}>
                                <div className={classes.label}><span>{t('proposal-detail-subview-cliente-text1-label')}</span></div>
                                <div className={classes.row}>
                                    <div className={classes.icon_invert}><span>-</span></div>
                                    <span className={classes.body_small}>{carArchiveSelected?.dtEnd ? Utils.formatStringDateTime(carArchiveSelected?.dtEnd).string : '-'}</span>
                                </div>
                            </div>

                            <div className={classes.line}></div>

                            <div className={classes.column}>
                                <div className={classes.label}><span>{t('proposal-detail-subview-cliente-text2-label')}</span></div>
                                <div className={classes.title}><span>{`${carArchiveSelected?.client?.firstName ? carArchiveSelected?.client?.firstName : ''} ${carArchiveSelected?.client?.lastName ? carArchiveSelected?.client?.lastName : ''}`}</span></div>
                            </div>
                            <div className={classes.column}>
                                <div className={classes.label}><span>{t('proposal-detail-subview-cliente-text3-label')}</span></div>
                                <div className={classes.body}><span>{carArchiveSelected?.client?.phone ? carArchiveSelected?.client?.phone : '-'}</span></div>
                            </div>
                            <div className={classes.column}>
                                <div className={classes.label}><span>{t('proposal-detail-subview-cliente-text4-label')}</span></div>
                                <div className={classes.body}><span>{carArchiveSelected?.client?.email ? carArchiveSelected?.client?.email : '-'}</span></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {
                !history.location.state.isArchive ?
                    onFooterRender()
                    : null
            }
            {openPopUpConfirm && <PopupAlert
                open={openPopUpConfirm}
                type="ALERT_CONFIRM"
                onCancel={() => setOpenPopUpConfirm(false)}
                onClose={() => setOpenPopUpConfirm(false)}
                onConfirm={() => onConfirmCancelReservation()}
            />}
        </div>
    );
}

export default withRouter(ClienteSubview);