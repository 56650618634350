import React, { useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Styles from './style';
import { Divider, makeStyles, Modal, Typography, FormControlLabel, Checkbox   } from '@material-ui/core';
import Button from '../../components/Button';
import { usePushAlert } from '../../components/PushAlert';


const useStyles = makeStyles(Styles);

const DropdownModal = ({
    open,
    data,
    onSubmit,
    onClose
}) => {


    const { triggerPushAlert } = usePushAlert();

    const classes = useStyles();
    const { t } = useTranslation();

    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedOptionsSeted, setSelectedOptionsSeted] = useState([])

    const { title } = data

    const scrollRef = useRef(null)




    const handleChange = (opt, event) => {
        //Select or desselect all
        if (opt.id === '-1') {
            const _options = options.map(it => {
                it.checked = event.target.checked
                return it
            })
            setOptions(_options)
        } else {
            let optionsCopy = [];
            const option = options.find(it => it.id === opt.id)
            const index = options.findIndex(it => it.id === opt.id)
            optionsCopy = [...options]
            const _option = {
                ...option,
                checked: event.target.checked
            }
            optionsCopy[index] = _option
            setOptions(optionsCopy)
            if (opt.parent && event.target.checked) {
                const _options = options.map(it => {
                    if (it.id.includes(opt.id)) {
                        it.checked = true
                    }
                    return it
                })
                setOptions(_options)
            } else if (opt.parent && !event.target.checked) {
                const _options = options.map(it => {
                    if (opt.children.includes(it.id)) {
                        it.checked = false
                    }
                    if (it.id === opt.id) {
                        it.checked = false;
                    }
                    return it
                })
                setOptions(_options)

            } else if (opt.id !== '-1' && !event.target.checked) {
                const optionAll = {
                    ...optionsCopy[0],
                    checked: false
                }
                optionsCopy[0] = optionAll
                const index = optionsCopy.findIndex(it => it.id === opt.parentId)
                const optionParent = {
                    ...optionsCopy[index],
                    checked: false
                }
                optionsCopy[index] = optionParent
                setOptions(optionsCopy)
            } 
        }
    };


    const includes = (value, list) => {
        const obj = list.filter(it => it.id === value)
        return (obj && obj.length)
    }

    const onClean = () => {
        const _options = setupInit(data?.default)
        setOptions(_options)
        setSelectedOptions(data.selected)
        scrollRef?.current.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const setupInit = (list) => {
        const _state = data.options;
        let arrayOptions = []
        let obj = {}
        obj['-1'] = { id: '-1', name: 'Todos', checked: includes('-1', list), parent: true }
        arrayOptions.push(obj['-1'])
        for (var i = 0; i < _state.length; ++i) {
            obj[_state[i].id] = { ..._state[i], checked: includes(_state[i].id, list), parent: true, children: _state[i]?.children.map(it => it.id) };
            arrayOptions.push(obj[_state[i].id])
            if (_state[i]?.children && _state[i]?.children.length > 1) {
                for (var j = 0; j < _state[i]?.children.length; ++j) {
                    obj[_state[i]?.children[j].id] = { ..._state[i]?.children[j], parent: false, parentId: _state[i].id, checked: includes(_state[i]?.children[j].id, list) }
                    arrayOptions.push(obj[_state[i]?.children[j].id])
                }
            }
        }
        return arrayOptions
    }

    const submitHandler = () => {
        const selecteds = options.filter(it => it.checked)
        if (selecteds?.length) {
            onSubmit(selecteds)
        } else {
            throw triggerPushAlert({ title: 'Ocorreu um erro', description: 'Tem de seleccionar pelo menos uma Concessão', icon: 'info' });
        }
    }


    useEffect(() => {
        const _options = setupInit(data.selected)
        setOptions(_options)
        setSelectedOptions(data.selected)
    }, [data.default, data.options, data.selected])



    useEffect(() => {
        const all = selectedOptions.filter(it => it.id === '-1')
        if (all && all.length) {
            setSelectedOptionsSeted([...all])
        } else {
            const parents = selectedOptions.filter(it => it.children)
            const childrens = selectedOptions.filter(it => !it.children)
            const parentsIds = parents.map(it => it.id)
            const childrensOnly = childrens.filter(it => {
            const index = it.id?.indexOf("#")
            if (index !== -1) {
                return !parentsIds.includes(it.id?.substring(0, index))
            } else {
                return !parentsIds.includes(it.id)
            }
        })
            setSelectedOptionsSeted([...parents, ...childrensOnly])
        }
      
    }, [selectedOptions])


   

    return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="dropdown-modal-title"
                aria-describedby="dropdown-modal-description"
            >
                <div className={classes.root}>
                    <div className={classes.container}>
                        <div className={classes.boxHeader}>
                            <span onClick={onClose}>n</span>
                            <Typography variant='h2' style={{textTransform: 'uppercase'}}>
                                {t(title)}
                            </Typography>
                        </div>
                    <div className={classes.boxBody} ref={scrollRef}>
                        <div className={classes.boxSeleted}>
                            <Typography variant='h3' className={classes.boxSeletedTitle}>
                                Seleccionado(s):
                            </Typography>
                            {selectedOptionsSeted[0]?.id !== '-1' ?
                                (
                                    selectedOptionsSeted.map(opt => (
                                        <Typography key={opt.id} variant='h3' className={clsx({
                                            [classes.boxSeletedTitleValue]: true,
                                            [classes.bold]: opt.children
                                        })}>
                                            {opt.children ? `(${opt?.children?.length}) `: ''}{opt.name}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography key={selectedOptionsSeted[0].id} variant='h3' className={clsx({
                                        [classes.boxSeletedTitleValue]: true,
                                        [classes.bold]:true
                                    })}>
                                        {selectedOptionsSeted[0].name}
                                    </Typography>
                                )
                            }
                        </div>
                        <Divider className={classes.divider}/>
                        <div className={classes.boxContent}>
                            {options.map((opt, index) => ( 
                                <FormControlLabel
                                    key={`${opt.id}-${index}`}
                                    className={clsx({
                                        [classes.formControlLabel]: true,
                                        [classes.marginLeft]: !opt.parent
                                    })}
                                    control={
                                        <Checkbox
                                            checked={Boolean(opt.checked)}
                                            onChange={(event) => {
                                                handleChange(opt, event)
                                                }
                                            }
                                            name={opt.id}
                                            color="default"
                                        />
                                    }
                                    label={
                                        <Typography variant='h3' className={clsx({
                                            [classes.ckeckBoxLabel]: true,
                                            [classes.bold]: (opt?.children && opt?.children?.length > 1)
                                        })}>
                                            {opt.name}
                                         </Typography>}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={classes.searchActionsBox}>
                        <div className="searchActionsBoxInner">
                            <Button
                                variant='outlined'
                                styles={classes.buttonSearchBlack}
                                onClick={() => onClean()}
                            >
                                Limpar
                            </Button>
                            <Button
                                styles={classes.buttonSearch}
                                onClick={() => submitHandler()}
                            >{t('Submeter')}</Button>
                        </div>
                    </div>
                    </div>
                </div>
            </Modal>
    );
}

export default DropdownModal;