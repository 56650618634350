export const searchClients = /* GraphQL */ `
  query SearchClients($clients: SearchClientsInput) {
    searchClients(clients: $clients) {
      clientResponseList {
        id
        client {
          id
          lastName
          firstName
          phone
          email
          nif
          dtCreated
          changedBy
          dtChanged
          createdBy
        }
        plateNumber
        vin
        car {
          carBrand {
            description
          }
          carVersion {
            description
          }
          carModel {
            description
          }
        }
        idClient
        idStatus
        oidDealer
        dtEnd
      }
      message
      code
    }
  }
`;
export const createPi = /* GraphQL */ `
  query CreatePi($createPI: CreatePIInput) {
    createPI(createPI: $createPI) {
      pi {
        id
        client {
          id
          lastName
          firstName
          phone
          email
          nif
          dtCreated
          changedBy
          dtChanged
          createdBy
        }
        price
        plateNumber
        vin
        conditions
        rent
        car {
          id
          type
          idCar
          plateNumber
          vin
          pvp
          idBrand
          idCompany
          month
          year
          reference
          idModel
          idStatus
          idLocation
          idBodyType
          oidDealer
          outstand
          vc
          idVersion
          doorCount
          idFuel
          idSupplier
          idWebCategory
          km0
          kilometres
          keyNumber
          warranty
          power
          dtReception
          vatApply
          vnPrice
          specialOffer
          carPhotos {
            changedBy
            createdBy
            dtChanged
            dtCreated
            fileUrl
            id
            idCar
            priority
          }
          seatCount
          dtRelease
          displacement
          dtItv
          itvLastResult
          webPublished
          dtCreated
          changedBy
          dtChanged
          createdBy
          idExteriorColor
          combinedConsumption
          idStatusSupplier
          idInteriorColor
          dtRegistration
          distributorsSalePrice
          highwayConsumption
          idTransmission
          carOptionalEquipment
          dtLastInspection
          extIdInSupplier
          maintenanceBook
          urbanConsumption
          importedVehicle
        }
        idClient
        discount
        description
        idCarStock
        priceWithDiscount
        idStatus
        oidDealer
        dtCreated
        changedBy
        dtChanged
        createdBy
        idUser
      }
      message
      code
    }
  }
`;
export const listPi = /* GraphQL */ `
  query ListPi($listPI: ListPIInput) {
    listPI(listPI: $listPI) {
      pi {
        id
        client {
          id
          lastName
          firstName
          phone
          email
          nif
          dtCreated
          changedBy
          dtChanged
          createdBy
        }
        financeInfoSimulated {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
        financeInfoRecommended {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
        price
        plateNumber
        vin
        conditions
        rent
        car {
          id
          type
          idCar
          plateNumber
          vin
          pvp
          idBrand
          idCompany
          month
          year
          reference
          idModel
          idStatus
          idLocation
          idBodyType
          oidDealer
          outstand
          vc
          idVersion
          doorCount
          idFuel
          idSupplier
          idWebCategory
          km0
          kilometres
          keyNumber
          warranty
          power
          dtReception
          vatApply
          vnPrice
          specialOffer
          carPhotos {
            fileUrl
          }
          seatCount
          dtRelease
          displacement
          dtItv
          itvLastResult
          webPublished
          dtCreated
          changedBy
          dtChanged
          createdBy
          idExteriorColor
          combinedConsumption
          idStatusSupplier
          idInteriorColor
          dtRegistration
          distributorsSalePrice
          highwayConsumption
          idTransmission
          carOptionalEquipment
          dtLastInspection
          extIdInSupplier
          maintenanceBook
          urbanConsumption
          importedVehicle
          highlightedEquipment
          carReservation {
            changedBy
            createdBy
            dtChanged
            dtCreated
            extIdInOrigin
            id
            idCar
            idClientOrigin
            idConfig
            reservationEndDate
            reservationStartDate
            salesman {
              dealer {
                comercialName
                cpExt
                desig
              }
              email
              idUser
              name
            }
          }
          carBrand {
            description
            id
            idCar
          }
          carModel {
            description
            id
            idCar
          }
          carFuel {
            description
            idCar
            id
          }
          carStatus {
            idCar
            id
            description
          }
          carVersion {
            idCar
            id
            description
          }
          carExteriorColor {
            idCar
            id
            description
          }
          carLocation {
            idCar
            id
            description
          }
          carSupplier {
            idCar
            id
            description
          }
        }
        idClient
        discount
        description
        idCarStock
        priceWithDiscount
        idStatus
        oidDealer
        dtCreated
        changedBy
        dtChanged
        createdBy
        idUser
        dtEnd
      }
      message
      code
    }
  }
`;
export const authenticationExtraDirectLambda = /* GraphQL */ `
  query AuthenticationExtraDirectLambda($authentication: AuthenticationInput) {
    authenticationExtraDirectLambda(authentication: $authentication) {
      code
      message
      user {
        preferredLanguage
        idUser
        login
        displayName
        mail
        hash
        profiles {
          id
          name
        }
        dealer {
          objectId
          designation
          address
          postalcodeExtension
        }
        dealerParent {
          objectId
          designation
          address
          postalcodeExtension
        }
        dealersWithAccess {
          objectId
          designation
          address
          postalcodeExtension
        }
        brandsWithAccess {
          id
          name
        }
        dealerStructure {
          id
          name
          dealers {
            id
            name
          }
        }
        dealerGroup
      }
    }
  }
`;
export const searchStatus = /* GraphQL */ `
  query SearchStatus($searchStatus: SearchStatusInput) {
    searchStatus(searchStatus: $searchStatus) {
      vehicleReservation {
        id
        idCar
        idConfig
        extIdInOrigin
        dtCreated
        changedBy
        dtChanged
        createdBy
        reservationStartDate
        idClientOrigin
        reservationEndDate
        salesman {
          idUser
          name
          email
          dealer {
            comercialName
            cpExt
            desig
          }
        }
      }
      message
      code
    }
  }
`;





