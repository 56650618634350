import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth/auth-context';
import * as Utils from '../../service/utils';
import Styles from './style';

const useStyles = makeStyles(Styles);

const CardResultPI = ({
    data,
    onClick,
    btnVariant
}) => {

    const classes = useStyles();
    const { cognitoUser } = useContext(AuthContext);
    const { t } = useTranslation();

    const onClickHandler = (event) => {
        onClick();
    }

    const onArea4Render = () => {
        let reservation = data?.reservation;

        if (data?.status != "Ativo") {
            return (
                <div className={classes.area4} style={{ backgroundColor: '#eeeeee' }}>
                    <div className={classes.column}>
                        <div className={classes.cell}>
                            <div style={{ width: '32px', height: '32px', borderRadius: '32px' }}>
                                <span className={classes.icon_drk} style={{ color: "#999999", fontSize: '40px' }}>F</span>
                            </div>
                        </div>
                    </div>
                    <Button styles={classes.button} onClick={onClickHandler} variant={btnVariant}>{t('comp-card-pi-btn-label')}</Button>
                    <div className={classes.column}></div>
                </div>
            );
        } else {
            if (!reservation) {
                return (
                    <div className={classes.area4}>
                        <div className={classes.column}></div>
                        <Button styles={classes.button} onClick={onClickHandler} variant={btnVariant}>{t('comp-card-pi-btn-label')}</Button>
                        <div className={classes.column}></div>
                    </div>
                );
            } else {
                if (reservation?.salesman?.idUser == cognitoUser.idUser) {
                    return (
                        <div className={classes.area4}>
                            <div className={classes.column}>
                                <div className={classes.cell}>
                                    <div style={{ width: '32px', height: '32px', borderRadius: '32px', backgroundColor: '#000000' }}>
                                        <span className={classes.icon_lgt}>h</span>
                                    </div>
                                </div>
                            </div>
                            <Button styles={classes.button} onClick={onClickHandler} variant={btnVariant}>{t('comp-card-pi-btn-label')}</Button>
                            <div className={classes.column}>
                                <div className={classes.cell}>
                                    <span className={classes.label}>{data.active ? `${t('comp-card-pi-text1-label')} ${Utils.formatStringDateTime(data.active).time}` : '-'}</span>
                                </div>
                                <div className={classes.cell}>
                                    <span className={classes.label}><strong>{data.active ? `${Utils.formatStringDateTime(data.active).day} ${Utils.formatStringDateTime(data.active).month.toUpperCase().substring(0, 3)}` : '-'}</strong></span>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className={classes.area4}>
                            <div className={classes.column}>
                                <div className={classes.cell}>
                                    <div style={{ width: '32px', height: '32px', borderRadius: '32px' }}>
                                        <span className={classes.icon_drk}>h</span>
                                    </div>
                                </div>
                            </div>
                            <Button styles={classes.button} onClick={onClickHandler} variant={btnVariant}>{t('comp-card-pi-btn-label')}</Button>
                            <div className={classes.column}>
                                <div className={classes.cell}>
                                    <span className={classes.label}>{data.active ? `${t('comp-card-pi-text1-label')} ${Utils.formatStringDateTime(data.active).time}` : '-'}</span>
                                </div>
                                <div className={classes.cell}>
                                    <span className={classes.label}><strong>{data.active ? `${Utils.formatStringDateTime(data.active).day} ${Utils.formatStringDateTime(data.active).month.toUpperCase().substring(0, 3)}` : '-'}</strong></span>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.area1}>
                <span className={classes.title}>{data.client ? data.client : '-'}</span>
            </div>
            <div className={classes.area2}>
                <span className={classes.icon_drk}>-</span>
                <span className={classes.text}>{data.datePiEnd ? Utils.formatStringDateTime(data.datePiEnd).string : '-'}</span>
            </div>
            <div className={classes.area3}>
                <div className={classes.row}>
                    {
                        data.thumbnail ? <img className={classes.image} src={data.thumbnail} alt="" /> : <div className={classes.placeholder}><span>.</span></div>
                    }
                    <div className={classes.column}>
                        <div className={classes.cell}>
                            <div className={classes.subtitle}>{data.car ? data.car : '-'}</div>
                        </div>
                        <div className={classes.cell}>
                            <div className={classes.description}>{data.version ? data.version : '-'}</div>
                        </div>
                    </div>
                </div>
            </div>
            {onArea4Render()}
        </div>
    );
}

export default CardResultPI;