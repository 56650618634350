import search_header_lexus from './search_header_lexus.png';
import search_header_toyota from './search_header_toyota.png';
import icon_config from './icon-config.png';
import logo_toyota from './logo-toyota.png';
import logo_lexus from './logo-lexus.png';
import logo_text_toyota from './logo-text-toyota.png';
import logo_text_lexus from './logo-text-lexus.png';

const Images = {
    search_header_lexus: search_header_lexus,
    search_header_toyota: search_header_toyota,
    icon_config: icon_config,
    logo_toyota: logo_toyota,
    logo_lexus: logo_lexus,
    logo_text_toyota: logo_text_toyota,
    logo_text_lexus: logo_text_lexus
}

export default Images;