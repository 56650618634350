import palette from '../palette';


const MuiToolbar = {
    root: {
        backgroundColor: palette.primary.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        padding: '0 !important'
    }
};

export default MuiToolbar;