import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment-precise-range-plugin';

import * as Utils from '../../../../../service/utils';

import Styles from './style';

const useStyles = makeStyles(Styles);

const PopupProposalSubview = ({
    data
}) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const [collapse, setCollapse] = useState({ equipments: false, finance: false, date: false });

    const calculateAge = (date) => {
        var start = moment(date);
        var time = moment.preciseDiff(start, moment(), true);
        return `(${time.years} ${t('search-proposal-subview-container-vehicle-text4-label1')} ${time.months} ${t('search-proposal-subview-container-vehicle-text4-label2')})`
    }

    const calculateKmPerYear = (km, date) => {
        var start = moment(date);
        var time = moment.preciseDiff(start, moment(), true);
        var average = time.years > 0 ? km / time.years : km;
        return `(${Utils.formatMetric(average)} Km / Ano)`
    }

    const showEquipmment = () => {
        setCollapse({ ...collapse, equipments: !collapse.equipments });
    }

    const formatStringVersion = (value) => {
        if (value.length > 0) {
            let formatString = value.split('|');
            formatString = `${formatString[0]} ${formatString[1]}p ${formatString[2]}cv`;
            return formatString;
        }
        return value
    }

    return (
        <div className={classes.wrap}>
            <div className={classes.headerInvert}>
                <div>{t('search-proposal-subview-header-text1-title')}</div>
            </div>
            <div className={classes.limit_area}>
                <div className={classes.box}>
                    <div className={classes.container}>
                        <div className={classes.clienteInvert}>
                            <span>{t('popup-overlay-subview-search-detail-proposal-client-label1')}</span>
                            <span style={{ fontWeight: '800' }}>{data.proposal?.client?.firstName || data.proposal?.client?.lastName ? `${data.proposal?.client?.firstName} ${data.proposal?.client?.lastName}` : '-'}</span>
                        </div>
                        <div className={classes.clienteInvert}>
                            <span>{t('search-proposal-subview-container-client-input3-label')}</span>
                            <span>{data.proposal?.client?.phone ? data.proposal?.client?.phone : '-'}</span>
                        </div>
                        <div className={classes.clienteInvert}>
                            <span>{t('search-proposal-subview-container-client-input4-label')}</span>
                            <span>{data.proposal?.client?.email ? data.proposal?.client?.email : '-'}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.headerInvert}>
                <div>{t('search-proposal-subview-header-text2-title')}</div>
            </div>
            <div className={classes.limit_area}>

                <div className={classes.box}>
                    <div className={classes.container}>

                        <div className={classes.modelInvert}>
                            <span>{t('search-proposal-subview-container-vehicle-text1-label')}</span>
                            <span>{data.car?.model ? data.car?.model : '-'}</span>
                            <span>{data.car?.version ? data.car?.version : '-'}</span>
                        </div>

                        <div className={classes.lineInvert}></div>

                        <div className={classes.priceInvert}>
                            <span>{t('search-proposal-subview-container-vehicle-text2-label')}</span>
                            {
                                data.car?.discountPrice > 0 ?
                                    <>
                                        <span>{data.car?.discountPrice ? Utils.formatPrice(data.car?.discountPrice) : '- €'}</span>
                                        <span>{data.car?.price ? `${t('popup-overlay-subview-search-detail-proposal-vehicle-label1')} ${Utils.formatPrice(data.car?.price)}` : `${t('popup-overlay-subview-search-detail-proposal-vehicle-label1')} - €`}</span>
                                    </>
                                    :
                                    <>
                                        <span>{data.car?.price ? Utils.formatPrice(data.car?.price) : '- €'}</span>
                                    </>
                            }
                        </div>

                        <div className={classes.lineInvert}></div>

                        <div className={classes.licenseInvert}>
                            <span>{t('search-proposal-subview-container-vehicle-text3-label')}</span>
                            <span>{data.car?.plateNumber ? Utils.formartLicensePlate(data.car?.plateNumber) : '-- -- --'}</span>
                        </div>

                        <div className={classes.lineInvert}></div>

                        <div className={classes.row}>

                            <div className={classes.columnLeft}>
                                <div className={classes.cellInvert}>
                                    <p>{t('search-proposal-subview-container-vehicle-text4-label')}</p>
                                    <p>{data.car?.registrationDate ? Utils.changeDateStringFormat(data.car?.registrationDate) : '-'}</p>
                                    <p>{data.car?.registrationDate ? calculateAge(data.car?.registrationDate) : '-'}</p>
                                </div>
                                <div className={classes.cellInvert}>
                                    <p>{t('search-proposal-subview-container-vehicle-text5-label')}</p>
                                    <p>{data.car?.fuel ? data.car?.fuel : '-'}</p>
                                    <p></p>
                                </div>
                                <div className={classes.cellInvert}>
                                    <p>{t('search-proposal-subview-container-vehicle-text6-label')}</p>
                                    <p>{data.car?.color ? data.car?.color : '-'}</p>
                                    <p></p>
                                </div>
                            </div>
                            <div className={classes.columnRight}>
                                <div className={classes.cellInvert}>
                                    <p>{t('search-proposal-subview-container-vehicle-text7-label')}</p>
                                    <p>{data.car?.km ? `${Utils.formatMetric(data.car?.km)} Km` : '-'}</p>
                                    <p>{data.car?.km && data.car?.registrationDate ? calculateKmPerYear(data.car?.km, data.car?.registrationDate) : '-'}</p>
                                </div>
                                <div className={classes.cellInvert}>
                                    <p>{t('search-proposal-subview-container-vehicle-text8-label')}</p>
                                    <p>{data.car?.doorCount ? data.car?.doorCount : '-'}</p>
                                    <p></p>
                                </div>
                                <div className={classes.cellInvert}>
                                    <p>{t('search-proposal-subview-container-vehicle-text9-label')}</p>
                                    <p>{data.car?.dealerName ? data.car?.dealerName : '-'}</p>
                                    <p></p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.equipments}>
                            <div className={classes.collapseInvert} style={{ height: collapse.equipments ? 'auto' : '0px' }}>
                                {
                                    data.car?.equipments ?
                                        data.car?.equipments.length > 0 ?
                                            data.car?.equipments.map((prop, index) => {
                                                return (
                                                    <div id="equipments-row" key={index}>{`• ${prop}`}</div>
                                                );
                                            }) :
                                            <p className={classes.message}>{t('search-proposal-subview-container-vehicle-text-message')}</p>
                                        :
                                        <p className={classes.message}>{t('search-proposal-subview-container-vehicle-text-message')}</p>
                                }
                            </div>
                            <div className={classes.anchorInvert} onClick={showEquipmment}>{collapse.equipments ? t('search-proposal-subview-container-vehicle-text2-anchor') : t('search-proposal-subview-container-vehicle-text1-anchor')}</div>
                        </div>

                    </div>
                </div>
            </div>
            {
                data.car?.carReturn ?
                    <>
                        <div className={classes.headerInvert}>
                            <div>{t('search-proposal-subview-header-text7-title')}</div>
                        </div>
                        <div className={classes.limit_area}>
                            <div className={classes.box}>
                                <div className={classes.container}>

                                    <div className={classes.licenseInvert}></div>

                                    <div className={classes.row}>
                                        <div className={classes.columnRight}>
                                            <span className={classes.label}>{t('search-proposal-subview-container-exchange-text1-label')}</span>
                                            <span className={classes.subtitleInvert}>{data.car?.carTrade?.licensePlate ? data.car?.carTrade?.licensePlate.match(/.{1,2}/g).join(' ').toUpperCase() : '-'}</span>
                                        </div>
                                        <div className={classes.verticalInvert}></div>
                                        <div className={classes.columnLeft}>
                                            <span className={classes.label}>{t('search-proposal-subview-container-exchange-text2-label')}</span>
                                            <span className={classes.subtitleInvert}>{data.car?.carTrade?.year ? data.car?.carTrade?.year : '-'}</span>
                                        </div>
                                    </div>

                                    <div className={classes.lineInvert}></div>

                                    <div className={classes.row}>
                                        <div className={classes.cell_3Invert}>
                                            <span>{t('search-proposal-subview-container-exchange-text4-label')}</span>
                                            <span>{data.car?.carTrade.version ? formatStringVersion(data.car?.carTrade.version) : '-'}</span>
                                        </div>
                                    </div>

                                    <div className={classes.lineInvert}></div>

                                    <div className={classes.row}>
                                        <div className={classes.cell_4Invert}>
                                            <span>{t('search-proposal-subview-container-exchange-text3-label')}</span>
                                            <span>{data.car?.carTrade.tradePrice ? Utils.formatPrice(data.car?.carTrade.tradePrice) : '- €'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
            }
            {
                data.car?.financeInfoSimulated ?
                    <>
                        <div className={classes.headerInvert}>
                            <div>{t('search-proposal-subview-header-text3-title')}</div>
                        </div>
                        <div className={classes.limit_area}>
                            <div className={classes.box}>
                                <div className={classes.container}>

                                    <div className={classes.licenseInvert}></div>

                                    <div className={classes.row}>
                                        <div className={classes.columnRight}>
                                            <span className={classes.label}>{t('search-proposal-subview-container-finance-text1-label')}</span>
                                            <span className={classes.subtitleInvert}>{data.car?.financeInfoSimulated?.initialEntrance ? Utils.formatPrice(data.car?.financeInfoSimulated?.initialEntrance) : '-'}</span>
                                        </div>
                                        <div className={classes.verticalInvert}></div>
                                        <div className={classes.columnLeft}>
                                            <span className={classes.label}>{t('search-proposal-subview-container-finance-text2-label')}</span>
                                            <span className={classes.subtitleInvert}>{data.car?.financeInfoSimulated?.capitalToFinance ? Utils.formatPrice(data.car?.financeInfoSimulated?.capitalToFinance) : '-'}</span>
                                        </div>
                                    </div>

                                    <div className={classes.lineInvert}></div>

                                    <div className={classes.row}>
                                        <div className={classes.fieldInvert}>
                                            <span>{t('search-proposal-subview-container-finance-text3-label')}</span>
                                            <span>{data.car?.financeInfoSimulated?.nrPayments && data.car?.financeInfoSimulated?.rent ? `${Utils.formatPrice(data.car?.financeInfoSimulated?.rent)}${t('search-proposal-subview-container-finance-text3-label1')} ${data.car?.financeInfoSimulated?.nrPayments}` : '-'}</span>
                                        </div>
                                    </div>

                                    <div className={classes.lineInvert}></div>

                                    <div className={classes.row}>
                                        <div className={classes.cell_2Invert}>
                                            <span>{t('search-proposal-subview-container-finance-text4-label')}</span>
                                            <span>{`${data.car?.financeInfoSimulated?.taeg && data.car?.financeInfoSimulated?.taeg > 0 ? Utils.formatPercentage(data.car?.financeInfoSimulated?.taeg) : '-'}`}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
            }
            {
                data.proposal?.description.length > 0 ?
                    <>
                        <div className={classes.headerInvert}>
                            <div>{t('search-proposal-subview-header-text4-title')}</div>
                        </div>
                        <div className={classes.limit_area}>
                            <div className={classes.box}>
                                <div className={classes.container}>
                                    <span className={classes.regular}>
                                        {data.proposal.description}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                    : null
            }
            {
                data.proposal?.dtEnd ?
                    <>
                        <div className={classes.headerInvert}>
                            <div>{t('search-proposal-subview-header-text5-title')}</div>
                        </div>
                        <div className={classes.limit_area}>
                            <div className={classes.box}>
                                <div className={classes.container}>

                                    <div className={classes.row}>
                                        <div className={classes.fieldInvert2}>
                                            <span>{t('search-proposal-subview-container-time-input-label')}</span>
                                            <span>{`${data.proposal?.time}  ${t('search-proposal-subview-container-time-input-label1')}  ${data.proposal?.date}`}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                    : null
            }
            <div style={{ width: '100%', height: '150px' }}></div>
        </div>
    );
}

export default PopupProposalSubview;