import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment-precise-range-plugin';

import { useTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom';

import Carrousel from '../../../../components/Carrousel';
import { useAppContext } from '../../../../context/app/app-context';
import * as Utils from '../../../../service/utils';

import Styles from './style';

const useStyles = makeStyles(Styles);

const DetailSubview = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { carSelected, setFinanceInfo, financeInfo } = useAppContext()

    useEffect(() => {
        if (!financeInfo.isSim) {
            setFinanceInfo({ isSim: false, data: carSelected.financeInfoRecommended });
        }
    }, []);

    const calculatePrice = () => {
        if (carSelected.discountPrice > 0) {
            return (
                <div style={{ flex: 1 }}>
                    <div>{carSelected.discountPrice ? Utils.formatPrice(carSelected.discountPrice) : '- €'}</div>
                    <div>{carSelected.price ? `${t('search-detail-subview-header-label')} ${Utils.formatPrice(carSelected.price)}` : `${t('search-detail-subview-header-label')} - €`}</div>
                </div>
            );
        } else {
            return (
                <div style={{ flex: 1 }}>
                    <div>{carSelected.price ? Utils.formatPrice(carSelected.price) : '- €'}</div>
                </div>
            );
        }
    }

    const calculateAge = (date) => {
        var start = moment(date);
        var time = moment.preciseDiff(start, moment(), true);
        return `(${time.years} ${t('search-detail-subview-registration-years-label')} ${t('search-detail-subview-registration-text1-label')} ${time.months} ${t('search-detail-subview-registration-months-label')})`
    }

    const calculateKmPerYear = (km, date) => {
        var start = moment(date);
        var time = moment.preciseDiff(start, moment(), true);
        var average = time.years > 0 ? km / time.years : km;
        return `(${Utils.formatMetric(average)} Km / Ano)`
    }


    return (
        <div className={classes.root}>
            <div className={classes.header_box}>
                <div className={classes.limit_area}>
                    <div className={classes.header}>
                        <div>{carSelected.version ? carSelected.version : '-'}</div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {calculatePrice()}
                        </div>
                    </div>
                </div>
            </div>


            <div className={classes.box}>
                <div className={classes.box_container}>
                    <div className={classes.limit_area}>
                        <Carrousel src={carSelected.images} />
                        <div className={classes.info}>
                            <div className={classes.license}>
                                <p className={classes.label}>{t('search-detail-subview-licence-label')}</p>
                                <p className={classes.title}>{carSelected.plateNumber ? Utils.formartLicensePlate(carSelected.plateNumber) : '-- -- --'}</p>
                            </div>
                            <div className={classes.line}></div>
                            <div className={classes.container}>
                                <div className={classes.columnLeft}>
                                    <div className={classes.cell}>
                                        <p>{t('search-detail-subview-registration-label')}</p>
                                        <p>{carSelected.registrationDate ? Utils.changeDateStringFormat(carSelected.registrationDate) : '-'}</p>
                                        <p>{carSelected.registrationDate ? calculateAge(carSelected.registrationDate) : '-'}</p>
                                    </div>
                                    <div className={classes.cell}>
                                        <p>{t('search-detail-subview-fuel-label')}</p>
                                        <p>{carSelected.fuel ? carSelected.fuel : '-'}</p>
                                        <p></p>
                                    </div>
                                    <div className={classes.cell}>
                                        <p>{t('search-detail-subview-color-label')}</p>
                                        <p>{carSelected.color ? carSelected.color : '-'}</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className={classes.columnRight}>
                                    <div className={classes.cell}>
                                        <p>{t('search-detail-subview-quilometers-label')}</p>
                                        <p>{carSelected.km ? `${Utils.formatMetric(carSelected.km)} Km` : '-'}</p>
                                        <p>{carSelected.km && carSelected.registrationDate ? calculateKmPerYear(carSelected.km, carSelected.registrationDate) : '-'}</p>
                                    </div>
                                    <div className={classes.cell}>
                                        <p>{t('search-detail-subview-doors-label')}</p>
                                        <p>{carSelected.doorCount ? carSelected.doorCount : '-'}</p>
                                        <p></p>
                                    </div>
                                    <div className={classes.cell}>
                                        <p>{t('search-detail-subview-location-label')}</p>
                                        <p>{carSelected.dealerName ? carSelected.dealerName : '-'}</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.line}></div>
                            <div className={classes.equipments}>
                                <div id="equipments-title">{t('search-detail-subview-equipment-label')}</div>
                                {
                                    carSelected.equipments ?
                                        carSelected.equipments.length > 0 ?
                                            carSelected.equipments.map((prop, index) => {
                                                return (
                                                    <div id="equipments-row" key={index}>{`• ${prop}`}</div>
                                                );
                                            }) :
                                            <p className={classes.message}>Sem informação</p>
                                        :
                                        <p className={classes.message}>Sem informação</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>






        </div>
    );
}

export default withRouter(DetailSubview);