import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {  useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


import Styles from './style';

const useStyles = makeStyles(Styles);

const MenuItem = ({
    menu,
    onClick
}) => {

    const location = useLocation();
    const classes = useStyles();
    const [active, setActive] = useState(false)


    const onClickMenuHandler = (event, menu) => {
        const data = {
            target: event.currentTarget,
            menu: menu
        }
        onClick(data)
    }



    useEffect(() => {
        let pathname = location.pathname
        pathname = pathname.substring(1) //to remove first '/' in path
        setActive((pathname === menu.id || pathname.includes(menu.id)))
    }, [location, menu.id])


    return (
        <div className={clsx({
            [classes.root]: true,
            [classes.active]: active
        })}
            onClick={event => onClickMenuHandler(event, menu)}
        >
            <span>{menu.icon}</span>
        </div>
    )
}

MenuItem.propTypes = {

}

export default MenuItem
