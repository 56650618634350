import { createMuiTheme } from '@material-ui/core';
import overrides from './overrides';
import typography from './typography';
import palette from './palette';
import breakpoints from './breakpoints';

const Theme = createMuiTheme({
    overrides,
    typography,
    palette,
    breakpoints
});

export default Theme
