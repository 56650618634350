const Styles = theme => ({
    root: {
        flex: 1, 
        maxWidth: '1146px', 
        display: 'flex', 
        flexWrap: 'wrap', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    container: {
        padding: '11px'
    },
    row: {
        flex: 1,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
    },
    line: {
        width: '100%', 
        height: '1px', 
        margin: '10px 0', 
        backgroundColor: '#444444'
    },
    card: {
        minWidth: '270px', 
        maxWidth: '330px', 
        padding: '15px', 
        borderRadius: '0px 16px 16px 16px',
        backgroundColor: 'rgba(255,255,255,0.2)',
    },
    card_title: {
        ...theme.typography.titleBold,
        fontSize: '18px',
        color:'#ffffff'
    },
    card_subtitle: {
        ...theme.typography.title,
        fontSize: '16px',
        color:'#ffffff'
    },
    card_body: {
        ...theme.typography.label,
        fontSize: '16px',
        color:'#cecece',
        fontWeight: 'normal'
    },
    card_body_small: {
        ...theme.typography.label,
        fontSize: '14px',
        color:'#cecece',
        fontWeight: 'normal'
    },
    button: {
        margin: '15px 0px 5px 0px',
        '& button': {
            backgroundColor: '#eeeeee',
            color: '#000000'
        }
    },
})

export default Styles;