import { API, graphqlOperation } from "aws-amplify";
import { getCarsWithFilter, getTradePrice, getSimulatorUsedCars, simulateFinanceUsedCars, reserveVehicle, cancelReserveVehicle, printPi, sendPi, listKpi, getEventsByIdSalesman, editEvent, getAvailableSlotsByIdSalesman } from '../../graphql/queries';
import { searchClients, createPi, listPi, authenticationExtraDirectLambda, searchStatus } from '../../graphql/customQueries';

// DONE ✅
export const getAuthenticationExtra = async (data) => {
    return await API.graphql({
        query: authenticationExtraDirectLambda,
        variables: { authentication: data },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅
export const getCars = async (data) => {
    return await API.graphql({
        query: getCarsWithFilter,
        variables: { filter: data },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅ -- Double check na resposta
export const getTradeValue = async (data) => {
    return await API.graphql({
        query: getTradePrice,
        variables: { trade: data },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅ -- Double check na resposta
export const getSimulatorCars = async (data) => {
    return await API.graphql({
        query: getSimulatorUsedCars,
        variables: { simulation: data },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅ -- Double check na resposta
export const simulateFinanceCar = async (data) => {
    return await API.graphql({
        query: simulateFinanceUsedCars,
        variables: { simulation: data },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅
export const createProposal = async (data, login) => {
    return await API.graphql({
        query: createPi,
        variables: { createPI: { body: data, login: login } },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅
export const searchCarStatus = async (data) => {
    return await API.graphql({
        query: searchStatus,
        variables: { searchStatus: data },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅
export const searchClient = async (data, dealerId, login) => {
    return await API.graphql({
        query: searchClients,
        variables: { clients: { query: data, dealerId: dealerId, login: login } },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅
export const getListPI = async (login) => {
    return await API.graphql({
        query: listPi,
        variables: { listPI: { login: login } },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅
export const reserveCar = async (data) => {
    return await API.graphql({
        query: reserveVehicle,
        variables: { reserveVehicle: data },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅
export const cancelReserveCar = async (data) => {
    return await API.graphql({
        query: cancelReserveVehicle,
        variables: { cancelReserveVehicle: data },
        authMode: 'AWS_IAM'
    });
}


// DONE ✅
export const piPrint = async (data) => {
    return await API.graphql({
        query: printPi,
        variables: { printPI: data },
        authMode: 'AWS_IAM'
    });
}

// DONE ✅
export const piSend = async (data) => {
    return await API.graphql({
        query: sendPi,
        variables: { sendPI: data },
        authMode: 'AWS_IAM'
    });
}


export const getKPIList = async (data) => {
    return await API.graphql({
        query: listKpi,
        variables: { listKPI: data },
        authMode: 'AWS_IAM'
    });
}

export const getEventsSalesman = async (data) => {
    return await API.graphql({
        query: getEventsByIdSalesman,
        variables: { events: data },
        authMode: 'AWS_IAM'
    });
}

export const setEditEvent = async (data) => {
    return await API.graphql({
        query: editEvent,
        variables: { event: data },
        authMode: 'AWS_IAM'
    });
}

export const getSalerAvailableSlots = async (data) => {
    return await API.graphql({
        query: getAvailableSlotsByIdSalesman,
        variables: { slots: data },
        authMode: 'AWS_IAM'
    });
}