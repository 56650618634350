import React from 'react';
import Styles from './style';
import { makeStyles, Modal, Typography } from '@material-ui/core';
import { useAppContext } from '../../context/app/app-context';
import * as Utils from '../../service/utils';


const useStyles = makeStyles(Styles);

const PopupAlert = ({
    open,
    type,  //ALERT_CONFIRM OR ALERT_TEXT OR ALERT_TEXT_CONFIRM
    onConfirm,
    onCancel,
    onClose
}) => {

    const classes = useStyles();

    const { popupAltert } = useAppContext()

    const renderContent = () => {
        switch (type ? type : popupAltert?.type) {
            case 'ALERT_CONFIRM':
                return (
                    <div className={classes.container}>
                        <div className={classes.boxTitle}>
                            {
                                popupAltert?.icon == "info" ? <span>\</span> : <span>{popupAltert?.icon}</span>
                            }
                            <Typography variant="h2">{popupAltert?.title}</Typography>
                        </div>
                        <Typography variant="h6">
                             {Utils.renderHTML(`${popupAltert?.description}`)}
                        </Typography>
                        <div className={classes.actionBox}>
                            <div className={classes.actionOk} onClick={onConfirm ? onConfirm : popupAltert?.actions?.onConfirm}>
                                <span>OK</span>
                            </div>
                            <div className={classes.actionCancel} onClick={onCancel ? onCancel : popupAltert?.actions?.onCancel}>
                                <span>Cancelar</span>
                            </div>
                        </div>
                    </div>
                )

            case 'ALERT_TEXT_CONFIRM':
                return (
                    <div className={classes.container}>
                        <div className={classes.boxTitle}>
                            {
                                popupAltert?.icon == "info" ? <span>\</span> : <span>{popupAltert?.icon}</span>
                            }
                            <Typography variant="h2">{popupAltert?.title}</Typography>
                        </div>
                        <Typography variant="h6">
                             {Utils.renderHTML(`${popupAltert?.description}`)}
                        </Typography>
                        <div className={classes.actionBox}>
                            <div className={classes.actionOk} onClick={onConfirm ? onConfirm : popupAltert?.actions?.onConfirm}>
                                <span>OK</span>
                            </div>
                        </div>
                    </div>
                )

            case 'ALERT_TEXT':
                return (
                    <div className={classes.container}>
                        <Typography variant="h6">
                            {Utils.renderHTML(`${popupAltert?.description}`)}
                        </Typography>
                        <div className={classes.boxClose} onClick={() => onClose()}>
                            <span>F</span>
                        </div>
                    </div>
                )

            default:
                break;
        }
    }


    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="popup-alert-title"
            aria-describedby="popup-alert-description"
        >
            <div className={classes.root}>
                {renderContent()}
            </div>
        </Modal>
    );
}

export default PopupAlert;