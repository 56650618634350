import React from 'react';
import clsx from 'clsx';
import Styles from './style';
import { makeStyles, Button as Btn } from '@material-ui/core';


const useStyles = makeStyles(Styles);

const Button = ({
    type,
    children,
    styles,
    variant = 'contained',
    onClick,
    disabled
}) => {

    const classes = useStyles();

    const onClickHandle = (event) => {
        if (!disabled && onClick) onClick(event)
    }

    return (
        <div className={clsx(classes.root, styles)} onClick={onClickHandle}>
            <Btn variant={variant} disableRipple={true} color="primary" type={type} disabled={disabled}>
                {children}
            </Btn>
        </div>
    );
}

export default Button;