import Palette from '../palette';

const MuiButton = {
    root: {
        width: '100%',
        height: '100%',
        borderRadius: 8,
        textTransform: 'none',
        padding: '8px 0 7px',
        backgroundColor: Palette.white,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)'
    },
    underline: {
        '&::before': {
            borderBottom: 'none',
            display: 'none'
        },
        '&::after': {
            borderBottom: 'none',
            display: 'none'
        },
        '&&&&:hover::before': {
            borderBottom: 'none',
            display: 'none'
        }
    },
    input: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        lineHeight: 1.38,
        color: Palette.black,
        textAlign: 'center'
    }
};

export default MuiButton;
