
const Styles = theme => ({
    root: {
        zIndex: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        position: 'fixed'
    },
    pooper: {
        marginTop: '-38px',
        '& .MuiPaper-root': {
            borderRadius: 10,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: theme.palette.white,
            padding: 0
        }
    },
    container: {
        width: 260,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    boxHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '12px 0 22px',
        textAlign: 'center',
        '& h2': {
            lineHeight: 1
        },
        '& span': {
            fontSize: 48,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font',
            position: 'absolute',
            left: 0,
            cursor: 'pointer'
        }
    },
    boxInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: 10,
        padding: '0 20px',
        '& span': {
            marginBottom: 2
        },
        '& h2': {
            fontWeight: 700
        }
    },
    boxItems: {
        width: '100%',
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    item: {
        height: 48,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderTop: `1px solid ${theme.palette.verylightgray}`,
        cursor: 'pointer',
        '& span': {
            fontSize: 16,
            lineHeight: 1.38,
            fontFamily: 'Open Sans',
        }
    },
    arrowUp: {
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid white',
        position: 'absolute',
        right: '8px',
        marginTop: '-10px'
    }
})

export default Styles;