import React from 'react';
import './style.css';

const DayButton = ({ value, empty, passed, available, today, onClick }) => {

    const onClickHandler = () => {
        onClick()
    }

    return (
        <div className="daybutton-root" onClick={ empty || passed || !available ? null : onClickHandler} style={{cursor: empty || passed || !available? 'initial' : 'pointer'}}>
            {
                empty ?
                    <div className="daybutton-box" style={{ backgroundColor: 'transparent' }}>
                        <span>{value}</span>
                    </div>
                    :
                    passed ?
                        <div className="daybutton-box" style={{ backgroundColor: 'transparent' }}>
                            <span style={{ color: 'rgb(51,51,51)', fontWeight: 'normal' }} >{value}</span>
                        </div>
                        :
                        available ?
                            <div className="daybutton-box" style={{ backgroundColor: today ? 'rgb(255,255,255)' : 'rgb(52,52,52)' }}>
                                <span style={{ color: today ? 'rgb(52,52,52)' : 'rgb(255,255,255)', fontWeight: today ? '800' : 'normal' }} >{value}</span>
                            </div>
                            :
                            <div className="daybutton-box" style={{ backgroundColor: today ? 'rgb(255,255,255)' : 'rgb(38,38,38)' }}>
                                <span style={{ color: today ? 'rgb(226,226,226)' : 'rgb(51,51,51)', fontWeight: today ? '800' : 'normal' }} >{value}</span>
                            </div>
            }
        </div>
    );
}

export default DayButton;