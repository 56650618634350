import { Auth } from "aws-amplify";

export const signIn = async (username, password) => {
    return await Auth.signIn(username, password);
}

export const signOut = async () => {
    return await Auth.signOut();
}

export const signUp = async (userData) => {
    return await Auth.signUp(userData);
}

export const confirmSignUp = async (username, code) => {
    return await Auth.confirmSignUp(username, code);
}

export const completeNewPassword = async (cognitoUser, newPassword) => {
    return await Auth.completeNewPassword(cognitoUser, newPassword)
}

export const forgotPassword = async (username) => {
    return await Auth.forgotPassword(username);
}

export const forgotPasswordSubmit = async (username, code, newPassword) => {
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
}

export const changePassword = async (cognitoUser, oldPassword, newPassword) => {
    return await Auth.changePassword(cognitoUser, oldPassword, newPassword);
}

export const currentUserPoolUser = async () =>{
    return await Auth.currentUserPoolUser();
}

export const currentSession = async () =>{
    return await Auth.currentSession();
}

export const updateUserAttributes = async (cognitoUser, attributes) => {
    return Auth.updateUserAttributes(cognitoUser, attributes);
}

export const currentCredentials = async () => {
    return await Auth.currentCredentials();
}



