import React, { useState, useContext, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/';

import moment from 'moment';
import 'moment-precise-range-plugin';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { withRouter } from 'react-router-dom';

import Button from '../../../../components/Button';
import InputText from '../../../../components/InputText';
import Toggle from '../../../../components/Toggle';
import PopupAlert from '../../../../components/PopupAlert';
import PopOverlayView, { usePopOverlay } from '../../../../components/PopOverlayView';
import PopupSearchSubview from './PopupSearchSubview';
import PopupProposalSubview from './PopupProposalSubview';
import PopupScheduleSubview from './PopupScheduleSubview';

import * as Utils from '../../../../service/utils';
import { ServiceContext } from '../../../../context/service/service-context';
import { useAppContext } from '../../../../context/app/app-context';
import { AuthContext } from '../../../../context/auth/auth-context';

import Styles from './style';

import { useNavBar } from '../../';

const useStyles = makeStyles(Styles);

const PedidoSubview = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { state, init, close, setFooter } = usePopOverlay();

    const scheduleToogle = useRef();
    const { carSelected, setCarSelected, setPopupAlert, dataPIInsert, setDataPIInsert } = useAppContext()
    const { cognitoUser, brandAccess } = useContext(AuthContext);
    const { Usados } = useContext(ServiceContext);
    const { goToTab, setRequestStatus } = useNavBar();
    const [collapse, setCollapse] = useState({ equipments: false, finance: false, exchange: false, date: false, schedule: false });
    const [openPopUp, setOpenPopUp] = useState(false);
    const [openPopUpConfirm, setOpenPopUpConfirm] = useState(false);
    const [reservation, setResevation] = useState(null);
    const [proposal, setProposal] = useState(null);




    const [scheduleID, setScheduleID] = useState();

    useEffect(() => {
        loadPIData();
    }, [])

    const setEvent = async () => {
        const params = {
            action: 'checkIn',
            idEvent: scheduleID,
            login: cognitoUser.login,
            body: {}
        }
        await Usados.SetEvent(params);
    }

    const savePIData = () => {
        const params = {
            name: submitProposal.values.name,
            lastname: submitProposal.values.lastname,
            phone: submitProposal.values.phone,
            email: submitProposal.values.email,
            comment: submitProposal.values.comment,
            registryID: scheduleID
        }
        setDataPIInsert(params);
    }

    const loadPIData = () => {
        if (dataPIInsert != null) {
            submitProposal.setFieldValue('name', dataPIInsert.name);
            submitProposal.setFieldValue('lastname', dataPIInsert.lastname);
            submitProposal.setFieldValue('phone', dataPIInsert.phone);
            submitProposal.setFieldValue('email', dataPIInsert.email);
            submitProposal.setFieldValue('comment', dataPIInsert.comment);
        }

        if (dataPIInsert?.registryID) {
            scheduleToogle.current.setOn();
            setScheduleID(dataPIInsert?.registryID);
        }
    }

    const searchClientRequest = useFormik({
        initialValues: {
            search: ''
        },
        onSubmit: async (values) => {
            try {
                setRequestStatus(true);
                const query = {}
                if (Utils.isStringEmail(values.search)) {
                    query.email = values.search;
                } else {
                    query.phone = values.search;
                }
                const result = await Usados.SearchClients(query, cognitoUser.dealer.objectId, cognitoUser.login);
                setRequestStatus(false);
                if (result?.searchClients?.clientResponseList.length > 0) {
                    init({
                        header: {
                            title: 'Cliente',
                            back: {
                                action: close,
                                hide: false
                            },
                            close: {
                                hide: true
                            }
                        },
                        footer: {
                            hide: true
                        },
                        render: <PopupSearchSubview data={result?.searchClients?.clientResponseList} actions={{ onSelect: clientSearchSelect }} />
                    }, true);
                } else {
                    const popupAlerData = {
                        description: 'Não foram encontrados resultados na pesquisa.',
                        type: 'ALERT_TEXT_CONFIRM',
                        title: 'Sem resultados',
                        icon: 'info',
                        actions: {
                            onConfirm: () => setOpenPopUp(false)
                        }
                    }
                    setPopupAlert(popupAlerData)
                    setOpenPopUp(true);
                }
            } catch (error) {
                setRequestStatus(false);
            }
        },
    });


    

    const submitProposal = useFormik({
        initialValues: {
            name: '',
            lastname: '',
            phone: '',
            email: '',
            comment: '',
            time: '',
            date: '',
        },
        onSubmit: async (values) => {
            try {
                setRequestStatus(true);
                const params = {
                    client: {
                        firstName: values.name,
                        lastName: values.lastname,
                        phone: values.phone,
                        email: values.email,
                    },
                    oidDealer: carSelected?.dealerId,
                    description: values?.comment,
                    financeInfoRecommended: carSelected?.financeInfoRecommended,
                    plateNumber: carSelected?.plateNumber,
                    price: carSelected?.price,
                    priceWithDiscount: carSelected?.discountPrice,
                    vin: carSelected?.vin,
                    financeInfoType: carSelected?.financeInfoRecommended && collapse?.finance ? 'simulated' : 'recommended',
                    dtEnd: collapse?.date ? Utils.convertDateToTimestampMili(values?.time, values?.date) : null
                }

                if (collapse?.exchange) {
                    params.carReturn = {
                        tradeValue: carSelected?.carReturn?.tradePrice,
                        tradeVin: carSelected?.carReturn?.vin,
                        tradePlateNumber: carSelected?.carReturn?.licensePlate,
                    }
                }

                if (collapse.finance) {
                    params.financeInfoSimulated = carSelected?.financeInfoSimulated;
                }

                if (scheduleToogle.current.value()) {
                    params.externalIdEvent = scheduleID;
                }

                const result = await Usados.CreateNewProposal(params, cognitoUser.login);

                if(result?.createPI === null){
                    return null
                }

                params.time = values.time;
                params.date = values.date;
                const status = await Usados.GetVehicleReservationStatus({ plateNumber: carSelected.plateNumber, vin: carSelected.vin })
                
                if(scheduleID){
                    const schedule = await setEvent();
                }

                setRequestStatus(false);
                init({
                    header: {
                        title: 'Proposta',
                    },
                    footer: {
                        hide: false,
                        pin: true,
                        render: onFooterRender(result?.createPI?.pi[0]?.id, status?.searchStatus?.vehicleReservation)
                    },
                    render: <PopupProposalSubview data={{ car: carSelected, proposal: params }} />
                }, true);
            } catch (error) {
                setRequestStatus(false);
            }
        },
    });

    const carReservation = async (idPI) => {
        const params = {
            idClientOrigin: brandAccess,
            idPI: idPI,
            login: cognitoUser.login,
            plateNumber: carSelected.plateNumber,
            vin: carSelected.vin
        }
        const _proposal = {
            client: {
                firstName: submitProposal.values.name,
                lastName: submitProposal.values.lastname,
                phone: submitProposal.values.phone,
                email: submitProposal.values.email,
            },
            description: submitProposal.values.comment,
            dtEnd: collapse?.date ? Utils.convertDateToTimestampMili(submitProposal?.values?.time, submitProposal?.values?.date) : null,
            time: submitProposal?.values?.time,
            date: submitProposal?.values?.date
        }

        setProposal(_proposal)

        try {
            setRequestStatus(true);
            const result = await Usados.ReserveVehicle(params)
            setResevation(result?.reserveVehicle?.vehicleReservation);

            init({
                header: {
                    title: 'Proposta',
                },
                footer: {
                    hide: false,
                    pin: true,
                    render: onFooterRender(result?.reserveVehicle?.vehicleReservation?.extIdInOrigin, result?.reserveVehicle?.vehicleReservation)
                },
                render: <PopupProposalSubview data={{ car: carSelected, proposal: _proposal }} />
            }, true);
            setRequestStatus(false);
        } catch (error) {
            setRequestStatus(false);
            console.log(error);
        }
    }

    const handleRemoveReservation = () => {
        const popupAlerData = {
            description: t('cancel-reservation-confirm')
        }
        setPopupAlert(popupAlerData)
        setOpenPopUpConfirm(true)
    }

    const onConfirmCancelReservation = async () => {
        setOpenPopUpConfirm(false)
        setRequestStatus(true);
        try {
            const params = {
                idPI: reservation?.extIdInOrigin,
                login: cognitoUser?.login,
                plateNumber: carSelected?.plateNumber,
                vin: carSelected?.vin
            }
            await Usados.CancelReserveVehicle(params);

            init({
                header: {
                    title: 'Proposta',
                },
                footer: {
                    hide: false,
                    pin: true,
                    render: onFooterRender(reservation?.extIdInOrigin, null)
                },
                render: <PopupProposalSubview data={{ car: carSelected, proposal: proposal }} />
            }, true);
            setRequestStatus(false);
          
        } catch (error) {

        }
    }


    const printPI = async (idPI) => {
        const params = {
            login: cognitoUser.login,
            idPI: idPI,
        }
        try {
            setRequestStatus(true);
            await Usados.PrintProposal(params);
            setRequestStatus(false);
        } catch (error) {
            console.log(error);
            setRequestStatus(false);
        }
    }

    const sendPI = async (idPI) => {
        const params = {
            login: cognitoUser.login,
            idPI: idPI,
        }
        try {
            setRequestStatus(true);
            await Usados.SendProposal(params);
            setRequestStatus(false);
        } catch (error) {
            console.log(error);
            setRequestStatus(false);
        }
    }

    const resetFields = () => {
        submitProposal.setFieldValue('name', '');
        submitProposal.setFieldValue('lastname', '');
        submitProposal.setFieldValue('phone', '');
        submitProposal.setFieldValue('email', '');
        submitProposal.setFieldValue('comment', '');
    }

    const calculateAge = (date) => {
        var start = moment(date);
        var time = moment.preciseDiff(start, moment(), true);
        return `(${time.years} ${t('search-proposal-subview-container-vehicle-text4-label1')} ${time.months} ${t('search-proposal-subview-container-vehicle-text4-label2')})`
    }

    const calculateKmPerYear = (km, date) => {
        var start = moment(date);
        var time = moment.preciseDiff(start, moment(), true);
        var average = time.years > 0 ? km / time.years : km;
        return `(${Utils.formatMetric(average)} Km / Ano)`
    }

    const showEquipmment = () => {
        setCollapse({ ...collapse, equipments: !collapse.equipments });
    }

    const showFinance = (isActive) => {
        if (!isActive) {
            setCollapse({ ...collapse, finance: isActive });
        } else if (!carSelected.financeInfoSimulated && isActive) {
            goToTab(2);
        } else if (carSelected.financeInfoSimulated && isActive) {
            setCollapse({ ...collapse, finance: isActive });
        }
    }

    const showExchange = (isActive) => {
        if (!isActive) {
            setCollapse({ ...collapse, exchange: isActive });
        } else if (!carSelected.carReturn && isActive) {
            goToTab(1);
        } else if (carSelected.carReturn && isActive) {
            setCollapse({ ...collapse, exchange: isActive });
        }
    }

    const showDate = (isActive) => {
        setCollapse({ ...collapse, date: isActive });
    }

    const onChangeFinanceHandler = () => {
        goToTab(2);
    }

    const onChangeExchangeeHandler = () => {
        goToTab(1);
    }

    const formatStringVersion = (value) => {
        if (value.length > 0) {
            let formatString = value.split('|');
            formatString = `${formatString[0]} ${formatString[1]}p ${formatString[2]}cv`;
            return formatString;
        }
        return value
    }

    const clientSearchSelect = (data) => {
        submitProposal.setFieldValue('name', data.firstName);
        submitProposal.setFieldValue('lastname', data.lastName);
        submitProposal.setFieldValue('phone', data.phone);
        submitProposal.setFieldValue('email', data.email);
        const params = {
            name: data?.firstName,
            lastname: data?.lastName,
            phone: data?.phone,
            email: data?.email
        }
        setDataPIInsert({ ...dataPIInsert, ...params });
        close();
    }

    const clearSearchHandler = () => {
        searchClientRequest.setFieldValue('search', '');
    }

    const showLegal = (value) => {
        const popupAlerData = {
            description: value,
            type: 'ALERT_TEXT_CONFIRM',
            title: 'Informação Legal',
            icon: 'info',
            actions: {
                onConfirm: () => setOpenPopUp(false)
            }
        }
        setPopupAlert(popupAlerData)
        setOpenPopUp(true);
    }

    const customOnChangeHandler = (event) => {
        let id = event.target.id;
        let value = event.target.value;
        submitProposal.setFieldValue(id, value);
        const param = {
            [`${id}`]: value
        }
        setDataPIInsert({ ...dataPIInsert, ...param });
    }

    const searchSchedule = async (result) => {
        try {
            if (result) {
                setRequestStatus(true);
                const params = {
                    idSalesman: cognitoUser.idUser,
                    login: cognitoUser.login
                }
                const result = await Usados.GetSalesManSchedule(params);
                const filterData = Utils.orderDataByMonthYear(result);

                setRequestStatus(false);
                if (result?.length > 0) {
                    init({
                        header: {
                            title: 'Agendamentos',
                            back: {
                                action: searchScheduleClose,
                                hide: false
                            },
                            close: {
                                hide: true
                            }
                        },
                        footer: {
                            hide: true
                        },
                        render: <PopupScheduleSubview data={filterData} actions={{ onSelect: scheduleSelect }} />
                    }, true);
                } else {
                    const popupAlerData = {
                        description: 'Não foram encontrados agendamemtos.',
                        type: 'ALERT_TEXT_CONFIRM',
                        title: 'Sem resultados',
                        icon: 'info',
                        actions: {
                            onConfirm: () => setOpenPopUp(false)
                        }
                    }
                    scheduleToogle.current.setOff();
                    setPopupAlert(popupAlerData)
                    setOpenPopUp(true);
                }
            } else {
                clearClientData()
            }
        } catch (error) {
            setRequestStatus(false);
            console.log(error);
        }
    }

    const searchScheduleClose = async () => {
        scheduleToogle.current.setOff();
        close()
    }

    const scheduleSelect = (data) => {
        scheduleToogle.current.setOn();
        submitProposal.setFieldValue('name', data?.registryFirstName);
        submitProposal.setFieldValue('lastname', data?.registryLastName);
        submitProposal.setFieldValue('phone', data?.registryPhone);
        submitProposal.setFieldValue('email', data?.registryEmail);
        setScheduleID(data?.registryID);
        savePIDataSchedule(data);
        close();
    }

    const clearClientData = () => {
        submitProposal.setFieldValue('name', '');
        submitProposal.setFieldValue('lastname', '');
        submitProposal.setFieldValue('phone', '');
        submitProposal.setFieldValue('email', '');
        setScheduleID(null);
        savePIDataSchedule();
    }

    const savePIDataSchedule = (data = "") => {
        const params = {
            name: data?.registryFirstName,
            lastname: data?.registryLastName,
            phone: data?.registryPhone,
            email: data?.registryEmail,
            registryID: data?.registryID
        }
        setDataPIInsert({ ...dataPIInsert, ...params });
    }

    const onFooterRender = (idPi, reserveStatus) => {
        if (!reserveStatus) {
            return (
                <>
                    <div className={classes.row} onClick={() => carReservation(idPi)}>
                        <Button styles={classes.button}>{t('popup-overlay-subview-search-detail-proposal-btn1-label')}</Button>
                    </div>
                    <div className={classes.row}>
                        <Button variant='outlined' styles={classes.button} onClick={() => sendPI(idPi)}>{t('popup-overlay-subview-search-detail-proposal-btn2-label')}</Button>
                        <Button variant='outlined' styles={classes.button} onClick={() => printPI(idPi)}>{t('popup-overlay-subview-search-detail-proposal-btn3-label')}</Button>
                    </div>
                </>
            );
        } else {
            if (reserveStatus?.salesman?.idUser == cognitoUser?.idUser) {
                return (
                    <>
                        <div className={classes.row}>
                            <div className={classes.cell_invert_footer}>
                                <div className={classes.item1} onClick={() => handleRemoveReservation()} ><div className={classes.icon_footer}><span>h</span></div></div>
                                <div className={classes.item2}><div className={classes.title_invert_footer}>{t('footer-proposal-text-label1')}</div></div>
                                <div className={classes.item1}>
                                    <div className={classes.middle_column}>
                                        <span className={classes.text_invert_footer}>{`${t('footer-proposal-text-label2')} ${reserveStatus?.reservationEndDate ? Utils.formatStringDateTime(reserveStatus?.reservationEndDate).time : '-'}`}</span>
                                        <span className={classes.label_invert_footer}><strong>{reserveStatus?.reservationEndDate ? Utils.formatStringDateTime(reserveStatus?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <Button variant='outlined' styles={classes.button} onClick={() => sendPI(idPi)}>{t('popup-overlay-subview-search-detail-proposal-btn2-label')}</Button>
                            <Button variant='outlined' styles={classes.button} onClick={() => printPI(idPi)}>{t('popup-overlay-subview-search-detail-proposal-btn3-label')}</Button>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div className={classes.row}>
                            <div className={classes.cell_footer}>
                                <div className={classes.item1}><div className={classes.icon_footer}><span>h</span></div></div>
                                <div className={classes.item2}><div className={classes.title_footer}>{t('footer-proposal-text-label3')}</div></div>
                                <div className={classes.item1}>
                                    <div className={classes.middle_column}>
                                        <span className={classes.text_footer}>{`${t('footer-proposal-text-label2')} ${reserveStatus?.reservationEndDate ? Utils.formatStringDateTime(reserveStatus?.reservationEndDate).time : '-'}`}</span>
                                        <span className={classes.text_footer}><strong>{reserveStatus?.reservationEndDate ? Utils.formatStringDateTime(reserveStatus?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.columnLeft_footer}>
                                <a>{t('footer-proposal-text-label4')}</a>
                                <a>{reserveStatus.salesman?.name ? reserveStatus.salesman?.name : '-'}</a>
                            </div>
                            <div className={classes.vertical_footer}></div>
                            <div className={classes.columnRight_footer}>
                                <a>{t('footer-proposal-text-label5')}</a>
                                <a>{`${reserveStatus.salesman?.dealer?.comercialName ? reserveStatus.salesman?.dealer?.comercialName : reserveStatus.salesman?.dealer?.desig} - ${reserveStatus.salesman?.dealer?.cpExt ? reserveStatus.salesman?.dealer.cpExt : '-'}`}</a>
                            </div>
                        </div>
                    </>
                );
            }
        }
    }

    return (
        <div className={classes.root}>

            <div className={classes.header_box}>
                <div className={classes.limit_area}>
                    <div className={classes.header}>
                        <div>{t('search-proposal-subview-header-text1-title')}</div>
                        <form onSubmit={searchClientRequest.handleSubmit} style={{ width: '100%' }}>
                            <InputText
                                id="search"
                                name="search"
                                placeholder='E-mail ou Nº de telefone'
                                value={searchClientRequest.values.search}
                                onChange={searchClientRequest.handleChange}
                                styles={classes.inputfield_left}
                                endAdornment={
                                    searchClientRequest.values.search.length > 0 ?
                                        <span onClick={clearSearchHandler} className={classes.icon}>F</span>
                                        :
                                        <span className={classes.icon}></span>
                                }
                            />
                        </form>
                    </div>
                </div>
            </div>



            <div className={classes.box_root}>
                <div className={classes.box_container}>
                    <div className={classes.limit_area}>
                        <form onSubmit={submitProposal.handleSubmit}>

                            <div className={classes.box}>
                                <div className={classes.container}>
                                    <div className={clsx(classes.row, classes.row_center)}>
                                        <span className={classes.title}>{t('search-proposal-subview-container-finance-toogle2-label')}</span>
                                        <Toggle ref={scheduleToogle} onChange={searchSchedule} />
                                    </div>

                                    <div className={classes.line}></div>


                                    <div className={classes.inputlabel}><span>{t('search-proposal-subview-container-client-input1-label')}</span></div>
                                    <InputText
                                        id="name"
                                        name="name"
                                        value={submitProposal.values.name}
                                        onChange={customOnChangeHandler}
                                        styles={classes.inputfield_bold}
                                        placeholder={t('search-proposal-subview-container-client-input1-label')}
                                        helperText={"Name needs to be 'a'"}
                                        required={true}
                                    />
                                    <div className={classes.inputlabel}><span>{t('search-proposal-subview-container-client-input2-label')}</span></div>
                                    <InputText
                                        id="lastname"
                                        name="lastname"
                                        value={submitProposal.values.lastname}
                                        onChange={customOnChangeHandler}
                                        styles={classes.inputfield_bold}
                                        placeholder={t('search-proposal-subview-container-client-input2-label')}
                                        required={true}
                                    />
                                    <div className={classes.inputlabel}><span>{t('search-proposal-subview-container-client-input3-label')}</span></div>
                                    <InputText
                                        id="phone"
                                        name="phone"
                                        type="number"
                                        maxLength="9"
                                        value={submitProposal.values.phone}
                                        onChange={customOnChangeHandler}
                                        styles={classes.inputfield}
                                        placeholder={t('search-proposal-subview-container-client-input3-label')}
                                        required={true}
                                        maxLength={9}
                                    />
                                    <div className={classes.inputlabel}><span>{t('search-proposal-subview-container-client-input4-label')}</span></div>
                                    <InputText
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={submitProposal.values.email}
                                        onChange={customOnChangeHandler}
                                        styles={classes.inputfield}
                                        placeholder={t('search-proposal-subview-container-client-input4-label')}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div className={classes.header}>
                                <div>{t('search-proposal-subview-header-text2-title')}</div>
                            </div>
                            <div className={classes.box}>
                                <div className={classes.container}>

                                    <div className={classes.model}>
                                        <span>{t('search-proposal-subview-container-vehicle-text1-label')}</span>
                                        <span>{carSelected.model ? carSelected.model : '-'}</span>
                                        <span>{carSelected.version ? carSelected.version : '-'}</span>
                                    </div>

                                    <div className={classes.line}></div>

                                    <div className={classes.price}>
                                        <span>{t('search-proposal-subview-container-vehicle-text2-label')}</span>
                                        {
                                            carSelected.discountPrice > 0 ?
                                                <>
                                                    <span>{carSelected.discountPrice ? Utils.formatPrice(carSelected.discountPrice) : '- €'}</span>
                                                    <span>{carSelected.price ? `antes ${Utils.formatPrice(carSelected.price)}` : `antes - €`}</span>
                                                </>
                                                :
                                                <>
                                                    <span>{carSelected.price ? Utils.formatPrice(carSelected.price) : '- €'}</span>
                                                </>
                                        }
                                    </div>

                                    <div className={classes.line}></div>

                                    <div className={classes.license}>
                                        <span>{t('search-proposal-subview-container-vehicle-text3-label')}</span>
                                        <span>{carSelected.plateNumber ? Utils.formartLicensePlate(carSelected.plateNumber) : '-- -- --'}</span>
                                    </div>

                                    <div className={classes.line}></div>

                                    <div className={classes.row} style={{ alignItems: 'flex-start' }}>

                                        <div className={classes.columnLeft}>
                                            <div className={classes.cell}>
                                                <p>{t('search-proposal-subview-container-vehicle-text4-label')}</p>
                                                <p>{carSelected.registrationDate ? Utils.changeDateStringFormat(carSelected.registrationDate) : '-'}</p>
                                                <p>{carSelected.registrationDate ? calculateAge(carSelected.registrationDate) : '-'}</p>
                                            </div>
                                            <div className={classes.cell}>
                                                <p>{t('search-proposal-subview-container-vehicle-text5-label')}</p>
                                                <p>{carSelected.fuel ? carSelected.fuel : '-'}</p>
                                                <p></p>
                                            </div>
                                            <div className={classes.cell}>
                                                <p>{t('search-proposal-subview-container-vehicle-text6-label')}</p>
                                                <p>{carSelected.color ? carSelected.color : '-'}</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className={classes.columnRight}>
                                            <div className={classes.cell}>
                                                <p>{t('search-proposal-subview-container-vehicle-text7-label')}</p>
                                                <p>{carSelected.km ? `${Utils.formatMetric(carSelected.km)} Km` : '-'}</p>
                                                <p>{carSelected.km && carSelected.registrationDate ? calculateKmPerYear(carSelected.km, carSelected.registrationDate) : '-'}</p>
                                            </div>
                                            <div className={classes.cell}>
                                                <p>{t('search-proposal-subview-container-vehicle-text8-label')}</p>
                                                <p>{carSelected.doorCount ? carSelected.doorCount : '-'}</p>
                                                <p></p>
                                            </div>
                                            <div className={classes.cell}>
                                                <p>{t('search-proposal-subview-container-vehicle-text9-label')}</p>
                                                <p>{carSelected.dealerName ? carSelected.dealerName : '-'}</p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.equipments}>
                                        <div className={classes.collapse} style={{ height: collapse.equipments ? 'auto' : '0px' }}>
                                            {
                                                carSelected.equipments ?
                                                    carSelected.equipments.length > 0 ?
                                                        carSelected.equipments.map((prop, index) => {
                                                            return (
                                                                <div id="equipments-row" key={index}>{`• ${prop}`}</div>
                                                            );
                                                        }) :
                                                        <p className={classes.message}>{t('search-proposal-subview-container-vehicle-text-message')}</p>
                                                    :
                                                    <p className={classes.message}>{t('search-proposal-subview-container-vehicle-text-message')}</p>
                                            }
                                        </div>
                                        <span className={classes.anchor} onClick={showEquipmment}>{collapse.equipments ? t('search-proposal-subview-container-vehicle-text2-anchor') : t('search-proposal-subview-container-vehicle-text1-anchor')}</span>
                                    </div>

                                </div>
                            </div>


                            <div className={classes.header}>
                                <div>{t('search-proposal-subview-header-text7-title')}</div>
                            </div>
                            <div className={classes.box}>
                                <div className={classes.container}>
                                    <div className={clsx(classes.row, classes.row_center)}>
                                        <span className={classes.title}>{t('search-proposal-subview-container-finance-toogle3-label')}</span>
                                        <Toggle onChange={showExchange} />
                                    </div>

                                    <div className={classes.row}>
                                        <div className={classes.collapse} style={{ height: collapse.exchange && carSelected.carReturn ? 'auto' : '0px' }}>

                                            <div className={classes.line}></div>


                                            <div className={classes.row}>
                                                <div className={classes.columnRight}>
                                                    <span className={classes.label}>{t('search-proposal-subview-container-exchange-text1-label')}</span>
                                                    <span className={classes.subtitle}>{carSelected?.carTrade?.licensePlate ? carSelected?.carTrade?.licensePlate.match(/.{1,2}/g).join(' ').toUpperCase() : '-- -- --'}</span>
                                                </div>
                                                <div className={classes.vertical}></div>
                                                <div className={classes.columnLeft}>
                                                    <span className={classes.label}>{t('search-proposal-subview-container-exchange-text2-label')}</span>
                                                    <span className={classes.subtitle}>{carSelected?.carTrade?.year && carSelected?.carTrade?.year > 0 ? carSelected?.carTrade?.year : '-'}</span>
                                                </div>
                                            </div>

                                            <br />

                                            <div className={classes.row}>
                                                <div className={classes.field2}>
                                                    <span className={classes.label}>{t('search-proposal-subview-container-exchange-text4-label')}</span>
                                                    <span>{carSelected?.carTrade?.version ? formatStringVersion(carSelected?.carTrade?.version) : '-'}</span>
                                                </div>
                                            </div>

                                            <div className={classes.line}></div>

                                            <div className={classes.row}>
                                                <div className={classes.field}>
                                                    <span>{t('search-proposal-subview-container-exchange-text3-label')}</span>
                                                    <span>{carSelected?.carTrade?.tradePrice ? Utils.formatPrice(carSelected?.carTrade?.tradePrice) : '- €'}</span>
                                                </div>
                                            </div>

                                            <div className={classes.row} style={{ justifyContent: 'center' }}>
                                                <Button variant='outlined' styles={classes.button} onClick={onChangeExchangeeHandler}>{t('search-proposal-subview-container-finance-btn-label')}</Button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div className={classes.header}>
                                <div>{t('search-proposal-subview-header-text3-title')}</div>
                            </div>
                            <div className={classes.box}>
                                <div className={classes.container}>
                                    <div className={clsx(classes.row, classes.row_center)}>
                                        <span className={classes.title}>{t('search-proposal-subview-container-finance-toogle-label')}</span>
                                        <Toggle onChange={showFinance} />
                                    </div>

                                    <div className={classes.row}>
                                        <div className={classes.collapse} style={{ height: collapse.finance && carSelected.financeInfoSimulated ? 'auto' : '0px' }}>

                                            <div className={classes.line}></div>

                                            <div className={classes.row}>
                                                <div className={classes.columnRight}>
                                                    <span className={classes.label}>{t('search-proposal-subview-container-finance-text1-label')}</span>
                                                    <span className={classes.subtitle}>{carSelected.financeInfoSimulated?.initialEntrance ? Utils.formatPrice(carSelected.financeInfoSimulated?.initialEntrance) : '-'}</span>
                                                </div>
                                                <div className={classes.vertical}></div>
                                                <div className={classes.columnLeft}>
                                                    <span className={classes.label}>{t('search-proposal-subview-container-finance-text2-label')}</span>
                                                    <span className={classes.subtitle}>{carSelected.financeInfoSimulated?.capitalToFinance ? Utils.formatPrice(carSelected.financeInfoSimulated?.capitalToFinance) : '-'}</span>
                                                </div>
                                            </div>

                                            <div className={classes.line}></div>

                                            <div className={classes.row}>
                                                <div className={classes.field}>
                                                    <span>{t('search-proposal-subview-container-finance-text3-label')}</span>
                                                    <span>{carSelected.financeInfoSimulated?.nrPayments && carSelected.financeInfoSimulated?.rent ? `${Utils.formatPrice(carSelected.financeInfoSimulated?.rent)}${t('search-proposal-subview-container-finance-text3-label1')} ${carSelected.financeInfoSimulated?.nrPayments}` : '-'}</span>
                                                </div>
                                            </div>

                                            <div className={classes.line}></div>

                                            <div className={classes.row} style={{ justifyContent: 'center' }}>
                                                <div className={classes.cell_2}>
                                                    <span>{t('search-proposal-subview-container-finance-text4-label')}</span>
                                                    <span>{`${carSelected.financeInfoSimulated?.taeg && carSelected.financeInfoSimulated?.taeg > 0 ? Utils.formatPercentage(carSelected.financeInfoSimulated?.taeg) : '-'}`}</span>
                                                    <div className={classes.legal} onClick={() => showLegal(carSelected.financeInfoSimulated?.legalInfo)}>
                                                        <span>i</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classes.row} style={{ justifyContent: 'center' }}>
                                                <Button variant='outlined' styles={classes.button} onClick={onChangeFinanceHandler}>{t('search-proposal-subview-container-finance-btn-label')}</Button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className={classes.header}>
                                <div>{t('search-proposal-subview-header-text4-title')}</div>
                            </div>
                            <div className={classes.box}>
                                <div className={classes.container}>
                                    <div className={classes.inputarea_cell}>
                                        <InputText
                                            id="comment"
                                            name="comment"
                                            placeholder={t('search-proposal-subview-container-comment-input-label')}
                                            value={submitProposal.values.comment}
                                            onChange={customOnChangeHandler}
                                            styles={clsx(classes.inputfield, classes.inputarea)}
                                            multiline={true}
                                            maxLength={200}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className={classes.header}>
                                <div>{t('search-proposal-subview-header-text5-title')}</div>
                            </div>

                            <div className={classes.box}>
                                <div className={classes.container}>

                                    <div className={clsx(classes.row, classes.row_center)}>
                                        <span className={classes.title}>{t('search-proposal-subview-container-time-toogle-label')}</span>
                                        <Toggle onChange={showDate} />
                                    </div>

                                    <div className={classes.row}>
                                        <div className={classes.collapse} style={{ height: collapse.date ? 'auto' : '0px' }}>

                                            <div className={classes.line}></div>

                                            <div className={classes.row}>
                                                <div className={classes.field}>
                                                    <span>{t('search-proposal-subview-container-time-input-label')}</span>
                                                    <div className={classes.time_row}>
                                                        <div className={classes.time_cell}>
                                                            <InputText
                                                                id="time"
                                                                name="time"
                                                                type="time"
                                                                value={submitProposal.values.time}
                                                                placeholder='hh:mm'
                                                                onChange={submitProposal.handleChange}
                                                                styles={classes.inputfield}
                                                                required={collapse.date ? true : false}
                                                            />
                                                        </div>
                                                        <span className={classes.text_cell}>{t('search-proposal-subview-container-time-input-label1')}</span>
                                                        <div className={classes.date_cell}>
                                                            <InputText
                                                                id="date"
                                                                name="date"
                                                                type="date"
                                                                value={submitProposal.values.date}
                                                                onChange={submitProposal.handleChange}
                                                                styles={classes.inputfield}
                                                                required={collapse.date ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className={classes.header}>
                                <div>{t('search-proposal-subview-header-text6-title')}</div>
                            </div>

                            <div className={classes.box}>
                                <div className={classes.container}>
                                    <div className={classes.row}>
                                        <Button variant='outlined' styles={classes.button} onClick={resetFields}>{t('search-proposal-subview-container-final-btn1-label')}</Button>
                                        <Button styles={classes.button} type='submit'>{t('search-proposal-subview-container-final-btn2-label')}</Button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <PopOverlayView />
            {
                openPopUp ? (<PopupAlert open={openPopUp} />) : null
            }

            {openPopUpConfirm && <PopupAlert
                open={openPopUpConfirm}
                type="ALERT_CONFIRM"
                onCancel={() => setOpenPopUpConfirm(false)}
                onClose={() => setOpenPopUpConfirm(false)}
                onConfirm={() => onConfirmCancelReservation()}
            />}
        </div >
    );
}

export default withRouter(PedidoSubview);