const Styles = theme => ({
    root: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        // height: '100vh',
        // padding: '0px',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'space-between'
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'Column'
    },
    toolbarContainer: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        zIndex: '10',
    },
    viewContainer: {
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'flex-start',
        flexGrow: 1,
        // overflowY: 'scroll'
        overflowY: 'hidden'
    }
})

export default Styles;