import React, { useEffect, useState, useContext } from 'react';

import { makeStyles, CircularProgress } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';


import { useFormik } from 'formik';

import Button from '../../../../components/Button';
import InputText from '../../../../components/InputText';
import DropdownText from '../../../../components/DropdownText';
import DropRadioModal from '../../../../components/DropRadioModal';
import PopupAlert from '../../../../components/PopupAlert';


import * as Utils from '../../../../service/utils';
import { useAppContext } from '../../../../context/app/app-context';
import { ServiceContext } from '../../../../context/service/service-context';
import Styles from './style';

const useStyles = makeStyles(Styles);

const FinanciaSubview = () => {

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { carSelected, setCarSelected } = useAppContext();
    const { Usados } = useContext(ServiceContext);

    const [openDropRadioModal, setOpenDropRadioModal] = useState(false);
    const [dataDropRadioModal, setDataDropRadioModal] = useState(null);

    const [financialOption, setFinancialOption] = useState();
    const [productSelected, setProductSelected] = useState({});

    const { setPopupAlert } = useAppContext()

    const [selectedProduct, setSelectedProduct] = useState('none');
    const [selectedFee, setSelectedFee] = useState('none');

    const [isRequest, setIsRequest] = useState(false);
    const [openInfoLegal, setOpenInfoLegal] = useState(false)

    const [tempSimulation, setTempSimulation] = useState({ data: {}, isSim: false, saved: false, new: false })


    useEffect(async () => {
        setTempSimulation({ ...tempSimulation, haveFinance: carSelected.financeInfoRecommended || carSelected.financeInfoSimulated ? true : false, isSim: carSelected.financeInfoSimulated ? true : false, data: carSelected.financeInfoSimulated ? carSelected.financeInfoSimulated : carSelected.financeInfoRecommended });
        await getSimualtionData();
    }, [])


    const getSimualtionData = async () => {
        try {
            setIsRequest(true);
            const response = await Usados.GetSimulatorUsedCars({ licensePlate: carSelected.plateNumber });
            let finance = response?.getSimulatorUsedCars;
            setFinancialOption(finance);
            onSelectProduct(finance);
            setIsRequest(false);
        } catch (error) {
            console.log(error);
        }
    }

    const saveFinance = useFormik({
        initialValues: {
        },
        onSubmit: async (values) => {
            try {
                if (tempSimulation.saved && tempSimulation.new) {
                    const popupAlerData = {
                        description: "Vai substituir os dados gravados anteriormente. Concorda?",
                        type: 'ALERT_CONFIRM',
                        actions: {
                            onConfirm: onOverwriteSimulation,
                            onCancel: () => setOpenInfoLegal(false)
                        }
                    }
                    setPopupAlert(popupAlerData)
                    setOpenInfoLegal(true);
                } else {
                    setCarSelected({ ...carSelected, financeInfoSimulated: tempSimulation.data });
                    setTempSimulation({ ...tempSimulation, saved: true, new: false })
                    history.push({ state: { ...carSelected, financeInfoSimulated: tempSimulation.data } })

                }
            } catch (error) {
                throw error
            }
        },
    });

    const onOverwriteSimulation = () => {
        setCarSelected({ ...carSelected, financeInfoSimulated: tempSimulation.data });
        setTempSimulation({ ...tempSimulation, saved: true, new: false })
        history.replace({ state: { ...carSelected, financeInfoSimulated: tempSimulation.data } })
        setOpenInfoLegal(false);
    }
    const simulateFinance = useFormik({
        initialValues: {
            initialEntrance: ''
        },
        onSubmit: async (values) => {
            try {
                await onRequestSimulation(values);
            } catch (error) {
                throw error
            }
        },
    });

    const onRequestSimulation = async (values) => {
        try {
            setOpenInfoLegal(false);
            const params = {
                licensePlate: carSelected.plateNumber,
                finalPrice: carSelected.discountPrice > 0 ? carSelected.discountPrice : carSelected.price,
                initEntrance: values.initialEntrance,
                idProduct: productSelected.id,
                monthPeriod: selectedFee
            }
            setIsRequest(true);
            const result = await Usados.GetSimulateFinanceUsedCars(params);
            let data = {
                acceptanceRent: result?.simulateFinanceUsedCars?.acceptanceFee,
                capitalToFinance: result?.simulateFinanceUsedCars?.financialFee,
                initialEntrance: values?.initialEntrance,
                monthlyRent: result?.simulateFinanceUsedCars?.monthlyFee,
                nrPayments: result?.simulateFinanceUsedCars?.monthPeriod,
                legalInfo: result?.simulateFinanceUsedCars?.legalInfo,
                rent: result?.simulateFinanceUsedCars?.monthlyPayment,
                taeg: result?.simulateFinanceUsedCars?.taeg,
                tan: result?.simulateFinanceUsedCars?.tan,
                mtic: result?.simulateFinanceUsedCars?.mtic
            }
            if (result) {
                setTempSimulation({ ...tempSimulation, isSim: true, data: data, new: true })
            }
            setIsRequest(false);
        } catch (error) {
            console.log(error);
            setIsRequest(false);

        }
    }

    const calculatePrice = () => {
        if (carSelected.discountPrice > 0) {
            return (
                <div style={{ flex: 1 }}>
                    <div>{carSelected.discountPrice ? Utils.formatPrice(carSelected.discountPrice) : '- €'}</div>
                    <div>{carSelected.price ? `${t('search-detail-subview-header-label')} ${Utils.formatPrice(carSelected.price)}` : `${t('search-detail-subview-header-label')} - €`}</div>
                </div>
            );
        } else {
            return (
                <div style={{ flex: 1 }}>
                    <div>{carSelected.price ? Utils.formatPrice(carSelected.price) : '- €'}</div>
                </div>
            );
        }
    }

    const onSelectRadio = (data) => {
        switch (data.type) {
            case 'financeiro':
                setSelectedProduct(data.value);
                onSelectProduct(financialOption, data.value);
                break;
            case 'fee':
                setSelectedFee(data.value);
                break;
            default:
                break;
        }
    }

    const onDropdownClick = (key) => {
        let _data = null;
        switch (key) {
            case 'financeiro':
                _data = {
                    title: t('search-finance-subview-product-text-label'),
                    type: 'financeiro',
                    options: financialOption.financialProducts.map(it => {
                        const dt = {
                            id: it.name,
                            name: it.name,
                        }
                        return dt
                    }),
                    selected: selectedProduct
                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            case 'fee':
                _data = {
                    title: t('search-finance-subview-fee-text-label'),
                    type: 'fee',
                    options: productSelected.monthPeriods.map(it => {
                        const dt = {
                            id: String(it),
                            name: String(it)
                        }
                        return dt
                    }),
                    selected: selectedFee,
                }
                setDataDropRadioModal(_data)
                setOpenDropRadioModal(true)
                break;

            default:
                break;
        }
    }

    const onSelectProduct = (data, index = null) => {
        let financials = data.financialProducts;
        if (index) {
            let result = financials.find(option => option.name === index);
            setProductSelected(result);
            setSelectedProduct(result.name);
            setSelectedFee(String(result.monthPeriods[0]));
        } else {
            let inicial = financials[0];
            setProductSelected(inicial);
            setSelectedProduct(inicial.name);
            setSelectedFee(String(inicial.monthPeriods[0]));
        }
    }

    const showLegal = (value) => {
        const popupAlerData = {
            description: value,
            type: 'ALERT_TEXT_CONFIRM',
            title: 'Informação Legal',
            icon: 'info',
            actions: {
                onConfirm: () => setOpenInfoLegal(false)
            }
        }
        setPopupAlert(popupAlerData)
        setOpenInfoLegal(true);
    }

    const clearSimulation = () => {
        setTempSimulation({ ...tempSimulation, data: carSelected.financeInfoRecommended, isSim: false, saved: false, new: false });
        delete carSelected.financeInfoSimulate
        setCarSelected({...carSelected, financeInfoSimulated: null});
        history.replace({ state: { ...carSelected, financeInfoSimulated: null } });
    }

    return (
        <div className={classes.root}>

            <div className={classes.header_box}>
                <div className={classes.limit_area}>
                    <div className={classes.header}>
                        <div>{carSelected.version ? carSelected.version : '-'}</div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {calculatePrice()}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.box_root}>
                <div className={classes.box_container}>
                    {
                        tempSimulation.haveFinance || tempSimulation.isSim ?
                            <>
                                <div className={classes.limit_area}>
                                    <div className={classes.container}>
                                        {
                                            tempSimulation.isSim ?
                                                <div className={classes.title}>
                                                    <span>{t('search-finance-subview-simulated-text-title')}</span>
                                                </div>
                                                :
                                                <div className={classes.title}>
                                                    <span>{t('search-finance-subview-recommend-text-title')}</span>
                                                </div>
                                        }
                                        <div className={classes.row}>
                                            <div className={classes.columnRight}>
                                                <span>{t('search-finance-subview-entrance-text-label')}</span>
                                                <span>{tempSimulation.data?.initialEntrance ? Utils.formatPrice(tempSimulation.data?.initialEntrance) : '-'}</span>
                                            </div>
                                            <div className={classes.vertical}></div>
                                            <div className={classes.columnLeft}>
                                                <span>{t('search-finance-subview-capital-text-label')}</span>
                                                <span>{tempSimulation.data?.capitalToFinance ? Utils.formatPrice(tempSimulation.data?.capitalToFinance) : '-'}</span>
                                            </div>
                                        </div>
                                        <div className={classes.column}>
                                            <div className={classes.field}>
                                                <span>{t('search-finance-subview-payment-text-label')}</span>
                                                <span>{tempSimulation.data?.nrPayments && tempSimulation.data?.rent ? `${Utils.formatPrice(tempSimulation.data?.rent)}/ mês x ${tempSimulation.data?.nrPayments}` : '-'}</span>
                                            </div>
                                        </div>
                                        <div className={classes.column}>
                                            <div className={classes.cell_2}>
                                                <span>{t('search-proposal-subview-container-finance-text4-label')}</span>
                                                <span>{`${tempSimulation.data?.taeg && tempSimulation.data?.taeg > 0 ? Utils.formatPercentage(tempSimulation.data?.taeg) : '-'}`}</span>
                                                <div className={classes.legal} onClick={() => showLegal(tempSimulation.data?.legalInfo)}>
                                                    <span>i</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className={classes.column} style={{ flexDirection: 'row' }}>
                                            {
                                                tempSimulation.isSim ?
                                                    <Button variant='outlined' styles={classes.button} onClick={clearSimulation}>Limpar</Button>
                                                    : null
                                            }
                                            {
                                                tempSimulation.new || tempSimulation.saved ?
                                                    <Button variant={!tempSimulation.new ? 'outlined' : 'contained'} styles={classes.button} onClick={saveFinance.handleSubmit} disabled={!tempSimulation.new}>{tempSimulation.saved ? 'Gravado' : 'Gravar'}</Button>
                                                    :
                                                    null
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className={classes.line}></div>
                            </>
                            : null
                    }
                    <div className={classes.limit_area}>
                        <div className={classes.box}>
                            <div style={{ width: '100%', height: '100%', display: isRequest ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: '1000', backgroundColor: 'rgba(250,250,250,0.9)' }}>
                                <CircularProgress className={classes.circularProgress} />
                            </div>
                            <div className={classes.container}>
                                <div className={classes.title}>
                                    <span>{t('search-finance-subview-finance-text1-title')}<br />{t('search-finance-subview-finance-text2-title')}</span>
                                </div>
                                <form onSubmit={simulateFinance.handleSubmit} >
                                    <div className={classes.labelInput}><span>{t('search-finance-subview-entrance-text-label')}</span></div>
                                    <InputText
                                        id="initialEntrance"
                                        type='eur'
                                        name="initialEntrance"
                                        value={simulateFinance.values.initialEntrance}
                                        onChange={value => simulateFinance.setFieldValue('initialEntrance', value)}
                                        styles={classes.inputfield}
                                        placeholder={'0,00 €'}
                                    />
                                    <DropdownText
                                        id="financeiro"
                                        title={t('search-finance-subview-product-text-label')}
                                        data={selectedProduct}
                                        onClick={onDropdownClick} />
                                    <DropdownText
                                        id="fee"
                                        title={t('search-finance-subview-fee-text-label')}
                                        data={selectedFee}
                                        onClick={onDropdownClick} />
                                    <div className={classes.column}>
                                        <Button variant={tempSimulation.new ? 'outlined' : 'contained'} styles={classes.button} type='submit'>{t('search-finance-subview-simutale-btn-label')}</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {openDropRadioModal ? (
                <DropRadioModal
                    open={openDropRadioModal}
                    data={dataDropRadioModal}
                    hiddenDefault={true}
                    hiddenButton={false}
                    onSelect={data => onSelectRadio(data)}
                    onClose={() => setOpenDropRadioModal(false)} />
            ) : null}
            {openInfoLegal ? (<PopupAlert open={openInfoLegal} />) : null}
        </div>
    );
}

export default withRouter(FinanciaSubview);