import React, { useState, useEffect } from 'react';
import { makeStyles, Modal, Typography } from '@material-ui/core';
import Styles from './style';

const pushAlertStatus = {
    state: {
        isOpen: false,
        title: '',
        description: '',
        icon: null,
        actions: {
            onCancel: null,
            onClose: null,
            onConfirm: null
        }
    },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const usePushAlert = () => {

    const [state, set] = useState(pushAlertStatus.state);
    if (!pushAlertStatus.setters.includes(set)) {
        pushAlertStatus.setters.push(set);
    }

    useEffect(() => () => {
        pushAlertStatus.setters = pushAlertStatus.setters.filter(setter => setter !== set)
    }, []);

    function open() {
        pushAlertStatus.setState({ ...state, isOpen: true });
    }

    function close() {
        pushAlertStatus.setState({ ...state, isOpen: false });
    }

    function triggerPushAlert(params) {
        pushAlertStatus.setState({ ...state, ...params, isOpen: true });
    }

    function clear() {
        pushAlertStatus.setState({ isOpen: false, title: null, description: null, icon: null, actions: { onCancel: null, onClose: null, onConfirm: null } });
    }

    return { state, open, close, clear, triggerPushAlert };
}

pushAlertStatus.setState = pushAlertStatus.setState.bind(pushAlertStatus);

const useStyles = makeStyles(Styles);

const PushAlert = () => {

    const classes = useStyles();
    const { state, close, clear } = usePushAlert()

    const onConfirmHandler = () => {
        if(state?.actions?.onConfirm){
            state?.actions?.onConfirm();
        }
        clear();
        close();

    }

    const onCancelhandler = () => {
        state?.actions?.onCancel()
        clear();
        close();
    }

    const onClosehandler = () => {
        state?.actions?.onClose()
        clear();
        close();
    }

    return (
        <Modal open={state.isOpen} onClose={onClosehandler} aria-labelledby="popup-alert-title" aria-describedby="popup-alert-description">
            <div className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.boxTitle}>
                        {
                            state.icon ?
                                (state.icon == "info" ? <span>\</span> : <span>{state.icon}</span>)
                                : null
                        }
                        {
                            state.title ?
                                <Typography variant="h2">{state.title}</Typography>
                                : null
                        }
                    </div>
                    {
                        state.description ?
                            <Typography variant="h6">
                                {state.description}
                            </Typography>
                            : null
                    }
                    <div className={classes.actionBox}>
                        <div className={classes.actionOk} onClick={onConfirmHandler}>
                            <span>OK</span>
                        </div>
                        {
                            state.actions?.onCancel ?
                                <div className={classes.actionCancel} onClick={onCancelhandler}>
                                    <span>Cancelar</span>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default PushAlert;