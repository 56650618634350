import { List } from "@material-ui/icons";
import React from 'react';

export const getMax = (list, field) => {
    if (!list && !list.length) return 0
    const allValues = list.map(it => it[`${field}`])
    const max = allValues.reduce((a, b) => { return Math.max(a, b) });
    return max
}

export const getMin = (list, field) => {
    if (!list && !list.length) return 0
    const allValues = list.map(it => it[`${field}`])
    const max = allValues.reduce((a, b) => { return Math.min(a, b) });
    return max
}

export const getUniquevalues = (list, field) => {
    const unique = [...new Set(list.map(item => item[`${field}`]))];
    return unique
}

export const countByProperty = (list, field, value) => {
    return list.filter(item => item[`${field}`] === value).length;

}

export const formatPrice = (value) => {
    let result = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value);
    // result = `${result.split(',')[0]} €`;
    return result;
}

export const formatMetric = (value) => {
    return new Intl.NumberFormat().format(value);
}

export const formatPercentage = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 3 }).format(value / 100);
}

export const stringToJson = (value) => {
    return JSON.parse(value);
}

export const isStringEmail = (value) => {
    let split = value.split('@');
    return split.length >= 2;
}

export const convertDateToTimestampMili = (time, date) => {
    let str = `${date} ${time}:00`
    var datum = Date.parse(str);
    return datum
}

export const formatStringDateTime = (value) => {
    let timestamp = new Date(parseInt(value));
    let optionsDate = { month: 'long', day: 'numeric' };
    let optionsTime = { hour: 'numeric', minute: 'numeric' };
    let date = timestamp.toLocaleDateString('pt-PT', optionsDate);
    const i = date?.lastIndexOf(' ')
    date = date.substring(0, i+4)
    let time = timestamp.toLocaleDateString('pt-PT', optionsTime).split(',')[1];
    let monthLabel = timestamp.toLocaleDateString('pt-PT', { month: 'long' });
    let params = {
        date: date,
        time: time,
        day: timestamp.getDate(),
        month: monthLabel.charAt(0).toUpperCase() + monthLabel.slice(1),
        string: `${date} - ${time}`
    }
    return params;
}

export const getDateTimeValues = (value) => {
    let timestamp = new Date(value);
    let optionsDay = { day: 'numeric' };
    let optionsMonth = { month: 'long', };
    let optionsYear = { year: 'numeric' };
    let optionsTime = { hour: 'numeric', minute: 'numeric' };
    let optionsWeek = { weekday: 'long' };
    let day = timestamp.toLocaleDateString('pt-PT', optionsDay);
    let month = timestamp.toLocaleDateString('pt-PT', optionsMonth);
    let year = timestamp.toLocaleDateString('pt-PT', optionsYear);
    let week = timestamp.toLocaleDateString('pt-PT', optionsWeek);
    let time = timestamp.toLocaleDateString('pt-PT', optionsTime).split(',')[1];
    return {
        day: day,
        month: month,
        year: year,
        week: week,
        hour: time.split(':')[0],
        min: time.split(':')[1],
        date: `${day}-${month}-${year}`,
        time: time,
    }
}

export const getFormattedDate = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
}

export const formartLicensePlate = (value) => {
    let replace = value.replaceAll("-", "");
    replace = replace.replaceAll(" ", "");
    replace = replace.match(/.{1,2}/g).join(' ');
    return replace;
}

export const inputFormatLicensePlate = (value) => {
    let format = value.replaceAll(/[^a-zA-Z0-9 ]/g, "");
    format = format.replaceAll(" ", "");
    if (format.length > 2) {
        format = format.match(/.{1,2}/g).join(' ');
    }
    return format;
}

export function AppendArrayHandler(...source) {
    let array = []
    source.map((prop) => {
        if (Array.isArray(prop)) {
            Array.prototype.push.apply(array, prop);
        }
    });
    return array;
}

export function changeDateStringFormat(value) {
    let date = value.split('-');
    return `${date[2]}/${date[1]}/${date[0]}`
}

export function changePhoneStringFormat(value) {
    try {
        const part1 = value.slice(0, 3);
        const part2 = value.slice(3, 6);
        const part3 = value.slice(6, 9);
        return `${part1} ${part2} ${part3}`
    } catch (error) {
        return ''
    }
}

export const orderDataByMonthYear = (schedules) => {
    // ORDENAR O OBJECTO POR DATA TAL COMO ESTA PELA 'registryDtSchedule'
    var sortData = schedules?.sort(function (a, b) {
        return new Date(a.registryDtSchedule) - new Date(b.registryDtSchedule)
    });

    // AGRUPAR OS DADOS QUE FAZEM PARTE DO MESMO MES E ANO DENTRO DE ARRAYS
    let groupData = sortData?.reduce((array, item) => {
        let data = new Date(item.registryDtSchedule);
        let level = `${data.getFullYear()}-${data.getUTCMonth() + 1}`;
        array[level] = [...array[level] || [], item];
        return array;
    }, {});

    // METER AS COISAS BONITAS
    let array = [];
    for (let prop in groupData) {
        let date = new Date(prop).toLocaleDateString('pt-PT', { month: 'long', year: 'numeric' });
        let item = {
            label: date,
            data: groupData[prop]
        }
        array.push(item);
    }
    return array
}


export const sortByProperty = (list, property) => {
    const listSorted = list.sort((a, b) => { return (a[property] === b[property]) ? 0 : (a[property] < b[property]) ? -1 : 1 })
    return listSorted
}

export const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
