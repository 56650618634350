
const Styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.fafafa
    },
    header: {
        width: '100%',
        height: '105px',
        backgroundColor: "#000000"
    },
    headerBox: {
        position: 'relative',
        height: '105px',
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: '421px'
        }
    },
    headerBoxOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        boxShadow: '0px 0px 7px #000000 inset',
        backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0.05) 65%, rgba(0, 0, 0, 0.85)), linear-gradient(to bottom,  rgba(0, 0, 0, 0.15) 26%, rgba(0, 0, 0, 0.85)), linear-gradient(to left, rgba(0, 0, 0, 0.05) 65%, rgba(0, 0, 0, 0.85) ) "
        // backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 26%, rgba(0, 0, 0, 0.85))'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 10,
        flexGrow: 1,
        maxWidth: theme.breakpoints.values.sm,
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '640px'
        }
    },

    box: {
        flex: 1,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    box_container: {
        padding: '15px 0px',
        overflowY: 'auto',
    },
    searchRegistrationBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '5px 20px',
        '& h6': {
            marginBottom: 6
        }
    },
    searchRegistrationBoxInner: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    searchActionsBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 0',
        margin: '0 8px',
    },
    buttonSearch: {
        width: '100%',
        margin: '0 12px'
    },
    boxDivider: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 0',
        '& span': {
            fontFamily: 'Open Sans',
            color: theme.palette.darkgray,
            fontWeight: 400,
            fontSize: '13px',
            lineHeight: 1.38
        }
    },
    dropdownBox: {
        margin: '0 20px'
    },
    dropdownBoxInner: {
        width: '100%'
    },
    boxItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '5px 12px',
        '& h6': {
            marginBottom: 6
        }
    },
    boxItemInner: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '& h6': {
            minWidth: '24px',
            textAlign: 'center'
        }
    },
    inputText: {
        width: '100%'
    },
    inputTextRegCar: {
        width: '100%',
        margin: 0,
        '& input': {
            fontSize: 22
        }
    },
    buttonSubmitSearch: {
        width: '100%',
        marginLeft: 11
    },
    moreRecentBox: {
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: theme.breakpoints.values.sm,
        '& h1': {
            color: theme.palette.black,
            marginBottom: 10
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 'calc(100% - 20px)'
        }
    },
    moreRecentBoxSlickBox: {
        display: 'flex',
        width: '100%'
    },
    iconBox: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
            fontSize: 32,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font',
            margin: '0 6px'
        }
    },
    moreRecentBoxCards: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 10,
        flexGrow: 1,
        width: '100%',
        maxWidth: theme.breakpoints.values.sm,
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '640px'
        }
    }
})

export default Styles;