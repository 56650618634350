import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Button';

import Styles from './style';

const useStyles = makeStyles(Styles);

const PopupSearchSubview = ({
    data,
    actions
}) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const onItemSelectedHandler = (values) => {
        actions.onSelect(values);
    }

    return (
        <div className={classes.root}>
            {
                data.map((prop, index) => {
                    return (
                        <div key={index} className={classes.container}>
                            <div className={classes.card}>
                                {
                                    prop.client?.firstName || prop.client?.lastName ? <div className={classes.card_title}>{`${prop.client?.firstName} ${prop.client?.lastName}`}</div> : null
                                }
                                <div className={classes.line}></div>
                                <div className={classes.card_subtitle}>
                                    {
                                        prop.car?.carBrand?.description || prop.car?.carModel?.description ? <div className={classes.card_title}>{`${prop.car?.carBrand?.description ? prop.car?.carBrand?.description : ''} ${prop.car?.carModel?.description ? prop.car?.carModel?.description : ''}`}</div> : null
                                    }
                                </div>
                                <div className={classes.card_body_small}>
                                    {
                                        prop.car?.carVersion?.description ? prop.car?.carVersion?.description : '-'
                                    }
                                </div>
                                <div className={classes.line}></div>
                                {
                                    prop.client?.phone ? <div className={classes.card_body}>{`${prop.client.phone}`}</div> : <div className={classes.card_body}>-</div>
                                }
                                {
                                    prop.client?.email ? <div className={classes.card_body}>{`${prop.client.email}`}</div> : <div className={classes.card_body}>-</div>
                                }
                                <div className={classes.row}>
                                    <Button styles={classes.button} onClick={() => onItemSelectedHandler(prop.client)}>{t('popup-overlay-subview-search-detail-search-client-btn-label')}</Button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default PopupSearchSubview;