
const Styles = theme => ({
    root: {
        display: 'flex',
        margin: '10px',
        flexDirection: 'column',
        boxShadow: '0 2px 2px 0 rgb(0, 0, 0, 0.3)',
        borderBottomRightRadius: '16px',
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '360px'
        },
    },
    row1: {
        width: '100%',
        height: '48px',
        display: 'flex'
    },
    boxDay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '100%',
        backgroundColor: '#999999',
        '& span': {
            fontFamily: 'Open Sans',
            fontSize: '20px',
            fontWeight: 800,
            color: '#FFF',
        }
    },
    boxMonth: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '70px',
        height: '100%',
        backgroundColor: '#999999',
        margin: '0 1px',
        '& span': {
            fontFamily: 'Open Sans',
            fontSize: '20px',
            fontWeight: 400,
            color: '#FFF',
        }
    },
    boxHour: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '82px',
        flexGrow: 1,
        height: '100%',
        backgroundColor: '#999999',
        '& span': {
            fontFamily: 'Open Sans',
            fontSize: '20px',
            fontWeight: 800,
            color: '#FFF'
        }
    },
    boxCheck: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '100%',
        backgroundColor: '#FFF',
        '& span': {
            fontFamily: 'Cycloid-Font',
            fontSize: '48px',
            color: '#FFF'
        }
    },
    boxCheck1: {
        backgroundColor: '#FFF',
        '& span': {
            color: '#cecece'
        }
    },
    boxCheck2: {
        backgroundColor: '#000',
        '& span': {
            color: '#FFF'
        }
    },
    boxCheck3: {
        backgroundColor: '#FFF',
        '& span': {
            color: '#000'
        }
    },
    boxFav: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '100%',
        backgroundColor: '#FFF',
        borderLeft: '1px solid #eeeeee',
        '& span': {
            fontFamily: 'Cycloid-Font',
            fontSize: '48px',
            color: '#cecece'
        }
    },
    row2: {
        height: '48px',
        borderBottom: '1px solid #eeeeee',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px'
    },
    row3: {
        height: '48px',
        display: 'flex',
        borderBottomRightRadius: '16px',
        alignItems: 'center',
        padding: '0 10px',
        justifyContent: 'space-between',
        '& h2': {
            fontWeight: 400,
            lineHeight: 1
        }
    },
    icon_phone: {
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#000000',
        width: '32px',
        height: '32px',
    },
    boxFavActive: {
        backgroundColor: '#000',
        '& a': {
            textDecoration: 'none !important'
        },
        '& span': {
            color: '#FFF'
        }
    },
    boxPhone: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    }
})

export default Styles;