import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import aws_export from './aws-exports';
import './assets/fonts/cycloid-font.ttf';
import App from './App';
import './i18n';
import './index.css';
import AuthContextProvider from './context/auth/auth-context';


Amplify.configure(aws_export);


ReactDOM.render(
    <AuthContextProvider>
        <App />
    </AuthContextProvider>
, document.getElementById('root'));

reportWebVitals();
