const Styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.fafafa
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        backgroundColor: '#000000',
        // boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.07)',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.16)',
    },
    header_title: {
        width: '220px',
        textAlign: 'center',
        marginBottom: '5px',
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff',
        lineHeight: '1.07'
    },
    header_subtitle: {
        width: '220px',
        textAlign: 'center',
        ...theme.typography.body,
        fontSize: '14px',
        color: '#ffffff',
        lineHeight: '1.07'
    },
    box: {
        flex: 1,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    container: {
        padding: '0px 0px',
        overflowY: 'auto',
    },
    area: {
        padding: '20px',
    },
    row: {
        width: '100%',
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    vertical: {
        width: '1px', 
        minHeight: '48px', 
        backgroundColor: '#444444'
    },
    columnLeft:{
        flex: 1,
        padding: '0px 10px',
        minHeight: '48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#999999',
            textAlign: 'right'
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '14px',
            textAlign: 'right',
        }
    },
    columnRight:{
        flex: 1,
        padding: '0px 10px',
        minHeight: '48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#999999',
            textAlign: 'left'
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '14px',
            textAlign: 'left'
        }
    },
    cell: {
        flex: '1',
        display: 'flex',
        margin: '5px',
        height: '48px',
        borderRadius: '48px',
        border: 'solid 1px #eeeeee'
    },
    item1: {
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }, 
    item2: {
        flex: '1.3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        minHeight: '126px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px 20px',
        boxShadow: '0px -2px 2px rgba(0, 0, 0, 0.07)',
        backgroundColor: theme.palette.fafafa,
    },
    middle_column:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('limit')]: {
            width: '600px',
            margin: 'auto',
        },
    },
    icon: {
        width: '32px',
        height: '32px',
        borderRadius: '32px',
        backgroundColor: '#000000',
        '& span': {
            fontSize: '32px',
            fontFamily: 'Cycloid-Font',
            margin: '0 143px 0 0',
            color: '#ffffff'
        }
    },
    icon_invert: {
        width: '32px',
        height: '32px',
        borderRadius: '32px',
        '& span': {
            fontSize: '32px',
            fontFamily: 'Cycloid-Font',
            margin: '0 143px 0 0',
        }
    },
    button: {
        width: '100%',
        margin: '5px'
    },
    title: {
        ...theme.typography.titleBold,
        fontSize: '16px',
        textAlign:  'center'
    },
    text: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    label: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    body: {
        ...theme.typography.body,
        fontSize: '16px',
        lineHeight: 'normal'
    },
    title_footer: {
        ...theme.typography.titleBold,
        fontSize: '16px',
        textAlign:  'center'
    },
    text_footer: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    label_footer: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    actionsBoxDropdownContainer: {
        flex: 1,
        height: '32px',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 3px 6px 0 rgb(0 0 0 / 16%)',
    },
    actionsBoxDropdownContainerInner: {
        flex: 1,
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px'
        }
    },
    actionsBoxDropdown: {
        cursor: 'pointer',
        flex: 1,
        height: '32px',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
            borderLeft: `1px solid ${theme.palette.verylightgray}`,
            borderRight: `1px solid ${theme.palette.verylightgray}`,
        },
        '& h6': {
            fontSize: 13
        },
        '& span': {
            fontSize: 24,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font'
        },
    },
    icon_nav: {
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#000000',
        marginRight: '6px'
    },
    filterBoxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px 10px 6px',
        '& h6': {
            fontFamily: 'Open Sans',
            fontSize: '10px',
            textAlign: 'center',
            color: '#999999',
            marginTop: '6px'
        },
        [theme.breakpoints.up('sm')]: {
            '& h6': {
                display: 'none'
            }
        },
    },
    filterBoxContainerInner: {
        width: '100%',
        height: '32px',
        padding: '3px',
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px'
        },
    },
    filterItem: {
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            fontFamily: 'Open Sans',
            fontSize: '10px',
            fontWeight: 'bold'
        }
    },
    filterItemActive: {
        backgroundColor: 'white'
    },
    boxScrolled: {
        height: 'calc(100vh - 230px)',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    scheduleCardsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 1,
        flexDirection: 'column'
    },
    scheduleSectionContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    scheduleSectionContainerCardsBox: {
        display: 'flex',
        justifyContent: 'center'
    },
    scheduleSectionContainerCardsBoxInner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start'
        },
    },
    line_header: {
        flex: 1,
        height: '1px',
        backgroundColor: '#000'
    },
    label_header: {
        ...theme.typography.titleBold,
        fontSize: '13px',
        color: '#000',
        textAlign: 'center',
        padding: '15px'
    },
    noResults: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        marginTop: '60px',
        textAlign: 'center'
    },
    circularProgress: {
        color: '#DCDCDC'
    },
    loading: {
        width: '100%',
        zIndex: '5',
        backgroundColor: 'rgba(250,250,250,0.9)',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    }
})

export default Styles;