import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Slider, { slickGoTo } from "react-slick";
import clsx from 'clsx';

import Styles from './style';

const useStyles = makeStyles(Styles);

const MAX_DOTS = 5;




const Carrousel = (props) => {

    const classes = useStyles();
    const sliderRef = useRef();
    const [current, setCurrent] = useState({ dotIndex: 0, itemIndex: 0, prevItemIndex: 0 });
    const [dots, setDots] = useState([]);
    const [preview, setPreview] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);

    useEffect(() => {
        renderDots(current);
    }, [current])

    const onScrollPositionChange = (index) => {
        let total = props.src.length;
        // +1
        if (index > current.prevItemIndex) {
            if (current.dotIndex === MAX_DOTS - 2 && index + 1 < total - 1) {
                setCurrent({ ...current, itemIndex: index, prevItemIndex: current.itemIndex });
            } else {
                setCurrent({ dotIndex: current.dotIndex + 1, itemIndex: index, prevItemIndex: current.itemIndex });
            }
        }
        // -1
        if (index < current.prevItemIndex) {
            if (current.dotIndex === 1 && index - 1 > 0) {
                setCurrent({ ...current, itemIndex: index, prevItemIndex: current.itemIndex });
            } else {
                setCurrent({ dotIndex: current.dotIndex - 1, itemIndex: index, prevItemIndex: current.itemIndex });
            }
        }
    }

    const onOpenFullscreenHandler = () => {
        setFullscreen(true)
    };

    const onCloseFullscreenHandler = () => {
        setFullscreen(false)
    };

    const onRailStatusHandler = () => {
        setPreview(!preview);
    };

    const onGoToIndex = (index) => {
        sliderRef.current.slickGoTo(index);
    }

    const renderDots = (index) => {
        let indicators = []

        const totalItem = props.src?.length;
        const lessThan5 = totalItem <= MAX_DOTS;
        const totalCount = lessThan5 ? totalItem : MAX_DOTS

        for (let i = 0; i < totalCount; i++) {
            indicators.push(
                <div key={i.toString()}
                    className={clsx(
                        classes.dot,
                        (i === index.dotIndex) ? classes.active : null,
                        (i === 0 && (index.itemIndex >= MAX_DOTS - 1)) ? classes.small : null,
                        (i === (MAX_DOTS - 1) && (index.itemIndex + MAX_DOTS - 1 <= totalItem)) ? classes.small : null
                    )
                    }
                ></div >
            )
        }
        setDots(indicators);
    }

    return (
        <div className={classes.root}>
            <Slider ref={sliderRef} afterChange={onScrollPositionChange} infinite={false} className="carrousel">
                {
                    props.src ?
                        props.src.length > 0 ?
                            props.src.map((prop, index) => {
                                return (
                                    <div key={index}>
                                        <div><img src={prop.fileUrl} alt="" /></div>
                                    </div>
                                );
                            })
                            :
                            <div className={classes.placeholder}>
                                <span>.</span>
                            </div>
                        :
                        <div className={classes.placeholder}>
                            <span>.</span>
                        </div>
                }
            </Slider>

            <div className={classes.footer}>
                <span onClick={onRailStatusHandler} style={{ transform: preview ? 'rotate(180deg)' : 'rotate(0deg)' }}>$</span>
                <div className={classes.indicators}>
                    {dots}
                </div>
                <div className={classes.fullscreen_icon}>
                    <div>R</div>
                    <div onClick={onOpenFullscreenHandler}>R</div>
                </div>
            </div>

            <div className={classes.preview} style={{ transform: preview ? 'translateY(-92px)' : 'translateY(0px)' }}>
                <div className={classes.track}>
                    {
                        props.src ?
                            props.src.length > 0 ?
                                props.src.map((prop, index) => {
                                    return (
                                        <Thumbnail key={index} index={index} onClick={onGoToIndex} src={prop.fileUrl} style={{ opacity: index === current.itemIndex ? '0.8' : '0' }} />
                                    );
                                })
                                :
                                <Thumbnail key={0} index={0} onClick={onGoToIndex} src={null} style={{ opacity: 0 === current.itemIndex ? '0.8' : '0' }} />
                            :
                            <Thumbnail key={0} index={0} onClick={onGoToIndex} src={null} style={{ opacity: 0 === current.itemIndex ? '0.8' : '0' }} />
                    }
                </div>
            </div>

            <div className={classes.fullscreen} style={{ display: fullscreen ? 'flex' : 'none' }}>
                <div className={classes.fullscreen_header}>
                    <span onClick={onCloseFullscreenHandler}>F</span>
                </div>
                <div className={classes.fullscreen_canvas}>
                    {
                        props.src ?
                            props.src.length > 0 ?
                                <img src={props.src[current.itemIndex]?.fileUrl} alt="" />
                                :
                                null
                            :
                            null
                    }
                </div>
            </div>

        </div>
    );
}

export default Carrousel;


const Thumbnail = (props) => {

    const classes = useStyles();

    const onClickHandler = () => {
        props.onClick(props.index)
    }

    return (
        <div className={classes.thumbnail} onClick={onClickHandler} >
            <div className={classes.select_overlay} style={props.style}></div>
            <div className={classes.preview_overlay}><span>.</span></div>
            <img src={props.src ? props.src : null} alt="" />
        </div>
    )
}