
const Styles = theme => ({
    wrap: {
        flex: 1,
    },
    root: {
        width: '100%',
        minHeight: 'calc(100vh - 128px)',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.fafafa
    },
    header_box: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000',
    },
    header: {
        maxHeight: '79px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '6px 20px',
        backgroundColor: '#000000',
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff',
    },
    headerInvert: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '6px 20px',
        backgroundColor: '#444444',
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff;',
    },
    box: {
        width: '100%',
        position: 'relative',
    },
    container: {
        padding: '15px 20px',
    },
    row: {
        flex: 1,
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    row_center: {
        justifyContent: 'center',
        '& :nth-child(2)': {
            marginLeft: '30px'
        }
    },
    cell: {
        flex: 1,
        padding: '5px 0px',
        '& :nth-child(1)': {
            margin: 0,
            padding: 0,
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            margin: 0,
            padding: 0,
            ...theme.typography.title,
            fontSize: '14px',
        },
        '& :nth-child(3)': {
            margin: 0,
            padding: 0,
            ...theme.typography.body
        },
    },
    cellInvert: {
        flex: 1,
        padding: '5px 0px',
        '& :nth-child(1)': {
            margin: 0,
            padding: 0,
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            margin: 0,
            padding: 0,
            ...theme.typography.title,
            fontSize: '14px',
            color: '#ffffff'
        },
        '& :nth-child(3)': {
            margin: 0,
            padding: 0,
            ...theme.typography.body,
            color: '#ffffff'
        },
    },
    cell_2: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& :nth-child(1)': {
            ...theme.typography.label,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '24px',
        }
    },
    cell_2Invert: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& :nth-child(1)': {
            ...theme.typography.label,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '24px',
            color: '#ffffff'
        }
    },
    cell_3Invert: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& :nth-child(1)': {
            ...theme.typography.label,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '18px',
            color: '#ffffff'
        }
    },
    cell_4Invert: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& :nth-child(1)': {
            ...theme.typography.label,
            fontSize: '11px',
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '24px',
            color: '#ffffff'
        }
    },
    field: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '&:nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
        },
        '&:nth-child(2)': {
            ...theme.typography.title,
            fontSize: '24px',
        }
    },
    fieldInvert: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#ffffff'
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '24px',
            color: '#ffffff'
        }
    },
    fieldInvert2: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#ffffff'
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '16px',
            color: '#ffffff',
            marginTop: '6px'
        }
    },
    fieldInvert3: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#ffffff'
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '16px',
            color: '#ffffff',
            marginTop: '18px'
        }
    },
    columnLeft: {
        flex: 1,
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
    },
    columnRight: {
        flex: 1,
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
    },
    collapse: {
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& #equipments-row': {
            width: '100%',
            ...theme.typography.body
        },
    },

    collapseInvert: {
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& #equipments-row': {
            width: '100%',
            ...theme.typography.body,
            color: '#ffffff'
        },
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('limit')]: {
            width: '600px',
            margin: '0px auto',
        },
    },





    button: {
        width: '100%',
        margin: '10px 5px'
    },

    buttonSelect: {
        margin: '15px 0px 5px 0px',
        '& button': {
            backgroundColor: '#eeeeee',
            color: '#000000'
        }
    },

    inputfield: {
        width: '100%',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'left',
        }
    },

    time_row: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    time_cell: {
        width: '95px',
        padding: '0px 16px 0px 0px',
        // backgroundColor: 'gray',s
    },
    date_cell: {
        width: '145px',
        padding: '0px 0px 0px 16px',
        // backgroundColor: 'brown',
    },
    text_cell: {
        ...theme.typography.body,
        fontSize: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center'
    },

    inputfield_time: {
        width: '48px',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'left',
        }
    },

    inputfield_date: {
        width: '122px',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'left',
        }
    },

    inputlabel: {
        width: '100%',
        height: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 0px',
        '& span': {
            ...theme.typography.label,
            fontSize: '11px',
            color: '#000000'
        },
    },
    inputarea: {
        height: '100% !important',
    },
    line: {
        flex: 1,
        margin: '10px 0px',
        height: '1px',
        backgroundColor: '#000000'
    },
    lineInvert: {
        flex: 1,
        margin: '10px 0px',
        height: '1px',
        backgroundColor: '#444444'
    },
    vertical: {
        width: '1px',
        height: '32px',
        margin: '0px 8px',
        backgroundColor: '#cecece'
    },

    verticalInvert: {
        width: '1px',
        height: '32px',
        margin: '0px 8px',
        backgroundColor: '#444444'
    },

    title: {
        ...theme.typography.titleBold,
        fontSize: '13px',
    },
    subtitle: {
        ...theme.typography.title,
        fontSize: '14px',
    },
    subtitleInvert: {
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff'
    },
    label: {
        ...theme.typography.title,
        fontSize: '10px',
        color: '#999999',
    },
    body: {
        ...theme.typography.body,
        fontSize: '13px',
        lineHeight: '1.15'
    },
    bodyInvert: {
        ...theme.typography.body,
        fontSize: '13px',
        lineHeight: '1.15',
        color: '#ffffff'
    },
    regular: {
        ...theme.typography.body,
        fontSize: '16px',
        lineHeight: '1.15',
        color: '#ffffff'
    },
    anchor: {
        ...theme.typography.label,
        margin: '10px 0px',
        cursor: 'pointer'
    },
    anchorInvert: {
        ...theme.typography.label,
        margin: '10px 0px',
        cursor: 'pointer',
        color: '#ffffff'
    },
    message: {
        ...theme.typography.body,
        color: '#999999'
    },
    icon: {
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#000000',
        marginRight: '6px',
        cursor: 'pointer'
    },


    model: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.titleBold,
            fontSize: '18px',
            lineHeight: '1'
        },
        '& :nth-child(3)': {
            ...theme.typography.title,
            fontSize: '14px',
        },
    },
    modelInvert: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.titleBold,
            fontSize: '18px',
            lineHeight: '1',
            color: '#ffffff'
        },
        '& :nth-child(3)': {
            ...theme.typography.title,
            fontSize: '14px',
            color: '#ffffff'
        },
    },
    license: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
        },
    },
    licenseInvert: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            color: '#ffffff'
        },
    },
    cliente: {
        flex: 1,
        display: 'flex',
        margin: '5px 0px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clienteInvert: {
        flex: 1,
        display: 'flex',
        margin: '5px 0px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.body,
            fontSize: '16px',
            color: '#ffffff'
        },
    },
    price: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
        },
        '& :nth-child(3)': {
            ...theme.typography.body,
            fontSize: '13px',
            color: '#999999'
        },
    },
    priceInvert: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& :nth-child(1)': {
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            color: '#ffffff'
        },
        '& :nth-child(3)': {
            ...theme.typography.body,
            fontSize: '13px',
            color: '#999999'
        },
    },
    equipments: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px'
    },
    circularProgress: {
        color: '#DCDCDC'
    },



    cardTitle: {
        ...theme.typography.titleBold,
        fontSize: '18px',
        color: '#ffffff'
    },
    cardSubtitle: {
        ...theme.typography.title,
        fontSize: '16px',
        color: '#ffffff'
    },
    cardBody: {
        ...theme.typography.label,
        fontSize: '16px',
        color: '#cecece',
        fontWeight: 'normal'
    },
    cardBodySmall: {
        ...theme.typography.label,
        fontSize: '14px',
        color: '#cecece',
        fontWeight: 'normal'
    },
})

export default Styles;