import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Button';
import * as Utils from '../../../../../service/utils';

import Styles from './style';

const useStyles = makeStyles(Styles);

const PopupScheduleSubview = ({
    data,
    actions
}) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const onItemSelectedHandler = (values) => {
        actions.onSelect(values);
    }

    return (
        <div className={classes.root}>
            {
                data.map((prop, index) => {
                    return (
                        <>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                                <div className={classes.line_header}></div>
                                <div className={classes.label_header}>{prop.label.toUpperCase().replace("DE", "")}</div>
                                <div className={classes.line_header}></div>
                            </div>
                            <div className={classes.box}>
                                {
                                    prop.data.map((prop, index) => {
                                        return (
                                            <div key={index} className={classes.container}>
                                                <div style={{ flex: 1, height: '48px', backgroundColor: 'rgb(51,51,51)', borderRadius: '0px 16px 0px 0px', display: 'flex' }}>
                                                    <div style={{ width: '48px', height: '48px', backgroundColor: 'rgb(65,65,65)', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid rgb(51,51,51)' }}><span className={classes.header_bold}>{Utils.getDateTimeValues(`${prop?.registryDtSchedule} ${prop?.registryTimeSchedule}`).day}</span></div>
                                                    <div style={{ width: '70px', height: '48px', backgroundColor: 'rgb(65,65,65)', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid rgb(51,51,51)' }}><span className={classes.header_light}>{Utils.getDateTimeValues(`${prop?.registryDtSchedule} ${prop?.registryTimeSchedule}`).week.substring(0, 3).toUpperCase()}</span></div>
                                                    <div style={{ flex: 1, height: '48px', backgroundColor: 'rgb(65,65,65)', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid rgb(51,51,51)' }}><span className={classes.header_bold}>{Utils.getDateTimeValues(`${prop?.registryDtSchedule} ${prop?.registryTimeSchedule}`).time}</span></div>
                                                </div>
                                                <div className={classes.card}>
                                                    {
                                                        prop?.registryFirstName || prop?.registryLastName ? <div className={classes.card_title}>{`${prop?.registryFirstName} ${prop?.registryLastName}`}</div> : null
                                                    }
                                                    <div className={classes.line}></div>
                                                    {
                                                        prop?.registryPhone ? <div className={classes.card_body}>{`${prop?.registryPhone}`}</div> : <div className={classes.card_body}>-</div>
                                                    }
                                                    <div className={classes.row}>
                                                        <Button styles={classes.button} onClick={() => onItemSelectedHandler(prop)}>{t('popup-overlay-subview-search-detail-search-client-btn-label')}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    );
                })
            }
        </div >
    );
}

export default PopupScheduleSubview;



