import ClienteSubview from './Views/Cliente';
import VehicleSubview from './Views/Vehicle';
import FinanceSubview from './Views/Finance';
import NotesSubview from './Views/Notes';

export const Structer = {
    title: 'Nome e Apelido',
    sections: [
        {
            id: 'detail',
            index: 0,
            label: 'proposal-detail-subview-tab1-label',
            bold: false, 
            path: '/archive/detail',
            component: <ClienteSubview />
        },
        {
            id: 'detail',
            index: 1,
            label: 'proposal-detail-subview-tab2-label',
            bold: false, 
            path: '/archive/detail',
            component: <VehicleSubview />
        },
        {
            id: 'detail',
            index: 2,
            label: 'proposal-detail-subview-tab3-label',
            bold: false, 
            path: '/archive/detail',
            component: <FinanceSubview />
        },
        {
            id: 'detail',
            index: 3,
            label: 'proposal-detail-subview-tab4-label',
            bold: false, 
            path: '/archive/detail',
            component: <NotesSubview />
        },
    ],
}