import React from 'react';
import Button from '../../../../../components/Button';
import './style.css';

const ScheduleConfirmView = ({ data, onClick }) => {

    const onClickHandler = () => {
        onClick()
    }

    return (
        <div className="schedule-confirm-root">
            <div className="row">
                <div className="label-bold">
                    <span>{`${data.day.monthLabel} ${data.day.year}`}</span>
                </div>
            </div>
            <div className="row">
                <div className="icon">
                    <span>-</span>
                </div>
                <div className="label-bold">
                    <span>{data.day.day}</span>
                </div>
                <div className="label">
                    <span>{data.day.weekday}</span>
                </div>
            </div>
            <div className="row">
                <div className="icon">
                    <span>G</span>
                </div>
                <div className="label-bold">
                    <span>{data.time.format}</span>
                </div>
            </div>
            <div className="row" style={{ position: 'absolute', bottom: '-25px', left: 0, right: 0, zIndex: 1 }}>
                <Button onClick={onClickHandler}>Agendar</Button>
            </div>
        </div>
    );
}

export default ScheduleConfirmView;