const Styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        margin: '5px 0px 5px 0px',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            width: '360px',
            margin: '10px',
        },
    },
    area1: {
        width: '100%',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0px 16px 0px 0px',
        margin: '0px 0px 1px 0px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)',
        backgroundColor: '#ffffff',

        ...theme.typography.titleBold,
        fontSize: '18px',
        color: '#999999',
        textAlign: 'center'
    },
    area2: {
        width: '100%',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 0px 1px 0px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)',
        backgroundColor: '#ffffff'
    },
    area3: {
        width: '100%',
        height: '90px',
        display: 'flex',
        // alignItems: 'center',
        margin: '0px 0px 1px 0px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)',
        backgroundColor: '#ffffff'
    },
    area4: {
        width: '100%',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0px 0px 16px 16px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)',
        backgroundColor: '#ffffff'
    },
    row: {
        flex: '1',
        display: "flex",
    },
    cell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1px 0px'
    },
    column: {
        flex: '1',
        padding: '7px 10px',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center'
    },
    title: {
        ...theme.typography.titleBold,
        fontSize: '18px',
        lineHeight: '1',
    },
    subtitle: {
        ...theme.typography.title,
        fontSize: '16px',
        lineHeight: '1.13',
        textTransform: 'uppercase'
    },
    description: {
        ...theme.typography.body,
        fontSize: '14px',
        lineHeight: '1.14',
        color: '#999999'
    },
    text: {
        ...theme.typography.body,
        fontSize: '14px',
        lineHeight: 'normal',
    },
    label: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    icon_drk: {
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#000000',
    },
    icon_lgt: {
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#ffffff',
    },
    image: {
        width: '120px',
        height: '90px'
    },
    placeholder: {
        width: '120px',
        height: '90px',
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        '& span': {
            fontSize: '35px',
            fontFamily: 'Cycloid-Font',
            color: '#000000',
            opacity: '0.3'
        }
    },
})

export default Styles;