import React from 'react';
import Styles from './style';
import { makeStyles, Grid, CircularProgress  } from '@material-ui/core';


const useStyles = makeStyles(Styles);


const Loading = () => {

    const classes = useStyles();

    return (
        <Grid
            className={classes.root}
            container
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center', flexGrow: 1, height: '100%'}}>
            <Grid item>
                <CircularProgress className={classes.circularProgress}/>
            </Grid>
        </Grid>
    )
}

export default Loading;