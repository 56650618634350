import React, { useEffect, useState, useContext } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, CircularProgress } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useHistory, useParams, withRouter } from 'react-router-dom';

import { ServiceContext } from '../../context/service/service-context';

import * as Utils from '../../service/utils';
import { useAppContext } from '../../context/app/app-context';
import { AuthContext } from '../../context/auth/auth-context';

import PopupAlert from '../../components/PopupAlert';


import { Structer } from './config';
import Styles from './style';

const useStyles = makeStyles(Styles);


const StyledTabs = withStyles({
    root: {
        minHeight: '32px',
    },
    indicator: {
        display: 'none',
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        flex: 1,
        minHeight: '32px',
        maxWidth: '100%',
        margin: '0px 2px 0px 0px',
        padding: '3px 3px 3px 3px',
        borderRadius: '6px 6px 0px 0px',
        backgroundColor: '#ffffff',
        color: '#000000',
        opacity: 1,

        ...theme.typography.body,
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.15,
        letterSpacing: 'normal',
        textAlign: 'center',
    },
    selected: {
        backgroundColor: '#000000',
        color: '#ffffff',
    }
}))((props) => <Tab disableRipple {...props} />);

const navBarStatus = {
    state: { index: 0, isRequest: false },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};
export const useNavBar = () => {

    const [state, set] = useState(navBarStatus.state);
    if (!navBarStatus.setters.includes(set)) {
        navBarStatus.setters.push(set);
    }

    useEffect(() => () => {
        navBarStatus.setters = navBarStatus.setters.filter(setter => setter !== set)
    }, []);

    function goToTab(index) {
        navBarStatus.setState({ ...state, index: index });
    }

    function setRequestStatus(value) {
        navBarStatus.setState({ ...state, isRequest: value });
    }

    return { state, goToTab, setRequestStatus };
}

navBarStatus.setState = navBarStatus.setState.bind(navBarStatus);


const SearchDetailView = () => {

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { carSelected, setCarSelected, setTradeCar, setFinanceInfo, setDataPIInsert, setPopupAlert} = useAppContext()
    const { cognitoUser } = useContext(AuthContext);

    const { Usados } = useContext(ServiceContext);
    const { viewId } = useParams();
    const { state, goToTab } = useNavBar();
    const [structer, setStructer] = useState({ index: 0, component: null });
    const [reservation, setResevation] = useState(null);
    const [openPopUpConfirm, setOpenPopUpConfirm] = useState(false);


    useEffect(() => {
        const _carSeleted = history.location.state
        setCarSelected(_carSeleted)
        onLoadPage();
        vehicleReservationStatus(_carSeleted);
        return () => {
            goToTab(0);
        }
    }, []);

    useEffect(() => {
        handleChange(null, state.index);
    }, [state.index]);

    const vehicleReservationStatus = async (_carSeleted) => {
        const params = {
            plateNumber: _carSeleted.plateNumber,
            vin: _carSeleted.vin
        }
        const result = await Usados.GetVehicleReservationStatus(params);
        setResevation(result?.searchStatus?.vehicleReservation);
    }

    const onLoadPage = () => {
        const viewIndex = Structer.sections.find(item => item.id == viewId);
        setStructer({ index: viewIndex ? viewIndex.index : 0, component: viewIndex ? viewIndex.component : Structer.sections[0].component });
    }

    const handleChange = (event, newValue) => {
        const viewIndex = Structer.sections.find(item => item.index == newValue);
        setStructer({ index: viewIndex.index, component: viewIndex.component });
        goToTab(viewIndex.index);
    };

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const headerBackHandler = () => {
        setCarSelected([]);
        setTradeCar(null);
        setDataPIInsert(null);
        setFinanceInfo({ isSim: false, data: {} });
        goToTab(0);
        history.goBack();
    }


    const handleRemoveReservation = async () => {
        const popupAlerData = {
            description: t('cancel-reservation-confirm')
        }
        setPopupAlert(popupAlerData)
        setOpenPopUpConfirm(true)
    }

    const onConfirmCancelReservation = async () => {
        setOpenPopUpConfirm(false)
        try {
            const params = {
                idPI: reservation?.extIdInOrigin,
                login: cognitoUser?.login,
                plateNumber: carSelected?.plateNumber,
                vin: carSelected?.vin
            }
            await Usados.CancelReserveVehicle(params);
            vehicleReservationStatus(carSelected);

        } catch (error) {

        }
    }

    return (
        <div className={classes.root}>

            <div className={classes.loading} style={{ display: state.isRequest ? 'flex' : 'none' }}>
                <CircularProgress className={classes.circularProgress} />
            </div>

            <div className={classes.header_box}>
                <div className={classes.header}>
                    <span onClick={headerBackHandler} className={classes.back_button}>n</span>
                    <div>{carSelected.model ? carSelected.model : '-'}</div>
                    <span></span>
                </div>
            </div>

            <div className={classes.nav}>
                <div className={classes.nav_child1}></div>
                <div className={classes.nav_child2}>
                    {
                        Structer.sections.length > 0 ?
                            <StyledTabs
                                value={structer.index}
                                onChange={handleChange}>
                                <StyledTab key={0} label={t('search-detail-nav-detail-tab')} {...a11yProps(0)} />
                                <StyledTab key={1} label={t('search-detail-nav-exchange-tab')} {...a11yProps(1)} />
                                <StyledTab key={2} label={t('search-detail-nav-finance-tab')} {...a11yProps(2)} />
                                {
                                    reservation ?
                                        <div key={3} className={classes.custom_tab_reserved} {...a11yProps(3)}   onClick={() => handleRemoveReservation()} >
                                            <span className={classes.custom_tab_cell1}>h</span>
                                            <div className={classes.custom_tab_cell2}>
                                                <div className={classes.middle_column}>
                                                    <span className={classes.custom_tab_text}>{`${t('footer-proposal-text-label2')} ${reservation?.reservationEndDate ? Utils.formatStringDateTime(reservation?.reservationEndDate).time : '-'}`}</span>
                                                     <span className={classes.custom_tab_label}><strong>{reservation?.reservationEndDate ? Utils.formatStringDateTime(reservation?.reservationEndDate).date.replace('de', '').toUpperCase() : '-'}</strong></span>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div key={3}
                                            className={classes.custom_tab} {...a11yProps(3)}
                                            style={{ backgroundColor: structer.index == 3 ? '#000000' : '#ffffff' }}
                                            onClick={() => handleChange(null, 3)}>
                                            <span className={classes.custom_tab_title} style={{ color: structer.index == 3 ? '#ffffff' : '#000000' }}>
                                                <strong>{t('search-detail-nav-proposal-tab')}</strong>
                                            </span>
                                        </div>
                                }
                            </StyledTabs>
                            :
                            null
                    }
                </div>
                <div className={classes.nav_child3}></div>
            </div>
            <div className={classes.subview}>
                {
                    structer.component
                }
            </div>
            {openPopUpConfirm && <PopupAlert
                open={openPopUpConfirm}
                type="ALERT_CONFIRM"
                onCancel={() => setOpenPopUpConfirm(false)}
                onClose={() => setOpenPopUpConfirm(false)}
                onConfirm={() => onConfirmCancelReservation()}
            />}
        </div>
    );
}

export default withRouter(SearchDetailView);