import React, { createContext } from 'react';
import { UsadosService } from './services/usados/usados-service';

export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

    const Usados = UsadosService();

    return (
        <ServiceContext.Provider value={{ Usados }}>
            { props.children}
        </ServiceContext.Provider>
    )
}

export default ServiceContextProvider;