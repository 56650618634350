const Styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.fafafa,
    },
    header_box: {
        height: '80px !important',
        display: 'flex',
        padding: '0px 20px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000',

    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '6px 0px',
        backgroundColor: '#000000',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.16)',
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff',
    },
    box: {
        width: '100%',
        flex: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        alignItems: 'center',
        overflowY: 'auto',
    },
    container: {
        flex: 1, 
        maxWidth: '1146px', 
        display: 'flex', 
        flexWrap: 'wrap', 
        justifyContent: 'center', 
        alignItems: 'center',
        padding: '10px 10px 30px 10px'
    },

    // limit_area: {
    //     width: '100%',
    //     display: 'flex',
    //     justifyContent: 'space-evenly',
    //     flexWrap: 'wrap',
    //     [theme.breakpoints.between('sm', 'lg')]: {
    //         maxWidth: '752px',
    //     },
    //     [theme.breakpoints.up('lg')]: {
    //         maxWidth: '1142px',
    //     },
    // },

    limit_area: {
        width: '100%',
        [theme.breakpoints.up('limit')]: {
            width: '600px',
            margin: 'auto',
        },
    },

    inputfield: {
        width: '100%',
        margin: '10px 0px',
        '& input': {
            margin: '10px',
            textAlign: 'left',
        }
    },
    icon: {
        fontFamily: 'Cycloid-Font',
        fontSize: '32px',
        color: '#000000',
        marginRight: '6px'
    },
    circularProgress: {
        color: '#DCDCDC'
    },
    actionsBoxRight: {
        flex: 1,
        height: '32px',
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h6': {
            fontSize: 13
        },
        '& span': {
            fontSize: 24,
            lineHeight: 1,
            fontFamily: 'Cycloid-Font'
        }
    },
    nav_actions: {
        flex:1,
        height: '100%',
        display: 'flex',
        [theme.breakpoints.up('limit')]: {
            minWidth: '600px !important',
        },
    },
    nav: {
        width: '100%',
        height: '32px',
        display: 'flex',
        paddingTop: '2px',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.07)',
        position: 'relative',
        zIndex: '1'
    },
    nav_child1: {
        height: '100%',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            flex:1,
            margin: '0px 2px 0px 0px'
        },
    },
    nav_child2: {
        flex:1,
        width: '300px',
        height: '100%',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            minWidth: '300px !important',
        },
    },
    nav_child3: {
        height: '100%',
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('limit')]: {
            flex:1,
            margin: '0px 0px 0px 2px'
        },
    },
})

export default Styles;