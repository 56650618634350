const Styles = theme => ({
    root: {
        flex: 1,
        // height: '272px',
        position: 'relative',
        overflow: 'hidden'
    },
    indicators: {
        flex: 1,
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    preview: {
        width: '100%',
        height: '60px',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        zIndex: 5,
        transition: 'all 0.2s linear',
        backgroundColor: '#000000',
        overflow: 'scroll'
    },
    track: {
        padding: '0px 2px',
        display: 'flex',
        alignItems: 'center'
    },
    thumbnail: {
        width: '69px',
        height: '52px',
        margin: '0px 2px',
        position: 'relative',
        backgroundColor: '#fafafa',
        '& img': {
            height: '100%',
            position: 'relative',
        }
    },
    placeholder: {
        flex: 1,
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        '& span': {
            fontSize: '48px',
            fontFamily: 'Cycloid-Font',
            color: '#000000',
            opacity: '0.3'
        }
    },
    preview_overlay: {
        width: '69px',
        height: '52px',
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        position: 'absolute',
        zIndex: 100,
        backgroundColor: "rgba(0,0,0,0.1)",
        '& span': {
            fontSize: '40px',
            fontFamily: 'Cycloid-Font',
            color: '#000000',
            opacity: '0.3'
        }
    },
    select_overlay: {
        width: '69px',
        height: '52px',
        position: 'absolute',
        zIndex: 200,
        backgroundColor: "#ffffff"
    },
    fullscreen_icon: {
        width: '32px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
        '& :nth-child(1)': {
            fontSize: '24px',
            fontFamily: 'Cycloid-Font',
            color: '#ffffff',
        },
        '& :nth-child(2)': {
            fontSize: '24px',
            fontFamily: 'Cycloid-Font',
            color: '#ffffff',
            position: 'absolute',
            transform: 'rotateX(180deg)'
        },
    },
    footer: {
        flex: 1,
        height: '32px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 10px',
        zIndex: 10,
        backgroundColor: '#000000',
        '& span': {
            fontSize: '24px',
            fontFamily: 'Cycloid-Font',
            color: '#ffffff',
            cursor: 'pointer'
        }
    },
    dot: {
        width: "6px",
        height: "6px",
        borderRadius: "6px",
        margin: "auto 5px",
        backgroundColor: "#ffffff"
    },
    active: {
        backgroundColor: "#ff0022"
    },
    last: {
        width: "6px !important",
        height: "6px !important",
        // backgroundColor: "#ff0022"
    },
    small: {
        width: "4px",
        height: "4px",
    },
    fullscreen: {
        width: '100%',
        height: '100%',
        top: 0,
        bottomm: 0,
        right: 0,
        left: 0,
        position: 'fixed',
        flexDirection: 'column',
        backgroundColor: '#000000',
        zIndex: 999999,
    },
    fullscreen_header: {
        width: '100%',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& :nth-child(1)': {
            width: '48px',
            height: '48px',
            fontSize: '48px',
            fontFamily: 'Cycloid-Font',
            color: '#ffffff',
        },
    },
    fullscreen_canvas: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            [theme.breakpoints.up('limit')]: {
                width: '600px',
            },
        },
    }
})

export default Styles;