
const Styles = theme => ({
    root: {
        width: '100%',
        height: 48,
        backgroundColor: theme.palette.white,
        borderRadius: 8,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)',
        margin: '5px 0',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer'

    },
    inner: {
        backgroundColor: theme.palette.white,
        padding: '6px 10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 8
    },
    valueBox: {
        display: 'flex',
        flexDirection: 'row'
    },
    left: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },
    total: {
        marginRight: 6
    },
    text: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    icon: {
        fontSize: 24,
        lineHeight: 1.21,
        fontFamily: 'Cycloid-Font'
    },
    box: {
        paddingLeft: 10,
        backgroundColor: theme.palette.lightgray,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderTopRightRadius: 8
    },
    removeBorder: {
        borderTopLeftRadius: 0
    },
    darkgray: {
        color: theme.palette.darkgray
    }
})

export default Styles;