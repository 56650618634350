import React from 'react';
import Styles from './style';
import { useTranslation } from 'react-i18next';
import { makeStyles, ClickAwayListener, Popper, Paper, Grow, Typography  } from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles(Styles);

const AttendancePopup = ({
    event,
    anchorEl,
    onSetEventStatus,
    onClose
}) => {

    const classes = useStyles();

    const { t } = useTranslation();

    const open = Boolean(anchorEl);

    const onClickItemHandler = (it) => {
        onSetEventStatus(it)
    }




    if(!open) return null
 
    return (
        <div className={classes.root}>
            <Popper
                anchorEl={anchorEl}
                open={open}
                className={classes.pooper}
                placement="bottom-end"
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}>
                        <Paper elevation={0}>
                            <ClickAwayListener onClickAway={() => onClose()}>
                                <div className={classes.container}>
                                    <div className={classes.boxHeader}>
                                        <Typography variant="h2">{t('schedule-popup-attendance-title')}</Typography>
                                    </div>
                                    <div className={classes.boxItems}>
                                        <div className={clsx({
                                            [classes.item]: true,
                                            [classes.itemActiveAbsent]: !event?.registryDtCheckIn
                                        })} onClick={() => onClickItemHandler(0)}>
                                            <p>Ausente</p>
                                            <span></span>
                                        </div>
                                        <div className={clsx({
                                            [classes.item]: true,
                                            [classes.itemActivePresent]: event?.registryDtCheckIn
                                        })} onClick={() => onClickItemHandler(1)}>
                                            <p>Presente</p>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div className={classes.arrowUp} />
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
       
    );
}

export default AttendancePopup;

