import DetailSubview from './Views/DetailSubview';
import FinanciaSubview from './Views/FinanciaSubview';
import PedidoSubview from './Views/PedidoSubview';
import RetomaSubview from './Views/RetomaSubview';

export const Structer = {
    title: 'quiz-page-title',
    sections: [
        {
            id: 'search',
            index: 0,
            label: 'search-detail-nav-detail-tab',
            bold: false, 
            path: '/search/results/detail',
            component: <DetailSubview/>
        },
        {
            id: 'search',
            index: 1,
            label: 'search-detail-nav-exchange-tab',
            bold: false, 
            path: '/search/results/detail',
            component: <RetomaSubview/>
        },
        {
            id: 'search',
            index: 2,
            label: 'search-detail-nav-finance-tab',
            bold: false, 
            path: '/search/results/detail',
            component: <FinanciaSubview/>
        },
        {
            id: 'search',
            index: 3,
            label: 'search-detail-nav-proposal-tab',
            bold: true, 
            path: '/search/results/detail',
            component: <PedidoSubview/>
        },
    ],
}