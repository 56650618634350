const Styles = theme => ({
    root: {
        zIndex: 1010,
        position: 'absolute',
        height: '100%',
        top: 0,
        right: 0,
        left: 0
    },
    circularProgress: {
        color: '#DCDCDC'
    }
})

export default Styles;

