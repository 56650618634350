const Styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.fafafa
    },
    header_box: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000',
    },
    header: {
        flex: '1',
        height: '79px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '6px 10px 6px 10px',
        backgroundColor: '#000000',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.16)',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '14px',
            color: '#ffffff',
        },
        '& :nth-child(2)': {
            '& :nth-child(1)': {
                ...theme.typography.title,
                fontSize: '24px',
                color: '#ffffff',

            },
            '& :nth-child(2)': {
                ...theme.typography.body,
                fontSize: '13px',
                color: '#999999',
            },
        },
    },
    
    info: {
        width: '100%',
        padding: '10px 0px'
    },
    line: {
        flex: 1,
        margin: '10px',
        height: '1px',
        backgroundColor: '#000000'
    },
    license: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 10px',
        '& :nth-child(1)': {
            margin: 0,
            padding: 0,
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            margin: 0,
            padding: 0,
            ...theme.typography.title,
        },
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('limit')]: {
            width: '600px',
            margin: 'auto',
        },
    },
    equipments: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 10px 22px 10px',
        '& #equipments-title': {
            ...theme.typography.title,
            fontSize: '14px',
            margin: '0px 0px 10px 0px'
        },
        '& #equipments-row': {
            width: '100%',
            ...theme.typography.body
        }
    },

    container: {
        flex: 1,
        display: 'flex',
        margin: '0px 10px'
    },
    
    box: {
        flex: 1,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    box_container: {
        padding: '0px 0px 15px 0px',
        overflowY: 'auto',
    },

    columnLeft: {
        flex: 1,
        textAlign: 'left'
    },
    columnRight: {
        flex: 1,
        textAlign: 'right'
    },
    cell: {
        flex: 1,
        padding: '5px 0px',
        '& :nth-child(1)': {
            margin: 0,
            padding: 0,
            ...theme.typography.label
        },
        '& :nth-child(2)': {
            margin: 0,
            padding: 0,
            ...theme.typography.title,
            fontSize: '14px',
        },
        '& :nth-child(3)': {
            margin: 0,
            padding: 0,
            ...theme.typography.body
        },
    },
    message: {
        ...theme.typography.body,
        color: '#999999'
    },
    body: {
        fontSize: '14px',
        ...theme.typography.body,
    },
})

export default Styles;