import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import DayButton from './Components/DayButton';


import * as Utils from '../Resources/Utils';
import './style.css';
import { months } from 'moment';

const calendarStatus = {
    state: {
        current: null,
        original: null
    },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const useCalendar = () => {

    const [calendar, setCalendar] = useState(calendarStatus.state);
    if (!calendarStatus.setters.includes(setCalendar)) {
        calendarStatus.setters.push(setCalendar);
    }

    useEffect(() => () => {
        calendarStatus.setters = calendarStatus.setters.filter(setter => setter !== setCalendar)
    }, []);

    function setCalendarState(current, original) {
        calendarStatus.setState({ current: current, original: original });
    }

    return { calendar, setCalendarState };
}

calendarStatus.setState = calendarStatus.setState.bind(calendarStatus);


const Calendar = forwardRef((props, ref) => {

    const { calendar, setCalendarState } = useCalendar();

    useImperativeHandle(ref, () => ({
        async prevMonth() {
            onPrevMonthHandler();
        },
        async nextMonth() {
            onNextMonthHandler();
        }
    }));


    useEffect(() => {
        init(props.config?.month ? props.config?.month - 1 : null, props.config?.year)
    }, [props.config?.month, props.config?.year, props.config?.dateSlots]);


    const init = (month = null, year = null) => {
        let daysInMonths = getDaysInMonth(month, year);
        let formatData = formatOject(daysInMonths?.days, props.config?.dateSlots);
        setCalendarState(formatData, daysInMonths);
    }

    const getDaysInMonth = (month = null, year = null) => {
        if (year == null) {
            year = new Date().getFullYear();
        }
        if (month == null) {
            month = new Date().getMonth();
        }
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return {
            year: year,
            month: month,
            monthLabel: new Date(year, parseInt(month), 1).toLocaleDateString('pt-PT', { month: 'long' }),
            days: days
        }
    }

    const formatOject = (data, dataValidater = null) => {
        let array = [];
        for (let prop in data) {
            let month = data[prop].toLocaleDateString('pt-PT', { month: 'long' });
            let params = {
                date: data[prop],
                weekday: Utils.getWeekdayString(data[prop].getDay()),
                day: ("0" + (data[prop].getDate())).slice(-2),
                month: `${("0" + (data[prop].getMonth() + 1)).slice(-2)}`,
                monthLabel: month.charAt(0).toUpperCase() + month.slice(1),
                year: data[prop].getFullYear(),
                isToday: false,
                isAvailable: true,
                isPassed: false,
            }
            params.isPassed = validatePassedDays(data[prop]);
            params.isToday = validateToday(data[prop]);
            if (dataValidater) {
                let day = new Date(data[prop]);
                params.isAvailable = dataValidater.includes(`${day.getFullYear()}-${("0" + (day.getMonth() + 1)).slice(-2)}-${("0" + day.getDate()).slice(-2)}`);
            }
            array.push(params);
        }
        let groupData = array.reduce((array, item) => {
            let weekday = item.date.getDay();
            array[weekday] = [...array[weekday] || [], item];
            return array;
        }, {});
        return groupData;
    }

    const validateToday = (date) => {
        const today = new Date();
        return date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
    }

    const validatePassedDays = (date) => {
        const today = new Date();
        if (date > today || validateToday(date)) {
            return false;
        } else {
            return true;
        }
    }

    const validatePassedMonths = (month, year) => {
        const today = new Date();
        if (today.getFullYear() >= year && today.getMonth() > month) {
            return true;
        } else {
            return false;
        }
    }

    const onPrevMonthHandler = async () => {
        let month = 0;
        let year = 0;
        if (props.config?.onPrevMonth == null) {
            month = calendar?.original?.month - 1 < 0 ? 11 : calendar?.original?.month - 1;
            year = calendar.original.month - 1 < 0 ? calendar.original.year - 1 : calendar.original.year;
            init(month, year);
        } else {
            month = calendar?.original?.month - 1 < 0 ? 11 : calendar?.original?.month - 1;
            year = calendar.original.month - 1 < 0 ? calendar.original.year - 1 : calendar.original.year;
            init(month, year);
            await props.config?.onPrevMonth({ calendar: calendar, current: { month: month, year: year } });
        }
    }

    const onNextMonthHandler = async () => {
        let month = 0;
        let year = 0;
        if (props.config?.onNextMonth == null) {
            month = calendar?.original?.month + 1 > 11 ? 0 : calendar?.original?.month + 1;
            year = calendar?.original?.month + 1 > 11 ? calendar?.original?.year + 1 : calendar?.original?.year;
            init(month, year);
        } else {
            month = calendar?.original?.month + 1 > 11 ? 0 : calendar?.original?.month + 1;
            year = calendar?.original?.month + 1 > 11 ? calendar?.original?.year + 1 : calendar?.original?.year;
            init(month, year);
            await props.config?.onNextMonth({ calendar: calendar, current: { month: month, year: year } });
        }
    }

    const onDayPickHandler = async (value) => {
        await props.config?.onDayPick(value)
    }

    const onCalendarRender = (data) => {
        let columns = [];
        let isStart = false;
        for (let prop in data) {
            let column =
                <div key={`column-${prop}`} className="calendar-column">
                    <div className="label">
                        <span>{Utils.getWeekdayString(prop).substring(0, 3).toUpperCase()}</span>
                    </div>
                    <div className="days">
                        {
                            data[prop].map((item, index) => {
                                let day = item.date.getDate();
                                if (day !== 1 && !isStart) {
                                    if (index === 0) {
                                        return (
                                            <>
                                                <DayButton key={`empty-day-${day}`} empty />
                                                <DayButton key={`day-${day}`} onClick={() => onDayPickHandler(item)} value={day} passed={item.isPassed} today={item.isToday} available={item.isAvailable} />

                                            </>
                                        );
                                    } else {
                                        return (
                                            <DayButton key={`day-${day}`} onClick={() => onDayPickHandler(item)} value={day} passed={item.isPassed} today={item.isToday} available={item.isAvailable} />
                                        )
                                    }

                                } else {
                                    isStart = true;
                                    return (
                                        <DayButton key={`day-${day}`} onClick={() => onDayPickHandler(item)} value={day} passed={item.isPassed} today={item.isToday} available={item.isAvailable} />
                                    );
                                }

                            })
                        }
                    </div>
                </div>
            columns.push(column);
        }
        return columns;
    }

    return (
        <div className="calendar-root">
            <div className="calendar-container">
                {
                    !props.config?.hideHeader ?
                        <div className="calendar-header">
                            <div className="button" onClick={onPrevMonthHandler}>
                                <div className="icon">
                                    <span>n</span>
                                </div>
                            </div>
                            <div className="label"><span>{calendar?.original ? `${calendar?.original?.monthLabel?.toUpperCase()} ${calendar?.original?.year}` : ''}</span></div>
                            <div className="button" onClick={onNextMonthHandler}>
                                <div className="icon">
                                    <span>{`{`}</span>
                                </div>
                            </div>
                        </div>
                        : null
                }
                <div className="calendar-box">
                    {
                        calendar?.current ? onCalendarRender(calendar?.current) : null
                    }
                </div>
            </div>
        </div>
    );
});

export default Calendar;