import Palette from '../palette';

const MuiFormControlLabel = {
    label: {
        color: Palette.white,
    }
   
};

export default MuiFormControlLabel;
