import React from 'react';
import './style.css';

const TimeButton = ({ value, empty, passed, disable, today, onClick }) => {

    const onClickHandler = () => {
        onClick()
    }

    return (
        <div className="timebutton-root" onClick={empty || passed || disable ? null : onClickHandler} style={{ cursor: empty || passed || disable ? 'initial' : 'pointer' }}>
            {
                empty ?
                    <div className="timebutton-box" style={{ backgroundColor: 'transparent' }}>
                        <span>{value}</span>
                    </div>
                    :
                    passed ?
                        <div className="timebutton-box" style={{ backgroundColor: 'transparent' }}>
                            <span style={{ color: 'rgb(51,51,51)', fontWeight: '500' }} >{value}</span>
                        </div>
                        :
                        disable ?
                            <div className="timebutton-box" style={{ backgroundColor: today ? 'rgb(255,255,255)' : 'rgb(38,38,38)' }}>
                                <span style={{ color: today ? 'rgb(226,226,226)' : 'rgb(51,51,51)', fontWeight: today ? 'bold' : '500' }} >{value}</span>
                            </div>
                            :
                            <div className="timebutton-box" style={{ backgroundColor: today ? 'rgb(255,255,255)' : 'rgb(52,52,52)' }}>
                                <span style={{ color: today ? 'rgb(52,52,52)' : 'rgb(255,255,255)', fontWeight: today ? 'bold' : '500' }} >{value}</span>
                            </div>
            }
        </div>
    );
}

export default TimeButton;