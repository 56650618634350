import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import Styles from './style';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';
import { makeStyles, Input as Inp } from '@material-ui/core';


const useStyles = makeStyles(Styles);

const InputText = ({
    id,
    name,
    type,
    value,
    styles,
    error,
    onChange,
    onBlur,
    required,
    placeholder,
    multiline,
    endAdornment,
    maxLength,
}) => {



    const classes = useStyles();
    const inputRef = useRef();


    if (type && type !== 'car' && type !== 'number' && type !== 'email' && type !== 'time' && type !== 'date') {
        return (
            <div className={clsx(classes.root, styles)}>
                <Inp
                    ref={inputRef}
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputComponent={NumberFormatCustom}
                    inputProps={{
                        type: type,
                        value: value,
                        inputMode: 'numeric',
                        autoComplete: 'off',
                        placeholder: placeholder
                        // min: minValue,
                        //max: maxValue
                    }}
                />
            </div>
        )
    }

    if (type && type === 'car') {
        return (
            <div className={clsx({ [classes.root]: true,  [classes.error]: error }, styles)}>
                <Inp
                    className={classes.car}
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputComponent={TextMask}
                    maxLength={maxLength}
                    inputProps={{
                        type: type,
                        autoComplete: 'off'
                    }}
                />
            </div>
        )
    }

    if (type && type === 'time') {
        return (
            <div className={clsx(classes.root, styles)} >
                <Inp
                    autoComplete='off'
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{
                        value: value,
                        autoComplete: 'off'
                    }}
                    type={type}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    multiline={multiline}
                    required={required}
                    endAdornment={endAdornment}
                />
            </div>
        )
    }

    if (type && type === 'date') {
        return (
            <div className={clsx(classes.root, styles)} >
                <Inp
                    autoComplete='off'
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputProps={{
                        value: value,
                        autoComplete: 'off'
                    }}
                    type={type}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    multiline={multiline}
                    required={required}
                    endAdornment={endAdornment}
                />
            </div>
        )
    }

    return (
        <div className={clsx(classes.root, styles)} >
            <Inp
                autoComplete='off'
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                    value: value,
                    autoComplete: 'off',
                    maxLength: maxLength
                }}
                type={type}
                maxLength={maxLength}
                placeholder={placeholder}
                multiline={multiline}
                required={required}
                endAdornment={endAdornment}
            />
        </div>
    );
}

export default InputText;




function NumberFormatCustom(props) {



    const { inputRef, value, type, onChange, min, max, placeholder, ...other } = props;



    let thousandSeparator = '.'
    let decimalSeparator = ','
    let suffix = ' €'
    let decimalScale = 2
    let fixedDecimalScale = true

    if (type === 'km') {
        thousandSeparator = ' '
        decimalSeparator = ','
        suffix = ''
        decimalScale = 0
        fixedDecimalScale = false

    }

    if (type === 'year') {
        thousandSeparator = ''
        decimalSeparator = ','
        suffix = ''
        decimalScale = 0
        fixedDecimalScale = false

    }


    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange(values.floatValue)
            }}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={decimalScale}
            isNumericString
            value={value}
            fixedDecimalScale={fixedDecimalScale}
            //defaultValue={value}
            suffix={suffix}
            placeholder={placeholder}
        /*
        isAllowed={(values) => {
            const { floatValue } = values;
            return floatValue >= min && floatValue <= max;
        }}
        */
        />
    );
}


function TextMask(props) {

    const classes = useStyles();


    
    const { inputRef, type, ...other } = props;

    if (!type) {
        return null
    }
    return (
        <MaskedInput
            className={classes.maskedInput}
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask[type]?.value}
            placeholderChar={mask[type]?.palaceholder}
            showMask={true}
            guide={false}
            placeholder={'XX XX XX'}
        />
    );
}



const mask = {
    car: {
        value: [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, ' ', /[a-zA-Z0-9]/, /[a-zA-Z0-9]/],
        palaceholder: '\u002D'
    }
}
