
import React, { useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/';
import { withRouter, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import Button from '../../../../components/Button';

import { ServiceContext } from '../../../../context/service/service-context';
import { AuthContext } from '../../../../context/auth/auth-context';
import { AppContext } from '../../../../context/app/app-context';
import { useNavBar } from '../../';

import Styles from './style';

const useStyles = makeStyles(Styles);

const ManagmentSubview = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();;
    const history = useHistory();
    const { Usados } = useContext(ServiceContext);
    const { setRequestStatus } = useNavBar();
    const {  setPopupTerms } = useContext(AppContext);
    const { cognitoUser, signout, brandAccess } = useContext(AuthContext);

    useEffect(() => { }, []);

    const onTermsConditionsHandler = () => {
        setPopupTerms(true)
    }

    const onLogoutHandler = () => {
        try {
            Cookies.remove('extranettoyota')
            Cookies.remove('extranetlexus')
            signout()
        } catch (error) {
            
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <span className={classes.header_title}>{cognitoUser?.displayName}</span>
                <span className={classes.header_subtitle}>{`${cognitoUser?.dealer.designation} - ${cognitoUser?.dealer.postalcodeExtension}`}</span>
            </div>
            <div className={classes.box}>
                <div className={classes.container}>
                    <div className={classes.area}>
                        <div className={classes.limit_area}>
                            <div className={classes.body}>
                                <div className={classes.row} onClick={onLogoutHandler}>
                                    <Button styles={classes.button}  >
                                        {t('user-subview-managment-btn1-label')}
                                    </Button>
                                </div>
                                <div className={classes.row} onClick={onTermsConditionsHandler}>
                                    <Button variant="outlined" styles={classes.button}>{t('user-subview-managment-btn2-label')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ManagmentSubview);