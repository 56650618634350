import React, { useContext, useEffect, useState } from 'react';
import Styles from './style';
import { useTranslation } from 'react-i18next';
import { makeStyles, ClickAwayListener, Popper, Paper, Grow, Typography  } from '@material-ui/core';
import { AuthContext } from '../../context/auth/auth-context';
import { AppContext } from '../../context/app/app-context';


const useStyles = makeStyles(Styles);

const MenuUser = ({
    anchorEl,
    onClose
}) => {

    const classes = useStyles();

    const { t } = useTranslation();

    const { cognitoUser, signout } = useContext(AuthContext);
    const {  setPopupTerms } = useContext(AppContext);

    const [dealerName, setDealerName] = useState('')
    const [sellerName, setSellerName] = useState('')


    const onClickTerms = () => {
        onClose()
        setPopupTerms(true)
    }


    useEffect(() => {
        try {
           const { dealer } = cognitoUser
            const { displayName } = cognitoUser
            setDealerName(dealer?.designation)
            setSellerName(displayName)

       } catch (error) {
           console.log(error)
       }
    }, [cognitoUser])




    const open = Boolean(anchorEl);


    if(!open) return null
 
    return (
        <div className={classes.root}>
            <Popper
                anchorEl={anchorEl}
                open={open}
                className={classes.pooper}
                placement="bottom-end"
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}>
                        <Paper elevation={0}>
                            <ClickAwayListener onClickAway={() => onClose()}>
                                <div className={classes.container}>
                                    <div className={classes.boxHeader}>
                                        <span onClick={() => onClose()}>n</span>
                                        <Typography variant="h2">{t('menu-user-title')}</Typography>
                                    </div>
                                    <div className={classes.boxInfo}>
                                        <Typography variant="caption">{t('menu-user-seller')}</Typography>
                                        <Typography variant="h4">{sellerName}</Typography>
                                    </div>
                                    <div className={classes.boxInfo}>
                                        <Typography variant="caption">{t('menu-user-dealer')}</Typography>
                                        <Typography variant="h4">{dealerName}</Typography>
                                    </div>
                                    <div className={classes.boxItems}>
                                        <div className={classes.item}>
                                            <span>{t('menu-user-performance')}</span>
                                        </div>
                                        <div className={classes.item} onClick={() => signout()}>
                                            <span>{t('menu-user-logout')}</span>
                                        </div>
                                        <div className={classes.item} onClick={() => onClickTerms()}>
                                            <span>{t('menu-user-terms-and-use')}</span>
                                        </div>
                                    </div>
                                    <div className={classes.arrowUp} />
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
       
    );
}

export default MenuUser;