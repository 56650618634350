import ScheduleSubview from './Views/Schedule';
import PerformanceSubview from './Views/Performance';
import ManagmentSubview from './Views/Management';

export const Structer = {
    title: 'user-view-title',
    sections: [
        {
            id: 'user',
            index: 0,
            label: 'user-subview-tab1-label',
            bold: false, 
            path: '/user',
            component: <ScheduleSubview />
        },
        {
            id: 'user',
            index: 1,
            label: 'user-subview-tab2-label',
            bold: false, 
            path: '/user',
            component: <PerformanceSubview />
        },
        {
            id: 'user',
            index: 2,
            label: 'user-subview-tab3-label',
            bold: false, 
            path: '/user',
            component: <ManagmentSubview />
        },
    ],
}