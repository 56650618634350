import Palette from '../palette';

const MuiButton = {
    root: {
        width: '100%',
        height: '100%',
        borderRadius: 24,
        textTransform: 'none',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.07)',
        backgroundColor: 'transparent' //Palette.fafafa
    },
    label: {
        fontSize: '16px',
        fontFamily: 'Open Sans',
        lineHeight: 1.38
    },
    containedPrimary: {
        '&:hover': {
            backgroundColor: `${Palette.black10} !important`,
            border: `1px solid ${Palette.transparent}`,
            '& .MuiButton-label': {
                color: Palette.primary.main
            }
        }
    },
    outlinedPrimary: {
        '&:hover': {
            backgroundColor: `${Palette.white} !important`,
            border: `1px solid ${Palette.black10}`,
            '& .MuiButton-label': {
                color: Palette.primary.main
            }
        }
    }
};

export default MuiButton;
