/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const authenticationExtraDirectLambda = /* GraphQL */ `
  query AuthenticationExtraDirectLambda($authentication: AuthenticationInput) {
    authenticationExtraDirectLambda(authentication: $authentication) {
      code
      message
      user {
        preferredLanguage
        idUser
        login
        displayName
        mail
        hash
        profiles {
          id
          name
        }
        dealer {
          objectId
          designation
          address
          postalcodeExtension
        }
        dealerParent {
          objectId
          designation
          address
          postalcodeExtension
        }
        dealersWithAccess {
          objectId
          designation
          address
          postalcodeExtension
        }
        brandsWithAccess {
          id
          name
        }
        dealerStructure {
          id
          name
        }
        dealerGroup
      }
    }
  }
`;
export const getSimulatorUsedCars = /* GraphQL */ `
  query GetSimulatorUsedCars($simulation: GetSimulatorInput) {
    getSimulatorUsedCars(simulation: $simulation) {
      commercialDesignation
      paintingTypes
      pvp
      financialCampaigns {
        name
        id
        isDefault
        discount
      }
      financialInsurances {
        name
        id
        isDefault
      }
      maintenanceContracts {
        name
        code
        pvp
      }
      accessoryFamilies {
        name
        priority
        id
        accessories {
          name
          code
          pvp
          isFeatured
        }
      }
      financialProducts {
        name
        id
        isDefault
        monthPeriods
      }
      message
      code
    }
  }
`;
export const simulateFinanceUsedCars = /* GraphQL */ `
  query SimulateFinanceUsedCars($simulation: SimulateInput) {
    simulateFinanceUsedCars(simulation: $simulation) {
      monthlyPayment
      vfmg
      monthPeriod
      financialFee
      taeg
      isuc
      tan
      monthlyFee
      acceptanceFee
      mtic
      legalInfo
      message
      code
    }
  }
`;
export const getTradePrice = /* GraphQL */ `
  query GetTradePrice($trade: TradePriceInput) {
    getTradePrice(trade: $trade) {
      message
      code
      name
      type
      typeCode
      origin
      month
      year
      acceptNewsletter
      cp4
      cp3
      vin
      model
      email
      km
      phone
      obs
      types
      models
      tradePrice
      imported
      idVehicle
      isToyota
      licensePlate
      make
      makes
      idToyotaTrade
      isLexus
      offerModels
      toyota_EUROTAX_TRADE
      campaignModels
      toyota_EUROTAX_PROMOTION
      toyota_EUROTAX_VEHICLE
      totalTradePrice
    }
  }
`;
export const getCarsWithFilter = /* GraphQL */ `
  query GetCarsWithFilter($filter: Filter) {
    getCarsWithFilter(filter: $filter) {
      cars {
        plateNumber
        vin
        dealerId
        dealerName
        dealerParentId
        dealerParentName
        model
        year
        km
        fuel
        color
        price
        discountPrice
        version
        creationDate
        registrationDate
        reservedUntil
        images {
          priority
          fileUrl
          id
        }
        equipments
        doorCount
        fee
        tan
        taeg
        initialEntrance
        nrPayments
        capitalToFinance
        acceptanceFee
        monthlyFee
        MTIC
        legalInfo
      }
      code
      message
    }
  }
`;
export const createPi = /* GraphQL */ `
  query CreatePi($createPI: CreatePIInput) {
    createPI(createPI: $createPI) {
      pi {
        id
        client {
          id
          lastName
          firstName
          phone
          email
          nif
          dtCreated
          changedBy
          dtChanged
          createdBy
        }
        price
        plateNumber
        vin
        conditions
        rent
        car {
          id
          type
          idCar
          plateNumber
          vin
          pvp
          idBrand
          idCompany
          month
          year
          reference
          idModel
          idStatus
          idLocation
          idBodyType
          oidDealer
          outstand
          vc
          idVersion
          doorCount
          idFuel
          idSupplier
          idWebCategory
          km0
          kilometres
          keyNumber
          warranty
          power
          dtReception
          vatApply
          vnPrice
          specialOffer
          seatCount
          dtRelease
          displacement
          dtItv
          itvLastResult
          webPublished
          dtCreated
          changedBy
          dtChanged
          createdBy
          idExteriorColor
          combinedConsumption
          idStatusSupplier
          idInteriorColor
          dtRegistration
          distributorsSalePrice
          highwayConsumption
          idTransmission
          carOptionalEquipment
          dtLastInspection
          extIdInSupplier
          maintenanceBook
          urbanConsumption
          importedVehicle
          highlightedEquipment
        }
        idClient
        discount
        description
        idCarStock
        priceWithDiscount
        idStatus
        oidDealer
        dtCreated
        changedBy
        dtChanged
        createdBy
        idUser
        dtEnd
        financeInfoSimulated {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
        financeInfoRecommended {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
      }
      message
      code
    }
  }
`;
export const listPi = /* GraphQL */ `
  query ListPi($listPI: ListPIInput) {
    listPI(listPI: $listPI) {
      pi {
        id
        client {
          id
          lastName
          firstName
          phone
          email
          nif
          dtCreated
          changedBy
          dtChanged
          createdBy
        }
        price
        plateNumber
        vin
        conditions
        rent
        car {
          id
          type
          idCar
          plateNumber
          vin
          pvp
          idBrand
          idCompany
          month
          year
          reference
          idModel
          idStatus
          idLocation
          idBodyType
          oidDealer
          outstand
          vc
          idVersion
          doorCount
          idFuel
          idSupplier
          idWebCategory
          km0
          kilometres
          keyNumber
          warranty
          power
          dtReception
          vatApply
          vnPrice
          specialOffer
          seatCount
          dtRelease
          displacement
          dtItv
          itvLastResult
          webPublished
          dtCreated
          changedBy
          dtChanged
          createdBy
          idExteriorColor
          combinedConsumption
          idStatusSupplier
          idInteriorColor
          dtRegistration
          distributorsSalePrice
          highwayConsumption
          idTransmission
          carOptionalEquipment
          dtLastInspection
          extIdInSupplier
          maintenanceBook
          urbanConsumption
          importedVehicle
          highlightedEquipment
        }
        idClient
        discount
        description
        idCarStock
        priceWithDiscount
        idStatus
        oidDealer
        dtCreated
        changedBy
        dtChanged
        createdBy
        idUser
        dtEnd
        financeInfoSimulated {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
        financeInfoRecommended {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
      }
      message
      code
    }
  }
`;
export const searchClients = /* GraphQL */ `
  query SearchClients($clients: SearchClientsInput) {
    searchClients(clients: $clients) {
      clientResponseList {
        id
        client {
          id
          lastName
          firstName
          phone
          email
          nif
          dtCreated
          changedBy
          dtChanged
          createdBy
        }
        price
        plateNumber
        vin
        conditions
        rent
        car {
          id
          type
          idCar
          plateNumber
          vin
          pvp
          idBrand
          idCompany
          month
          year
          reference
          idModel
          idStatus
          idLocation
          idBodyType
          oidDealer
          outstand
          vc
          idVersion
          doorCount
          idFuel
          idSupplier
          idWebCategory
          km0
          kilometres
          keyNumber
          warranty
          power
          dtReception
          vatApply
          vnPrice
          specialOffer
          seatCount
          dtRelease
          displacement
          dtItv
          itvLastResult
          webPublished
          dtCreated
          changedBy
          dtChanged
          createdBy
          idExteriorColor
          combinedConsumption
          idStatusSupplier
          idInteriorColor
          dtRegistration
          distributorsSalePrice
          highwayConsumption
          idTransmission
          carOptionalEquipment
          dtLastInspection
          extIdInSupplier
          maintenanceBook
          urbanConsumption
          importedVehicle
          highlightedEquipment
        }
        idClient
        discount
        description
        idCarStock
        priceWithDiscount
        idStatus
        oidDealer
        dtCreated
        changedBy
        dtChanged
        createdBy
        idUser
        dtEnd
        financeInfoSimulated {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
        financeInfoRecommended {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
      }
      message
      code
    }
  }
`;
export const reserveVehicle = /* GraphQL */ `
  query ReserveVehicle($reserveVehicle: ReserveVehicleInput) {
    reserveVehicle(reserveVehicle: $reserveVehicle) {
      vehicleReservation {
        id
        idCar
        idConfig
        extIdInOrigin
        dtCreated
        changedBy
        dtChanged
        createdBy
        reservationStartDate
        idClientOrigin
        reservationEndDate
        salesman {
          idUser
          name
          email
        }
      }
      message
      code
    }
  }
`;
export const cancelReserveVehicle = /* GraphQL */ `
  query CancelReserveVehicle($cancelReserveVehicle: CancelReserveVehicleInput) {
    cancelReserveVehicle(cancelReserveVehicle: $cancelReserveVehicle) {
      vehicleReservation {
        id
        idCar
        idConfig
        extIdInOrigin
        dtCreated
        changedBy
        dtChanged
        createdBy
        reservationStartDate
        idClientOrigin
        reservationEndDate
        salesman {
          idUser
          name
          email
        }
      }
      message
      code
    }
  }
`;
export const searchStatus = /* GraphQL */ `
  query SearchStatus($searchStatus: SearchStatusInput) {
    searchStatus(searchStatus: $searchStatus) {
      vehicleReservation {
        id
        idCar
        idConfig
        extIdInOrigin
        dtCreated
        changedBy
        dtChanged
        createdBy
        reservationStartDate
        idClientOrigin
        reservationEndDate
        salesman {
          idUser
          name
          email
        }
      }
      message
      code
    }
  }
`;
export const sendPi = /* GraphQL */ `
  query SendPi($sendPI: SendPIInput) {
    sendPI(sendPI: $sendPI) {
      pi {
        id
        client {
          id
          lastName
          firstName
          phone
          email
          nif
          dtCreated
          changedBy
          dtChanged
          createdBy
        }
        price
        plateNumber
        vin
        conditions
        rent
        car {
          id
          type
          idCar
          plateNumber
          vin
          pvp
          idBrand
          idCompany
          month
          year
          reference
          idModel
          idStatus
          idLocation
          idBodyType
          oidDealer
          outstand
          vc
          idVersion
          doorCount
          idFuel
          idSupplier
          idWebCategory
          km0
          kilometres
          keyNumber
          warranty
          power
          dtReception
          vatApply
          vnPrice
          specialOffer
          seatCount
          dtRelease
          displacement
          dtItv
          itvLastResult
          webPublished
          dtCreated
          changedBy
          dtChanged
          createdBy
          idExteriorColor
          combinedConsumption
          idStatusSupplier
          idInteriorColor
          dtRegistration
          distributorsSalePrice
          highwayConsumption
          idTransmission
          carOptionalEquipment
          dtLastInspection
          extIdInSupplier
          maintenanceBook
          urbanConsumption
          importedVehicle
          highlightedEquipment
        }
        idClient
        discount
        description
        idCarStock
        priceWithDiscount
        idStatus
        oidDealer
        dtCreated
        changedBy
        dtChanged
        createdBy
        idUser
        dtEnd
        financeInfoSimulated {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
        financeInfoRecommended {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
      }
      message
      code
    }
  }
`;
export const printPi = /* GraphQL */ `
  query PrintPi($printPI: PrintPIInput) {
    printPI(printPI: $printPI) {
      pi {
        id
        client {
          id
          lastName
          firstName
          phone
          email
          nif
          dtCreated
          changedBy
          dtChanged
          createdBy
        }
        price
        plateNumber
        vin
        conditions
        rent
        car {
          id
          type
          idCar
          plateNumber
          vin
          pvp
          idBrand
          idCompany
          month
          year
          reference
          idModel
          idStatus
          idLocation
          idBodyType
          oidDealer
          outstand
          vc
          idVersion
          doorCount
          idFuel
          idSupplier
          idWebCategory
          km0
          kilometres
          keyNumber
          warranty
          power
          dtReception
          vatApply
          vnPrice
          specialOffer
          seatCount
          dtRelease
          displacement
          dtItv
          itvLastResult
          webPublished
          dtCreated
          changedBy
          dtChanged
          createdBy
          idExteriorColor
          combinedConsumption
          idStatusSupplier
          idInteriorColor
          dtRegistration
          distributorsSalePrice
          highwayConsumption
          idTransmission
          carOptionalEquipment
          dtLastInspection
          extIdInSupplier
          maintenanceBook
          urbanConsumption
          importedVehicle
          highlightedEquipment
        }
        idClient
        discount
        description
        idCarStock
        priceWithDiscount
        idStatus
        oidDealer
        dtCreated
        changedBy
        dtChanged
        createdBy
        idUser
        dtEnd
        financeInfoSimulated {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
        financeInfoRecommended {
          rent
          nrPayments
          mtic
          monthlyRent
          legalInfo
          tan
          taeg
          initialEntrance
          capitalToFinance
          acceptanceRent
        }
      }
      message
      code
    }
  }
`;
export const listKpi = /* GraphQL */ `
  query ListKpi($listKPI: ListKPIInput) {
    listKPI(listKPI: $listKPI) {
      dashboard
      kpiList {
        name
        value
        dtStart
        dtEnd
        user
        priority
      }
      message
      code
    }
  }
`;
export const getEventsByIdSalesman = /* GraphQL */ `
  query GetEventsByIdSalesman($events: GetEventsByIdSalesmanInput) {
    getEventsByIdSalesman(events: $events) {
      eventsInfo {
        eventID
        eventName
        eventStatus
        eventCreatedBy
        eventDtCreated
        eventChangedBy
        eventDtChanged
        eventDtStart
        relationID
        relationEventID
        relationEventTypeID
        relationCreatedBy
        relationDtCreated
        relationChangedBy
        relationDtChanged
        typeID
        typeType
        typeCreatedBy
        typeDtCreated
        typeChangedBy
        typeDtChanged
        registryID
        registryEventID
        registryStatus
        registryOidDealer
        registryFirstName
        registryLastName
        registryDtBirth
        registryNif
        registryEmail
        registryPhone
        registryDistrict
        registryPlateNumber
        registryKM
        registryCheckUp
        registryInsurance
        registryAssistance
        registryWarranty
        registryIdModelTestDrive
        registryNewVehicle
        registryUsedVehicle
        registryObservations
        registryObservationsDealer
        registryDtRegistry
        registryDtSchedule
        registryTimeSchedule
        registryCreatedBy
        registryDtCreated
        registryChangedBy
        registryDtChanged
        registrySetScheduleBy
        registryDtSetSchedule
        registryDtCheckIn
        registryDiscountRank
        registryObservationsRank
        registryRankChangedBy
        registryDtRankChanged
        registryCoupon
        registrySkydiver
        registryStatusRegistry
        registryIdSalesmanAssigned
        registryIdContactStatus
      }
      message
      code
    }
  }
`;
export const editEvent = /* GraphQL */ `
  query EditEvent($event: EditEventInput) {
    editEvent(event: $event) {
      message
      code
    }
  }
`;
export const getAvailableSlotsByIdSalesman = /* GraphQL */ `
  query GetAvailableSlotsByIdSalesman(
    $slots: GetAvailableSlotsByIdSalesmanInput
  ) {
    getAvailableSlotsByIdSalesman(slots: $slots) {
      scheduleList {
        id
        date
        hours
      }
      message
      code
    }
  }
`;
export const getBoModule = /* GraphQL */ `
  query GetBoModule($id: ID!) {
    getBOModule(id: $id) {
      id
      name
      description
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const listBoModules = /* GraphQL */ `
  query ListBoModules(
    $filter: ModelBOModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBOModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        enabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCar = /* GraphQL */ `
  query GetCar($PK: String!, $SK: String!) {
    getCar(PK: $PK, SK: $SK) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2PK
      GSI2SK
      model
      creationDate
      color
      km
      dealerId
      dealerName
      dealerParentId
      dealerParentName
      dealerGroup
      fuel
      plateNumber
      price
      discountPrice
      vin
      registrationDate
      year
      month
      version
      reservedUntil
      images
      equipments
      doorCount
      fee
      tan
      taeg
      initialEntrance
      nrPayments
      capitalToFinance
      acceptanceFee
      monthlyFee
      MTIC
      legalInfo
      createdAt
      updatedAt
    }
  }
`;
export const listCars = /* GraphQL */ `
  query ListCars(
    $PK: String
    $SK: ModelStringKeyConditionInput
    $filter: ModelCarFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCars(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2PK
        GSI2SK
        model
        creationDate
        color
        km
        dealerId
        dealerName
        dealerParentId
        dealerParentName
        dealerGroup
        fuel
        plateNumber
        price
        discountPrice
        vin
        registrationDate
        year
        month
        version
        reservedUntil
        images
        equipments
        doorCount
        fee
        tan
        taeg
        initialEntrance
        nrPayments
        capitalToFinance
        acceptanceFee
        monthlyFee
        MTIC
        legalInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDealer = /* GraphQL */ `
  query GetDealer($PK: String!, $SK: String!) {
    getDealer(PK: $PK, SK: $SK) {
      PK
      SK
      address
      dealerParentId
      dealerId
      district
      municipality
      openingDate
      isCAMember
      postalCodeDesignation
      designation
      image
      brand
      createdAt
      updatedAt
    }
  }
`;
export const listDealers = /* GraphQL */ `
  query ListDealers(
    $PK: String
    $SK: ModelStringKeyConditionInput
    $filter: ModelDealerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDealers(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        address
        dealerParentId
        dealerId
        district
        municipality
        openingDate
        isCAMember
        postalCodeDesignation
        designation
        image
        brand
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const authenticationExtra = /* GraphQL */ `
  query AuthenticationExtra($params: QueryAuthenticationExtraParamsInput!) {
    authenticationExtra(params: $params) {
      code
      message
      user {
        preferredLanguage
        idUser
        login
        displayName
        mail
        hash
        profiles {
          id
          name
        }
        dealer {
          objectId
          designation
          address
          postalcodeExtension
        }
        dealerParent {
          objectId
          designation
          address
          postalcodeExtension
        }
        dealersWithAccess {
          objectId
          designation
          address
          postalcodeExtension
        }
        brandsWithAccess {
          id
          name
        }
        dealerStructure {
          id
          name
        }
        dealerGroup
      }
    }
  }
`;
export const queryByGsi1 = /* GraphQL */ `
  query QueryByGsi1(
    $GSI1PK: String
    $GSI1SK: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByGSI1(
      GSI1PK: $GSI1PK
      GSI1SK: $GSI1SK
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2PK
        GSI2SK
        model
        creationDate
        color
        km
        dealerId
        dealerName
        dealerParentId
        dealerParentName
        dealerGroup
        fuel
        plateNumber
        price
        discountPrice
        vin
        registrationDate
        year
        month
        version
        reservedUntil
        images
        equipments
        doorCount
        fee
        tan
        taeg
        initialEntrance
        nrPayments
        capitalToFinance
        acceptanceFee
        monthlyFee
        MTIC
        legalInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryByGsi2 = /* GraphQL */ `
  query QueryByGsi2(
    $GSI2PK: String
    $GSI2SK: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByGSI2(
      GSI2PK: $GSI2PK
      GSI2SK: $GSI2SK
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        SK
        GSI1PK
        GSI1SK
        GSI2PK
        GSI2SK
        model
        creationDate
        color
        km
        dealerId
        dealerName
        dealerParentId
        dealerParentName
        dealerGroup
        fuel
        plateNumber
        price
        discountPrice
        vin
        registrationDate
        year
        month
        version
        reservedUntil
        images
        equipments
        doorCount
        fee
        tan
        taeg
        initialEntrance
        nrPayments
        capitalToFinance
        acceptanceFee
        monthlyFee
        MTIC
        legalInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
