const Styles = theme => ({
    root: {
        height: 48,
        minHeight: 48,
        margin: '0 auto'
    },
    logoContainer: {
        marginRight: 'auto',
        paddingLeft: 10,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    logoBox: {
        height: 38,
        width: 38,
        marginRight: 8,
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    },
    textBox: {
        height: 24,
        width: 92,
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    }
})

export default Styles;