const breakpoints = {
    values: {
        xs: 100,
        sm: 421,
        md: 769,
        lg: 1025,
        xl: 1367,
        max: 10000,
        limit: 600
    }
}
export default breakpoints;






