import React, { Suspense, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import ServiceContextProvider from './context/service/service-context';
import Layout from './layout';
import ThemeToyota from './theme/sample/Toyota';
import ThemeLexus from './theme/sample/Lexus';
import { AppContextProvider } from './context/app/app-context';
import { AuthContext } from './context/auth/auth-context';

import PushAlert from './components/PushAlert';


function App() {
  
  const { brandAccess, isAuthenticated} = useContext(AuthContext);


  const theme = brandAccess == 1 ? ThemeToyota : ThemeLexus

  if (!brandAccess) return null

  return (
    <Suspense fallback={<div></div>}>
        <ServiceContextProvider>
          <AppContextProvider>
            <BrowserRouter>
            <ThemeProvider theme={theme}>
                <PushAlert />
                <Layout />
              </ThemeProvider>
            </BrowserRouter>
          </AppContextProvider>
        </ServiceContextProvider>
    </Suspense>
  );
}

export default App;



