import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const darkgray = '#999999';
const lightgray = '#cecece';
const fafafa = '#fafafa';
const black90 = 'rgba(0, 0, 0, 0.9)';
const verylightgray = '#eeeeee';
const cherry_red = 'rgba(255, 0, 34, 0.1)'
const transparent = 'rgba(0, 0, 0, 0)'
const black10 = 'rgba(0, 0, 0, 0.1)'
const initGradient = '#707070'




const palette =  {
    black,
    white,
    fafafa,
    darkgray,
    lightgray,
    verylightgray,
    black90,
    cherry_red,
    transparent,
    black10,
    initGradient,
    primary: {
        contrastText: white,
        dark: '#000000',
        main: '#000000',
        light: '#000000'
    },
    secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue['A400'],
        light: colors.blue['A400']
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
    },
    text: {
        primary: black,
        secondary: darkgray,
        link: colors.blue[600]
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
        default: '#F4F6F8',
        paper: white
    },
    divider: colors.grey[200]
}



export default palette