import React, { useEffect, useContext, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles, CircularProgress } from '@material-ui/core';

import Schedule, { useSchedule } from '../../../../../components/Schedule/Schedule';
import ScheduleConfirmView from '../ScheduleConfirmView';

import { ServiceContext } from '../../../../../context/service/service-context';
import { AuthContext } from '../../../../../context/auth/auth-context';

import Styles from './style';

const useStyles = makeStyles(Styles);

const PopupEditScheduleSubview = ({ confirmation }) => {

    const CONFIG = {
        datePicker: {
            hideHeader: true,
            validateSlots: false,
            onPrevMonth: async (value) => await onPrevMonth(value),
            onNextMonth: async (value) => await onNextMonth(value),
            onDayPicker: (value) => onDayPickerHandler(value),
        },
        timePicker: {
            hideHeader: true,
            validateSlots: false,
            onTimePicker: (value) => onTimePickerHandler(value),
        },
        schedule: {
            setConfirmRender: (data) => <ScheduleConfirmView data={data} onClick={() => scheduleRef.current.confirmSchedule()} />,
            onConfirmSchedule: (value) => onConfirmScheduleHandler(value)
        }
    }

    const classes = useStyles();
    const { t } = useTranslation();
    const scheduleRef = useRef();
    const { schedule, prevSelection, clearData } = useSchedule();
    const { cognitoUser } = useContext(AuthContext);
    const { Usados } = useContext(ServiceContext);
    const [state, setState] = useState({ month: new Date().getMonth() + 1, year: new Date().getFullYear(), daySlots: [], timeSlots: [], all: [] })
    const [isRequest, setIsRequest] = useState(false);

    useEffect(() => {
        getSlots(state.month, state.year);
    }, [])

    const getSlots = async (month, year) => {
        const params = {
            idSalesman: cognitoUser.idUser,
            month: month,
            year: year
        }
        setIsRequest(true);
        let result = await Usados.getAvailableSlots(params);
        let daySlots = extractDays(result?.getAvailableSlotsByIdSalesman?.scheduleList);
        setState({ ...state, month: month, year: year, daySlots: daySlots, all: result?.getAvailableSlotsByIdSalesman?.scheduleList });
        setIsRequest(false);
    }

    const extractDays = (data) => {
        let array = [];
        for (let item in data) {
            array.push(data[item].date);
        }
        return array;
    }

    const extractTime = (date) => {
        let hours = state.all.find(item => item.date == date).hours;
        setState({ ...state, timeSlots: hours });
    }

    const onPrevMonth = async (value) => {
        await getSlots(value.current.month + 1, value.current.year);
    }

    const onPrevMonthHandler = async (value) => {
        let passed = validatePassedMonths(schedule?.calendar?.original?.month - 1, schedule?.calendar?.original?.year);
        if (!passed) {
            await scheduleRef.current.prevMonth()
        }
    }

    const onNextMonth = async (value) => {
        await getSlots(value.current.month + 1, value.current.year);
    }

    const onNextMonthHandler = async (value) => {
        await scheduleRef.current.nextMonth()
    }

    const onDayPickerHandler = (value) => {
        extractTime(`${value.year}-${value.month}-${value.day}`);
    }

    const onTimePickerHandler = (value) => {}

    const onConfirmScheduleHandler = (value) => {
        confirmation(value);
        clearData();
    }

    const onPreviousSelectionHandler = () => {
        prevSelection();
    }

    const validatePassedMonths = (month, year) => {
        const today = new Date();
        if (today.getFullYear() >= year && today.getMonth() > month) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className={classes.root}>

            <div style={{ width: '100%', height: '100%', display: isRequest ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: '1000',  bottom: 0, top: '48px', backgroundColor: 'rgba(250,250,250,0.3)' }}>
                <CircularProgress className={classes.circularProgress} />
            </div>

            <div style={{ width: '240px', textAlign: 'center', margin: '10px 0px 20px 0px' }}>
                <span className={classes.header_regular}>Seleccione o dia pretendidopara aceder aos horários disponíveis.</span>
            </div>
            {
                schedule.day == null ?
                    <>
                        <div style={{ width: '100%', height: '15px', display: 'flex', alignItems: 'center' }}>
                            <div className={classes.line_header}></div>
                            <div className={classes.label_header}>{`${schedule?.calendar?.original?.monthLabel.toUpperCase()} ${schedule?.calendar?.original?.year}`}</div>
                            <div className={classes.line_header}></div>
                        </div>
                        <div style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className={classes.calendar_month_button} onClick={onPrevMonthHandler} style={{ opacity: validatePassedMonths(schedule?.calendar?.original?.month - 1, schedule?.calendar?.original?.year) ? 0.5 : 1 }}>n</div>
                            <div className={classes.calendar_month_button} onClick={onNextMonthHandler}>{`{`}</div>
                        </div>
                    </>
                    : null
            }
            {
                schedule.day != null && schedule.time == null ?
                    <>
                        <div style={{ width: '100%', height: '15px', display: 'flex', alignItems: 'center' }}>
                            <div className={classes.line_header}></div>
                            <div className={classes.line_header}></div>
                        </div>
                        <div style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div onClick={onPreviousSelectionHandler} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <div className={classes.calendar_month_button}>n</div>
                                <span className={classes.header_bold}>Voltar</span>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '15px' }}>
                            <div onClick={onPreviousSelectionHandler} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <div className={classes.calendar_month_button}>-</div>
                                <div className={classes.header_bold2}>{schedule.day.day} </div>
                                <div className={classes.header_regular2}> {`${schedule.day.weekday.substring(0, 3)} - `}</div>
                                <div className={classes.header_bold2}>{(`${schedule.day.monthLabel} ${schedule.day.year}`).toUpperCase()}</div>
                            </div>
                        </div>
                    </>
                    : null
            }
            {
                schedule.day != null && schedule.time != null ?
                    <>
                        <div style={{ width: '100%', height: '15px', display: 'flex', alignItems: 'center' }}>
                            <div className={classes.line_header}></div>
                            <div className={classes.line_header}></div>
                        </div>
                        <div style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div onClick={onPreviousSelectionHandler} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <div className={classes.calendar_month_button}>n</div>
                                <span className={classes.header_bold}>Voltar</span>
                            </div>
                        </div>
                    </>
                    : null
            }
            <Schedule
                ref={scheduleRef}
                config={{
                    ...CONFIG,
                    datePicker: {
                        ...CONFIG.datePicker,
                        month: state.month,
                        year: state.year,
                        dateSlots: state.daySlots
                    },
                    timePicker: {
                        ...CONFIG.timePicker,
                        timeSlots: state.timeSlots
                    }
                }} />
        </div>
    );
}

export default PopupEditScheduleSubview;