import React, { useContext, useEffect, useState}  from 'react';
import Styles from './style';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '../../components/Button';
import Images from '../../assets/images'
import { AuthContext } from '../../context/auth/auth-context';



const useStyles = makeStyles(Styles);

const LoginView = () => {

    const classes = useStyles();

    const { t } = useTranslation();

    const { brandAccess, login } = useContext(AuthContext);

    const [data, setData] = useState(null)




    const loginHandler = () => {
        login()
    }


    useEffect(() => {
        const isToyota = brandAccess === 1
        if (isToyota) {
            setData({
                logo: Images.logo_toyota,
                title: 'Toyota'
            })
        } else {
            setData({
                logo: Images.logo_lexus,
                title: 'Lexus'
            })
        }
    
    }, [brandAccess])


    return (
        <div className={classes.root}>
            <div className={classes.card}>
                <div className={classes.logoBox}>
                    <img src={data?.logo} alt="" />
                </div>
                <div className={classes.textBox}>
                    <Typography variant="h2">{data?.title}</Typography>
                    <Typography variant="h2">Usados Online</Typography>
                </div>
                
                <Button
                    styles={null}
                    onClick={() => loginHandler()}
                >
                    {t('login-button')}
                </Button>

            </div>
        </div>
    );
}

export default LoginView;