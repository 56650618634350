import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './style';
import { makeStyles, Typography } from '@material-ui/core';
import { useAppContext } from '../../context/app/app-context';
import Button from '../../components/Button';
import * as Utils from '../../service/utils'


const useStyles = makeStyles(Styles);

const FeaturedCarCard = ({
    data,
    actions,
    onSeeLegalInfo
}) => {

    const { t } = useTranslation();


    const classes = useStyles();

    const boxImageRef = useRef(null);

    const { setPopupAlert } = useAppContext()

    const [loading, setLoading] = useState(true)
    const [feeAndTaeg, setfeeAndTaeg] = useState('')
    const [oldPriceStr, setOldPriceStr] = useState('')
    const [priceStr, setPriceStr] = useState('')
    const [hasDiscount, setHasDiscount] = useState(false)
    const [km, setkm] = useState('')
    const [registration, setRegistration] = useState(null)
    const [locked, setlocked] = useState(false)
    const [reservationDate, setReservationDate] = useState(null)
    const [images, setImages] = useState([])



    const onClickLegalInfoHandler = () => {
        const popupAlerData = {
            description: data?.legalInfo
        }
        setPopupAlert(popupAlerData)
        onSeeLegalInfo(true)
    }


    useEffect(() => {
        if (data) {
            try {
                const fee = data.fee ? parseFloat(data.fee).toFixed(2).toString().replaceAll('.', ',') : ''
                const taeg = data.taeg ? parseFloat(data.taeg).toFixed(3).toString().replaceAll('.', ',') : ''

                const _feeAndTaeg = `${fee} €/mês (TAEG: ${taeg}%)`;
                if (data?.discountPrice) {
                    setHasDiscount(true)
                    const _oldPriceStr = `antes ${Utils.formatPrice(data?.price)}`
                    const _priceStr = Utils.formatPrice(data?.discountPrice);
                    setPriceStr(_priceStr)
                    setOldPriceStr(_oldPriceStr)
                } else {
                    setHasDiscount(false)
                    const _priceStr = Utils.formatPrice(data?.price);
                    setPriceStr(_priceStr)
                }

                const locked = !data.reservedUntil ? false : (
                    new Date(data.reservedUntil).getTime() > new Date().getTime()
                )
               
                const dateSplited = data.registrationDate?.split('-')
                if (dateSplited) setRegistration({ year: dateSplited[0], month: dateSplited[1] })
                setkm(Utils.formatMetric(data.km))

                const _images = data?.images

                setlocked(locked)
                setfeeAndTaeg(_feeAndTaeg)
                setImages(_images)
                setLoading(false)
                setReservationDate(new Date(data.reservedUntil).getTime())

            } catch (error) {
                setLoading(false)
            }
        }
    }, [data])

    if (!data && loading) {
        return null
    }

    const viewActionHandler = () => {
        actions.onView(data);
    }

    return (
        <div className={classes.root}>
            <div className={classes.box1}>
                <div ref={boxImageRef} className={classes.boxImage} style={{ height: boxImageRef?.current?.offsetWidth ? ((boxImageRef?.current?.offsetWidth) * 3 / 4) : 0 }}>
                    <img src={images[0]?.fileUrl} alt="" />
                </div>
                <div className={classes.boxInfo}>
                    <Typography className={classes.carTitle} variant="h3">{data?.model}</Typography>
                    <Typography className={classes.carModel} variant="subtitle2">{data?.version}</Typography>
                </div>
            </div>
            <div className={classes.box2}>
                <Typography className={classes.price} variant="h1">{priceStr}</Typography>
                {hasDiscount ? (<Typography className={classes.oldPrice} variant="subtitle1">{oldPriceStr}</Typography>) : null }
            </div>
            <div className={classes.box3}>
                <span>-</span>
                <Typography variant="h4">{registration?.year}&nbsp;/&nbsp;{registration?.month}</Typography>
                <Typography variant="h4">&nbsp;-&nbsp;</Typography>
                <Typography variant="h4">{km}&nbsp;km</Typography>
            </div>
            <div className={classes.box4}>
                <div style={{ display: 'flex' }}></div>
                <Typography className={classes.feeAndTaeg} variant="h4">
                    {feeAndTaeg}
                    <p onClick={() => onClickLegalInfoHandler()}><span>i</span></p>
                </Typography>
            
                <div className={classes.boxButton}>
                    {locked ? <span className={classes.iconLock}>h</span> : null}
                    <Button onClick={viewActionHandler}>Ver Detalhe</Button>
                    {locked && reservationDate ?
                        <div className={classes.boxlockedDate}>
                            <span>{`${t('comp-card-pi-text1-label')} ${Utils.formatStringDateTime(reservationDate).time}`}</span>
                            <p>{`${Utils.formatStringDateTime(reservationDate).day} ${Utils.formatStringDateTime(reservationDate).month.toUpperCase().substring(0, 3)}`}</p>
                        </div> : null
                    }
                </div>
            </div>
        </div>
    );
}

export default FeaturedCarCard;