const Styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.fafafa,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        backgroundColor: '#000000',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.16)',
    },
    header_title: {
        width: '220px',
        textAlign: 'center',
        marginBottom: '5px',
        ...theme.typography.title,
        fontSize: '14px',
        color: '#ffffff',
        lineHeight: '1.07'
    },
    header_subtitle: {
        width: '220px',
        textAlign: 'center',
        ...theme.typography.body,
        fontSize: '14px',
        color: '#ffffff',
        lineHeight: '1.07'
    },
    box: {
        flex: 1,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    container: {
        padding: '15px 0px',
        overflowY: 'auto',
    },
    area: {
        padding: '20px',
    },
    row: {
        width: '100%',
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    vertical: {
        width: '1px', 
        minHeight: '48px', 
        backgroundColor: '#444444'
    },
    columnLeft:{
        flex: 1,
        padding: '0px 10px',
        minHeight: '48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#999999',
            textAlign: 'right'
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '14px',
            textAlign: 'right',
        }
    },
    columnRight:{
        flex: 1,
        padding: '0px 10px',
        minHeight: '48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& :nth-child(1)': {
            ...theme.typography.title,
            fontSize: '11px',
            color: '#999999',
            textAlign: 'left'
        },
        '& :nth-child(2)': {
            ...theme.typography.title,
            fontSize: '14px',
            textAlign: 'left'
        }
    },
    cell: {
        flex: '1',
        display: 'flex',
        margin: '5px',
        height: '48px',
        borderRadius: '48px',
        border: 'solid 1px #eeeeee'
    },
    item1: {
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }, 
    item2: {
        flex: '1.3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        minHeight: '126px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px 20px',
        boxShadow: '0px -2px 2px rgba(0, 0, 0, 0.07)',
        backgroundColor: theme.palette.fafafa,
    },
    middle_column:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    limit_area: {
        width: '100%',
        [theme.breakpoints.up('limit')]: {
            width: '600px',
            margin: 'auto',
        },
    },
    icon: {
        width: '32px',
        height: '32px',
        borderRadius: '32px',
        backgroundColor: '#000000',
        '& span': {
            fontSize: '32px',
            fontFamily: 'Cycloid-Font',
            margin: '0 143px 0 0',
            color: '#ffffff'
        }
    },
    icon_invert: {
        width: '32px',
        height: '32px',
        borderRadius: '32px',
        '& span': {
            fontSize: '32px',
            fontFamily: 'Cycloid-Font',
            margin: '0 143px 0 0',
        }
    },
    button: {
        width: '100%',
        margin: '5px'
    },
    title: {
        ...theme.typography.titleBold,
        fontSize: '16px',
        textAlign:  'center'
    },
    text: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    label: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    body: {
        ...theme.typography.body,
        fontSize: '16px',
        lineHeight: 'normal'
    },
    title_footer: {
        ...theme.typography.titleBold,
        fontSize: '16px',
        textAlign:  'center'
    },
    text_footer: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
    label_footer: {
        ...theme.typography.body,
        fontSize: '11px',
        lineHeight: 'normal',
    },
})

export default Styles;