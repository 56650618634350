import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';

import Calendar, { useCalendar } from '../Calendar';
import Timer from '../Timer';

import * as Utils from '../Resources/Utils';
import './style.css';
import { brown } from '@material-ui/core/colors';

const scheduleStatus = {
    state: {
        day: null,
        time: null,
        calendar: null,
    },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const useSchedule = () => {

    const [schedule, setSchedule] = useState(scheduleStatus.state);
    if (!scheduleStatus.setters.includes(setSchedule)) {
        scheduleStatus.setters.push(setSchedule);
    }

    useEffect(() => () => {
        scheduleStatus.setters = scheduleStatus.setters.filter(setter => setter !== setSchedule)
    }, []);

    function setScheduleState(states) {
        scheduleStatus.setState({ ...schedule, ...states });
    }

    function clearData() {
        scheduleStatus.setState({ day: null, time: null, calendar: null });
    }

    function prevSelection() {
        if (schedule.time) {
            setScheduleState({ ...schedule, time: null });
        } else if (schedule.day) {
            setScheduleState({ ...schedule, day: null });
        }
    }

    return { schedule, setScheduleState, prevSelection, clearData };
}

scheduleStatus.setState = scheduleStatus.setState.bind(scheduleStatus);

const Schedule = forwardRef((props, ref) => {

    const calendarRef = useRef()
    const { calendar } = useCalendar();
    const { schedule, setScheduleState } = useSchedule();

    useImperativeHandle(ref, () => ({
        async prevMonth() {
            await calendarRef.current.prevMonth();
        },
        async nextMonth() {
            await calendarRef.current.nextMonth();
        },
        async confirmSchedule() {
            onConfirmSchedule();
        }
    }));

    useEffect(() => {
        setScheduleState({ ...schedule, calendar: calendar })
    }, [calendar])

    const daySelectedFormated = (value) => {
        const day = ("0" + value?.date.getDate()).slice(-2);
        const weekday = Utils.getWeekdayString(value?.date.getDay()).substring(0, 3)
        let month = value?.date.toLocaleDateString('pt-PT', { month: 'long' });
        month = month.charAt(0).toUpperCase() + month.slice(1);
        const year = value?.date.getFullYear();
        return `${day} ${weekday} - ${month} ${year}`;
    }

    const onDayPickerHandler = async (value) => {
        await props.config?.datePicker?.onDayPicker(value);
        setScheduleState({ ...schedule, day: value });
    }

    const onTimePickerHandler = async (value) => {
        await props.config?.timePicker?.onTimePicker(value)
        setScheduleState({ ...schedule, time: value });
    }

    const onPreviousSelectionHandler = () => {
        if (schedule.time) {
            setScheduleState({ ...schedule, time: null });
        } else if (schedule.day) {
            setScheduleState({ ...schedule, day: null });
        }
    }

    const onConfirmSchedule = () => {
        const params = {
            date: schedule.time.date,
            format: {
                weekday: schedule.day.weekday,
                day: schedule.day.day,
                month: schedule.day.monthLabel,
                year: schedule.day.year,
                hour: schedule.time.hour,
                minute: schedule.time.minute,
                date: `${schedule.day.year}-${schedule.day.month}-${schedule.day.day}`,
                time: schedule.time.format,
                timeMili: schedule.time.formatMili,
            }
        }
        props.config?.schedule.onConfirmSchedule(params);
    }

    return (
        <div className="schedule-root">
            {
                schedule.day == null ? <Calendar ref={calendarRef} config={{ ...props.config?.datePicker, onDayPick: (value) => onDayPickerHandler(value) }} /> : null
            }
            {
                schedule.day && schedule.time == null ?
                    <div className="schedule-container">
                        {
                            !props.config?.timePicker.hideHeader ?
                                <div className="schedule-header">
                                    <div className="button" onClick={onPreviousSelectionHandler}>
                                        <div className="icon">
                                            <span>n</span>
                                        </div>
                                        <div className="label">
                                            <span>Voltar</span>
                                        </div>
                                    </div>
                                </div> :
                                null
                        }
                        {
                            !props.config?.timePicker.hideHeader ?
                                <div className="schedule-detail">
                                    <div className="icon">
                                        <span>-</span>
                                    </div>
                                    <span>{daySelectedFormated(schedule?.day)}</span>
                                </div> :
                                null
                        }
                        <Timer config={{ ...props.config.timePicker, onTimePicker: (value) => onTimePickerHandler(value), pickedDay: schedule?.day ? schedule?.day?.date : null }} />
                    </div>
                    : null
            }
            {
                schedule.day && schedule.time ?
                    props.config?.schedule?.setConfirmRender ?
                        props.config?.schedule?.setConfirmRender(schedule)
                        :
                        <div className="schedule-container">
                            {
                                !props.config.hideHeader ?
                                    <div className="schedule-header">
                                        <div className="button" onClick={onPreviousSelectionHandler}>
                                            <div className="icon">
                                                <span>n</span>
                                            </div>
                                            <div className="label">
                                                <span>Voltar</span>
                                            </div>
                                        </div>
                                    </div> :
                                    null
                            }
                            <div className="schedule-box">
                                <div className="row">
                                    <div className="label">
                                        <span>{`${schedule.day.monthLabel} ${schedule.day.year}`}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="icon">
                                        <span>-</span>
                                    </div>
                                    <div className="label">
                                        <span>{`${schedule.day.day} ${schedule.day.weekday}`}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="icon">
                                        <span>G</span>
                                    </div>
                                    <div className="label">
                                        <span>{schedule.time.format}</span>
                                    </div>
                                </div>
                                <div className="button" onClick={onConfirmSchedule}>
                                    <span>Submeter</span>
                                </div>
                            </div>
                        </div>
                    : null
            }
        </div>
    )


})

export default Schedule