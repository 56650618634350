import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const ScrollReset = () => {

    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 1500);

        return () => {
            clearTimeout(timer)
        }
    }, [location.pathname]);

    return null;
};

export default ScrollReset;
