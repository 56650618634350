import Palette from '../palette';

const MuiCheckbox = {
    root: {
        color: Palette.white,
        '&.MuiTypography-body1': {
            fontWeight: 700,
            fontSize: 17,
            lineHeight: 1.13,
            color: Palette.white
        }
    },
   
};

export default MuiCheckbox;
