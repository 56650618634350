import {
    getListPI, piSend, piPrint, reserveCar, cancelReserveCar, getTradeValue, getSimulatorCars, simulateFinanceCar,
    searchClient, createProposal, searchCarStatus, getCars, getAuthenticationExtra, getKPIList,
    getEventsSalesman, setEditEvent, getSalerAvailableSlots
} from '../../../../service/api';

import { usePushAlert } from '../../../../components/PushAlert';

export const UsadosService = () => {

    const { triggerPushAlert } = usePushAlert();

    // DONE ✅
    const GetVehicleList = async (params) => {
        try {
            const { data } = await getCars(params);
            if (data?.getCarsWithFilter?.code !== "0") {
                if(data?.getCarsWithFilter?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.getCarsWithFilter?.message });
                    return null;
                } else {
                    throw new Error(data?.getCarsWithFilter);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.', });
                    break;
            }
        }
    }

    // DONE ✅
    const GetAuthentication = async (params) => {
        try {
            const { data } = await getAuthenticationExtra(params);
            if (data?.authenticationExtraDirectLambda?.code !== "0") {
                if(data?.authenticationExtraDirectLambda?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.authenticationExtraDirectLambda?.message });
                    return null;
                } else {
                    throw new Error(data?.authenticationExtraDirectLambda);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const GetProposalList = async (params) => {
        try {
            const { data } = await getListPI(params);
            if (data?.listPI?.code !== "0") {
                if(data?.listPI?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.listPI?.message });
                    return null;
                } else {
                    throw new Error(data?.listPI);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const PrintProposal = async (params) => {
        try {
            const { data } = await piPrint(params);
            if (data?.printPI?.code !== "0") {
                if(data?.printPI?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.printPI?.message });
                    return null;
                } else {
                    throw new Error(data?.printPI);
                }
            }
            triggerPushAlert({ title: 'Sucesso', description: 'Pedido realizado com sucesso.', icon: 'l' });
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const SendProposal = async (params) => {
        try {
            const { data } = await piSend(params);
            if (data?.sendPI?.code !== "0") {
                if(data?.sendPI?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.sendPI?.message });
                    return null;
                } else {
                    throw new Error(data?.sendPI);
                }
            } else {
                triggerPushAlert({ title: 'Sucesso', description: 'Pedido realizado com sucesso.', icon: 'l' });
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const ReserveVehicle = async (params) => {
        try {
            const { data } = await reserveCar(params);
            if (data?.reserveVehicle?.code !== "0") {
                if(data?.reserveVehicle?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.reserveVehicle?.message });
                    return null;
                } else {
                    throw new Error(data?.reserveVehicle);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: error?.message ? error?.message : 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE
    const CancelReserveVehicle = async (params) => {
        try {
            const { data } = await cancelReserveCar(params);
            if (data?.cancelReserveVehicle?.code !== "0") {
                if (data?.cancelReserveVehicle?.message) {
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.cancelReserveVehicle?.message });
                    return null;
                } else {
                    throw new Error(data?.cancelReserveVehicle);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: error?.message ? error?.message : 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }


    // DONE ✅
    const GetTradePrice = async (params) => {
        try {
            const { data } = await getTradeValue(params);
            if (data?.getTradePrice?.code !== "0") {
                if(data?.getTradePrice?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.getTradePrice?.message });
                    return null;
                } else {
                    throw new Error(data?.getTradePrice);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const GetSimulatorUsedCars = async (params) => {
        try {
            const { data } = await getSimulatorCars(params);
            if (data?.getSimulatorUsedCars?.code !== "0") {
                if(data?.getSimulatorUsedCars?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.getSimulatorUsedCars?.message });
                    return null;
                } else {
                    throw new Error(data?.getSimulatorUsedCars);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const GetSimulateFinanceUsedCars = async (params) => {
        try {
            const { data } = await simulateFinanceCar(params);
            if (data?.simulateFinanceUsedCars?.code !== "0") {
                if(data?.simulateFinanceUsedCars?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.simulateFinanceUsedCars?.message });
                    return null;
                } else {
                    throw new Error(data?.simulateFinanceUsedCars);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const SearchClients = async (query, dealerId, login) => {
        try {
            const { data } = await searchClient(query, dealerId, login);
            if (data?.searchClients?.code !== "0") {
                if(data?.searchClients?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.searchClients?.message });
                    return null;
                } else {
                    throw new Error(data?.searchClients);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const CreateNewProposal = async (proposal, login) => {
        try {
            const { data } = await createProposal(proposal, login);
            if (data?.createPI?.code !== "0") {
                if(data?.createPI?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.createPI?.message });
                    return null;
                } else {
                    throw new Error(data?.createPI);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const GetVehicleReservationStatus = async (query) => {
        try {
            const { data } = await searchCarStatus(query);
            /*if (data?.searchStatus?.code !== "0") {
                if(data?.searchStatus?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.searchStatus?.message });
                    return null;
                } else {
                    throw new Error(data?.searchStatus);
                }
            }*/
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    // DONE ✅
    const GetKPIList = async (query) => {
        try {
            const { data } = await getKPIList(query);
            if (data?.listKPI?.code !== "0") {
                if(data?.listKPI?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.listKPI?.message });
                    return null;
                } else {
                    throw new Error(data?.listKPI);
                }
            }
            return data.listKPI
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    const GetSalesManSchedule = async (query) => {
        try {
            const { data } = await getEventsSalesman(query);
            if (data?.getEventsByIdSalesman?.code !== "0") {
                if(data?.getEventsByIdSalesman?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.getEventsByIdSalesman?.message });
                    return null;
                } else {
                    throw new Error(data?.getEventsByIdSalesman);
                }
            }
            return data?.getEventsByIdSalesman?.eventsInfo
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    const SetEvent = async (query) => {
        try {
            const { data } = await setEditEvent(query);
            if (data?.editEvent?.code !== "0") {
                if(data?.editEvent?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.editEvent?.message });
                    return null;
                } else {
                    throw new Error(data?.editEvent);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }

    const getAvailableSlots = async (query) => {
        try {
            const { data } = await getSalerAvailableSlots(query);
            if (data?.getAvailableSlotsByIdSalesman?.code !== "0") {
                if(data?.getAvailableSlotsByIdSalesman?.message){
                    triggerPushAlert({ title: 'Ocorreu um erro', description: data?.getAvailableSlotsByIdSalesman?.message });
                    return null;
                } else {
                    throw new Error(data?.getAvailableSlotsByIdSalesman);
                }
            }
            return data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerPushAlert({ title: 'Sem internet', description: 'Verifique as suas definições de ligação à internet.', icon: '' })
                    break;
                default:
                    triggerPushAlert({ title: 'Ocorreu um erro', description: 'Não é possível satisfazer o pedido.' });
                    break;
            }
        }
    }



    return {
        GetProposalList, PrintProposal, SendProposal, ReserveVehicle,
        GetTradePrice, GetSimulatorUsedCars, GetSimulateFinanceUsedCars,
        SearchClients, CreateNewProposal, GetVehicleReservationStatus,
        GetVehicleList, GetAuthentication, GetKPIList, GetSalesManSchedule, SetEvent,
        getAvailableSlots, CancelReserveVehicle
    }
}